import { getFieldsByPrefix } from '../utils';
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
export const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

/**
 * For create and update only, convert uploaded image in base 64 and attach it to
 * the `file` sent property, with `src` and `title` attributes.
 */
export const addUploadFeature = async params => {
  const { data = {} } = params;
  const allAttachFieldsObj = getFieldsByPrefix(data, 'attachments');
  const allAttachFieldsKeys = Object.keys(allAttachFieldsObj);
  if (allAttachFieldsKeys.length) {
    const transformedFields = await allAttachFieldsKeys.reduce(
      async (previousPromise, cur) => {
        const acc = await previousPromise;
        let attachments = allAttachFieldsObj[cur];
        if (!Array.isArray(attachments)) {
          // if multiple is set to false on file input it won't be array
          attachments = [attachments];
        }
        // only freshly dropped files are instance of File
        const formerFiles = attachments.filter(
          a => !(a.rawFile instanceof File),
        );
        const newFiles = attachments.filter(a => a.rawFile instanceof File);
        const base64Files = await Promise.all(
          newFiles.map(convertFileToBase64),
        );
        const transformedNewFiles = base64Files.map((file64, index) => ({
          src: file64,
          title: `${newFiles[index].title}`,
        }));
        acc[cur] = [...transformedNewFiles, ...formerFiles];
        return acc;
      },
      {},
    );
    return {
      ...params,
      data: {
        ...data,
        ...transformedFields,
      },
    };
  }
  return Promise.resolve(params);
};
