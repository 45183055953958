import React, { Fragment, useState, useEffect } from 'react';
import {
  useRefresh,
  TextField,
  FunctionField,
  TopToolbar,
  Pagination,
  useRedirect,
  List,
  DateField,
} from 'react-admin';
import startCase from 'lodash/startCase';
import { IconButton, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { Datagrid } from '../../components/common';
import { NewEvent, NotesModal } from '../../components/common/modals';
import { CustomButton } from '../../design/material-ui';
import { useStyles } from '../../resources/payers/payers.styles';
import { AddIcon, EditIcon, CopyIcon } from '../../design';
import { limitStringSize } from '../../utils';
import { ListSearch } from '../../components/common/ListSearch';
const TaskBuilderActions = ({ setTaskStatus, setSearchText }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Task builder</span>
        <div style={{ display: 'flex' }}>
          <ListSearch doSearch={setSearchText} placeholder='Search user name' />
          <div style={{ marginTop: 10 }}>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
            />
          </div>
        </div>
        <TaskTabs setTaskStatus={setTaskStatus} classes={classes} />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='taskBuilderForm'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const TaskTabs = ({ setTaskStatus, classes }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTaskStatus(newValue);
  };
  return (
    <Paper className={classes.tabContainer} square>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={handleChange}
        aria-label='disabled tabs example'
      >
        <Tab
          classes={{ root: classes.tab, selected: classes.selectedTab }}
          label='Active'
        />
        <Tab
          classes={{ root: classes.tab, selected: classes.selectedTab }}
          label='Inactive'
        />
      </Tabs>
    </Paper>
  );
};

export const TaskBuilderList = props => {
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [taskRecord, setTaskRecord] = useState();
  const [copyRecord, setCopyRecord] = useState();
  const [taskStatus, setTaskStatus] = useState(0);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const redirect = useRedirect();
  const { permissions = '' } = props;
  useEffect(() => {
    // see comment on case_communication
    if (taskRecord || copyRecord) {
      setEventModal(true);
    }
  }, [taskRecord, copyRecord]);
  const handleModalClose = () => {
    setEventModal(false);
    setTaskRecord(undefined);
    setCopyRecord(undefined);
  };

  useEffect(() => {
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);

  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    // needed if the user selects the same note again
    // since it only it will not show unless selectedNote changes.
    setSelectedNote('');
  };

  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (userPermissions.indexOf('admin') === -1) {
    redirect('/');
    return null;
  }

  return (
    <Fragment>
      <List
        {...props}
        empty={false}
        exporter={false}
        bulkActionButtons={false}
        className={classes.list}
        actions={
          <TaskBuilderActions
            setTaskStatus={setTaskStatus}
            setSearchText={setSearchText}
          />
        }
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
        filter={{ is_active: taskStatus === 0, q: searchText }}
      >
        <Datagrid>
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return (
                <div style={{ marginTop: 10, width: 100 }}>
                  <CustomButton
                    Icon={CopyIcon}
                    color='#1061A0'
                    backgroundColor='#EFF4FB'
                    label='Copy task'
                    type='button'
                    variant='text'
                    size='small'
                    onClick={() => setCopyRecord(record)}
                  />
                </div>
              );
            }}
          />
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setTaskRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              );
            }}
          />
          <TextField
            source='title'
            label='Task name'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='name'
            label='Task type'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { name } = record;
              return name ? 'System-created' : 'User-created';
            }}
          />
          <FunctionField
            source='event'
            label='Action'
            sortable={false}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { event_type } = record;
              return event_type?.name;
            }}
          />
          <FunctionField
            sortable={false}
            source='facilities'
            label='Facilities'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { facilities } = record;
              const maxLength = 80;
              const truncated = limitStringSize(facilities, maxLength, true);
              return facilities && facilities.length > maxLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setSelectedNote(facilities)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <TextField
            source='facility_groups'
            label='Groups'
            sortable={false}
            headerClassName={classes.listItemLabel}
          />

          <FunctionField
            source='states'
            label='States'
            sortable={false}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { states } = record;
              return states?.join(', ');
            }}
          />
          <TextField
            source='payers'
            label='Payers'
            sortable={false}
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='lines_of_business'
            label='Lines of business'
            sortable={false}
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='rate_types'
            label='Rate types'
            sortable={false}
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='is_navihealth'
            label='NaviHealth contracts'
            render={record => {
              const { is_navihealth } = record;
              return !!is_navihealth ? 'Yes' : '';
            }}
          />
          <FunctionField
            source='role'
            label='Assigned to'
            sortable={false}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { role } = record;
              return role?.role ? startCase(role.role?.replace(/_/g, ' ')) : '';
            }}
          />
          <TextField
            source='additional_days'
            label='Days due from trigger'
            headerClassName={classes.listItemLabel}
            type='number'
          />
          <TextField
            source='description'
            label='Task description'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='autoTaskSettingsCreatedBy.name'
            label='Created by'
            render={record => {
              const { autoTaskSettingsCreatedBy } = record;
              return autoTaskSettingsCreatedBy?.name;
            }}
          />
          <DateField source='created_at' label='Created at' showTime />
          <FunctionField
            source='autoTaskSettingsUpdatedBy.name'
            label='Last edited by'
            render={record => {
              const { autoTaskSettingsUpdatedBy } = record;
              return autoTaskSettingsUpdatedBy?.name;
            }}
          />
          <DateField source='updated_at' label='Last edited at' showTime />
        </Datagrid>
      </List>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          form='taskBuilderForm'
          refresh={refresh}
          isEdit={!!taskRecord}
          isCopy={!!copyRecord}
          record={taskRecord || copyRecord}
        />
      )}
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
          title='Facilities'
        />
      )}
    </Fragment>
  );
};

export const taskBuilderResource = {
  name: 'task-builder',
  list: TaskBuilderList,
  icon: AddIcon,
  options: { label: 'Task builder' },
};
