import { uiTypes } from '../actions/ui';
import get from 'lodash/get';
const initialState = {
  sidebarCases: {},
  newFacility: {},
  otherCases: false,
  redirect: false,
  caseTasksCount: 0,
  faxInboxCount: 0,
  residentCaseCount: 0,
  showFab: true,
};
export function uiReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case uiTypes.GET_SIDEBAR_CASES:
      return {
        ...state,
        sidebarCases: action.payload,
      };
    case uiTypes.UPDATE_FACILITY_LIST:
      return {
        ...state,
        newFacility: { ...action.payload },
      };
    case uiTypes.CLEAR_REDIRECT:
      return {
        ...state,
        redirect: false,
      };
    case uiTypes.GET_CASE_TASKS_COUNT:
      return {
        ...state,
        caseTasksCount: action.payload,
      };
    case uiTypes.GET_FAX_INBOX_COUNT:
      return {
        ...state,
        faxInboxCount: action.payload,
      };
    case uiTypes.GET_RESIDENT_CASE_COUNT:
      return {
        ...state,
        residentCaseCount: action.payload,
      };
    case uiTypes.UPDATE_SHOW_FAB:
      return {
        ...state,
        showFab: action.payload,
      };
    case 'RA/FETCH_ERROR':
      const { error } = action;
      if (get(error, 'body.message', '').startsWith('Data not found!')) {
        return {
          ...state,
          redirect: true,
        };
      }
      return {
        ...state,
      };
    default:
      return state;
  }
}
