import React, { useState, useEffect } from 'react';

import { Form } from 'react-final-form';
import {
  useDataProvider,
  useRefresh,
  useNotify,
  cacheDataProviderProxy,
  required,
} from 'react-admin';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { useStyles } from '../modal.styles';
import { handleNumbers, getDateInputValue } from '.';
import {
  FormTextField,
  SwitchInput,
  ReferenceInput,
  CheckboxInput,
  SelectComponent,
} from '../modalForms';
import { SaveButton } from '../../../../design';
import { TrackUser } from '../../../../components/wrappers';
import { ToggleCaseManagerButton } from '../../../../components/common';
import { constants } from '../../../../utils';
const { roles, events: eventTypes, states } = constants;

export const TaskBuilderForm = ({
  record = {},
  handleClose,
  isEdit,
  isCopy,
}) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    additional_days: 0,
    is_active: true,
    role: roles.CASE_MANAGER,
    facility_ids: [],
    group_ids: [],
    states: [],
    payer_ids: [],
    lob_ids: [],
    authorization_type_ids: [],
  });
  const roleTypes = [
    { id: 3, name: 'Case Manager' },
    { id: 4, name: 'Quality Reviewer' },
    { id: 5, name: 'Team Leader' },
    { id: 6, name: 'Backend Reviewer' },
  ];
  const [facilitySelection, setFacilitySelection] = useState('facility');
  const handleFacilitySelection = value => {
    setFacilitySelection(value);
    if (value === 'facility') {
      setFormData(f => ({ ...f, group_ids: [], states: [] }));
    } else if (value === 'group') {
      setFormData(f => ({
        ...f,
        facility_ids: [],
        states:
          (isEdit || isCopy) && record.states === formData.states
            ? formData.states
            : [], //don't clear out states on initial edit render
      }));
    } else if (value === 'state') {
      setFormData(f => ({
        ...f,
        facility_ids: [],
        group_ids: [],
        states:
          (isEdit || isCopy) && record.states === formData.states
            ? formData.states
            : [], //don't clear out states on initial edit render
      }));
    }
  };
  const isSystemCreatedTask = !!formData.name;
  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('task-builder', {
          id: record.id,
          data: {
            ...value,
          },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('task-builder', {
          data: {
            ...value,
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if ((isEdit || isCopy) && record) {
      if (record.states?.length && !record.group_ids?.length) {
        setFacilitySelection('state');
      } else if (record.group_ids?.length) {
        setFacilitySelection('group');
      }
      if (isEdit)
        setFormData(f => ({
          ...f,
          ...record,
          states: record.states || [],
        }));
      else if (isCopy) {
        const { id, ...rest } = record;
        setFormData(f => ({
          ...f,
          ...rest,
          states: record.states || [],
        }));
      }
    }
  }, [isEdit, isCopy, record]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...formData,
      }}
      render={({ submitError, handleSubmit, submitting, pristine, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormTextField
                name='title'
                customOnChange={customOnChange}
                label='Task name'
                fullWidth
                required
                validate={required()}
                disabled={isSystemCreatedTask}
              />
              {!isSystemCreatedTask && (
                <>
                  <div style={{ paddingTop: 15 }}>
                    <Typography variant='h7' color='secondary'>
                      Action
                    </Typography>
                    <ReferenceInput
                      reference='events/list'
                      customOnChange={customOnChange}
                      className={classes.input}
                      fullWidth
                      select
                      name='event_type_id'
                      label='Event'
                      required
                      validate={required()}
                    />
                    <Typography variant='caption' color='error'>
                      Note: Tasks will be only triggered on the initial creation
                      of the event, not upon editing.
                    </Typography>
                  </div>
                  <div style={{ paddingTop: 15 }}>
                    <Typography variant='h7' color='secondary'>
                      Select Criteria
                    </Typography>
                  </div>
                  <div style={{ paddingTop: 15 }}>
                    <FacilityGroupFilter
                      customOnChange={customOnChange}
                      setFormData={setFormData}
                      classes={classes}
                      facilitySelection={facilitySelection}
                      handleFacilitySelection={handleFacilitySelection}
                    />
                    <ReferenceInput
                      reference='payers/list'
                      perPage={1000}
                      customOnChange={customOnChange}
                      name='payer_ids'
                      label='Payers'
                      fullWidth
                      viewAll
                      multiple
                    />
                    <ReferenceInput
                      reference='lob/list'
                      customOnChange={customOnChange}
                      name='lob_ids'
                      label='Lines of business'
                      multiple
                      fullWidth
                    />
                    {![
                      eventTypes.DISCHARGE,
                      eventTypes.PLANNED_DISCHARGE,
                    ].includes(formData.event_type_id) && (
                      <ReferenceInput
                        reference='auth-types/list'
                        customOnChange={customOnChange}
                        name='authorization_type_ids'
                        label='Rate types'
                        multiple
                        options={{
                          filter: {
                            rate: 1,
                            exclude_part_b: false,
                          },
                        }}
                        fullWidth
                      />
                    )}
                    <CheckboxInput
                      name='is_navihealth'
                      customOnChange={customOnChange}
                      label='NaviHealth contracts'
                      checked={values.is_navihealth}
                    />
                  </div>
                  <div style={{ paddingTop: 15 }}>
                    <Typography variant='h7' color='secondary'>
                      Assign to
                    </Typography>
                    <SelectComponent
                      customOnChange={customOnChange}
                      name='role_id'
                      label='Roles'
                      validate={required()}
                      required
                      choices={roleTypes}
                      fullWidth
                    />
                  </div>
                </>
              )}
              <div style={{ paddingTop: 10 }}>
                <Typography variant='h7' color='secondary'>
                  Days due from trigger
                </Typography>
                <div
                  className={classes.inputContainerWrap}
                  style={{ marginTop: 5 }}
                >
                  <div style={{ display: 'flex' }}>
                    <FormTextField
                      name='additional_days'
                      customOnChange={customOnChange}
                      label='Days'
                      type='number'
                      required
                      validate={required()}
                    />
                  </div>
                </div>
              </div>
              <div>
                <SwitchInput
                  name='is_active'
                  customOnChange={customOnChange}
                  label={formData.is_active ? 'Active' : 'Inactive'}
                  checked={formData.is_active}
                />
                {!formData.is_active && (
                  <FormTextField
                    name='inactive_date'
                    className={classes.input}
                    label='Inactive date'
                    style={{ marginRight: 0 }}
                    customOnChange={customOnChange}
                    type='date'
                    required
                    validate={required()}
                    fullWidth
                  />
                )}
              </div>
              {!isSystemCreatedTask && (
                <FormTextField
                  name='description'
                  customOnChange={customOnChange}
                  label='Task description'
                  multiline
                  fullWidth
                />
              )}
              <div style={{ display: 'flex' }}>
                {formData['autoTaskSettingsUpdatedBy.first_name'] && (
                  <div className={classes.subtitle} style={{ display: 'flex' }}>
                    <span>
                      Last modified by{' '}
                      {formData['autoTaskSettingsUpdatedBy.first_name']}{' '}
                      {formData['autoTaskSettingsUpdatedBy.last_name']} at
                    </span>
                    <span style={{ paddingLeft: 5 }}>
                      <TrackUser timestamp={formData.updated_at} />
                    </span>
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions
              className={classes.padding16}
              style={{ paddingBottom: 16 }}
            >
              <SaveButton
                // onClick={handleClose}
                className={classes.saveButton}
                disabled={submitting}
                type='submit'
              />
            </DialogActions>
          </form>
        );
      }}
    />
  );
};
const FacilityGroupFilter = ({
  customOnChange,
  setFormData,
  classes,
  facilitySelection,
  handleFacilitySelection,
}) => {
  return (
    <div>
      <ToggleCaseManagerButton
        initialDivision={facilitySelection}
        setDivision={handleFacilitySelection}
        showBoth={true}
        showBothLabel='States'
        showBothOption='state'
      />
      {facilitySelection === 'facility' && (
        <ReferenceInput
          reference='facilities/list'
          customOnChange={customOnChange}
          setFormData={setFormData}
          name='facility_ids'
          label='Facilities'
          required
          multiple
          fullWidth
          perPage={500}
          selectAll
          options={{
            filter: {
              bulk: true,
              active: true,
              inactive: false,
            },
            sort: {
              field: 'name',
              order: 'ASC',
            },
          }}
          className={classes.label}
          style={{
            marginTop: 20,
          }}
        />
      )}
      {facilitySelection === 'group' && (
        <>
          <ReferenceInput
            reference='facility-groups'
            customOnChange={customOnChange}
            setFormData={setFormData}
            name='group_ids'
            label='Facility groups'
            required
            multiple
            fullWidth
            perPage={500}
            selectAll
            options={{
              filter: {},
              sort: {
                field: 'name',
                order: 'ASC',
              },
            }}
            className={classes.label}
            style={{ marginTop: 20 }}
          />
          <SelectComponent
            customOnChange={customOnChange}
            label='States'
            name='states'
            choices={states}
            multiple
            fullWidth
          />
        </>
      )}
      {facilitySelection === 'state' && (
        <SelectComponent
          customOnChange={customOnChange}
          label='States'
          validate={required()}
          required
          name='states'
          choices={states}
          multiple
          fullWidth
          style={{ marginTop: 20 }}
        />
      )}
    </div>
  );
};
