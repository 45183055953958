import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  IconButton,
  Chip,
  Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { usePermissions } from 'react-admin';
import { Dashboard } from '../../../pages';
import { required } from 'react-admin';
import { GroupFacilityToggle } from '../groupFacilityToggle';
import { useStyles } from '../../../pages/dashboard/dashboard.styles';
import { ReferenceInput, AutocompleteInput } from './modalForms';
import { CloseIcon } from '../../../design';

export function DashboardForm({ open, handleClose, facilityIds }) {
  const classes = useStyles();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [formData, setFormData] = useState({
    facility_ids: [facilityIds],
    group_id: [],
  });
  const [selectGroup, selectGroupToggle] = useState(false);
  const [otherUser, setOtherUser] = useState(false);
  const [facilities, setFacilities] = useState([]);

  const handleDelete = useCallback(value => {
    setFacilities(cur => cur.filter(c => c !== value));
    setFormData(f => ({
      ...f,
      facility_ids: f.facility_ids.filter(f => f !== value.value),
    }));
  }, []);
  const onClick = useCallback(
    value => {
      value[0].name = value[0].name.split(' - ')[0].split(' , ')[0];
      setFacilities(curFacilities => [...curFacilities, ...value]);
      const facilityIds = [...facilities.map(row => row.value), value[0].value];
      setFormData(f => ({ ...f, facility_ids: facilityIds }));
    },
    [setFacilities, facilities],
  );

  useEffect(() => {
    setFacilities([]);
    selectGroup
      ? setFormData(f => ({ ...f, facility_ids: [], group_id: [] }))
      : setFormData(f => ({ ...f, group_id: null }));
  }, [selectGroup]);

  useEffect(() => {
    if (otherUser) {
      setFacilities([]);
      setFormData(f => ({
        ...f,
        facility_ids: [],
        group_id: [],
        user_id: null,
        selectGroup: false,
      }));
      selectGroupToggle(false);
    } else {
      setFacilities([]);
      setFormData(f => ({
        ...f,
        facility_ids: [],
        group_id: null,
        user_id: undefined,
      }));
    }
  }, [otherUser]);
  const onSubmit = value => {};
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (name === 'user_id') {
      setFacilities([]);
      setFormData({
        ...formData,
        [name]: value,
        facility_ids: [],
        group_ids: [],
      });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xl' fullWidth>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogActions>
                <IconButton
                  onClick={handleClose}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </DialogActions>
              <DialogContent>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                  }}
                >
                  {(userPermissions.indexOf('admin') > -1 ||
                    userPermissions.indexOf('supervisor') > -1 ||
                    userPermissions.indexOf('covering_user') > -1) && (
                    <FormControlLabel
                      label={
                        otherUser
                          ? userPermissions.indexOf('covering_user') > -1
                            ? 'Covered Dashboards'
                            : 'User Dashboards'
                          : 'My Dashboards'
                      }
                      control={
                        <Switch
                          className={classes.dashboard}
                          checked={otherUser}
                          onChange={() => setOtherUser(!otherUser)}
                        />
                      }
                    />
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                  }}
                >
                  <div
                    style={{
                      width: 220,
                    }}
                  >
                    {otherUser && (
                      <div>
                        <AutocompleteInput
                          reference='facility-users/list'
                          customOnChange={customOnChange}
                          name='user_id'
                          label='Case manager'
                          required
                          fullWidth
                          style={{ marginRight: 0, marginBottom: 0 }}
                          options={{
                            filter: {
                              all_facilities: true,
                              is_active: true,
                              covering_user:
                                userPermissions.indexOf('covering_user') > -1,
                            },
                            sort: { field: 'last_name', order: 'ASC' },
                          }}
                          viewAll
                          openOnFocus
                          autocompleteProps={{ openOnFocus: true }}
                        />
                      </div>
                    )}

                    <div
                      style={{
                        width: '100%',
                      }}
                    ></div>

                    {userPermissions.indexOf('admin') > -1 && !otherUser && (
                      <GroupFacilityToggle
                        selectGroup={selectGroup}
                        selectGroupToggle={selectGroupToggle}
                        facilityLabel='Facilities'
                        groupLabel='Facility Groups'
                      />
                    )}
                    {userPermissions.indexOf('admin') > -1 &&
                      selectGroup &&
                      !otherUser && (
                        <ReferenceInput
                          style={{
                            width: 220,
                          }}
                          reference='facility-groups'
                          customOnChange={customOnChange}
                          name='group_id'
                          label='Select group'
                          validate={required()}
                          setFormData={setFormData}
                          required
                          perPage={500}
                          selectAll
                          options={{
                            filter: {},
                            sort: { field: 'name', order: 'ASC' },
                          }}
                        />
                      )}
                    {!selectGroup && (
                      <div
                        style={{
                          width: 220,
                        }}
                      >
                        <AutocompleteInput
                          key={formData.user_id}
                          trimLength={1}
                          reference='facilities/list'
                          customOnChange={customOnChange}
                          name='facility_ids'
                          label='Select facility'
                          validate={required()}
                          required={!facilities.length}
                          multiSelect
                          setFormData={setFormData}
                          perPage={500}
                          fullWidth
                          disabled={otherUser && !values.user_id}
                          options={{
                            filter: {
                              caseManagerId: formData.user_id,
                              dashboard: true,
                            },
                            sort: { field: 'name', order: 'ASC' },
                          }}
                          viewAll
                          openOnFocus
                          autocompleteProps={{ openOnFocus: true }}
                          parentOnClick={onClick}
                          getName
                          highlightItems={facilities?.map(value => value.value)}
                          paperClassName={classes.paper}
                        />
                        {!!facilities.length && (
                          <div style={{ margin: 10, display: 'flex' }}>
                            {facilities.map(row => {
                              return (
                                <Chip
                                  key={row.value}
                                  label={
                                    <Typography
                                      style={{
                                        whiteSpace: 'normal',
                                        fontSize: 13,
                                      }}
                                    >
                                      {row.name}
                                    </Typography>
                                  }
                                  onDelete={() => handleDelete(row)}
                                  className={classes.chip}
                                  style={{
                                    height: '100%',
                                  }}
                                  size='small'
                                  disabled={!facilities}
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {!!formData.facility_ids?.length ||
                (Array.isArray(formData.group_id)
                  ? !!formData.group_id?.length
                  : formData.group_id !== null) ? (
                  <Dashboard
                    viewMultiple={true}
                    facility_ids={formData.facility_ids}
                    group_id={formData.group_id}
                    user_id={formData.user_id}
                  />
                ) : (
                  <div style={{ height: 600 }}></div>
                )}
              </DialogContent>
            </form>
          );
        }}
      />
    </Dialog>
  );
}
