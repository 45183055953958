import React, { useRef, useEffect } from 'react';

import {
  List,
  Datagrid,
  TextField,
  // FunctionField,
  // BooleanField,
  useRefresh,
  Pagination,
  // NumberField,
  // ChipField,
  // Filter,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Toolbar, Button, Link } from '@material-ui/core';
import { DateField } from '../../components/common';
import { LaunchIcon } from '../../design';
import { useStyles } from './progress.styles';

const CaseLevelActions = ({ integration_id }) => {
  const classes = useStyles();
  return (
    <Toolbar
      className={classes.listActionWrapper}
      disableGutters
      style={{ minHeight: '100%' }}
    >
      <div className={classes.listActionWrapper} style={{ padding: 24 }}>
        <span />
        {!!integration_id && (
          <Button
            color='primary'
            component={Link}
            underline='none'
            href={`https://usnpint.pointclickcare.com/clinical/meddiag/medDiagChart.xhtml?ESOLclientid=${integration_id}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ textTransform: 'none' }}
            startIcon={<LaunchIcon />}
          >
            View Diagnosis in PointClickCare
          </Button>
        )}
      </div>
    </Toolbar>
  );
};

export function PccDiagnosisList({ staticContext, integration_id, ..._props }) {
  const { formBasePath, caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  return (
    <List
      empty={false}
      {...props}
      exporter={false}
      bulkActionButtons={false}
      className={classes.list}
      actions={<CaseLevelActions integration_id={integration_id} />}
      pagination={
        <Pagination
          rowsPerPageOptions={[]}
          rowsPerPage={1000}
          style={{
            display: 'flex',
          }}
        />
      }
    >
      <Datagrid>
        <TextField sortable={true} label='ICD10' source='icd10' />
        <TextField
          sortable={true}
          label='ICD10 Description'
          source='icd10Description'
        />
        <DateField sortable={true} label='Date' source='onsetDate' />
        <TextField sortable={false} label='Rank' source='rankDescription' />
        <TextField
          sortable={false}
          label='Classification'
          source='classificationDescription'
        />
        <TextField sortable={true} label='Status' source='clinicalStatus' />
      </Datagrid>
    </List>
  );
}

export const diagnosisResource = {
  name: 'clinical/diagnoses',
  list: PccDiagnosisList,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'clinical/diagnoses',
        hasList: true,
        hasEdit: !!diagnosisResource.edit,
        hasShow: !!diagnosisResource.show,
        hasCreate: !!diagnosisResource.create,
      };
  const updatedProps = {
    ...props,
    basePath,
    caseId,
    ...resource,
  };
  return updatedProps;
}
