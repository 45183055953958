import { makeStyles } from '@material-ui/core';
import { styles, colors } from '../design';
import { style } from '../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 0,
  },
  title: {
    fontStyle: 'Nunito Sans',
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.black,
    paddingLeft: 12,
    marginBottom: 10,
  },
  fileIcons: {
    color: '#E32939',
  },
  viewIcon: {
    color: '#5C738E',
  },
  emailLogBtn: {
    marginRight: 10,
    textTransform: 'capitalize',
    fontSize: '1rem',
    color: '#1061A0',
    backgroundColor: '#EFF4FB',
  },
  attText: {
    overflowY: 'hidden',
    padding: '4px 4px 4px 8px',
    cursor: 'pointer',
    color: 'rgb(0, 145, 255)',
  },
  docsList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  viewEmail: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  resend: {
    textTransform: 'capitalize',
    marginLeft: 5,
  },
}));
