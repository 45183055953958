import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { isFuture, isValid } from 'date-fns';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormGroup,
  Button,
} from '@material-ui/core';
import { stringify } from 'query-string';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  FileInput,
  handleNumbers,
  getDateInputValue,
  RadioInput,
  CheckboxInput,
  // SelectComponent,
  composeValidators,
  validateDate,
  minReasonLength,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { useGetCaseId } from '../../../../hooks';
import { uiActions } from '../../../../state/actions';
import { SaveButton, RemoveIcon, AddIcon } from '../../../../design';
import { constants, getBaseReportUrl } from '../../../../utils';

const RESOLUTION_NEW_PAYER_ID = 1;
const RESOLUTION_DISCHARGED_ID = 2;
const RESOLUTION_AUTH_EXTENDED_ID = 3;
const APPEAL_DENIAL_OVERTURNED_ID = 3;
const RESOLUTION_RESCINDED_ID = 5;
const { appeal_type, denial_type } = constants;

export const DenialForm = ({
  isEdit,
  caseId,
  record = {},
  handleClose,
  refresh,
  changeForm,
}) => {
  const baseUrl = getBaseReportUrl();
  const facilityId = useSelector(state => state.facility.id);
  const token = localStorage.getItem('token');

  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  const notify = useNotify();
  const [formData, setFormData] = useState({
    case_id: caseId,
    is_appealing: '',
    // letter_received: null,
  });
  const [disableAppealToggle, setDisableAppealToggle] = useState(false);
  const [createAppeal, setCreateAppeal] = useState(false);
  const [create2ndAppeal, setCreate2ndAppeal] = useState(false);
  const [create3rdAppeal, setCreate3rdAppeal] = useState(false);
  const [create4thAppeal, setCreate4thAppeal] = useState(false);
  const [create5thAppeal, setCreate5thAppeal] = useState(false);
  const [submitAndPayerChange, setSubmitAndPayerChange] = useState(false);
  const [submitAndExtendAuth, setSubmitAndExtendAuth] = useState(false);
  const [submitAndNewAuth, setSubmitAndNewAuth] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [downloadNOMNC, setDownloadNOMNC] = useState(false);
  const denialOverturned =
    formData.appeal_status_id_l1 === APPEAL_DENIAL_OVERTURNED_ID ||
    formData.appeal_status_id_l2 === APPEAL_DENIAL_OVERTURNED_ID ||
    formData.appeal_status_id_l3 === APPEAL_DENIAL_OVERTURNED_ID ||
    formData.appeal_status_id_l4 === APPEAL_DENIAL_OVERTURNED_ID ||
    formData.appeal_status_id_l5 === APPEAL_DENIAL_OVERTURNED_ID;

  const removeAppeal = appealNum => {
    appealNum === 1 && setCreateAppeal(false);
    appealNum === 2 && setCreate2ndAppeal(false);
    appealNum === 3 && setCreate3rdAppeal(false);
    appealNum === 4 && setCreate4thAppeal(false);
    appealNum === 5 && setCreate5thAppeal(false);
    setFormData(f => ({
      ...f,
      [`appeal_type_id_l${appealNum}`]: undefined,
      [`date_sent_l${appealNum}`]: undefined,
      [`appeal_status_id_l${appealNum}`]: [],
      [`attachments_l${appealNum}`]: [],
    }));
  };
  useEffect(() => {
    if (isEdit && record) {
      const fields = sanitizeRecord(record, {
        setCreateAppeal,
        setCreate2ndAppeal,
        setCreate3rdAppeal,
        setCreate4thAppeal,
        setCreate5thAppeal,
        setDisableAppealToggle,
      });
      if (denialOverturned) fields.resolution_id = RESOLUTION_AUTH_EXTENDED_ID;
      setFormData(f => ({
        ...f,
        ...fields,
      }));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, record]);

  const updateTasksCount = useCallback(() => {
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }, [caseIdFromUrl, dispatch]);

  const confirmSubmit = value => {
    onSubmit(value);
  };
  function onSubmit(value) {
    setSubmitting(true);
    const { signature_required: _signature_required } = value;
    const signature_required = parseInt(_signature_required) === 1;

    if (isEdit) {
      return dataProvider
        .update('denials', {
          id: record.id,
          data: {
            ...value,
            signature_required,
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          setSubmitting(false);
          if (data.already_discharged)
            notify(
              'Resident already discharged, previous data will be used',
              'warning',
              {},
              false,
              15000,
            );
          else notify('form.updated');
          if (submitAndExtendAuth) {
            changeForm('authorization', {
              caseId: value.case_id,
              authorizationId: value.authorization_id,
              isEdit: true,
              record: {},
            });
          } else if (submitAndNewAuth) {
            changeForm('authorization', {
              caseId: value.case_id,
              authorizationId: value.authorization_id,
              isEdit: false,
              record: {},
            });
          } else if (submitAndPayerChange) {
            changeForm('casePayer', {
              caseId: value.case_id,
              isEdit: false,
              record: {},
            });
          } else {
            handleClose();
          }
          updateTasksCount();
          typeof refresh === 'function' && refresh();

          if (downloadNOMNC) {
            window.open(
              `${baseUrl}/download?${stringify({
                _facilityId: facilityId,
                report: 'denial-nomnc',
                id: data.id,
                token,
              })}`,
            );
          }
        })
        .catch(error => {
          setSubmitAndPayerChange(false);
          setSubmitAndExtendAuth(false);
          setSubmitAndNewAuth(false);
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          );
        });
    } else {
      return dataProvider
        .create('denials', {
          data: {
            ...value,
            signature_required,
          },
        })
        .then(({ data }) => {
          setSubmitting(false);
          if (data.already_discharged)
            notify(
              'Resident already discharged, previous data will be used',
              'warning',
              {},
              false,
              15000,
            );
          else notify('form.created');
          if (submitAndExtendAuth) {
            changeForm('authorization', {
              caseId: value.case_id,
              authorizationId: value.authorization_id,
              isEdit: true,
              record: {},
            });
          } else if (submitAndNewAuth) {
            changeForm('authorization', {
              caseId: value.case_id,
              authorizationId: value.authorization_id,
              isEdit: false,
              record: {},
            });
          } else if (submitAndPayerChange) {
            changeForm('casePayer', {
              caseId: value.case_id,
              isEdit: false,
              record: {},
            });
          } else {
            handleClose();
          }
          updateTasksCount();
          typeof refresh === 'function' && refresh();
          if (downloadNOMNC) {
            window.open(
              `${baseUrl}/download?${stringify({
                _facilityId: facilityId,
                report: 'denial-nomnc',
                id: data.id,
                token,
              })}`,
            );
          }
        })
        .catch(error => {
          setSubmitAndPayerChange(false);
          setSubmitAndExtendAuth(false);
          setSubmitAndNewAuth(false);
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          );
        });
    }
  }

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    // if (typeof eventOrValue === 'object' && !eventOrValue instanceof Date) {
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'is_appealing') {
      if (value === 'no' || value === 'unknown') {
        removeAppeal(1);
        removeAppeal(2);
        removeAppeal(3);
        removeAppeal(4);
        removeAppeal(5);
      }
      setFormData(f => ({ ...f, [name]: value }));
      return;
    }

    if (name === 'last_covered') {
      const last_covered = new Date(value);
      const newValues = {
        [name]: value,
      };
      if (isValid(last_covered) && isFuture(last_covered)) {
        if (denialOverturned) {
          newValues.resolution_id = RESOLUTION_AUTH_EXTENDED_ID;
        } else {
          setFormData(f => ({
            ...f,
            ...newValues,
            discharge_date: undefined,
            discharge_location_id: undefined,
            resolution_id: null,
            resolved_date: null,
            reason: undefined,
          }));
          return;
        }
      }
      setFormData(f => ({ ...f, ...newValues }));
      return;
    }

    if (
      name === 'appeal_status_id_l1' ||
      name === 'appeal_status_id_l2' ||
      name === 'appeal_status_id_l3' ||
      name === 'appeal_status_id_l4' ||
      name === 'appeal_status_id_l5'
    ) {
      const newValues = {
        [name]: value,
      };
      const last_covered = new Date(formData.last_covered);
      if (
        value === APPEAL_DENIAL_OVERTURNED_ID &&
        isValid(last_covered) &&
        isFuture(last_covered)
      ) {
        newValues.resolution_id = RESOLUTION_AUTH_EXTENDED_ID;
      } else if (isValid(last_covered) && isFuture(last_covered)) {
        newValues.resolution_id = undefined;
      }
      setFormData(f => ({ ...f, ...newValues }));
      return;
    }
    if (
      name === 'denial_type_id' &&
      [
        denial_type.NOMNC_INSURANCE_MCR_HMO_DENIAL,
        denial_type.NOMNC_FACILITY_MCR_HMO_DENIAL,
      ].includes(value)
    ) {
      setFormData(f => ({ ...f, signature_required: 1, [name]: value }));
      return;
    }
    setFormData(f => ({ ...f, [name]: value }));
  };

  return (
    <Fragment>
      <Form
        onSubmit={confirmSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {!isEdit && !caseId && (
                  <div style={{ paddingBottom: 15, marginRight: 3 }}>
                    <AutocompleteInput
                      reference='cases/list'
                      customOnChange={customOnChange}
                      name='case_id'
                      label='Case'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      options={{
                        filter: { division: 'all', active: isEdit ? 0 : 1 },
                      }}
                    />
                  </div>
                )}
                {values.case_id >= 0 && (
                  <ReferenceInput
                    key={values.case_id}
                    reference='auth-number/list'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                    name='authorization_id'
                    label='Auth number'
                    fullWidth
                    options={{
                      filter: { case_id: values.case_id },
                    }}
                    style={{ marginLeft: 0 }}
                    disabled={isEdit}
                  />
                )}
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    customOnChange={customOnChange}
                    label='Denial type'
                    validate={required()}
                    required
                    name='denial_type_id'
                    reference='denial-types/list'
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='verbal_received'
                    customOnChange={customOnChange}
                    validate={composeValidators([required(), validateDate()])}
                    required
                    type='date'
                    label='Verbal received date'
                  />
                  <FormTextField
                    name='denial_received'
                    customOnChange={customOnChange}
                    validate={composeValidators([validateDate()])}
                    type='date'
                    label='Letter received date'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='last_covered'
                    validate={
                      values.resolution_id !== RESOLUTION_AUTH_EXTENDED_ID &&
                      values.resolution_id !== RESOLUTION_RESCINDED_ID
                        ? composeValidators([required(), validateDate()])
                        : undefined
                    }
                    required={
                      values.resolution_id !== RESOLUTION_AUTH_EXTENDED_ID &&
                      values.resolution_id !== RESOLUTION_RESCINDED_ID
                    }
                    customOnChange={customOnChange}
                    type='date'
                    label='Last covered date'
                  />
                  <ReferenceInput
                    reference='post-lcd-options/list'
                    customOnChange={customOnChange}
                    name='post_lcd_option_id'
                    label='Post LCD plan'
                  />
                </div>
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                >
                  Denial letter instructions
                </DialogContentText>
                <Divider className={classes.divider} />
                <div>
                  <RadioInput
                    name='signature_required'
                    className={classes.radio}
                    customOnChange={customOnChange}
                    required
                    validate={required()}
                    value={parseInt(formData.signature_required)}
                    label='Signature required'
                    row
                    radios={[
                      { label: 'Yes', value: 1 },
                      { label: 'No', value: 0 },
                    ]}
                    disabled={[
                      denial_type.NOMNC_INSURANCE_MCR_HMO_DENIAL,
                      denial_type.NOMNC_FACILITY_MCR_HMO_DENIAL,
                    ].includes(values.denial_type_id)}
                  />
                </div>
                {values.signature_required === 1 ||
                values.signature_required === '1' ? (
                  <FormGroup row>
                    <CheckboxInput
                      name='signature_today'
                      customOnChange={customOnChange}
                      label='Same day signature required'
                      checked={values.signature_today}
                    />
                  </FormGroup>
                ) : (
                  <div style={{ paddingTop: 10 }} />
                )}

                {values.signature_required === 1 ||
                values.signature_required === '1' ? (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='signature_required_by'
                        customOnChange={customOnChange}
                        type='date'
                        label='Signature required by'
                        validate={composeValidators([
                          required(),
                          validateDate(),
                        ])}
                        required
                      />
                      <FormTextField
                        name='sent_to_facility'
                        customOnChange={customOnChange}
                        validate={composeValidators([validateDate()])}
                        type='date'
                        label='Denial sent to facility'
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='signed_denial_received'
                        customOnChange={customOnChange}
                        validate={composeValidators([validateDate()])}
                        type='date'
                        label='Signed denial received'
                      />
                      <FormTextField
                        name='sent_to_insurance'
                        customOnChange={customOnChange}
                        validate={composeValidators([validateDate()])}
                        type='date'
                        label='Signed denial sent to ins.'
                        labelStyle={{ width: 195 }}
                      />
                    </div>
                  </Fragment>
                ) : (
                  <FormTextField
                    name='sent_to_facility'
                    customOnChange={customOnChange}
                    validate={composeValidators([validateDate()])}
                    type='date'
                    label='Denial sent to facility'
                    style={{ marginLeft: 0 }}
                  />
                )}
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                >
                  Appeal
                </DialogContentText>
                <Divider className={classes.divider} />
                <div>
                  <RadioInput
                    name='is_appealing'
                    className={classes.radio}
                    customOnChange={customOnChange}
                    required
                    validate={required()}
                    value={formData.is_appealing}
                    label='Will the denial be appealed?'
                    disabled={disableAppealToggle}
                    row
                    radios={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                      { label: 'Unknown', value: 'unknown' },
                    ]}
                  />
                </div>
                {values.is_appealing === 'yes' && (
                  <div className={classes.secondaryActionContainer}>
                    {createAppeal ? (
                      <Button
                        disabled={!!record.appeal_type_id_l1}
                        startIcon={<RemoveIcon />}
                        onClick={() => removeAppeal(1)}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Remove appeal
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setCreateAppeal(true)}
                        startIcon={<AddIcon />}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Create appeal
                      </Button>
                    )}
                  </div>
                )}
                {createAppeal && (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='appeal-types/list'
                        customOnChange={customOnChange}
                        validate={required()}
                        required
                        name='appeal_type_id_l1'
                        label='Appeal type'
                        disabled={!!record.appeal_type_id_l1}
                        style={{ marginRight: 0 }}
                      />
                    </div>

                    {formData.appeal_type_id_l1 === appeal_type.OTHER && (
                      <div className={clsx(classes.inputContainerWrap)}>
                        <FormTextField
                          label='Reason for appeal'
                          title='reason'
                          name='reason_l1'
                          required
                          validate={composeValidators([
                            required(),
                            minReasonLength(),
                          ])}
                          fullWidth
                          style={{ marginRight: 0 }}
                          customOnChange={customOnChange}
                        />
                      </div>
                    )}

                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='date_sent_l1'
                        validate={composeValidators([
                          required(),
                          validateDate(),
                        ])}
                        required
                        customOnChange={customOnChange}
                        type='date'
                        label='Date sent'
                        disabled={!!record.appeal_type_id_l1}
                      />
                      <ReferenceInput
                        customOnChange={customOnChange}
                        label='Appeal status'
                        validate={required()}
                        required
                        name='appeal_status_id_l1'
                        reference='appeal-statuses/list'
                        disabled={!!record.appeal_type_id_l1}
                      />
                    </div>
                    {!record.appeal_type_id_l1 && (
                      <div className={classes.insuranceTitle}>
                        First level appeal attachments
                        <FileInput
                          title='title'
                          name='attachments_l1'
                          setFormData={setFormData}
                          formData={formData}
                          classes={{ dropZone: classes.dropZone }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
                {values.is_appealing === 'yes' && (
                  <div className={classes.secondaryActionContainer}>
                    {create2ndAppeal ? (
                      <Button
                        disabled={!!record.appeal_type_id_l2}
                        startIcon={<RemoveIcon />}
                        onClick={() => removeAppeal(2)}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Remove 2nd level appeal
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setCreate2ndAppeal(true)}
                        startIcon={<AddIcon />}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Create 2nd level appeal
                      </Button>
                    )}
                  </div>
                )}
                {create2ndAppeal && (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='appeal-types/list'
                        customOnChange={customOnChange}
                        validate={required()}
                        required
                        name='appeal_type_id_l2'
                        label='Appeal type'
                        disabled={!!record.appeal_type_id_l2}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    {formData.appeal_type_id_l2 === appeal_type.OTHER && (
                      <div className={clsx(classes.inputContainerWrap)}>
                        <FormTextField
                          label='Reason for appeal'
                          title='reason'
                          name='reason_l2'
                          required
                          validate={composeValidators([
                            required(),
                            minReasonLength(),
                          ])}
                          fullWidth
                          style={{ marginRight: 0 }}
                          customOnChange={customOnChange}
                        />
                      </div>
                    )}
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='date_sent_l2'
                        validate={composeValidators([
                          required(),
                          validateDate(),
                        ])}
                        required
                        customOnChange={customOnChange}
                        type='date'
                        label='Date sent'
                        disabled={!!record.appeal_type_id_l2}
                      />
                      <ReferenceInput
                        customOnChange={customOnChange}
                        label='Appeal status'
                        validate={required()}
                        required
                        name='appeal_status_id_l2'
                        reference='appeal-statuses/list'
                        disabled={!!record.appeal_type_id_l2}
                      />
                    </div>
                    {!record.appeal_type_id_l2 && (
                      <div className={classes.insuranceTitle}>
                        Second level appeal attachments
                        <FileInput
                          title='title'
                          name='attachments_l2'
                          setFormData={setFormData}
                          formData={formData}
                          classes={{ dropZone: classes.dropZone }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
                {values.is_appealing === 'yes' && (
                  <div className={classes.secondaryActionContainer}>
                    {create3rdAppeal ? (
                      <Button
                        disabled={!!record.appeal_type_id_l3}
                        startIcon={<RemoveIcon />}
                        onClick={() => removeAppeal(3)}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Remove 3rd level appeal
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setCreate3rdAppeal(true)}
                        startIcon={<AddIcon />}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Create 3rd level appeal
                      </Button>
                    )}
                  </div>
                )}
                {create3rdAppeal && (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='appeal-types/list'
                        customOnChange={customOnChange}
                        validate={required()}
                        required
                        name='appeal_type_id_l3'
                        label='Appeal type'
                        disabled={!!record.appeal_type_id_l3}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    {formData.appeal_type_id_l3 === appeal_type.OTHER && (
                      <div className={clsx(classes.inputContainerWrap)}>
                        <FormTextField
                          label='Reason for appeal'
                          title='reason'
                          name='reason_l3'
                          required
                          validate={composeValidators([
                            required(),
                            minReasonLength(),
                          ])}
                          fullWidth
                          style={{ marginRight: 0 }}
                          customOnChange={customOnChange}
                        />
                      </div>
                    )}
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='date_sent_l3'
                        validate={composeValidators([
                          required(),
                          validateDate(),
                        ])}
                        required
                        customOnChange={customOnChange}
                        type='date'
                        label='Date sent'
                        disabled={!!record.appeal_type_id_l3}
                      />
                      <ReferenceInput
                        customOnChange={customOnChange}
                        label='Appeal status'
                        validate={required()}
                        required
                        name='appeal_status_id_l3'
                        reference='appeal-statuses/list'
                        disabled={!!record.appeal_type_id_l3}
                      />
                    </div>
                    {!record.appeal_type_id_l3 && (
                      <div className={classes.insuranceTitle}>
                        Third level appeal attachments
                        <FileInput
                          title='title'
                          name='attachments_l3'
                          setFormData={setFormData}
                          formData={formData}
                          classes={{ dropZone: classes.dropZone }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
                {values.is_appealing === 'yes' && (
                  <div className={classes.secondaryActionContainer}>
                    {create4thAppeal ? (
                      <Button
                        disabled={!!record.appeal_type_id_l4}
                        startIcon={<RemoveIcon />}
                        onClick={() => removeAppeal(4)}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Remove 4th level appeal
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setCreate4thAppeal(true)}
                        startIcon={<AddIcon />}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Create 4th level appeal
                      </Button>
                    )}
                  </div>
                )}
                {create4thAppeal && (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='appeal-types/list'
                        customOnChange={customOnChange}
                        validate={required()}
                        required
                        name='appeal_type_id_l4'
                        label='Appeal type'
                        disabled={!!record.appeal_type_id_l4}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    {formData.appeal_type_id_l4 === appeal_type.OTHER && (
                      <div className={clsx(classes.inputContainerWrap)}>
                        <FormTextField
                          label='Reason for appeal'
                          title='reason'
                          name='reason_l4'
                          required
                          validate={composeValidators([
                            required(),
                            minReasonLength(),
                          ])}
                          fullWidth
                          style={{ marginRight: 0 }}
                          customOnChange={customOnChange}
                        />
                      </div>
                    )}
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='date_sent_l4'
                        validate={composeValidators([
                          required(),
                          validateDate(),
                        ])}
                        required
                        customOnChange={customOnChange}
                        type='date'
                        label='Date sent'
                        disabled={!!record.appeal_type_id_l4}
                      />
                      <ReferenceInput
                        customOnChange={customOnChange}
                        label='Appeal status'
                        validate={required()}
                        required
                        name='appeal_status_id_l4'
                        reference='appeal-statuses/list'
                        disabled={!!record.appeal_type_id_l4}
                      />
                    </div>
                    {!record.appeal_type_id_l4 && (
                      <div className={classes.insuranceTitle}>
                        Fourth level appeal attachments
                        <FileInput
                          title='title'
                          name='attachments_l4'
                          setFormData={setFormData}
                          formData={formData}
                          classes={{ dropZone: classes.dropZone }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}

                {values.is_appealing === 'yes' && (
                  <div className={classes.secondaryActionContainer}>
                    {create5thAppeal ? (
                      <Button
                        disabled={!!record.appeal_type_id_l5}
                        startIcon={<RemoveIcon />}
                        onClick={() => removeAppeal(5)}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Remove 5th level appeal
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setCreate5thAppeal(true)}
                        startIcon={<AddIcon />}
                        color='primary'
                        classes={{
                          label: classes.sectionButtonLabel,
                        }}
                      >
                        Create 5th level appeal
                      </Button>
                    )}
                  </div>
                )}
                {create5thAppeal && (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='appeal-types/list'
                        customOnChange={customOnChange}
                        validate={required()}
                        required
                        name='appeal_type_id_l5'
                        label='Appeal type'
                        disabled={!!record.appeal_type_id_l5}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    {formData.appeal_type_id_l5 === appeal_type.OTHER && (
                      <div className={clsx(classes.inputContainerWrap)}>
                        <FormTextField
                          label='Reason for appeal'
                          title='reason'
                          name='reason_l5'
                          customOnChange={customOnChange}
                          required
                          validate={composeValidators([
                            required(),
                            minReasonLength(),
                          ])}
                          fullWidth
                          style={{ marginRight: 0 }}
                        />
                      </div>
                    )}
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='date_sent_l5'
                        validate={composeValidators([
                          required(),
                          validateDate(),
                        ])}
                        required
                        customOnChange={customOnChange}
                        type='date'
                        label='Date sent'
                        disabled={!!record.appeal_type_id_l5}
                      />
                      <ReferenceInput
                        customOnChange={customOnChange}
                        label='Appeal status'
                        validate={required()}
                        required
                        name='appeal_status_id_l5'
                        reference='appeal-statuses/list'
                        disabled={!!record.appeal_type_id_l5}
                      />
                    </div>
                    {!record.appeal_type_id_l5 && (
                      <div className={classes.insuranceTitle}>
                        Fifth level appeal attachments
                        <FileInput
                          title='title'
                          name='attachments_l5'
                          setFormData={setFormData}
                          formData={formData}
                          classes={{ dropZone: classes.dropZone }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}

                <Fragment>
                  <DialogContentText
                    align='left'
                    variant='h6'
                    className={classes.header}
                  >
                    Resolution
                  </DialogContentText>
                  <Divider className={classes.divider} />
                  {isEdit &&
                    record.resolution_id === RESOLUTION_DISCHARGED_ID &&
                    !denialOverturned && (
                      <DialogContentText>
                        NOTE: Changes to the resolution status and discharge can
                        only be made by deleting or editing the discharge event
                        on the Timeline.
                      </DialogContentText>
                    )}
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      name='resolved_date'
                      customOnChange={customOnChange}
                      type='date'
                      label='Date resolved'
                      validate={composeValidators([
                        validateDate(),
                        ...(values.resolution_id ? [required()] : []),
                      ])}
                      required={!!values.resolution_id}
                    />
                    <ReferenceInput
                      reference='resolutions/list'
                      customOnChange={customOnChange}
                      name='resolution_id'
                      label='Resolution'
                      validate={composeValidators([
                        validateDate(),
                        ...(values.resolved_date ? [required()] : []),
                      ])}
                      required={!!values.resolved_date}
                      disabled={
                        isEdit &&
                        record.resolution_id === RESOLUTION_DISCHARGED_ID &&
                        !denialOverturned
                      }
                    />
                  </div>

                  {values.resolution_id === RESOLUTION_DISCHARGED_ID ? (
                    <Fragment>
                      <div className={clsx(classes.inputContainerWrap)}>
                        <ReferenceInput
                          customOnChange={customOnChange}
                          label='Discharge location'
                          name='discharge_location_id'
                          validate={required()}
                          required
                          reference='discharge_locations/list'
                          disabled={
                            isEdit &&
                            record.resolution_id === RESOLUTION_DISCHARGED_ID &&
                            !denialOverturned
                          }
                        />
                        <FormTextField
                          name='discharge_date'
                          customOnChange={customOnChange}
                          type='date'
                          label='Discharge date'
                          validate={composeValidators([
                            required(),
                            validateDate(),
                          ])}
                          required
                          disabled={
                            isEdit &&
                            record.resolution_id === RESOLUTION_DISCHARGED_ID &&
                            !denialOverturned
                          }
                        />
                      </div>

                      <FormTextField
                        name='reason'
                        label='Discharge reason'
                        multiline
                        fullWidth
                        customOnChange={customOnChange}
                        validate={composeValidators([
                          required(),
                          minReasonLength(),
                        ])}
                        required
                        disabled={
                          isEdit &&
                          record.resolution_id === RESOLUTION_DISCHARGED_ID &&
                          !denialOverturned
                        }
                      />
                    </Fragment>
                  ) : (
                    <div style={{ flex: '1 0 160px' }} />
                  )}
                </Fragment>

                <FormTextField
                  name='comment'
                  label='Comments'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  style={{ marginLeft: 0 }}
                  validate={minReasonLength()}
                />
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Denial attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={isSubmitting}
                  type='submit'
                />
                {[
                  denial_type.NOMNC_INSURANCE_MCR_HMO_DENIAL,
                  denial_type.NOMNC_FACILITY_MCR_HMO_DENIAL,
                ].includes(values.denial_type_id) && (
                  <SaveButton
                    onClick={() => setDownloadNOMNC(true)}
                    className={classes.saveButton}
                    disabled={isSubmitting}
                    type='submit'
                    label='Save & download NOMNC'
                  />
                )}
                {values.resolution_id === RESOLUTION_NEW_PAYER_ID && (
                  <SaveButton
                    onClick={() => setSubmitAndPayerChange(true)}
                    className={classes.saveButton}
                    disabled={isSubmitting}
                    type='submit'
                    label='Save and change payer'
                  />
                )}
                {(values.resolution_id === RESOLUTION_AUTH_EXTENDED_ID ||
                  values.resolution_id === RESOLUTION_RESCINDED_ID) && (
                  <>
                    <SaveButton
                      onClick={() => setSubmitAndExtendAuth(true)}
                      className={classes.saveButton}
                      disabled={isSubmitting}
                      type='submit'
                      label='Save and edit existing auth'
                    />
                    <SaveButton
                      onClick={() => setSubmitAndNewAuth(true)}
                      className={classes.saveButton}
                      disabled={isSubmitting}
                      type='submit'
                      label='Save and add new auth'
                    />
                  </>
                )}
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

function sanitizeRecord(
  record,
  {
    setCreateAppeal,
    setCreate2ndAppeal,
    setCreate3rdAppeal,
    setCreate4thAppeal,
    setCreate5thAppeal,
    setDisableAppealToggle,
  },
) {
  const {
    id,
    admit_date,
    facility_id,
    first_name,
    last_name,
    middle_name,
    resident_id,
    post_lcd_option,
    appeal_type_name_l1,
    appeal_type_name_l2,
    appeal_type_name_l3,
    appeal_type_name_l4,
    appeal_type_name_l5,
    resolution_name,
    discharge_location,
    created_by,
    updated_by,
    vDenialUpdatedBy,
    vDenialCreatedBy,
    vDenialDocs,
    signature_required,
    signature_today,
    ...rest
  } = record;
  const {
    is_appealing,
    appeal_type_id_l1,
    appeal_type_id_l2,
    appeal_type_id_l3,
    appeal_type_id_l4,
    appeal_type_id_l5,
  } = record;

  if (
    appeal_type_id_l1 ||
    appeal_type_id_l2 ||
    appeal_type_id_l3 ||
    appeal_type_id_l4 ||
    appeal_type_id_l5
  ) {
    setDisableAppealToggle(true);
  } else {
    setDisableAppealToggle(false);
  }

  if (is_appealing === 'yes') {
    setCreateAppeal(!!appeal_type_id_l1);
    setCreate2ndAppeal(!!appeal_type_id_l2);
    setCreate3rdAppeal(!!appeal_type_id_l3);
    setCreate4thAppeal(!!appeal_type_id_l4);
    setCreate5thAppeal(!!appeal_type_id_l5);
  }
  return {
    ...rest,
    signature_required:
      signature_required ||
      [
        denial_type.NOMNC_INSURANCE_MCR_HMO_DENIAL,
        denial_type.NOMNC_FACILITY_MCR_HMO_DENIAL,
      ].includes(rest.denial_type_id)
        ? 1
        : 0,
    signature_today: !!signature_today,
  };
}
