import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  useRefresh,
  useShowController,
  ShowView,
  TabbedShowLayout,
  Tab,
  // NumberField,
  // ChipField,
  TopToolbar,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { NewEvent, Attachments } from '../../components/common/modals';
import { AddAttachmentModal } from '../../components/common/modals/modalForms';
import { GeneralInfo } from './general-info';
import { FacilityContacts } from './facility-contacts';
import { CommunicationGroups } from './communication-groups';
import { constants } from '../../utils';
import { useStyles } from './facility.styles';
import {
  FacilityIcon,
  EditIcon,
  AddIcon,
  AttachmentIcon,
  CustomButton,
  DeactivateFacilityIcon,
} from '../../design';
import { TrackUser } from '../../components/wrappers';
const { eventTypes } = constants;

const ShowActions = ({ basePath, data = {}, title, resource, permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [addOpen, setAddModal] = useState(false);
  const [editOpen, setEditModal] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const [deactivateFacilityOpen, setDeactivateFacility] = useState(false);
  const { document_ids, inactive } = data || {};
  const hasAttachments =
    !!data && Array.isArray(document_ids) && !!document_ids.length;
  const documentCount = document_ids?.length;
  const isAdmin =
    Array.isArray(permissions) && permissions.indexOf('admin') > -1;
  const isViewAdmin = permissions?.indexOf('view_admin') > -1;
  const isAdminAssistant = permissions?.indexOf('admin_assistant') > -1;
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <div className={classes.title}>
          Facility details
          <div
            className={classes.subtitle}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            Created by
            {'      '} {data?.facilityCreatedBy?.name} {'      '}| Last modified
            on <div style={{ width: 3 }} />
            <TrackUser timestamp={data?.updated_at} />
          </div>
          <div
            className={classes.subtitle}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            {data?.start_date && (
              <span>
                Start date:
                {'      '} {data?.start_date}{' '}
              </span>
            )}
            {data?.contract_end_date && (
              <span>
                Contract end date:
                {'      '} {data?.contract_end_date}{' '}
              </span>
            )}
          </div>
          {data?.inactive && (
            <div
              className={classes.subtitle}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 10,
              }}
            >
              <span className={classes.inactiveItem}>
                Inactive as of:
                {'      '}
                {data?.last_active_date}
              </span>
            </div>
          )}
        </div>

        <div
          className={clsx(classes.addContainer, {
            [classes.adminAddContainer]: isAdmin || isAdminAssistant,
          })}
        >
          {(isAdmin || isAdminAssistant) && (
            <CustomButton
              Icon={inactive ? undefined : DeactivateFacilityIcon}
              backgroundColor='#1061A0'
              color='#FFFFFF'
              label={inactive ? 'Reactivate facility' : 'Deactivate facility'}
              type='button'
              variant='text'
              size='small'
              onClick={() => setDeactivateFacility(true)}
            />
          )}
          {(isAdmin || isAdminAssistant) && (
            <Fragment>
              <CustomButton
                Icon={EditIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Edit'
                type='button'
                variant='text'
                size='small'
                onClick={() => setEditModal(true)}
              />
              {(isAdmin || isAdminAssistant) && (
                <CustomButton
                  Icon={AddIcon}
                  color='#1061A0'
                  backgroundColor='#EFF4FB'
                  label='Add new'
                  type='button'
                  variant='text'
                  size='small'
                  onClick={() => setAddModal(true)}
                />
              )}
            </Fragment>
          )}
          <CustomButton
            Icon={AttachmentIcon}
            label={hasAttachments ? 'View attachment' : 'Add attachment'}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            type='button'
            variant='text'
            size='small'
            onClick={() => setAttchModal(true)}
            badgeContent={documentCount}
            notRed
          />
        </div>
      </TopToolbar>
      {addOpen && (
        <NewEvent
          open={addOpen}
          handleClose={() => setAddModal(false)}
          form='facility'
          refresh={refresh}
        />
      )}
      {editOpen && (
        <NewEvent
          open={editOpen}
          handleClose={() => setEditModal(false)}
          form='facility'
          refresh={refresh}
          record={data}
          isEdit
        />
      )}
      {deactivateFacilityOpen && (
        <NewEvent
          open={deactivateFacilityOpen}
          handleClose={() => setDeactivateFacility(false)}
          form={inactive ? 'reactivateFacilityForm' : 'deactivateFacilityForm'}
          refresh={refresh}
          record={data}
          isEdit={inactive}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={data?.document_ids}
              title='Facility'
              eventId={data.id}
              eventName={eventTypes.FACILITY_NAME}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Facility'
              eventId={data.id}
              eventName={eventTypes.FACILITY_NAME}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const FacilityPageView = props => {
  const basePath = '/facility-details';
  const showProps = useShowController({
    ...props,
    resource: 'facilities',
    basePath,
  });
  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');
  const isAdmin =
    Array.isArray(userPermissions) && userPermissions.indexOf('admin') > -1;
  const isViewAdmin = userPermissions?.indexOf('view_admin') > -1;
  const isAdminAssistant = userPermissions?.indexOf('admin_assistant') > -1;
  const classes = useStyles();
  return (
    <Fragment>
      <ShowView
        {...showProps}
        actions={<ShowActions permissions={userPermissions} />}
      >
        <GeneralInfo permissions={userPermissions} />
      </ShowView>
      <ShowView {...showProps}>
        <TabbedShowLayout>
          <Tab
            label='Facility contacts'
            classes={{ root: classes.tab, selected: classes.selectedTab }}
          >
            <FacilityContacts
              {...props}
              permissions={userPermissions}
              isAdmin={isAdmin}
              isViewAdmin={isViewAdmin}
              isAdminAssistant={isAdminAssistant}
            />
          </Tab>
          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label='Communication groups'
            path='communication-groups'
          >
            <CommunicationGroups
              {...props}
              permissions={userPermissions}
              isAdmin={isAdmin}
              isViewAdmin={isViewAdmin}
              isAdminAssistant={isAdminAssistant}
            />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const { facility: { id } = {} } = state;
  return {
    id,
  };
};

// Need this because we are using 'useShowController' which is a
// react hook and we can't check if id when using hooks.
const Wrapper = props => {
  return props.id ? <FacilityPageView {...props} /> : null;
};

export const FacilityPage = connect(mapStateToProps, {})(Wrapper);

export const facilityResource = {
  name: 'facilities',
  list: FacilityPage,
  // show: ResidentShow,
  icon: FacilityIcon,
  options: { label: 'Facility Details' },
};
