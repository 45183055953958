import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export function StatusFilter({
  setShowAll,
  showAll,
  label = 'Show all cases',
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox checked={showAll} onChange={() => setShowAll(!showAll)} />
      }
      label={label}
    />
  );
}
