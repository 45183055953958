import React, { useState } from 'react';
import {
  List,
  ExportButton,
  downloadCSV,
  TopToolbar,
  TextField,
  FunctionField,
  Pagination,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';
import { DateField, Datagrid, ToggleButton } from '../../components/common';
import { useGetUserDivision } from '../../hooks';
import { useStyles } from './reports.styles';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const { id, title, task_type, ...rest } = field;
    rest.title = title || task_type;
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'title',
        'case_name',
        'related_to_type',
        'task_assigned_to',
        'date_due',
        'status',
      ],
      rename: [
        'Task',
        'Case Name',
        'Related To',
        'Assigned To',
        'Date Due',
        'Status',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `tasks-due-report-${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  classes,
}) => {
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <div className={classes.listActionWrapper}>
        <div style={{ display: 'flex', paddingBottom: 20 }}>
          <span className={classes.title}>Tasks due report</span>
          {/* Needed for spacing */}
          <span style={{ marginRight: 25 }} />
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
          />
        </div>
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          className={classes.exportButton}
        />
      </div>
    </TopToolbar>
  );
};

export const TasksDueReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(userDivision);

  return (
    <div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions setDivision={setDivision} classes={classes} />}
        exporter={exporter}
        filter={{ division }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <FunctionField
            source='title'
            label='Task'
            render={record => {
              const { title, task_type } = record;
              return title || task_type;
            }}
          />
          <TextField source='case_name' label='Case name' sortBy='last_name' />
          <TextField source='related_to_type' label='Related to' />
          <TextField
            source='task_assigned_to'
            label='Assigned to'
            sortBy='taskAssignedTo.name'
          />
          <DateField source='date_due' label='Date due' />
          <TextField source='status' label='Status' />
        </Datagrid>
      </List>
    </div>
  );
};

export const tasksDueReportResource = {
  name: 'tasks-due-report',
  list: TasksDueReportList,
};

function getResourceProps(props) {
  const basePath = '/tasks-due-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/tasks-due-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!tasksDueReportResource.show,
        hasCreate: !!tasksDueReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
