import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useStyles } from './toggleButton.styles';

export const ToggleCaseManagerButton = ({
  initialDivision,
  setDivision,
  showBoth = true,
  disabled,
  showBothOption = 'case_manager',
  showBothLabel = 'Case Managers',
}) => {
  useEffect(() => {
    setPosition(initialDivision);
    setDivision(initialDivision);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDivision]);
  const classes = useStyles();
  const [activePosition, setPosition] = useState(initialDivision);
  const handleClick = position => {
    setPosition(position);
    setDivision(position);
  };
  return (
    <div className={classes.toggleContainer} style={{ width: 300 }}>
      <div
        className={clsx(classes.button, classes.leftButton, {
          [classes.active]: activePosition === 'facility',
          [classes.disabled]: activePosition === 'facility' && disabled,
        })}
        onClick={() => !disabled && handleClick('facility')}
      >
        Facilities
      </div>
      <div
        className={clsx(
          classes.button,
          !showBoth ? classes.rightButton : classes.button,
          {
            [classes.active]: activePosition === 'group',
            [classes.disabled]: activePosition === 'group' && disabled,
          },
        )}
        onClick={() => !disabled && handleClick('group')}
      >
        Groups
      </div>
      {showBoth && (
        <div
          className={clsx(classes.button, classes.rightButton, {
            [classes.active]: activePosition === showBothOption,
            [classes.disabled]: activePosition === showBothOption && disabled,
          })}
          onClick={() => !disabled && handleClick(showBothOption)}
        >
          {showBothLabel}
        </div>
      )}
    </div>
  );
};
