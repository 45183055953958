import { listTypes } from '../actions/lists';
const initialState = {};
export function listReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case listTypes.GET_LISTS:
      return {
        ...state,
        lists: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
