import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import {
  List,
  ExportButton,
  downloadCSV,
  TopToolbar,
  TextField,
  FunctionField,
  Pagination,
  // Labeled,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';
import { DateField, Datagrid, ToggleButton } from '../../components/common';
import { NotesModal } from '../../components/common/modals';
import { limitStringSize } from '../../utils';
import { useGetUserDivision } from '../../hooks';
import { useStyles } from './reports.styles';

const exporter = fields => {
  const headers = [
    'resident',
    'discharge_date',
    'discharge_location',
    'comment',
  ];
  const rename = [
    'Resident',
    'Discharge Date',
    'Discharge Location',
    'Comment',
  ];
  let maxColumns = 0;
  fields.forEach(f => {
    const { planned_discharge_dates = [] } = f;
    const count = planned_discharge_dates.length;
    if (count > maxColumns) {
      maxColumns = count;
    }
  });
  for (let i = 0; i < maxColumns; i++) {
    rename.push('New Date', 'New Comment');
    headers.push('date' + i, 'comment' + i);
  }
  const fieldsForExport = fields.map(field => {
    const {
      planned_discharge_dates = [],
      discharge_date,
      discharge_location,
      comment,
      PlannedDischargeCase,
    } = field;
    const rv = planned_discharge_dates.reduce((acc, cur, i) => {
      const { new_date, new_comment } = cur;
      acc['date' + i] = new_date;
      acc['comment' + i] = new_comment;
      return acc;
    }, {});
    return {
      ...rv,
      discharge_date,
      discharge_location: discharge_location?.discharge_location,
      comment,
      resident: get(PlannedDischargeCase, 'v_resident.full_name'),
    };
  });

  jsonExport(
    fieldsForExport,
    {
      headers,
      rename,
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `change-planned-discharge-report-${format(new Date(), 'MM/dd/yyyy')}`,
      );
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  classes,
}) => {
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <div style={{ display: 'flex' }}>
        <span className={classes.title}>Changed planned discharge report</span>
        {/* Needed for spacing */}
        <span style={{ marginRight: 25 }} />
        <ToggleButton
          setDivision={setDivision}
          className={classes.toggleWrapper}
        />
      </div>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
    </TopToolbar>
  );
};

export const ChangedPlannedDischargeReportList = ({
  staticContext,
  ..._props
}) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(userDivision);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  const maxNotesLength = 160;

  useEffect(() => {
    // selectedNote can only change when user clicks on 'show more'
    // this will make sure that 'show modal' is called with the correct
    // note after the 'selectedNote' state changed since useState does
    // not accept a callback like setState does.
    // The above also applies to 'resourceRecord',
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);

  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    // needed if the user selects the same note again
    // since it only it will not show unless selectedNote changes.
    setSelectedNote(null);
  };

  return (
    <div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions setDivision={setDivision} classes={classes} />}
        exporter={exporter}
        filter={{ division }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField
            source='PlannedDischargeCase.v_resident.full_name'
            label='Resident'
          />
          <DateField source='discharge_date' label='Discharge Date' />
          <TextField
            source='discharge_location.discharge_location'
            label='Discharge location'
          />
          <FunctionField
            source='planned_discharge_dates'
            label='New date'
            sortable={false}
            render={record => {
              const { planned_discharge_dates = [] } = record;
              const lastChange = planned_discharge_dates.filter(
                p => !p.is_changed,
              )[0];
              const { new_date } = lastChange || {};
              return (
                <DateField
                  record={{ ...record, new_date }}
                  source='new_date'
                  label='New date'
                />
              );
            }}
          />
          <FunctionField
            source='planned_discharge_dates'
            label='New comment'
            sortable={false}
            render={record => {
              const { planned_discharge_dates = [] } = record;
              const lastChange = planned_discharge_dates.filter(
                p => !p.is_changed,
              )[0];
              const { new_comment } = lastChange || {};
              const truncated = limitStringSize(
                new_comment,
                maxNotesLength,
                true,
              );
              return new_comment && new_comment.length > maxNotesLength ? (
                <span>
                  <span>{truncated}</span>
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setSelectedNote(new_comment)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                <span>{truncated}</span>
              );
            }}
          />
        </Datagrid>
      </List>
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
        />
      )}
    </div>
  );
};

export const changedPlannedDischargeReportResource = {
  name: 'changed-planned-discharges-report',
  list: ChangedPlannedDischargeReportList,
};

function getResourceProps(props) {
  const basePath = '/changed-planned-discharges-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/changed-planned-discharges-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!changedPlannedDischargeReportResource.show,
        hasCreate: !!changedPlannedDischargeReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
