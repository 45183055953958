import React, { useState } from 'react';
import clsx from 'clsx';
import { useRedirect, useNotify, Notification } from 'react-admin';
import { authPost } from '../server';
import { useStyles } from './login.styles';
import {
  Typography,
  InputAdornment,
  IconButton,
  Button,
  CssBaseline,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '../design';
export const ChangePassword = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const [state, setState] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  });

  const handleChange = e => {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  };
  const submit = async e => {
    e.preventDefault();
    const response = await authPost('/change-password', { ...state });
    const { error } = response;
    if (error) {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning',
      );
      return;
    }
    notify('form.change_password');
    redirect('/');
  };
  const { old_password, new_password, confirm_password } = state;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={classes.paper}>
        <img src='/images/logo.png' alt='logo' className={classes.logo} />
        <Typography
          className={clsx(classes.welcome, classes.welcomeForgotPassword)}
        >
          Change Password
        </Typography>
        <Typography className={clsx(classes.signIn, classes.instructionsMsg)}>
          Enter your new password here.
        </Typography>

        <form className={classes.form} noValidate onSubmit={submit}>
          <div className={classes.inputWrapper}>
            <TextField
              margin='dense'
              required
              variant='outlined'
              fullWidth
              name='old_password'
              label='Old password'
              value={old_password}
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              margin='dense'
              required
              variant='outlined'
              fullWidth
              name='new_password'
              label='New password'
              value={new_password}
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              margin='dense'
              required
              variant='outlined'
              fullWidth
              name='confirm_password'
              label='Repeat new password'
              value={confirm_password}
              onChange={handleChange}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge='end'
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <Button
              type='submit'
              variant='contained'
              className={classes.submit}
              disableElevation
              color='primary'
              disabled={
                !old_password ||
                !new_password ||
                !confirm_password ||
                old_password.length < 8 ||
                new_password.length < 8 ||
                new_password !== confirm_password
              }
            >
              Change Password
            </Button>
          </div>
        </form>
      </div>
      <Notification />
    </main>
  );
};
