import React, { useState } from 'react';
import get from 'lodash/get';
import { useRedirect, useNotify, Notification } from 'react-admin';
import packageJson from '../../package.json';
import {
  TextField,
  Button,
  CssBaseline,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './login.styles';
import { Visibility, VisibilityOff } from '../design';
import { useLogin } from '../server';
export const Login = () => {
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const [state, setState] = useState({
    email: '',
    password: '',
  });
  const handleChange = e => {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  };
  const submit = e => {
    e.preventDefault();
    login(state).catch(error => {
      const status = get(error, 'status', '');
      if (status === 429) {
        notify(
          'Too many failed login attempts. Try again later or reset your password.',
        );
      } else {
        notify('Invalid email or password');
      }
    });
  };
  const [showPassword, setShowPassword] = useState(false);
  const { email, password } = state;
  const { version } = packageJson;

  return (
    <main className={classes.main}>
      <CssBaseline />
      <span className={classes.version}>{version}</span>
      <div className={classes.paper}>
        <img src='/images/logo.png' alt='logo' className={classes.logo} />
        <Typography className={classes.welcome}>Welcome</Typography>
        <Typography className={classes.signIn}>Sign in to continue</Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <div className={classes.inputWrapper}>
            <TextField
              required
              variant='outlined'
              margin='dense'
              fullWidth
              id='email'
              label='Email'
              name='email'
              value={email}
              autoComplete='email'
              autoFocus
              onChange={handleChange}
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              required
              variant='outlined'
              margin='dense'
              fullWidth
              name='password'
              label='Password'
              value={password}
              id='password'
              autoComplete='current-password'
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <Button
              type='submit'
              variant='contained'
              className={classes.submit}
              disableElevation
            >
              <span className={classes.buttonText}>Sign In</span>
            </Button>

            <Typography
              className={classes.buttonLink}
              variant='body2'
              onClick={() => redirect('/forgot-password')}
              color='primary'
              style={{ paddingTop: 10 }}
            >
              Forgot password?
            </Typography>
          </div>
        </form>
      </div>
      <Notification />
    </main>
  );
};
