import { makeStyles } from '@material-ui/core';
import { styles } from '../design';
import { style } from '../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  actionHeader: {
    paddingTop: 8,
  },
  actionContainer: {
    width: '100%',
    ...styles.flexRow,
    ...styles.justifyBetween,
    flexWrap: 'wrap',
    marginTop: 20,
  },
  actionButton: {
    marginTop: 5,
  },
  residentDivider: {
    color: '#829CB5',
    height: 2,
  },
  funcShowItem: {
    fontSize: 11,
  },
  commentField: {
    minWidth: '100%',
    marginBottom: 20,
  },
  infoContainer: {
    ...styles.flexRow,
  },
  info: {
    height: 15,
    width: 15,
    color: '#E91F31',
    fontWeight: 600,
  },
  stay: {
    height: 15,
    width: 15,
    color: '#1061A0',
    fontWeight: 600,
  },
  badge: {
    width: 20,
    minWidth: 20,
    height: 20,
    fontSize: 9,
    right: 5,
    top: 3,
    backgroundColor: '#E32939',
    color: '#FFFFFF',
    fontWeight: 600,
    zIndex: 0,
  },
  alertIcon: {
    color: '#1061A0',
  },
  newComment: {
    ...style(theme).showItem,
    minWidth: 240,
  },
  mltc_los: { color: '#1061A0' },
  subacute_los: {
    color: '#44C87A',
  },
  divider: {
    backgroundColor: '#E32939',
  },
  flags: {
    backgroundColor: 'rgba(249, 233, 234)',
    color: '#E32939',
    fontStyle: 'Nunito Sans',
    fontSize: 15,
    fontWeight: 400,
  },
}));
