import React, { useState, useEffect, useCallback, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  usePermissions,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  RadioInput,
  // SelectComponent,
  FileInput,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  validateDate,
  minReasonLength,
} from '../modalForms';
import { DeleteRecord } from '../../../wrappers';
import { useStyles } from '../modal.styles';
import { SaveButton, AddIcon } from '../../../../design';
import { useGetCaseId } from '../../../../hooks';
import { uiActions } from '../../../../state/actions';
import { authGet } from '../../../../server';
import { Loading } from '../../';
import { differenceInDays, parseISO } from 'date-fns';

async function fetchAndValidateResource(resource, value) {
  const response = await authGet(
    typeof value === 'object'
      ? [resource, { ...value }]
      : `/${resource}/${value}`,
  );
  return response;
}

const ExternalDataHeader = ({ location_type, location_name }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={clsx(classes.header)}>
        Discharge location in PointClickCare
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <TextField
          margin='dense'
          className={classes.textField}
          value={location_type}
          label='Location Type'
          disabled={true}
        />
        <TextField
          margin='dense'
          className={classes.textField}
          value={location_name}
          label='Location Name'
          disabled={true}
        />
      </div>
    </Fragment>
  );
};
export const DischargeForm = ({
  caseId,
  record = {},
  isEdit,
  handleClose,
  onSuccess,
  isPlannedDischarge,
  closeAside,
  eventName,
  meta,
}) => {
  const importsExternalData = [
    'importDischarge',
    'importPlannedDischarge',
    'importDeleteDischarge',
  ].includes(eventName);

  const deleteOnly = eventName === 'importDeleteDischarge';

  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [formData, setFormData] = useState({
    case_id: parseInt(caseId),
    discharge_status: isPlannedDischarge ? 'planned' : 'discharged',
  });
  const [loading, setLoading] = useState(importsExternalData);
  const [shortLengthOfStay, setShortLengthOfStay] = useState(false);
  const [externalData, setExternalData] = useState({
    location_type: '',
    location_name: '',
  });
  const [addDate, setAddDate] = useState(false);

  const fetchCase = useCallback(async caseId => {
    if (!caseId) return;
    const response = await authGet(`/case-data/${caseId}`);
    if (response.error) return;
    return response.data;
  }, []);

  useEffect(() => {
    /* if discharge_location_id is to Home (id #1),
    check if los is < 10 days, warn user accordingly */
    async function checkLos10() {
      if (formData.discharge_location_id === 1 && formData.discharge_date) {
        const { length_of_stay, last_admit_date, admit_date } = await fetchCase(
          caseId || caseIdFromUrl || formData.case_id,
        );
        if (!length_of_stay && (!last_admit_date || !admit_date)) return;
        const admitDate = new Date(last_admit_date || admit_date);
        if (
          length_of_stay <= 10 ||
          differenceInDays(parseISO(formData.discharge_date), admitDate) <= 10
        ) {
          setShortLengthOfStay(true);
        } else {
          setShortLengthOfStay(false);
        }
      } else {
        setShortLengthOfStay(false);
      }
    }
    checkLos10();
  }, [
    fetchCase,
    caseId,
    caseIdFromUrl,
    formData.case_id,
    formData.discharge_location_id,
    formData.discharge_date,
  ]);

  useEffect(() => {
    if (isEdit && record) {
      const {
        id,
        updated_by,
        created_by,
        discharge_location,
        dischargeUpdatedBy,
        dischargeCreatedBy,
        is_denial: _is_denial,
        ...rest
      } = record;
      setFormData(f => ({
        is_denial: _is_denial ? 1 : 0,
        ...f,
        ...rest,
      }));
    }
  }, [isEdit, record]);

  useEffect(() => {
    async function fetchImportedData() {
      if (importsExternalData && meta.id) {
        setLoading(true);
        const response = await fetchAndValidateResource(
          'census',
          meta.id,
        ).catch(e => {
          handleClose();
          throw e;
        });

        if (response.error) {
          notify(
            typeof response.error === 'string'
              ? response.error
              : response.error.message || 'ra.notification.http_error',
            'warning',
          );
          handleClose();
          return;
        }

        const { external_data, ...rest } = response.data;
        setFormData(f => ({
          ...f,
          ...rest,
        }));
        external_data && setExternalData(external_data);
        setLoading(false);
      }
    }
    fetchImportedData(meta.id);
  }, [handleClose, importsExternalData, meta.id, notify]);

  const onSubmit = value => {
    if (importsExternalData) value.census_id = meta.id;
    if (isEdit) {
      return dataProvider
        .update('discharge', {
          id: record.id,

          data: { ...value },
          previousData: { ...record },
        })
        .then(() => typeof onSuccess === 'function' && onSuccess())
        .then(() => {
          notify('form.updated');
          handleClose();
          updateTasksCount();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('discharge', { data: { ...value } })
        .then(() => typeof onSuccess === 'function' && onSuccess())
        .then(() => {
          notify('form.created');
          handleClose();
          updateTasksCount();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  const updateTasksCount = useCallback(() => {
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }, [caseIdFromUrl, dispatch]);
  return loading ? (
    <Loading
      /* keep modal size steady as square spinner rotates */
      style={{ height: 60 }}
    />
  ) : (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {!isEdit && !caseId && (
                  <div style={{ paddingBottom: 15, marginRight: 3 }}>
                    <AutocompleteInput
                      reference='cases/list'
                      customOnChange={customOnChange}
                      name='case_id'
                      label='Case'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{
                        openOnFocus: true,
                        initialInputValue: values.case_name,
                        disabled: !!importsExternalData,
                      }}
                      options={{
                        filter: { division: 'all', active: isEdit ? 0 : 1 },
                      }}
                    />
                  </div>
                )}
                {externalData.location_type && (
                  <ExternalDataHeader
                    location_type={externalData.location_type}
                    location_name={externalData.location_name}
                  />
                )}
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='discharge_date'
                    validate={composeValidators([
                      validateDate(),
                      ...(values.discharge_status !== 'planned'
                        ? [required()]
                        : []),
                    ])}
                    required={values.discharge_status !== 'planned'}
                    customOnChange={customOnChange}
                    type='date'
                    label={
                      isPlannedDischarge
                        ? 'Planned discharge date'
                        : 'Discharge date'
                    }
                    disabled={deleteOnly}
                  />
                  <ReferenceInput
                    customOnChange={customOnChange}
                    label='Discharge location'
                    name='discharge_location_id'
                    validate={required()}
                    required
                    reference='discharge_locations/list'
                    options={{
                      filter: { planned: !!isPlannedDischarge },
                    }}
                    disabled={deleteOnly}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='community_support'
                    customOnChange={customOnChange}
                    label='Community support'
                    fullWidth
                    disabled={deleteOnly}
                    style={{ marginRight: 0 }}
                  />
                  <FormTextField
                    name='comment'
                    label='Reason'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    validate={composeValidators([
                      required(),
                      minReasonLength(),
                    ])}
                    required
                    disabled={deleteOnly}
                  />
                  {shortLengthOfStay ? (
                    <div className={clsx(classes.warnColor)}>
                      For LOS less than 10 days must put in clear reason for
                      discharge
                    </div>
                  ) : (
                    undefined
                  )}
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <RadioInput
                    name='is_denial'
                    className={classes.radio}
                    customOnChange={customOnChange}
                    required
                    validate={required()}
                    value={parseInt(formData.is_denial)}
                    row
                    label='Is there a denial?'
                    radios={[
                      { label: 'Yes', value: 1 },
                      { label: 'No', value: 0 },
                    ]}
                    disabled={deleteOnly}
                  />
                </div>

                {(values.is_denial === 1 || values.is_denial === '1') && (
                  <ReferenceInput
                    fullWidth
                    key={formData.case_id}
                    reference='case_events/list'
                    customOnChange={customOnChange}
                    name='denial_id'
                    label='Denial'
                    options={{
                      filter: {
                        case_id: caseId || caseIdFromUrl || formData.case_id,
                        event_type: 'Denial',
                      },
                    }}
                  ></ReferenceInput>
                )}

                {isPlannedDischarge && isEdit && (
                  <Fragment>
                    <DialogContentText
                      align='left'
                      variant='h6'
                      className={classes.header}
                    >
                      New dates
                    </DialogContentText>
                    {Array.isArray(record.planned_discharge_dates) &&
                      !!record.planned_discharge_dates.length && (
                        <List>
                          {record.planned_discharge_dates.map((payload, i) => {
                            const { new_date, new_comment } = payload;
                            return (
                              <ListItem>
                                <ListItemText
                                  primary={new_date}
                                  secondary={new_comment}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      )}
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => setAddDate(true)}
                      color='primary'
                      classes={{
                        label: classes.sectionButtonLabel,
                      }}
                      disabled={deleteOnly}
                    >
                      Add new date
                    </Button>
                    {addDate && (
                      <Fragment>
                        <div className={clsx(classes.inputContainerWrap)}>
                          <FormTextField
                            name='new_date'
                            validate={composeValidators([validateDate()])}
                            required={values.discharge_status !== 'planned'}
                            customOnChange={customOnChange}
                            type='date'
                            label='New date'
                          />
                          {/* Needed for spacing */}
                          <div style={{ flex: '1 0 160px' }} />
                          <div style={{ flex: '1 0 160px' }} />
                        </div>
                        <FormTextField
                          name='new_comment'
                          label='Comments'
                          multiline
                          fullWidth
                          customOnChange={customOnChange}
                          validate={minReasonLength()}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16, justifyContent: 'space-between' }}
              >
                {((isEdit || deleteOnly) &&
                  userPermissions.indexOf('admin') > -1) ||
                userPermissions.indexOf('supervisor') > -1 ? (
                  <DeleteRecord
                    handleClose={handleClose}
                    resource='discharge'
                    id={record.id}
                    record={record}
                    onClick={
                      typeof closeAside === 'function' ? closeAside : undefined
                    }
                    onSuccess={updateTasksCount}
                  />
                ) : (
                  <span />
                )}
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting || deleteOnly}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
