import React, { Fragment, useState, useEffect } from 'react';
import {
  useRefresh,
  TextField,
  TopToolbar,
  Pagination,
  usePermissions,
  useRedirect,
  List,
  FunctionField,
  useListController,
  useUnselectAll,
} from 'react-admin';
import { Datagrid } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { CustomButton } from '../design/material-ui';
import { useStyles } from '../resources/payers/payers.styles';
import { AddIcon, EditIcon } from '../design';
import { IconButton } from '@material-ui/core';
import { ListSearch } from '../components/common/ListSearch';

const CategoriesActions = ({
  basePath,
  data = {},
  resource,
  setSearchText,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [eventOpen, setEventModal] = useState(false);

  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Categories</span>

        {userPermissions.indexOf('admin') > -1 ? (
          <div style={{ display: 'flex' }}>
            <ListSearch
              doSearch={setSearchText}
              placeholder='Search by category'
            />
            {userPermissions.indexOf('admin') > -1 ? (
              <div style={{ marginTop: 10 }}>
                <CustomButton
                  Icon={AddIcon}
                  color='#1061A0'
                  backgroundColor='#EFF4FB'
                  label='Add'
                  type='button'
                  variant='text'
                  size='small'
                  onClick={() => setEventModal(true)}
                />
              </div>
            ) : null}
          </div>
        ) : (
          //for spacing...
          <div />
        )}
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='categoryForm'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const CategoriesList = props => {
  const classes = useStyles();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [categoryRecord, setCategoryRecord] = useState();
  const [eventOpen, setEventModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    // see comment on case_communication
    if (categoryRecord) {
      setEventModal(true);
    }
  }, [categoryRecord]);
  const unselectAll = useUnselectAll('categories');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleModalClose = () => {
    setEventModal(false);
    setCategoryRecord(undefined);
  };
  const { permissions = '' } = props;
  const controllerProps = useListController({
    ...props,
    resource: 'categories',
    basePath: '/categories',
    filter: { q: searchText },
    sort: {
      field: 'category',
      order: 'ASC',
    },
  });

  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (userPermissions.indexOf('admin') === -1) {
    redirect('/');
    return null;
  }

  return (
    <Fragment>
      <List
        empty={false}
        {...controllerProps}
        exporter={false}
        bulkActionButtons={
          Array.isArray(userPermissions) &&
          userPermissions.indexOf('admin') === -1
            ? false // disable delete
            : undefined // will show default delete
        }
        className={classes.list}
        actions={<CategoriesActions setSearchText={setSearchText} />}
        sort={{
          field: 'category',
          order: 'ASC',
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid rowClick='show'>
          <TextField
            source='category'
            label='Category'
            headerClassName={classes.listItemLabel}
            sortable={false}
          />
          {userPermissions.indexOf('admin') > -1 && (
            <FunctionField
              source='status'
              label=''
              sortable={false}
              render={record => {
                return (
                  <IconButton
                    style={{ color: '#5C738E' }}
                    onClick={() => setCategoryRecord(record)}
                    size='small'
                  >
                    <EditIcon />
                  </IconButton>
                );
              }}
            />
          )}
        </Datagrid>
      </List>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={categoryRecord}
          form='categoryForm'
          isEdit
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const categoriesResource = {
  name: 'categories',
  list: CategoriesList,
  icon: AddIcon,
  options: { label: 'Categories' },
};
