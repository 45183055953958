import React, { Fragment, useState } from 'react';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  BooleanField,
  Pagination,
  Filter,
  DateInput,
  FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';
import { DateField, Datagrid, ToggleButton } from '../../components/common';
import { useGetUserDivision } from '../../hooks';
import { format, startOfMonth, subMonths, lastDayOfMonth } from 'date-fns';
import { useStyles } from './reports.styles';
import { useSidebarOpen } from '../../hooks';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      denial_type,
      case_name,
      admit_date,
      denial_received_date,
      signed_denial_received,
      last_covered,
      v_authorization,
      v_rate: { rate = {} } = {},
      resolved_flag,
      resolved_date,
      resolution_name,
      discharged_date,
      discharged_location,
      v_cases_payers,
      appealed,
      last_appeal_sent,
      last_appeal_type,
      last_appeal_status,
    } = field;
    const is_appealed =
      appealed === '1' ? 'Yes' : appealed === '0' ? 'No' : 'Unknown';
    const is_resolved = resolved_flag ? 'Yes' : 'No';
    const new_payer_date =
      Array.isArray(v_cases_payers) && v_cases_payers.length
        ? v_cases_payers[0].new_payer_date
        : null;
    const new_payer =
      Array.isArray(v_cases_payers) && v_cases_payers.length
        ? v_cases_payers[0].new_payer
        : null;
    const {
      insurance_auth_number,
      days_covered,
      payer_name,
      lob,
      authorization_type,
      extended_auth_end_date,
    } = v_authorization || {};

    return {
      denial_type,
      case_name,
      admit_date,
      denial_received_date,
      signed_denial_received,
      last_covered,
      insurance_auth_number,
      days_covered,
      payer_name,
      lob,
      authorization_type,
      rate,
      is_resolved,
      resolved_date,
      resolution_name,
      discharged_date,
      discharged_location,
      extended_auth_end_date,
      new_payer_date,
      new_payer,
      is_appealed,
      last_appeal_sent,
      last_appeal_type,
      last_appeal_status,
    };
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'denial_type',
        'case_name',
        'admit_date',
        'denial_received_date',
        'signed_denial_received',
        'last_covered',
        'insurance_auth_number',
        'days_covered',
        'payer_name',
        'lob',
        'authorization_type',
        'rate',
        'is_resolved',
        'resolved_date',
        'resolution_name',
        'discharged_date',
        'discharged_location',
        'extended_auth_end_date',
        'new_payer_date',
        'new_payer',
        'is_appealed',
        'last_appeal_sent',
        'last_appeal_type',
        'last_appeal_status',
      ],
      rename: [
        'Denial Type',
        'Case Name',
        'Admission Date',
        'Verbal Received Date',
        'Signed Received Date',
        'Last Covered',
        'Auth Number',
        'Days Covered',
        'Insurance',
        'LOB',
        'Level',
        'Rate',
        'Resolved',
        'Date Resolved',
        'Resolution',
        'Discharge Date',
        'Discharge Location',
        'Extended Auth End Date',
        'New Payer Active Date',
        'New Payer',
        'Appealed',
        'Last Appeal Sent',
        'Last Appeal Type',
        'Last Appeal Status',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `denial-report-${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  classes,
}) => {
  return (
    <TopToolbar
      className={clsx(
        classes.listActionWrapper,
        classes.flexWrap,
        classes.tabsActionWrapper,
      )}
      style={{ paddingTop: 19 }}
    >
      <div style={{ flex: '1 0 16px' }} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
        label='From'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
      />
      <DateInput
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
      />
    </Filter>
  );
};

const ReportListView = ({ formBasePath, ...props }) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  return (
    <div className={classes.listContainer}>
      <Datagrid {...props}>
        <TextField source='denial_type' label='Denial type' />
        <FunctionField
          source='case_name'
          label='Case name'
          sortBy='last_name'
          render={record => (
            <Button
              className={classes.referenceBtn}
              color='primary'
              onClick={e => e.stopPropagation()}
              component={Link}
              to={`/cases/${record.case_id}/timeline`}
            >
              {record.case_name}
            </Button>
          )}
        />
        <DateField source='admit_date' label='Admission date' />
        <DateField
          source='denial_received_date'
          label='Denial received date'
          sortable={false}
        />
        <DateField
          source='signed_denial_received'
          label='Signed received date'
        />
        <DateField source='last_covered' label='Last covered' />

        <TextField
          source='v_authorization.insurance_auth_number'
          label='Auth number'
          sortable={false}
        />
        <TextField
          source='v_authorization.days_covered'
          label='Days covered'
          sortable={false}
        />
        <TextField
          source='v_authorization.payer_name'
          label='Insurance'
          sortable={false}
        />
        <TextField source='v_authorization.lob' label='LOB' sortable={false} />
        <TextField
          source='v_authorization.authorization_type'
          label='Level'
          sortable={false}
        />
        <TextField source='rate.rate' label='Rate' sortable={false} />
        <FunctionField
          source='resolved_flag'
          label='Resolved'
          sortable={false}
          headerClassName={classes.listItemLabel}
          render={record => {
            const updatedRecord = {
              ...record,
              resolved_flag: !!record.resolved_flag,
            };
            return (
              <BooleanField
                record={updatedRecord}
                source='resolved_flag'
                headerClassName={classes.listItemLabel}
                style={{
                  color: updatedRecord.resolved_flag ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <DateField source='resolved_date' label='Date resolved' />
        <TextField source='resolution_name' label='Resolution' />
        <DateField source='discharge_date' label='Discharge date' />
        <TextField source='discharge_location' label='Discharge location' />
        <TextField
          source='v_authorization.extended_auth_end_date'
          label='Extended auth end date'
          sortable={false}
        />
        <TextField
          source='v_cases_payers[0].new_payer_date'
          label='New payer active date'
          sortable={false}
        />
        <TextField
          source='v_cases_payers[0].new_payer'
          label='New payer'
          sortable={false}
        />

        <FunctionField
          source='appealed'
          label='Appealed'
          sortable={false}
          headerClassName={classes.listItemLabel}
          render={record => {
            const updatedRecord = {
              ...record,
              appealed:
                record.appealed === '1'
                  ? true
                  : record.appealed === '0'
                  ? false
                  : record.appealed,
            };
            return updatedRecord.appealed !== 'unknown' ? (
              <BooleanField
                record={updatedRecord}
                source='appealed'
                headerClassName={classes.listItemLabel}
                style={{
                  color: updatedRecord.appealed ? '#44C87A' : '#5C738E',
                }}
              />
            ) : (
              <TextField source='appealed' />
            );
          }}
        />
        <DateField
          source='last_appeal_sent'
          label='Last appeal sent'
          sortable={false}
        />
        <TextField
          source='last_appeal_type'
          label='Last appeal type'
          sortable={false}
        />
        <TextField
          source='last_appeal_status'
          label='Last appeal status'
          sortable={false}
        />
      </Datagrid>
    </div>
  );
};

export const DenialReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(
    userDivision && userDivision !== 'all' ? userDivision : 'subacute',
  );

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <div className={classes.title} style={{ paddingTop: 30 }}>
          Denial report
        </div>
        <div style={{ padding: 30 }}>
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
            showBoth={false}
          />
        </div>
      </div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions setDivision={setDivision} classes={classes} />}
        exporter={exporter}
        sort={{ field: 'case_id', order: 'ASC' }}
        filter={{ division }}
        filters={<FiltersView classes={classes} />}
        filterDefaultValues={{
          start_date: format(
            startOfMonth(subMonths(new Date(), 1)),
            'yyyy-MM-dd',
          ),
          end_date: format(
            lastDayOfMonth(subMonths(new Date(), 1)),
            'yyyy-MM-dd',
          ),
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <ReportListView formBasePath={props} />
      </List>
    </Fragment>
  );
};

export const denialReportResource = {
  name: 'denial-report',
  list: DenialReportList,
};

function getResourceProps(props) {
  const basePath = '/denial-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/denial-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!denialReportResource.show,
        hasCreate: !!denialReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
