import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { CustomButton, CloseIcon } from '../../../design';
import { useStyles } from './modal.styles';

export const NotesModal = ({ note, open, handleClose, title }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <DialogContent>
        <h1 className={classes.title}>{title || 'Notes'}</h1>
        <DialogContentText>{note}</DialogContentText>
        <DialogActions>
          <CustomButton
            Icon={CloseIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Close'
            type='button'
            variant='text'
            size='small'
            onClick={handleClose}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
