import React from 'react';
import { useDelete, useNotify } from 'react-admin';
import { CustomButton, DeleteIcon } from '../../design';

export const DeleteRecord = ({
  resource,
  id,
  refresh,
  handleClose,
  record = {},
  onClick,
  onSuccess,
}) => {
  const notify = useNotify();
  const [deleteRecord, { loading }] = useDelete(
    resource,
    id,
    { ...record },
    {
      onSuccess: () => {
        notify('Element Deleted', 'info', {}, true);
        typeof refresh === 'function' && refresh();
        typeof handleClose === 'function' && setTimeout(handleClose, 4400);
        typeof onSuccess === 'function' && setTimeout(onSuccess, 6000);
      },
      onFailure: error => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        );
      },
      undoable: true,
      refresh: true,
    },
  );
  const handleClick = () => {
    deleteRecord();
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  return (
    <CustomButton
      Icon={DeleteIcon}
      color='rgb(220, 0, 78)'
      label='DELETE'
      type='button'
      variant='text'
      size='small'
      onClick={handleClick}
      disabled={loading}
    />
  );
};
