import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { format } from 'date-fns';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import {
  List,
  useShowController,
  ShowView,
  SimpleShowLayout,
  TextField,
  FunctionField,
  BooleanField,
  useRefresh,
  // NumberField,
  // ChipField,
  TopToolbar,
  // Filter,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
  usePermissions,
  useUnselectAll,
  useRecordContext,
} from 'react-admin';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Toolbar,
  Divider,
} from '@material-ui/core';
import { useSidebarOpen } from '../../hooks';
import {
  DateField,
  TrackUser,
  Datagrid,
  StatusFilter,
} from '../../components/common';
import { Attachments } from '../../components/common/modals';
import { useStyles } from './progress.styles';
import {
  ProgressIcon,
  EditIcon,
  AttachmentIcon,
  CustomButton,
  PdfIcon,
} from '../../design';
import { getBaseReportUrl } from '../../utils';

const CaseLevelActions = ({ showAll, setShowAll, residentCaseCount }) => {
  const classes = useStyles();
  return (
    <Toolbar
      className={classes.listActionWrapper}
      disableGutters
      style={{ minHeight: '100%' }}
    >
      <div className={classes.listActionWrapper} style={{ paddingLeft: 3 }}>
        {residentCaseCount > 1 && (
          <StatusFilter setShowAll={setShowAll} showAll={showAll} />
        )}
      </div>
    </Toolbar>
  );
};

const ProgressShowActions = ({ basePath, data = {}, title, resource }) => {
  const classes = useStyles();
  const {
    update_due,
    ProgressAuth: {
      start_date,
      raw_end,
      insurance_auth_number,
      v_case: { first_name, last_name } = {},
      authorization_type: { type } = {},
      v_cases_payer: { payers_name } = {},
    } = {},
  } = data || {};
  return (
    <TopToolbar className={classes.header}>
      <span className={classes.title}>
        {update_due
          ? `Progress ${format(
              new Date(`${update_due} 00:00`),
              'M/d/yyyy',
            )} - ${last_name}, ${first_name}`
          : 'Progress'}
      </span>
      <div className={classes.subtitle} style={{ paddingBottom: 10 }}>
        {`${payers_name ? payers_name : ''}${type ? ` - ${type}` : ''}`}
      </div>
      <div className={classes.subtitle} style={{ paddingBottom: 10 }}>
        {' '}
        {insurance_auth_number ? `Auth #: ${insurance_auth_number}` : ''}
      </div>
      <div className={classes.subtitle} style={{ paddingBottom: 10 }}>
        {start_date
          ? `Auth Dates: ${format(
              new Date(`${start_date} 00:00`),
              'M/d/yyyy',
            )}${raw_end ? ` - ${format(new Date(raw_end), 'M/d/yyyy')}` : ''}`
          : ''}
      </div>
    </TopToolbar>
  );
};

const ProgressListView = ({
  formBasePath,
  listRowStyle,
  caseId,
  isViewAdmin,
  ...props
}) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  const [attchOpen, setAttchModal] = useState(false);
  const [docIds, setDocIds] = useState(null);

  useEffect(() => {
    setAttchModal(!!docIds);
  }, [docIds]);

  return (
    <div className={classes.listContainer}>
      <Datagrid
        rowClick='show'
        rowStyle={(record, index) => listRowStyle(record, index)}
        {...props}
      >
        <FunctionField
          className={clsx(classes.showItem, classes.showFullWidth)}
          source='status'
          label=''
          render={record => {
            const {
              update_due,
              authorization_id,
              id,
              ProgressAuth: {
                start_date,
                raw_end,
                insurance_auth_number,
                authorization_type: { type } = {},
                v_case: { first_name, last_name, case_id } = {},
                v_cases_payer: { payers_name } = {},
              } = {},
            } = record;
            return (
              <IconButton
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${case_id}/progress/form?${qs.stringify({
                  nextUpdate: update_due,
                  previousPath: `/cases/${case_id}/clinical/progress`,
                  authId: authorization_id,
                  level: type,
                  payer: payers_name,
                  progressId: id,
                  authStart: start_date,
                  authEnd: raw_end,
                  insurance_auth_number: insurance_auth_number,
                  title: record.update_due
                    ? `Progress ${format(
                        new Date(`${record.update_due} 00:00`),
                        'M/d/yyyy',
                      )} - ${last_name}, ${first_name}`
                    : 'Progress',
                })}`}
              >
                <EditIcon />
              </IconButton>
            );
          }}
        />
        <DateField
          headerClassName={classes.listItemLabel}
          source='update_due'
          label='Date'
        />
        <FunctionField
          source='ProgressAuth'
          label='Case name'
          headerClassName={classes.listItemLabel}
          render={record => {
            const {
              ProgressAuth: {
                v_case: { full_name, admit_date, case_id } = {},
              } = {},
            } = record;
            return (
              <Button
                className={clsx(classes.referenceBtn, classes.listItemLabel)}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${case_id}/timeline`}
              >
                {`${full_name}-${admit_date}`}
              </Button>
            );
          }}
        />{' '}
        <FunctionField
          source='ProgressAuth'
          label='Auth #'
          headerClassName={classes.listItemLabel}
          render={record => {
            const { ProgressAuth: { insurance_auth_number } = {} } = record;
            return (
              <div
                className={clsx(classes.referenceBtn, classes.listItemLabel)}
                color='primary'
              >
                {insurance_auth_number}
              </div>
            );
          }}
        />
        <FunctionField
          source='ProgressAuth'
          label='Payer name'
          headerClassName={classes.listItemLabel}
          render={record => {
            const {
              ProgressAuth: { v_cases_payer: { payers_name } = {} } = {},
            } = record;
            return (
              <div
                className={clsx(classes.referenceBtn, classes.listItemLabel)}
                color='primary'
              >
                {payers_name}
              </div>
            );
          }}
        />
        <FunctionField
          source='ProgressAuth'
          label='Level'
          headerClassName={classes.listItemLabel}
          render={record => {
            const {
              ProgressAuth: { authorization_type: { type } = {} } = {},
            } = record;
            return (
              <div
                className={clsx(classes.referenceBtn, classes.listItemLabel)}
                color='primary'
              >
                {type}
              </div>
            );
          }}
        />
        <DateField
          source='submitted_date'
          label='Submitted to insurance'
          headerClassName={classes.listItemLabel}
        />
        <FunctionField
          source='submitted_via'
          label='Submitted via'
          headerClassName={classes.listItemLabel}
          render={record =>
            record.submitted_via ? startCase(record.submitted_via) : null
          }
        />
        <TrackedTextField
          source='submitted_via_notes'
          label='Submit notes'
          headerClassName={classes.listItemLabel}
        />
        <FunctionField
          headerClassName={classes.listItemLabel}
          source='email_log_id'
          label='Submitted fax/email'
          render={record => {
            const { email_log_id, email_log } = record;
            const emailLabel = email_log?.label || null;
            if (!email_log_id || !emailLabel) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${caseId}/emails?direction=Outbound&${qs.stringify({
                  log_id: email_log_id,
                })}`}
              >
                {emailLabel}
              </Button>
            );
          }}
        />
        <DateField
          headerClassName={classes.listItemLabel}
          source='start_date'
          label='Start date'
        />
        <DateField
          headerClassName={classes.listItemLabel}
          source='end_date'
          label='End date'
        />
        <TrackedTextField
          className={clsx(classes.showItem, classes.showFullWidth)}
          source='comments'
          label='Comments'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='pt_minutes_week'
          label='PT minutes weekly'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='ot_minutes_week'
          label='OT minutes weekly'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='slp_minutes_week'
          label='SLP minutes weekly'
        />
        <FunctionField
          source='vent'
          label='Vent'
          headerClassName={classes.listItemLabel}
          render={record => {
            return (
              <TrackedBooleanField
                record={record}
                source='vent'
                headerClassName={classes.listItemLabel}
                style={{
                  color: record.vent ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <FunctionField
          source='iv'
          label='IV'
          headerClassName={classes.listItemLabel}
          render={record => {
            return (
              <TrackedBooleanField
                record={record}
                source='iv'
                headerClassName={classes.listItemLabel}
                style={{
                  color: record.iv ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <FunctionField
          source='g_tube'
          label='G-Tube'
          headerClassName={classes.listItemLabel}
          render={record => {
            return (
              <TrackedBooleanField
                record={record}
                source='g_tube'
                headerClassName={classes.listItemLabel}
                style={{
                  color: record.g_tube ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='wound_count'
          label='Wounds'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='wound_area'
          label='Wound area'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='cognition'
          label='Cognition'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='weight_bearing_status'
          label='Weight bearing status'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='weight_bearing_area'
          label='Weight bearing area'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='bed_mobility'
          label='Bed mobility'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='transfers'
          label='Transfers'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='wc_mobility'
          label='W/C mobility'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='stair_mobility'
          label='Stairs'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='ambulation'
          label='Ambulation'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='range_of_motion'
          label='Range of motion'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='assistive_device'
          label='Assistive device in facility'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='feeding'
          label='Feeding'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='grooming'
          label='Grooming'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='bathing'
          label='Bathing'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='dressing_upper_body'
          label='Dressing - upper body'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='dressing_lower_body'
          label='Dressing - lower body'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='toileting_transfer'
          label='Toileting/hygiene'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='transfer_tub'
          label='Transfer tub/shower'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='home_management'
          label='Household management'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='diet'
          label='Diet'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='score'
          label='Score'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='test_name'
          label='Name of test'
        />
        <TrackedTextField
          className={classes.listItem}
          headerClassName={classes.listItemLabel}
          source='test_detail'
          label='Explanation'
        />
        <FunctionField
          label='Attachments'
          render={record => {
            const { document_ids } = record;
            return Array.isArray(document_ids) && document_ids.length ? (
              <CustomButton
                className={classes.attchListIcon}
                Icon={AttachmentIcon}
                color='#5C738E'
                variant='text'
                badgeContent={document_ids.length}
                onClick={e => {
                  e.stopPropagation();
                  setDocIds(document_ids);
                }}
                notRed
                fullSize
              />
            ) : null;
          }}
        />
      </Datagrid>
      {attchOpen && (
        <Attachments
          open={attchOpen}
          handleClose={() => setDocIds(null)}
          document_ids={docIds}
          title='Progress'
          // eventId={record.id}
          eventName='progress'
          // caseId={record.case_id}
          refresh={() => {}}
          add={false}
          isViewAdmin={isViewAdmin}
        />
      )}
    </div>
  );
};

export const ProgressList = ({ staticContext, ..._props }) => {
  const { formBasePath, caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');
  const [showAll, setShowAll] = useState(true);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const isFirstRender = useRef(true);
  const isAdminOrSupervisor =
    permissions?.indexOf('admin') > -1 ||
    permissions?.indexOf('supervisor') > -1;

  const isViewAdmin =
    permissions?.indexOf('view_admin') > -1 ||
    permissions?.indexOf('admin_assistant') > -1;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  const unselectAll = useUnselectAll('case-progress');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleShowAll = useCallback(value => {
    setShowAll(value);
  }, []);

  const listRowStyle = (record, index, activeId) => {
    if (
      caseId &&
      parseInt(record?.ProgressAuth?.v_case.case_id) !== parseInt(caseId)
    ) {
      return { backgroundColor: '#EFF4FB' };
    }
  };

  return (
    <List
      empty={false}
      {...props}
      exporter={false}
      bulkActionButtons={
        Array.isArray(userPermissions) && isAdminOrSupervisor
          ? undefined // will show default delete
          : false // disable delete
      }
      className={clsx(classes.list)}
      actions={
        <CaseLevelActions
          residentCaseCount={residentCaseCount}
          showAll={showAll}
          setShowAll={toggleShowAll}
        />
      }
      filter={{ all: showAll }}
    >
      <ProgressListView
        caseId={caseId}
        formBasePath={props}
        listRowStyle={listRowStyle}
        isViewAdmin={isViewAdmin}
      />
    </List>
  );
};

export const ProgressShow = ({ staticContext, ..._props }) => {
  const facilityId = useSelector(state => state.facility.id);
  const token = localStorage.getItem('token');
  const baseUrl = getBaseReportUrl();
  const classes = useStyles();
  const { formBasePath, caseId, ...props } = getResourceProps(_props);
  const [attchOpen, setAttchModal] = useState(false);
  const showProps = useShowController({ ...props });
  const { record } = showProps;

  const { permissions = '' } = usePermissions();
  const isViewAdmin =
    permissions?.indexOf('view_admin') > -1 ||
    permissions?.indexOf('admin_assistant') > -1;

  const exportToPdf = id => {
    window.open(
      `${baseUrl}/download?${stringify({
        _facilityId: facilityId,
        token,
        report: 'progress',
        _start: 0,
        _end: 1000,
        id: id,
      })}`,
      '_blank',
    );
  };
  return (
    <Fragment>
      <ShowView actions={<ProgressShowActions />} {...showProps}>
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          {/* needed for spacing */}
          <div className={classes.showItem} />
          <FunctionField
            style={{ paddingRight: 4 }}
            source='status'
            label=''
            render={record => {
              const {
                update_due,
                authorization_id,
                id,
                ProgressAuth: {
                  start_date,
                  raw_end,
                  insurance_auth_number,
                  v_case: { first_name, last_name } = {},
                  authorization_type: { type } = {},
                  v_cases_payer: { payers_name } = {},
                } = {},
              } = record;
              return (
                <CustomButton
                  Icon={EditIcon}
                  color='#1061A0'
                  backgroundColor='#EFF4FB'
                  label='Edit'
                  variant='text'
                  component={Link}
                  to={`${formBasePath}/form?${qs.stringify({
                    nextUpdate: update_due,
                    previousPath: `${props.basePath}/${props.id}/show`,
                    authId: authorization_id,
                    level: type,
                    payer: payers_name,
                    authStart: start_date,
                    authEnd: raw_end,
                    insurance_auth_number: insurance_auth_number,
                    progressId: id,
                    title: record.update_due
                      ? `Progress ${format(
                          new Date(`${record.update_due} 00:00`),
                          'M/d/yyyy',
                        )} - ${last_name}, ${first_name}`
                      : 'Progress',
                  })}`}
                  disabled={isViewAdmin}
                />
              );
            }}
          />

          <FunctionField
            style={{ paddingRight: 4 }}
            source='status'
            label=''
            render={record =>
              record.document_ids && record.document_ids.length ? (
                <CustomButton
                  Icon={AttachmentIcon}
                  color='#1061A0'
                  backgroundColor='#EFF4FB'
                  label='View attachment'
                  badgeContent={record.document_ids.length}
                  variant='text'
                  onClick={() => setAttchModal(true)}
                  notRed
                />
              ) : null
            }
          />

          <FunctionField
            source='status'
            label=''
            render={record => (
              <CustomButton
                Icon={PdfIcon}
                style={{
                  color: '#1061A0',
                  backgroundColor: '#EFF4FB',
                }}
                label='PDF Export'
                variant='text'
                onClick={() => exportToPdf(record.id)}
              />
            )}
          />

          <DateField
            className={classes.showItem}
            headerClassName={classes.showItemLabel}
            source='start_date'
            label='Start date'
          />
          <DateField
            className={classes.showItem}
            source='end_date'
            label='End date'
          />
          <TextField
            className={clsx(classes.showItem, classes.marginRight0)}
            source='status'
            label='Status'
          />
          <DateField
            className={classes.showItem}
            source='submitted_date'
            label='Submitted to insurance date'
          />
          <FunctionField
            source='submitted_via'
            label='Submitted via'
            className={classes.showItem}
            render={record =>
              record.submitted_via ? startCase(record.submitted_via) : null
            }
          />
          <TextField
            source='submitted_via_notes'
            label='Submit notes'
            className={classes.showItem}
            style={{ color: setColor(record, 'submitted_via_notes') }}
          />
          <FunctionField
            className={classes.showItem}
            source='email_log_id'
            label='Submitted fax/email'
            render={record => {
              const { email_log_id, email_log } = record;
              const emailLabel = email_log?.label || null;
              if (!email_log_id || !emailLabel) {
                return null;
              }
              return (
                <Button
                  className={classes.referenceBtn}
                  color='primary'
                  onClick={e => e.stopPropagation()}
                  component={Link}
                  to={`/cases/${caseId}/emails?direction=Outbound&${qs.stringify(
                    {
                      log_id: email_log_id,
                    },
                  )}`}
                >
                  {emailLabel}
                </Button>
              );
            }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='comments'
            label='Comments'
            style={{ color: setColor(record, 'comments') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='plof'
            label='PLOF'
            style={{ color: setColor(record, 'plof') }}
          />

          <Divider style={{ margin: 5, backgroundColor: '#C5DAF1' }} />

          <FunctionField
            className={clsx(
              classes.showItem,
              classes.showFullWidth,
              classes.marginBottom0,
            )}
            source='status'
            label=''
            render={record => (
              <div className={classes.sectionHeader}>Rehab Details</div>
            )}
          />
          <TextField
            className={classes.showItem}
            source='pt_minutes_week'
            label='PT minutes weekly'
            style={{ color: setColor(record, 'pt_minutes_week') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.marginRight0)}
            source='ot_minutes_week'
            label='OT minutes weekly'
            style={{ color: setColor(record, 'ot_minutes_week') }}
          />
          <TextField
            className={classes.showItem}
            source='slp_minutes_week'
            label='SLP minutes weekly'
            style={{ color: setColor(record, 'slp_minutes_week') }}
          />
          <FunctionField
            className={clsx(
              classes.showItem,
              classes.showFullWidth,
              classes.marginBottom0,
            )}
            source='status'
            label=''
            render={record => (
              <div className={classes.sectionHeader}>Nursing details</div>
            )}
          />
          <FunctionField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='vent'
            label=''
            render={record => {
              return (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!record.vent}
                        name='vent'
                        style={{
                          color: setColor(record, 'vent', {
                            color: '#5C738E',
                          }),
                        }}
                        disabled
                      />
                    }
                    label={<span className={classes.checkbox}>Vent</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={!!record.iv}
                        name='iv'
                        style={{
                          color: setColor(record, 'iv', {
                            color: '#5C738E',
                          }),
                        }}
                      />
                    }
                    label={<span className={classes.checkbox}>IV</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        checked={!!record.g_tube}
                        name='g_tube'
                        style={{
                          color: setColor(record, 'g_tube', {
                            color: '#5C738E',
                          }),
                        }}
                      />
                    }
                    label={<span className={classes.checkbox}>G-Tube</span>}
                  />
                </FormGroup>
              );
            }}
          />
          <DateField
            className={classes.showItem}
            source='iv_start_date'
            label='IV start date'
            style={{ color: setColor(record, 'iv_start_date') }}
          />
          <DateField
            className={classes.showItem}
            source='iv_end_date'
            label='IV end date'
            style={{ color: setColor(record, 'iv_end_date') }}
          />
          <div className={classes.showItem} />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='iv_notes'
            label='IV notes'
            style={{ color: setColor(record, 'iv_notes') }}
          />
          <TextField
            className={classes.showItem}
            source='wound_count'
            label='Wounds'
            style={{ color: setColor(record, 'wound_count') }}
          />
          <TextField
            className={classes.showItem}
            source='wound_area'
            label='Wound area'
            style={{ color: setColor(record, 'wound_area') }}
          />
          <EmptyCell className={classes.showItem} />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='wound_comment'
            label='Wound comments'
            style={{ color: setColor(record, 'wound_comment') }}
          />
          <TextField
            source='weight_bearing_status'
            label='Weight bearing status'
            className={classes.showItem}
            style={{ color: setColor(record, 'weight_bearing_status') }}
          />
          <TextField
            className={classes.showItem}
            source='weight_bearing_area'
            label='Weight bearing area'
            style={{ color: setColor(record, 'weight_bearing_area') }}
          />
          <EmptyCell className={classes.showItem} />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='weight_bearing_comment'
            label='Weight bearing comments'
            style={{ color: setColor(record, 'weight_bearing_comments') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='pain_level'
            label='Pain level, location & treatment'
            style={{ color: setColor(record, 'pain_level') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='misc_nursing'
            label='Misc other daily skilled nursing needs'
            style={{ color: setColor(record, 'misc_nursing') }}
          />
          <FunctionField
            className={clsx(
              classes.showItem,
              classes.showFullWidth,
              classes.marginBottom0,
            )}
            source='status'
            label=''
            render={record => (
              <div className={classes.sectionHeader}>
                PT performance section
              </div>
            )}
          />
          <TextField
            className={classes.showItem}
            source='bed_mobility'
            label='Bed mobility'
            style={{ color: setColor(record, 'bed_mobility') }}
          />
          <TextField
            className={classes.showItem}
            source='transfers'
            label='Transfers'
            style={{ color: setColor(record, 'transfers') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.marginRight0)}
            source='wc_mobility'
            label='W/C mobility'
            style={{ color: setColor(record, 'wc_mobility') }}
          />
          <TextField
            className={classes.showItem}
            source='stair_mobility'
            label='Stairs'
            style={{ color: setColor(record, 'stair_mobility') }}
          />
          <TextField
            className={classes.showItem}
            source='ambulation'
            label='Ambulation'
            style={{ color: setColor(record, 'ambulation') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.marginRight0)}
            source='range_of_motion'
            label='Range of motion'
            style={{ color: setColor(record, 'range_of_motion') }}
          />
          <TextField
            className={classes.showItem}
            source='assistive_device'
            label='Assistive device in facility'
            style={{ color: setColor(record, 'assistive_device') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='st_pt_goals'
            label='Short term PT goals'
            style={{ color: setColor(record, 'st_pt_goals') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='lt_pt_goals'
            label='Long term PT goals'
            style={{ color: setColor(record, 'lt_pt_goals') }}
          />
          <FunctionField
            className={clsx(
              classes.showItem,
              classes.showFullWidth,
              classes.marginBottom0,
            )}
            source='status'
            label=''
            render={record => (
              <div className={classes.sectionHeader}>
                OT performance section
              </div>
            )}
          />
          <TextField
            className={classes.showItem}
            source='feeding'
            label='Feeding'
            style={{ color: setColor(record, 'feeding') }}
          />
          <TextField
            className={classes.showItem}
            source='grooming'
            label='Grooming'
            style={{ color: setColor(record, 'grooming') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.marginRight0)}
            source='bathing'
            label='Bathing'
            style={{ color: setColor(record, 'bathing') }}
          />
          <TextField
            className={classes.showItem}
            source='dressing_upper_body'
            label='Dressing - upper body'
            style={{ color: setColor(record, 'dressing_upper_body') }}
          />
          <TextField
            className={classes.showItem}
            source='dressing_lower_body'
            label='Dressing - lower body'
            style={{ color: setColor(record, 'dressing_lower_body') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.marginRight0)}
            source='toileting_transfer'
            label='Toileting/hygiene'
            style={{ color: setColor(record, 'toileting_transfer') }}
          />
          <TextField
            className={classes.showItem}
            source='transfer_tub'
            label='Transfer tub/shower'
            style={{ color: setColor(record, 'transfer_tub') }}
          />
          <TextField
            className={classes.showItem}
            source='home_management'
            label='Household management'
            style={{ color: setColor(record, 'home_management') }}
          />
          <EmptyCell className={classes.showItem} />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='st_ot_goals'
            label='Short term OT goals'
            style={{ color: setColor(record, 'st_ot_goals') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='lt_ot_goals'
            label='Long term OT goals'
            style={{ color: setColor(record, 'lt_ot_goals') }}
          />
          <FunctionField
            className={clsx(
              classes.showItem,
              classes.showFullWidth,
              classes.marginBottom0,
            )}
            source='status'
            label=''
            render={record => (
              <div className={classes.sectionHeader}>Speech therapy</div>
            )}
          />
          <TextField
            className={classes.showItem}
            source='diet'
            label='Diet'
            style={{ color: setColor(record, 'diet') }}
          />
          <TextField
            className={classes.showItem}
            source='cognition'
            label='Cognition'
            style={{ color: setColor(record, 'cognition') }}
          />
          <EmptyCell className={classes.showItem} />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='slp_notes'
            label='SLP notes'
            style={{ color: setColor(record, 'slp_notes') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='st_slp_goals'
            label='Short term SLP goals'
            style={{ color: setColor(record, 'st_slp_goals') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.showFullWidth)}
            source='lt_slp_goals'
            label='Long term SLP goals'
            style={{ color: setColor(record, 'lt_slp_goals') }}
          />
          <FunctionField
            className={clsx(
              classes.showItem,
              classes.showFullWidth,
              classes.marginBottom0,
            )}
            source='status'
            label=''
            render={record => (
              <div className={classes.sectionHeader}>Standardized test</div>
            )}
          />
          <TextField
            className={classes.showItem}
            source='score'
            label='Score'
            style={{ color: setColor(record, 'score') }}
          />
          <TextField
            className={classes.showItem}
            source='test_name'
            label='Name of test'
            style={{ color: setColor(record, 'test_name') }}
          />
          <TextField
            className={clsx(classes.showItem, classes.marginRight0)}
            source='test_detail'
            label='Explanation'
            style={{ color: setColor(record, 'test_detail') }}
          />
          <FunctionField
            source='progressCreatedBy.name'
            label='Created by'
            className={classes.userTracking}
            render={record => {
              const { progressCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={progressCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='progressUpdatedBy.name'
            label='Last modified'
            className={classes.userTracking}
            render={record => {
              const { progressUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={progressUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
        </SimpleShowLayout>
      </ShowView>
      {attchOpen && (
        <Attachments
          open={attchOpen}
          handleClose={() => setAttchModal(false)}
          document_ids={record.document_ids}
          title='Progress'
          eventId={record.id}
          eventName='progress'
          caseId={record.case_id}
          refresh={() => {}}
          add={false}
          isViewAdmin={isViewAdmin}
        />
      )}
    </Fragment>
  );
};

const EmptyCell = () => {
  const classes = useStyles();
  return (
    <FunctionField
      className={classes.showItem}
      source='status'
      label=''
      render={record => ''}
    />
  );
};

export const progressResource = {
  name: 'progress',
  list: ProgressList,
  icon: ProgressIcon,
  show: ProgressShow,
};

function getResourceProps(props) {
  const { match: { params: { id: caseId, progressId } = {} } = {} } = props;
  const formBasePath = `/cases/${caseId}/progress`;
  const basePath = formBasePath;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-progress',
        hasList: true,
        hasEdit: !!progressResource.edit,
        hasShow: !!progressResource.show,
        hasCreate: !!progressResource.create,
      };
  const updatedProps = {
    ...props,
    basePath,
    caseId,
    formBasePath,
    ...resource,
  };
  if (progressId) {
    updatedProps.id = progressId;
  }
  return updatedProps;
}

const setColor = (record, name, style) => {
  const originalColor = style?.color || 'black';
  if (record?.prevProgress) {
    return record[name] !== record?.prevProgress[name] ? 'red' : originalColor;
  } else return originalColor;
};

const TrackedTextField = ({
  source,
  className,
  headerClassName,
  label,
  style,
}) => {
  const record = useRecordContext();
  return (
    <TextField
      label={label}
      headerClassName={headerClassName}
      className={className}
      style={{ ...style, color: setColor(record, source, style) }}
      source={source}
    />
  );
};
const TrackedBooleanField = ({
  source,
  className,
  headerClassName,
  label,
  style,
}) => {
  const record = useRecordContext();
  return (
    <BooleanField
      label={label}
      headerClassName={headerClassName}
      className={className}
      style={{ ...style, color: setColor(record, source, style) }}
      source={source}
    />
  );
};
