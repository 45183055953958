import { useGetUserRoles } from './useGetUserRoles';

export function useGetTaskPermissions() {
  const {
    profileLoaded,
    allRoles = [],
    has = () => false,
    hasOneOf = () => false,
  } = useGetUserRoles();

  /** @return {import('./useGetUserRoles').Role[]} with an empty array representing all roles */
  function getAssignToRoles() {
    if (
      hasOneOf([
        'team_leader',
        'supervisor',
        'admin',
        'view_admin',
        'admin_assistant',
      ])
    )
      return [];

    if (has('backend_reviewer')) return [...allRoles, 'case_manager'];

    return allRoles;
  }

  /** @type {import('./useGetUserRoles').Role[]} */
  const rolesAllowedToDeleteOwnTasks = [
    'admin',
    'case_manager',
    'team_leader',
    'quality_reviewer',
    'supervisor',
    'view_admin',
    'admin_assistant',
  ];

  /** @type {import('./useGetUserRoles').Role[]} */
  const rolesAllowedToDeleteOthersTasks = [
    'admin',
    'team_leader',
    'quality_reviewer',
    'supervisor',
    'view_admin',
    'admin_assistant',
  ];

  return {
    isLoaded: profileLoaded,
    assignToRoles: getAssignToRoles(),
    canDeleteOwnTasks: hasOneOf(rolesAllowedToDeleteOwnTasks),
    canDeleteOthersTasks: hasOneOf(rolesAllowedToDeleteOthersTasks),
  };
}
