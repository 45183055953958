import React, {
  Fragment,
  useEffect,
  cloneElement,
  useState,
  useCallback,
} from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  List,
  TextField,
  FunctionField,
  useNotify,
  TopToolbar,
  DateField,
  useRefresh,
  Pagination,
  useDataProvider,
  cacheDataProviderProxy,
  usePermissions,
} from 'react-admin';
import { addHours, isBefore } from 'date-fns';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Button, IconButton, Badge } from '@material-ui/core';
import { NewEvent, DeleteModal } from '../components/common/modals';
import { authGet } from '../server';
import {
  ToggleButton,
  Datagrid,
  FilterChips,
  FilterMenu,
} from '../components/common';
import { constants } from '../utils';
import { useGetUserDivision } from '../hooks';
import { InboxIcon, PdfIcon, ViewIcon } from '../design';
import { useStyles } from './facility-faxLogs.styles';
import { uiActions } from '../state/actions';
import { useDispatch } from 'react-redux';

const { faxInboxFilters } = constants;

const FacilityLevelActions = ({
  displayedFilters,
  exporter,
  filters,
  loading,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  activeStatusFilters = {},
  filterActions,
  setFilter,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <div>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title} style={{ paddingRight: 20 }}>
              Received Faxes
            </span>
            <ToggleButton setDivision={setDivision} />
          </div>

          <div style={{ display: 'flex', marginLeft: -36 }}>
            {filters &&
              cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
              })}
            <FilterMenu
              filterActions={filterActions}
              filters={faxInboxFilters}
            />
            <FilterChips
              activeFilters={activeStatusFilters}
              filterActions={filterActions}
              disabled={loading}
            />
          </div>
        </div>
      </TopToolbar>
    </Fragment>
  );
};

export const FaxInboxList = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(
    userDivision && userDivision !== 'all' ? userDivision : 'subacute',
  );
  const [activeStatusFilters, setActiveStatusFilters] = useState({
    Unassigned: 'Unassigned',
  });
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [fax, setFax] = useState(false);
  const notify = useNotify();
  const [deleting, setDeleting] = useState({
    active: false,
    id: 0,
    name: '',
  });

  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const updateRecordStatus = function(id) {
    dataProvider
      .update('fax-inbox', {
        id,
        data: { dismissed: null },
      })
      .then(({ data }) => {
        refresh();
        dispatch(uiActions.getFaxInboxCount());
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  const handleViewClick = async document_id => {
    const response = await authGet(`/documents/url/${document_id}`);
    if (response.error) {
      notify('ra.notification.http_error', 'warning');
    }
    const win = window.open(response.data.url, '_blank');
    win.focus();
  };
  useEffect(() => {
    // see comment on case_communication
    if (fax) {
      setEventModal(true);
    }
  }, [fax]);
  useEffect(() => {
    //run once to update ui
    dispatch(uiActions.getFaxInboxCount());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setEventModal(false);
    setFax(undefined);
  };
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={false}
        className={clsx(classes.list)}
        actions={
          <FacilityLevelActions
            setDivision={setDivision}
            activeStatusFilters={activeStatusFilters}
            filterActions={filterActions}
          />
        }
        filter={{ division, filtered: Object.keys(activeStatusFilters) }}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
        <Datagrid>
          <PdfIcon className={classes.fileIcons} />
          <TextField source='sender' label='Fax sender' />
          <TextField source='page_count' label='Page count' />
          <TextField source='size' label='Size' sortable={false} />
          <DateField source='date_received' label='Date received' showTime />
          <FunctionField
            source='case_name'
            label='Case name'
            sortable={false}
            render={record => {
              if (record.v_case) {
                const { case_name, id } = record.v_case;
                if (!case_name || !id) {
                  return null;
                }
                return (
                  <Button
                    className={classes.referenceBtn}
                    color='primary'
                    onClick={e => e.stopPropagation()}
                    component={Link}
                    to={`/cases/${id}/timeline`}
                  >
                    {case_name}
                  </Button>
                );
              }
            }}
          />
          <FunctionField
            source=''
            label=''
            render={record => {
              return (
                <IconButton
                  className={classes.referenceBtn}
                  color='primary'
                  onClick={() => setFax(record)}
                  disabled={!record.facility_id || isViewAdmin}
                >
                  {record.case_id ? 'Reassign' : 'Assign to Case'}
                </IconButton>
              );
            }}
          />
          <FunctionField
            source=''
            label=''
            render={record => {
              const { case_id, dismissed } = record;
              return (
                !case_id &&
                (dismissed ? (
                  <Button
                    className={classes.referenceBtn}
                    color='primary'
                    onClick={() => updateRecordStatus(record.id, null)}
                    disabled={isViewAdmin}
                  >
                    Undismiss
                  </Button>
                ) : (
                  <Button
                    className={classes.referenceBtn}
                    color='primary'
                    onClick={() => {
                      setDeleting({
                        active: true,
                        id: record.id,
                        name: `fax received from ${record.sender}`,
                      });
                    }}
                    disabled={isViewAdmin}
                  >
                    Dismiss
                  </Button>
                ))
              );
            }}
          />
          <FunctionField
            label=''
            source='id'
            render={record => {
              const id = record.inbound_fax_docs
                ? record.inbound_fax_docs[0]?.document_id
                : null;
              return id ? (
                <IconButton onClick={() => handleViewClick(id)}>
                  <ViewIcon className={classes.viewIcon} />
                </IconButton>
              ) : (
                getDisplayStatus(record)
              );
            }}
          />
        </Datagrid>
      </List>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          form='assignFaxForm'
          refresh={refresh}
          record={fax}
          isEdit={!!fax.facility_id}
        />
      )}

      <DeleteModal
        open={deleting.active}
        onClose={() => setDeleting({ active: false })}
        onDelete={(dispatch(uiActions.getFaxInboxCount()), refresh)}
        url={'fax-inbox/' + deleting.id}
        name={deleting.name}
        successMessage='Dismissed fax'
      />
    </Fragment>
  );
};

const BadgeIcon = ({ ...props }) => {
  const classes = useStyles();
  const faxInboxCount = useSelector(state => get(state, 'ui.faxInboxCount', 0));
  return (
    <Badge
      color='secondary'
      badgeContent={faxInboxCount}
      classes={{ badge: classes.badge }}
    >
      <InboxIcon {...props} />
    </Badge>
  );
};

export const faxInboxResource = {
  name: 'fax-inbox',
  list: FaxInboxList,
  icon: BadgeIcon,
};
function getDisplayStatus(record) {
  return isBefore(new Date(), addHours(new Date(record.created_at), 2))
    ? 'Pending'
    : 'Failed';
}
