import jwtDecode from 'jwt-decode';
import { authRoles } from './authRoles';
import { setAuthRequestToken } from './AuthRequest';
import { reduxStore } from '../createAdminStore';
import { userActions, listActions } from '../state/actions';
const { REACT_APP_LOGIN_URL } = process.env;

export const authProvider = {
  // called when the user attempts to log in
  login: async params => {
    let response;
    try {
      response = await loginUser(params);
    } catch (err) {
      return Promise.reject(err);
    }
    Promise.resolve();
    return response;
  },
  logout: async () => {
    // called when the user clicks on the logout button
    await reduxStore().dispatch(userActions.logout());
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    localStorage.removeItem('lastActive');
    localStorage.removeItem('facility_id');
    localStorage.removeItem('residentsDivision');
    localStorage.removeItem('residentsInsurance');
    localStorage.removeItem('residentsFilters');
    localStorage.removeItem('residentsCaseManager');
    localStorage.removeItem('mfaRequired');
    localStorage.removeItem('cellPhone');
    window.updateActivityTime && window.updateActivityTime.cancel();
    authUser.reset();
    reduxStore().dispatch(userActions.clearUserProfile());
    return Promise.resolve();
  },
  checkError: error => {
    // called when the API returns an error
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('facility_id');
      authUser.reset();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: params => {
    // called when the user navigates to a new location
    if (params.page === 'forgot-password') {
      return Promise.resolve();
    }
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  getPermissions: params => {
    // called when the user navigates to a new location that requires to check the user’s permissions.
    // if (params.page === 'forgot-password') {
    //   return Promise.resolve();
    // }
    const roles = localStorage.getItem('roles');
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
};

export async function loginUser(params) {
  const { email, password, isMfa, mfaToken } = params;
  let requiresMfa;
  let resToken;
  let cellPhone;
  if (isMfa) {
    requiresMfa = false;
    resToken = mfaToken;
  } else {
    const request = new Request(REACT_APP_LOGIN_URL, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'include',
    });
    await fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw response;
        }
        return response.json();
      })
      .then(({ requires_mfa, cell_phone, token /* , expiration */ }) => {
        requiresMfa = requires_mfa;
        resToken = token;
        cellPhone = cell_phone;
      });
  }
  const { load, token_expiration } = authUser;
  localStorage.setItem('token', resToken);
  load(resToken);
  setAuthRequestToken(resToken, token_expiration);
  localStorage.setItem('roles', authUser.roles);
  localStorage.setItem('jwtExpiry', authUser.token_expiration);
  localStorage.setItem('mfaRequired', requiresMfa);
  localStorage.setItem('cellPhone', cellPhone);
  if (!requiresMfa) {
    await reduxStore().dispatch(userActions.getUserProfile());
    reduxStore().dispatch(userActions.checkUsersTime());
    await reduxStore().dispatch(listActions.getStaticLists());
  }
  return !!requiresMfa;
}

/** Returns the auth token, if any. */
export function authToken() {
  return localStorage.getItem('token');
}

/** Authorized user info. */
export const authUser = {
  id: 0,
  // loggedIn: false,
  roles: [],
  isAdmin: false,
  isSupervisor: false,
  isCase_manager: false,

  /**
   * Loads `authUser` from the given token, if any, and returns a success bool.
   * @param {string} [token] Optional token, otherwise loaded from localStorage.
   */
  load(token) {
    try {
      if (!token) {
        token = authToken();
      }
      if (!token) {
        return false;
      }
      /** @type {AuthTokenInfo} */
      const tokenInfo = jwtDecode(token);
      if (tokenInfo) {
        const roles = tokenInfo.roles;
        authUser.id = tokenInfo.userId;
        authUser.loggedIn = true;
        authUser.roles = roles;
        authUser.token_expiration = tokenInfo.exp;
        authRoles.forEach(ar => {
          authUser[ar.prop] = roles.indexOf(ar.id) > -1;
        });
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  /**
   * Resets all `authUser` props.
   */
  reset() {
    authUser.id = 0;
    authUser.loggedIn = false;
    authUser.roles = [];
    authUser.token_expiration = undefined;
    authRoles.forEach(ar => (authUser[ar.prop] = false));
  },
};
