import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  FileInput,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  validateDate,
  minReasonLength,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { constants } from '../../../../utils';

const {
  pharmLogBillingChoices,
  pharmLogStatuses,
  pharmLogVendorChoices,
} = constants;

export const PharmLogForm = ({ caseId, record = {}, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    case_id: caseId,
  });
  useEffect(() => {
    if (isEdit && record) {
      const fields = sanitizeFields(record);
      setFormData(f => ({
        ...f,
        ...fields,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('pharmacy', {
          id: record.id,
          data: { ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('pharmacy', { data: { ...value } })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div style={{ paddingBottom: 15 }}>Case details</div>
                {!isEdit && !caseId && (
                  <div style={{ paddingBottom: 15, marginRight: 3 }}>
                    <AutocompleteInput
                      reference='cases/list'
                      customOnChange={customOnChange}
                      name='case_id'
                      label='Case'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      options={{
                        filter: { division: 'all', active: 0 },
                      }}
                    />
                  </div>
                )}
                {values.case_id >= 0 && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <ReferenceInput
                      key={values.case_id}
                      reference='pharm-log-payers/list'
                      customOnChange={customOnChange}
                      name='payer_id'
                      label='Payer'
                      validate={required()}
                      required
                      fullWidth
                      options={{
                        filter: { case_id: values.case_id },
                      }}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                )}
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='pharm-log-statuses/list'
                    customOnChange={customOnChange}
                    label='Status'
                    name='pharm_log_status_id'
                  />
                  <ReferenceInput
                    reference='facility-users/list'
                    customOnChange={customOnChange}
                    name='user_id'
                    label='Case manager'
                    validate={required()}
                    required
                    options={{
                      filter: {
                        division: 'subacute',
                        is_active: !isEdit,
                      },
                    }}
                    shouldFetchMore
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='facility-users/list'
                    customOnChange={customOnChange}
                    name='agent_assigned_id'
                    label='Agent assigned'
                    options={{
                      filter: {
                        all_facilities: true,
                        roles: 'supervisor',
                        specific_role: true,
                        is_active: !isEdit,
                      },
                    }}
                    shouldFetchMore
                  />
                  <FormTextField
                    name='prescribing_doctor'
                    customOnChange={customOnChange}
                    label='Prescribing doctor'
                  />
                </div>
                <div style={{ paddingTop: 10, paddingBottom: 15 }}>
                  Medication details
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='dispense_date'
                    customOnChange={customOnChange}
                    label='Dispense date'
                    type='date'
                    validate={composeValidators([required(), validateDate()])}
                    required
                  />
                  <FormTextField
                    name='rx_number'
                    customOnChange={customOnChange}
                    label='Rx number'
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <SelectComponent
                    name='drug_route'
                    customOnChange={customOnChange}
                    label='Drug Route'
                    choices={drugRouteList}
                    fullWidth
                    style={{ width: 264, marginRight: 22 }}
                  />
                  <AutocompleteInput
                    className={classes.input}
                    trimLength={1}
                    customOnChange={customOnChange}
                    reference='medications/list'
                    name='medication_id'
                    label='Medication'
                    openOnFocus
                    autocompleteProps={{ openOnFocus: true }}
                    viewAll
                    inputStyle={{ marginLeft: 0, marginRight: 0 }}
                    fullWidth
                    initialSelectedItem={
                      isEdit && record?.service
                        ? record?.service?.service
                        : undefined
                    }
                    clearable
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='dosage'
                    customOnChange={customOnChange}
                    label='Dosage'
                  />
                  <FormTextField
                    name='strength'
                    customOnChange={customOnChange}
                    label='Strength'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='national_drug_code'
                    customOnChange={customOnChange}
                    label='Drug code'
                  />
                  <FormTextField
                    name='days_supply'
                    customOnChange={customOnChange}
                    label='Days supply'
                    type='number'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='quantity'
                    customOnChange={customOnChange}
                    label='Quantity'
                    type='number'
                  />
                  <FormTextField
                    name='jcode'
                    customOnChange={customOnChange}
                    label='Jcode'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Order type'
                    name='order_type'
                    choices={[
                      { id: 'new', name: 'New' },
                      { id: 'refill', name: 'Refill' },
                    ]}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div style={{ paddingTop: 10, paddingBottom: 15 }}>
                  Approval
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='authorization_number'
                    customOnChange={customOnChange}
                    label='Authorization number'
                  />
                  <FormTextField
                    name='approval_date'
                    customOnChange={customOnChange}
                    label='Date of approval'
                    type='date'
                    disabled={
                      formData.pharm_log_status_id !== pharmLogStatuses.APPROVED
                    }
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='outcome_date'
                    customOnChange={customOnChange}
                    label='Date of outcome'
                    type='date'
                  />
                  <FormTextField
                    name='expiration_date'
                    customOnChange={customOnChange}
                    label='Expiration date'
                    type='date'
                  />
                </div>
                <FormTextField
                  name='plan_contact_information'
                  customOnChange={customOnChange}
                  label='Plan contact information'
                  multiline
                  fullWidth
                />
                <FormTextField
                  name='approval_comments'
                  customOnChange={customOnChange}
                  label='Approval comments'
                  multiline
                  fullWidth
                />
                <div style={{ paddingTop: 10, paddingBottom: 15 }}>Billing</div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Billing responsibility'
                    name='billing_responsibility'
                    choices={pharmLogBillingChoices}
                  />
                  <FormTextField
                    name='price_per_day'
                    customOnChange={customOnChange}
                    label='Price per day'
                    type='number'
                    step='any'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='total_price'
                    customOnChange={customOnChange}
                    label='Total price'
                    type='number'
                    step='any'
                  />
                  <FormTextField
                    name='average_wholesale_price'
                    customOnChange={customOnChange}
                    label='Avg wholesale price'
                    type='number'
                    step='any'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Vendor needed'
                    name='vendor_needed'
                    choices={pharmLogVendorChoices}
                  />
                  <div style={{ flex: '1 0 160px' }} />
                </div>
                <FormTextField
                  name='comments'
                  label='Comments'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  validate={minReasonLength()}
                />
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

const drugRouteList = [
  { id: 'iv', name: 'IV' },
  { id: 'oral', name: 'Oral' },
  { id: 'peg tube', name: 'PEG tube' },
  { id: 'topical', name: 'Topical' },
  { id: 'im', name: 'IM' },
  { id: 'id', name: 'ID' },
  { id: 'sq', name: 'SQ' },
];

function sanitizeFields(record) {
  const { id, PharmLogDocs, document_ids, payer, v_case, ...rest } = record;
  return { ...rest };
}
