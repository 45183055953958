import React, { useState, Fragment, useRef, useEffect } from 'react';

import {
  List,
  Datagrid,
  TextField,
  useRefresh,
  Pagination,
  usePermissions,
  TopToolbar,
  DateField,
  FunctionField,
  useUnselectAll,
} from 'react-admin';
import { IconButton } from '@material-ui/core';
import { useStyles } from './progress.styles';
import { NewEvent } from '../../components/common/modals';
import { CustomButton, AddIcon, EditIcon } from '../../design';

const DiagnosesActions = ({
  basePath,
  data = {},
  resource,
  userPermissions,
  caseId,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Add'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='caseDiagnosisForm'
          refresh={refresh}
          caseId={caseId}
        />
      )}
    </Fragment>
  );
};

export function DiagnosisList({ staticContext, integration_id, ..._props }) {
  const { formBasePath, caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const isFirstRender = useRef(true);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [diagnosisRecord, setDiagnosisRecord] = useState();
  const [eventOpen, setEventModal] = useState(false);
  useEffect(() => {
    // see comment on case_communication
    if (diagnosisRecord) {
      setEventModal(true);
    }
  }, [diagnosisRecord]);
  const handleModalClose = () => {
    setEventModal(false);
    setDiagnosisRecord(undefined);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  const unselectAll = useUnselectAll('clinical/manual-diagnoses');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={
          undefined // will show default delete
        }
        actions={
          <DiagnosesActions userPermissions={userPermissions} caseId={caseId} />
        }
        className={classes.list}
        pagination={
          <Pagination
            rowsPerPageOptions={[]}
            rowsPerPage={1000}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField
            sortable={true}
            label='Diagnosis'
            source='service.service'
          />
          <DateField source='created_at' label='Created at' showTime />
          <TextField sortable={true} label='Status' source='status' />
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setDiagnosisRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              );
            }}
          />
        </Datagrid>
      </List>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={diagnosisRecord}
          form='caseDiagnosisForm'
          isEdit
          refresh={refresh}
          caseId={caseId}
        />
      )}
    </Fragment>
  );
}

export const diagnosisResource = {
  name: 'clinical/manual-diagnoses',
  list: DiagnosisList,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { case_id: caseId } = {} } = {},
  } = props;
  const basePath = pathname;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'clinical/manual-diagnoses',
        hasList: true,
        hasEdit: !!diagnosisResource.edit,
        hasShow: !!diagnosisResource.show,
        hasCreate: !!diagnosisResource.create,
      };
  const updatedProps = {
    ...props,
    basePath,
    caseId,
    ...resource,
  };
  return updatedProps;
}
