import React, { useState, useEffect, Fragment } from 'react';
import { format, isValid } from 'date-fns';
import clsx from 'clsx';
import { authGet } from '../../../../server';
import { Form } from 'react-final-form';
import {
  required,
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
} from '@material-ui/core';
import {
  FormTextField,
  handleNumbers,
  getDateInputValue,
  composeValidators,
  minLength,
  validateSSNOnSubmit,
  validateDate,
  CheckboxInput,
  minReasonLength,
  ReferenceInput,
  SelectComponent,
} from '../modalForms';
import { RadioInput } from './inputs';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { useGlobalStyles } from '../../../../design/globalStyles';
import { useGetUserRoles } from '../../../../hooks';
export const AdmissionEditForm = ({
  handleClose,
  record = {},
  refresh,
  closeAside,
}) => {
  const classes = { ...useStyles(), ...useGlobalStyles() };
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [residentFields, setResidentFields] = useState({});
  const [formData, setFormData] = useState({});
  const { is_admin, is_supervisor } = useGetUserRoles();
  useEffect(() => {
    async function getResident() {
      const {
        admit_date: _admit_date,
        tracking_date: _tracking_date,
        admissionUpdatedBy,
        admissionCreatedBy,
        ...restRecord
      } = record;
      const admit_date = new Date(`${_admit_date} 00:00`);
      const tracking_date = new Date(`${_tracking_date} 00:00`);
      setFormData({
        ...restRecord,
        admit_date: isValid(admit_date)
          ? format(admit_date, 'yyyy-MM-dd')
          : null,
        tracking_date: isValid(tracking_date)
          ? format(tracking_date, 'yyyy-MM-dd')
          : null,
        edit_tracking_date:
          isValid(tracking_date) && _tracking_date !== _admit_date,
      });
      if (record.select_resident) {
        const data = await fetchResource('residents', record.select_resident);
        if (data !== 'error') {
          setResidentFields({ ...sanitizeResident(data) });
        }
      }
    }
    if (record) {
      getResident();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = value => {
    const newValues = {
      admit_date: value.admit_date,
      tracking_date: value.tracking_date,
      notes: value.notes,
      paperwork_sent: value.paperwork_sent,
      room_number: value.room_number,
      ssn: validateSSNOnSubmit(value.ssn),
      tracking_reason: formData.tracking_reason,
      primary_case_type: value.primary_case_type,
      primary_case_manager: value.primary_case_manager,
    };
    return dataProvider
      .update('admissions', {
        id: record.id,
        data: { ...newValues },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        handleClose();
        typeof refresh === 'function' && refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'edit_tracking_date' && value === false) {
      setFormData({
        ...formData,
        tracking_date: null,
        tracking_reason: null,
        [name]: value,
      });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
          ...residentFields,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {values.select_resident >= 0 && (
                  <Fragment>
                    <DialogContentText
                      align='left'
                      variant='h6'
                      className={classes.header}
                    >
                      Resident’s details
                    </DialogContentText>
                    <Divider className={classes.divider} />
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='first_name'
                        customOnChange={customOnChange}
                        label='First name'
                        disabled
                        style={{ marginRight: 10 }}
                      />
                      <FormTextField
                        name='middle_name'
                        label='Middle name'
                        customOnChange={customOnChange}
                        disabled
                        style={{ marginRight: 10 }}
                      />
                      <FormTextField
                        name='last_name'
                        customOnChange={customOnChange}
                        label='Last name'
                        disabled
                        style={{ marginRight: 0 }}
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='dob'
                        customOnChange={customOnChange}
                        type='date'
                        label='DOB'
                        disabled
                        validate={composeValidators([validateDate()])}
                      />
                      <FormTextField
                        name='ssn'
                        customOnChange={customOnChange}
                        label='SSN#'
                        disabled
                        format='ss'
                        validate={composeValidators([
                          minLength(9, 'Please enter a 9 digit SSN'),
                        ])}
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='gender'
                        customOnChange={customOnChange}
                        label='Gender'
                        disabled
                      />
                      <FormTextField
                        name='medicare_id'
                        customOnChange={customOnChange}
                        label='Medicare ID #'
                        disabled
                      />
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <FormTextField
                        name='medicaid_id'
                        customOnChange={customOnChange}
                        label='Medicaid ID #'
                        disabled
                      />
                      <FormTextField
                        customOnChange={customOnChange}
                        name='facility_resident_id'
                        label='Resident ID'
                        disabled
                      />
                    </div>
                  </Fragment>
                )}
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                >
                  Admission details
                </DialogContentText>
                <Divider className={classes.divider} />
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    // disabled
                    name='admit_date'
                    validate={composeValidators([required(), validateDate()])}
                    required
                    customOnChange={customOnChange}
                    type='date'
                    label='Admit date'
                    style={{ marginRight: 10 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <CheckboxInput
                    name='edit_tracking_date'
                    customOnChange={customOnChange}
                    label={`Change plan start date`}
                    checked={values.edit_tracking_date}
                  />
                  {formData.edit_tracking_date && (
                    <RadioInput
                      validate={
                        formData.edit_tracking_date ? required() : undefined
                      }
                      row
                      name='tracking_reason'
                      customOnChange={customOnChange}
                      value={formData.tracking_reason}
                      required
                      label='Reason'
                      radios={[
                        {
                          label: 'Pickup of skilled',
                          value: 'pickup_of_skilled',
                          message:
                            'Patient picked up for skilled care - different date than admission or payer change',
                        },

                        {
                          label: 'New plan enrollment',
                          value: 'new_plan_enrollment',
                          message:
                            'Patient enrolled in a new plan - requires auth from a different date than admission',
                        },
                        {
                          label: 'Pickup of plan',
                          value: 'pickup_of_plan',
                          message:
                            'Plan pickup - neither a pickup of skilled or new plan enrollment',
                        },
                      ]}
                    />
                  )}
                  <FormTextField
                    name='tracking_date'
                    customOnChange={customOnChange}
                    type='date'
                    validate={validateDate()}
                    label=''
                    disabled={!formData.edit_tracking_date}
                    style={{ marginRight: 0 }}
                    fullWidth
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='room_number'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                    label='Room#'
                    style={{ marginRight: 10 }}
                  />
                  <FormTextField
                    name='paperwork_sent'
                    customOnChange={customOnChange}
                    type='date'
                    label='Initial paperwork sent'
                    validate={composeValidators([validateDate()])}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <FormTextField
                  name='notes'
                  label='Notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  validate={minReasonLength()}
                />
                {(is_admin || is_supervisor) && (
                  <div className={clsx(classes.inputContainerWrap)}>
                    <SelectComponent
                      customOnChange={customOnChange}
                      label='Case type'
                      validate={required()}
                      required
                      name='primary_case_type'
                      choices={[
                        { id: 0, name: 'Subacute' },
                        { id: 1, name: 'MLTC' },
                      ]}
                      disabled={!is_admin && !is_supervisor}
                    />
                    <ReferenceInput
                      reference='facility-users/list'
                      customOnChange={customOnChange}
                      name='primary_case_manager'
                      label='Case manager'
                      validate={required()}
                      required
                      style={{ marginRight: 0 }}
                      options={{
                        filter: {
                          is_active: true,
                          all_roles: true,
                          all_facilities: true,
                        },
                        sort: { field: 'last_name', order: 'ASC' },
                      }}
                      perPage={500}
                      disabled={!is_admin}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

async function fetchResource(resource, value) {
  const response = await authGet(`/${resource}/${value}`);
  if (response.error) return 'error';
  return response.data;
}

function sanitizeResident(data) {
  const {
    first_name,
    middle_name,
    last_name,
    dob,
    ssn,
    gender,
    medicaid_id,
    medicare_id,
    facility_resident_id,
  } = data;
  return {
    first_name,
    middle_name,
    last_name,
    dob,
    ssn,
    gender,
    medicaid_id,
    medicare_id,
    facility_resident_id,
  };
}
