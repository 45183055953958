import React, { useState, useEffect, cloneElement } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard';
import {
  TextField,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
} from '@material-ui/core';
import { authGet, authPut, authPost } from '../server';
import { useGetUserRoles } from '../hooks';
import { TextEditor, CoverLetterTemplatesList } from '../components/common';
import { DeleteModal } from '../components/common/modals';
import { SaveButton, AttachmentIcon, DeleteIcon } from '../design';

import { useStyles } from './communication_templates.styles';

export function CommunicationTemplates() {
  const classes = useStyles();
  const redirect = useRedirect();
  const { is_admin, is_supervisor } = useGetUserRoles();
  const [templateTypes, setTemplateTypes] = useState([]);
  const [ejsDataList, setEjsDataList] = useState([]);
  const [selectedTemplateType, setSelectedTemplateType] = useState();

  useEffect(() => {
    (async function() {
      const [typesRes, ejsRes] = await Promise.all([
        authGet('/communication-template-types/list'),
        authGet('/communication-template-ejs-data/list'),
      ]);
      const { data: typesData, error: typesError } = typesRes;
      const { data: ejsData, error: ejsError } = ejsRes;
      !typesError && setTemplateTypes(typesData);
      !ejsError && setEjsDataList(ejsData);
    })();
  }, []);

  function handleClick(templateType) {
    setSelectedTemplateType(templateType);
  }

  if (is_admin === false && is_supervisor === false) {
    redirect('/');
    return null;
  }

  return (
    <div className={classes.main}>
      <div className={classes.title}>Communication templates</div>
      <div className={classes.typesList}>
        <TextField
          variant='outlined'
          margin='dense'
          select
          fullWidth
          label='Select template type'
          defaultValue=''
        >
          {templateTypes.map(t => {
            const { id, name } = t;
            return (
              <MenuItem key={id} value={id} onClick={() => handleClick(id)}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
      {!!selectedTemplateType && (
        <PageContent
          classes={classes}
          selectedTemplateType={selectedTemplateType}
          ejsDataList={ejsDataList}
        />
      )}
    </div>
  );
}

function PageContent({ classes, selectedTemplateType, ejsDataList }) {
  const component = pageValues[selectedTemplateType]?.component;

  if (!component) return null;
  return (
    <div>
      <div>
        <Typography color='secondary'>
          {`In order for the the system to insert the correct values into the template, you must wrap the value with a <% %> tag.`}
          <br />
          {`For example to get the resident's name, enter <%= resident_name %>.`}
        </Typography>
        <br />
        Here is the list of values you can use:
        <br />
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align='left' style={{ fontWeight: 'bold' }}>
                  Variable
                </TableCell>
                <TableCell align='left' style={{ fontWeight: 'bold' }}>
                  Definition
                </TableCell>
                <TableCell align='left' style={{ fontWeight: 'bold' }}>
                  Copy variable
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ejsDataList.map(ejs => {
                const { id, name } = ejs;
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={id}>
                    <TableCell align='left' color='primary'>
                      <Typography color='primary'>{id}</Typography>
                    </TableCell>
                    <TableCell align='left'>{name}</TableCell>
                    <TableCell align='right'>
                      <CopyClip text={id} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {cloneElement(component, { classes, selectedTemplateType })}
    </div>
  );
}

function EmailTemplateEdit({ selectedTemplateType }) {
  const notify = useNotify();
  const classes = useStyles();
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [editorState, setEditorState] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [deleteTemplateData, setDeleteTemplateData] = useState();
  const [attachmentRequired, setAttachmentRequired] = useState(false);

  async function fetchTemplatList() {
    const response = await authGet([
      '/communication-template/list',
      { communication_type: 'email' },
    ]);
    const { error, data } = response;
    if (!error) {
      setTemplateList(data);
    }
  }

  useEffect(() => {
    fetchTemplatList();
  }, []);

  const handleClick = templateId => {
    if (!templateId || templateId === 'Add New') {
      setEditorState('');
      setDisplayName('');
      setSelectedTemplate({});
      setAttachmentRequired(false);
      return;
    }
    (async function() {
      const response = await authGet([
        `/cases/0/communication-template/${templateId}`,
        { raw_ejs: 'true' },
      ]);
      const { error, data } = response;
      if (!error) {
        setSelectedTemplate(data.template);
        setEditorState(data.template?.text);
        setDisplayName(data.template?.name);
        setAttachmentRequired(data.template?.attachment_required);
      }
    })();
  };

  const handleSubmit = () => {
    (async function() {
      const templateId = selectedTemplate.id;
      const payload = {
        text: editorState,
        type: selectedTemplateType,
        name: displayName,
        attachment_required: attachmentRequired,
      };
      const response = templateId
        ? await authPut(
            `/cases/0/communication-template/${templateId}`,
            payload,
          )
        : await authPost('/communication-template', payload);
      if (response.error) {
        return notify(response.error.message, 'warning');
      }
      notify('Changes Saved');
      await fetchTemplatList();
    })();
  };

  const handleDelete = () => {
    const templateId = selectedTemplate.id;
    const data = {
      url: `/cases/0/communication-template/${templateId}`,
      name: displayName,
    };
    setDeleteTemplateData(data);
  };

  const onDeleted = () => {
    setEditorState('');
    setDisplayName('');
    setSelectedTemplate({});
    fetchTemplatList();
  };

  return (
    <div style={{ maxWidth: 817 }}>
      <TextField
        variant='outlined'
        select
        fullWidth
        label='Select email template'
        size='small'
        defaultValue=''
        style={{ marginBottom: 10 }}
      >
        <MenuItem value={'Add new'} onClick={() => handleClick('')}>
          Add new
        </MenuItem>
        {templateList.map(t => {
          const { id, name } = t;
          return (
            <MenuItem key={id} value={id} onClick={() => handleClick(id)}>
              {name}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        variant='outlined'
        fullWidth
        label='Enter template name'
        size='small'
        value={displayName}
        onChange={e => setDisplayName(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <TextEditor
        editorState={editorState}
        setEditorState={setEditorState}
        height='30vh'
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => setAttachmentRequired(!attachmentRequired)}
            checked={attachmentRequired}
            name='attachment_required'
            color='primary'
          />
        }
        label='Attachment required'
      />
      <div style={{ marginTop: 15 }}>
        <SaveButton onClick={handleSubmit} />
        {!!Object.keys(selectedTemplate).length && (
          <Button
            endIcon={<DeleteIcon />}
            variant='contained'
            onClick={handleDelete}
            className={classes.deleteBtn}
          >
            Delete template
          </Button>
        )}
      </div>
      {!!selectedTemplateType && (
        <DeleteModal
          open={!!deleteTemplateData}
          onClose={() => setDeleteTemplateData(null)}
          onDelete={onDeleted}
          {...deleteTemplateData}
        />
      )}
    </div>
  );
}

function FaxTemplateEdit({ selectedTemplateType }) {
  const notify = useNotify();
  const classes = useStyles();
  const [text, setText] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [deleteTemplateData, setDeleteTemplateData] = useState();

  const handleClick = templateId => {
    (async function() {
      const response = await authGet([
        `/cases/0/communication-template/${templateId}`,
        { raw_ejs: 'true' },
      ]);
      const { error, data } = response;
      if (!error) {
        setSelectedTemplate(data.template);
        setText(data.template?.text);
        setDisplayName(data.template?.name);
      }
    })();
  };

  const handleAddNew = e => {
    const isNew = e.target.checked;
    if (isNew) {
      setText('');
      setDisplayName('');
      setSelectedTemplate({});
    }
    setIsNewTemplate(isNew);
  };

  const handleSubmit = () => {
    (async function() {
      const templateId = selectedTemplate.id;
      const payload = {
        text,
        type: selectedTemplateType,
        name: displayName,
      };
      const response = templateId
        ? await authPut(
            `/cases/0/communication-template/${templateId}`,
            payload,
          )
        : await authPost('/communication-template', payload);
      if (response.error) {
        return notify(response.error.message, 'warning');
      }
      notify('Changes saved');
      setIsNewTemplate(false);
      // await fetchTemplatList();
    })();
  };

  const handleDelete = () => {
    const templateId = selectedTemplate.id;
    const data = {
      url: `/cases/0/communication-template/${templateId}`,
      name: displayName,
    };
    setDeleteTemplateData(data);
  };

  const onDeleted = () => {
    setDisplayName('');
    setSelectedTemplate({});
    setText('');
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={isNewTemplate}
            onChange={handleAddNew}
            name='isNewTemplate'
            color='primary'
          />
        }
        label='Add new template'
      />
      {!isNewTemplate && (
        <CoverLetterTemplatesList
          setComment={setText}
          caseId={0}
          variant='standard'
          onClick={handleClick}
          templateType={selectedTemplateType}
        />
      )}
      {(isNewTemplate || !!selectedTemplate.id) && (
        <TextField
          variant='outlined'
          fullWidth
          label={`${isNewTemplate ? 'Enter' : 'Edit'} template name`}
          size='small'
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
          style={{ marginBottom: 15 }}
        />
      )}
      <TextField
        name='text'
        value={text}
        onChange={e => setText(e.target.value)}
        label='Enter text'
        fullWidth
        multiline
        rows={5}
        variant='outlined'
      />
      <div style={{ marginTop: 15 }}>
        <SaveButton onClick={handleSubmit} />
        {!!Object.keys(selectedTemplate).length && (
          <Button
            endIcon={<DeleteIcon />}
            variant='contained'
            onClick={handleDelete}
            className={classes.deleteBtn}
          >
            Delete template
          </Button>
        )}
      </div>
      {!!selectedTemplateType && (
        <DeleteModal
          open={!!deleteTemplateData}
          onClose={() => setDeleteTemplateData(null)}
          onDelete={onDeleted}
          {...deleteTemplateData}
        />
      )}
    </div>
  );
}

function CopyClip({ text }) {
  const [copied, setCopied] = useState(false);

  function handleCopied() {
    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  }

  return (
    <span style={{ whiteSpace: 'nowrap', display: 'flex' }}>
      <Copy text={`<%= ${text} %>`} onCopy={handleCopied}>
        <AttachmentIcon
          style={{
            cursor: 'pointer',
            fontSize: 19,
          }}
        />
      </Copy>
      {copied && <Typography color='secondary'>Copied.</Typography>}
    </span>
  );
}

const pageValues = {
  quick_pick_short_term: {
    component: <FaxTemplateEdit />,
  },
  quick_pick_long_term: {
    component: <FaxTemplateEdit />,
  },
  email_template: {
    component: <EmailTemplateEdit />,
  },
};
