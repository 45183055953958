import React from 'react';
import {
  init as Sentry_init,
  configureScope as Sentry_configureScope,
  withScope as Sentry_withScope,
  captureException as Sentry_captureException,
  showReportDialog as Sentry_showReportDialog,
  captureMessage as Sentry_captureMessage,
} from '@sentry/browser';
import packagejson from '../../../package.json';
import { Button } from '@material-ui/core';

const { NODE_ENV = 'development' } = process.env;

const __DEV__ = NODE_ENV === 'development';

/** See https://docs.sentry.io/learn/configuration */
Sentry_init({
  /** Do not send errors in development. */
  beforeSend: __DEV__
    ? function() {
        return null;
      }
    : undefined,
  /** Where to send errors. This is NOT a secret. */
  dsn: 'https://69d47abae0a141e9a8b488357fb4e2cd@sentry.io/4158618',
  environment: NODE_ENV,
  release: packagejson.version,
});

export class ErrorSentry extends React.Component {
  static setUser(user) {
    Sentry_configureScope(scope => {
      scope.setUser({
        id: user.id,
        username: user.username,
        email: user.email,
      });
    });
  }

  static captureMessage(msg, level = 'error') {
    Sentry_captureMessage(msg, level);
  }

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry_withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry_captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div>
          <h3>Oops! Something went wrong please report feedback below.</h3>

          <div className='not-found-btn-row'>
            <a href='/'>
              <Button variant='flat' className='home-button'>
                Back to Home
              </Button>
            </a>
          </div>
          <div className='not-found-btn-row'>
            <a href='/' onClick={this.showReportDialog}>
              <Button variant='flat' className='home-button'>
                Report feedback
              </Button>
            </a>
          </div>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }

  showReportDialog = e => {
    e.preventDefault();
    Sentry_showReportDialog();
  };
}
