import { userTypes } from '../actions/user';
import { authUser, setAuthRequestToken } from '../../server';
const initialState = {
  profile: {},
};
export function userReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case userTypes.GET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case userTypes.CLEAR_USER_PROFILE:
      return {
        ...state,
        profile: {},
      };
    case userTypes.REFRESH_TOKEN:
      const { token } = action.payload;
      const { load } = authUser;
      load(token);
      localStorage.setItem('token', token);
      setAuthRequestToken(token, authUser.token_expiration);
      localStorage.setItem('roles', authUser.roles);
      localStorage.setItem('jwtExpiry', authUser.token_expiration);
      return {
        ...state,
      };
    default:
      return state;
  }
}
