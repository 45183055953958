import React, { useState, useEffect } from 'react';
import {
  useListController,
  ListView,
  FunctionField,
  TextField,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import clsx from 'clsx';
import { Datagrid } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { useStyles } from '../resources/payers/payers.styles';
import { getBaseReportUrl, getChip } from '../utils';
import { CustomButton, LaunchIcon, AddIcon } from '../design';
import { stringify } from 'query-string';
import { Chip } from '@material-ui/core';
const token = localStorage.getItem('token');
const baseUrl = getBaseReportUrl();

export const Contracts = ({ record, id, ...props }) => {
  const classes = useStyles();
  const { permissions } = props;
  const isAdminOrSupervisor =
    permissions?.indexOf('admin') > -1 ||
    permissions?.indexOf('supervisor') > -1;
  const isContractUser = permissions?.indexOf('contract_user') > -1;
  const [eventOpen, setEventModal] = useState(false);
  const [payerFacilityId, setPayerFacilityId] = useState();
  const [payerId, setPayerId] = useState();
  const [inNetwork, setInNetwork] = useState();
  const refresh = useRefresh();
  const controllerProps = useListController({
    ...props,
    resource: 'global-payer-contracts',
    filter: { id },
  });
  const showCloneContract = record => {
    setEventModal(true);
    setPayerFacilityId(record.id);
    setInNetwork(record.in_network);
    setPayerId(record.payer_id);
  };
  const unselectAll = useUnselectAll('global-payer-contracts');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='show-list'>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='cloneContract'
          refresh={refresh}
          payerFacilityId={payerFacilityId}
          payerId={payerId}
          meta={{ in_network: inNetwork }}
        />
      )}
      <ListView
        empty={false}
        {...controllerProps}
        bulkActionButtons={
          !isAdminOrSupervisor
            ? false // disable delete
            : undefined
        }
        exporter={false}
        actions={null}
      >
        <Datagrid>
          <TextField
            source='facility.name'
            label='Name'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='line_of_business.payer_type'
            label='Products (LOB)'
            sortable={false}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { line_of_businesses } = record;
              return Array.isArray(line_of_businesses)
                ? line_of_businesses.map(l => l.payer_type).join(', ')
                : null;
            }}
          />
          <FunctionField
            source='in_network'
            label='In network'
            render={record => {
              const { in_network } = record;
              return !!in_network ? 'In network' : 'Out of network';
            }}
          />
          <TextField
            source='effective_date'
            label='Effective date'
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='end_date'
            label='Recredential date'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='is_active'
            label='Status'
            render={record => {
              const { is_active } = record;
              const status =
                !!is_active || is_active === null ? 'Active' : 'Inactive';
              return (
                <span>
                  <Chip
                    size='small'
                    label={status}
                    className={clsx(classes.chip, classes[getChip(status)])}
                  ></Chip>
                </span>
              );
            }}
          />
          <FunctionField
            sortable={false}
            source='is_active'
            label=''
            render={record => {
              const { id, facility_id } = record;
              return (
                <CustomButton
                  Icon={LaunchIcon}
                  color='#1061A0'
                  backgroundColor='#EFF4FB'
                  label='Cheat Sheet'
                  type='button'
                  variant='text'
                  size='small'
                  onClick={() =>
                    window.open(
                      `${baseUrl}/download?${stringify({
                        _facilityId: facility_id,
                        token,
                        payer_facility_id: id,
                        report: 'cheat-sheet',
                        _start: 0,
                        _end: 1000,
                      })}`,
                      '_blank',
                    )
                  }
                />
              );
            }}
          />
          {isAdminOrSupervisor || isContractUser ? (
            <FunctionField
              sortable={false}
              source=''
              label=''
              render={record => {
                return record.has_rates || record.has_outliers ? (
                  <CustomButton
                    Icon={AddIcon}
                    color='#1061A0'
                    backgroundColor='#EFF4FB'
                    label='Copy contract details'
                    type='button'
                    variant='text'
                    size='small'
                    onClick={() => showCloneContract(record)}
                  />
                ) : null;
              }}
            />
          ) : null}
        </Datagrid>
      </ListView>
    </div>
  );
};
