import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  useNotify,
  useRefresh,
  useDataProvider,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent, Button } from '@material-ui/core';
import { FormTextField } from '.';
import { useStyles } from '../modal.styles';
import { CustomButton } from '../../../../design';
import { facilityActions } from '../../../../state/actions';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
export const DeactivateFacilityForm = ({ record, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const dataProvider = cacheDataProviderProxy(useDataProvider());

  useEffect(() => {
    let formattedDate;
    if (isEdit && record) {
      const { last_active_date } = record;
      formattedDate = format(
        new Date(`${last_active_date} 00:00`),
        'M/dd/yyyy',
      );
    } else {
      formattedDate = format(Date.now(), 'M/dd/yyyy');
    }
    setFormData(f => ({
      ...f,
      last_active_date: formattedDate,
    }));
  }, [isEdit, record]);
  const onSubmit = value => {
    return dataProvider
      .update('deactivate-facility', {
        id: record.id,
        data: {
          ...value,
          inactive: !isEdit,
        },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        dispatch(facilityActions.getFacility(data.id));
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='last_active_date'
                    className={clsx(classes.input)}
                    label={
                      isEdit
                        ? `${record.name} - Inactive As Of`
                        : `${record.name} - Last Active Date`
                    }
                    disabled={true}
                    style={{ marginRight: 0 }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <CustomButton
                  onClick={handleSubmit}
                  color='#F57C00'
                  label={isEdit ? 'Reactivate' : 'Deactivate'}
                  type='button'
                  variant='text'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
