import React, { Fragment, useState, useEffect, cloneElement } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';
import { format, isValid } from 'date-fns';
import {
  useListController,
  ListView,
  TextField,
  FunctionField,
  DateField,
  TopToolbar,
  useRefresh,
  useNotify,
  usePermissions,
  useUnselectAll,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { authGet } from '../../server';
import { IconButton } from '@material-ui/core';
import { getAttchIcon } from '../../utils';
import { Datagrid } from '../../components/common';
import { NewEvent } from '../../components/common/modals';
import { useStyles } from '../../resources/updates.styles';
import * as design from '../../design';
const { CensusIcon, AddIcon, EditIcon, ViewIcon, CustomButton } = design;

const FacilityLevelActions = ({ isViewAdmin }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}></span> {/* For spacing*/}
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Add'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
          disabled={isViewAdmin}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='censusForm'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const CensusFileList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const [eventOpen, setEventModal] = useState(false);
  const [censusRecord, setCensusRecord] = useState();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;

  const unselectAll = useUnselectAll('censuses');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // see comment on case_communication
    if (censusRecord) {
      setEventModal(true);
    }
  }, [censusRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setCensusRecord(undefined);
    refresh();
  };

  const handleViewClick = async document_id => {
    const response = await authGet(`/documents/url/${document_id}`);
    if (response.error) {
      notify('ra.notification.http_error', 'warning');
    }
    const win = window.open(response.data.url, '_blank');
    win.focus();
  };

  const controllerProps = useListController({
    ...props,
    sort: { field: 'date', order: 'DESC' },
  });

  return (
    <Fragment>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        bulkActionButtons={!isViewAdmin}
        className={clsx(classes.list)}
        actions={<FacilityLevelActions isViewAdmin={isViewAdmin} />}
      >
        <Datagrid>
          <FunctionField
            label=''
            source='document_type'
            render={record => {
              const { censusDocs = [] } = record;
              const docType = get(censusDocs[0], 'document_type');
              const IconComponent = design[getAttchIcon(docType)];
              return cloneElement(
                <IconComponent className={classes.fileIcons} />,
              );
            }}
          />

          <FunctionField
            source='date'
            label='Start date'
            render={record => {
              const { date } = record;
              if (!isValid(new Date(`${date} 00:00`))) {
                return null;
              }
              return (
                <span className={classes.noWrap}>
                  {format(new Date(`${date} 00:00`), 'M/d/yyyy')}
                </span>
              );
            }}
          />
          <FunctionField
            source='date'
            label='End date'
            render={record => {
              const { end_date } = record;
              if (!isValid(new Date(`${end_date} 00:00`))) {
                return null;
              }
              return (
                <span className={classes.noWrap}>
                  {format(new Date(`${end_date} 00:00`), 'M/d/yyyy')}
                </span>
              );
            }}
          />
          <TextField source='comment' label='Comment' />
          <DateField source='created_at' label='Upload date' />
          <TextField source='censusCreatedBy.name' label='Upload by' />
          <FunctionField
            render={record => {
              return (
                <EditIcon
                  className={classes.editIcon}
                  onClick={() =>
                    isViewAdmin ? false : setCensusRecord(record)
                  }
                  disabled={isViewAdmin}
                />
              );
            }}
          />
          <FunctionField
            label=''
            source='id'
            render={record => {
              const { censusDocs = [] } = record;
              const document_id = get(censusDocs[0], 'id');
              return (
                <IconButton onClick={() => handleViewClick(document_id)}>
                  <ViewIcon className={classes.viewIcon} />
                </IconButton>
              );
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          form='censusForm'
          refresh={refresh}
          record={censusRecord}
          isEdit
        />
      )}
    </Fragment>
  );
};

export const censusFileResource = {
  name: 'censuses',
  list: CensusFileList,
  // show: ResidentShow,
  icon: CensusIcon,
  options: { label: 'Census' },
};
function getResourceProps(props) {
  const basePath = '/censuses';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'censuses',
        hasList: true,
        hasEdit: false,
        hasShow: !!censusFileResource.show,
        hasCreate: !!censusFileResource.create,
      };
  return { ...props, basePath, ...resource };
}
