import React, { useState, useCallback, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  Typography,
  Chip,
  Button,
} from '@material-ui/core';
import { RadioInput } from '.';
import {
  handleNumbers,
  getDateInputValue,
  SelectComponent,
  AutocompleteInput,
  FormTextField,
  ReferenceInput,
  CheckboxInput,
  SwitchInput,
  minReasonLength,
} from '.';
import { useStyles } from '../modal.styles';
import { SaveButton, RemoveIcon, AddIcon } from '../../../../design';
import { constants } from '../../../../utils';
const { ruleTypes } = constants;
const {
  INCLUDE_ITEMS_LOWER_LEVELS,
  ADL_SCORE,
  MENTAL_STATUS_SCORE,
  DEPRESSION,
  COGNITIVE_SCORE,
  PERCENTAGE,
} = ruleTypes;
export const OutliersForm = ({
  record = {},
  isEdit,
  payerFacilityId,
  handleClose,
  meta,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [formData, setFormData] = useState({
    payer_facility_id: payerFacilityId,
    line_of_businesses: [],
    result: '',
    is_percent_rate: 'false',
  });
  const lobPickList = (meta.line_of_businesses || []).map(lob => ({
    id: lob.id,
    name: lob.payer_type,
  }));
  const [services, setServices] = useState([]);
  const [ruleArray, setRuleArray] = useState([]);
  const [counter, setCounter] = useState(0);
  const noFrequencyRuleIds = [
    INCLUDE_ITEMS_LOWER_LEVELS,
    ADL_SCORE,
    MENTAL_STATUS_SCORE,
    DEPRESSION,
    COGNITIVE_SCORE,
    PERCENTAGE,
  ];
  useEffect(
    () => {
      if (isEdit && record) {
        const { service_ids, rule_ids, line_of_business_ids, ...rest } = record;
        const lines_of_business = line_of_business_ids
          .split(',')
          .map(lob => parseInt(lob));
        if (service_ids) {
          const serviceArray = service_ids.split('!');
          const services = serviceArray.map(service => JSON.parse(service));
          setServices(services);
        }
        let mappedRules;
        if (rule_ids) {
          const rulesArray = rule_ids.split(';');
          const rules = rulesArray.map(rule => JSON.parse(rule));
          setCounter(rules.length);
          setRuleArray([
            ...ruleArray,
            ...rules.map((rule, index) => index + '_' + index),
          ]);
          const formRules = rules.map((rule, index) => {
            const name = index + '_' + index;
            return {
              [`${name}_id`]: rule.id,
              [`${name}_rule_type_id`]: rule.rule_type_id,
              [`${name}_min`]: rule.min || null,
              [`${name}_max`]: rule.max || null,
              [`${name}_frequency_type_id`]: rule.frequency_type_id || null,
              [`${name}_frequency_min`]: rule.frequency_min || null,
              [`${name}_frequency_max`]: rule.frequency_max || null,
            };
          });
          mappedRules = Object.assign(...formRules);
        }
        setFormData(f => ({
          ...f,
          is_percent_rate: !!rest.percent ? 'true' : 'false',
          line_of_businesses: lines_of_business,
          ...mappedRules,
          ...rest,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEdit, record],
  );

  const handleDelete = useCallback(value => {
    setServices(cur => cur.filter(c => c !== value));
  }, []);
  const onClick = useCallback(
    value => {
      value[0].name = value[0].name.split(' - ')[0].split(' , ')[0];
      setServices(curServices => [...curServices, ...value]);
    },
    [setServices],
  );

  const validateRules = rawRules => {
    const rules = rawRules.map(item =>
      item.reduce(
        (acc, [key, val]) => ({
          ...acc,
          [key
            .split('_')
            .slice(2)
            .join('_')]: val,
        }),
        {},
      ),
    );
    const validated = rules.map(row => {
      if (noFrequencyRuleIds.includes(row.rule_type_id)) {
        return {
          rule_type_id: row.rule_type_id,
          min: row.min,
          max: row.max,
        };
      } else return row;
    });
    const unique = [
      ...new Map(validated.map(v => [v.rule_type_id, v])).values(),
    ];
    if (unique.length !== validated.length) {
      notify(
        'Duplicate rule types cannot be entered in the same set of criteria.',
      );
      return;
    }
    return unique;
  };

  const onSubmit = value => {
    const service_ids = Array.from(new Set(services.map(row => row.value)));
    const rawRules = ruleArray.map((row, index) =>
      Object.entries(value).filter(name => name[0].includes(row)),
    );
    const rules = validateRules(rawRules);
    if (rawRules && rules) {
      if (isEdit) {
        const isPercent = value.is_percent_rate === 'true';
        return dataProvider
          .update('payers-facility/outliers', {
            id: record.id,
            data: {
              ...value,
              service_ids,
              rules,
              billed_by_facility:
                value.result === 'carve_out'
                  ? !!value.billed_by_facility
                  : null,
              requires_auth:
                value.result === 'carve_out' ? !!value.requires_auth : null,
              rate:
                value.result === 'carve_out' && !isPercent ? value.rate : null,
              percent:
                value.result === 'carve_out' && isPercent
                  ? value.percent
                  : null,
            },
            previousData: { ...record },
          })
          .then(({ data }) => {
            notify('form.updated');
            handleClose();
            refresh();
          })
          .catch(error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          );
      } else {
        return dataProvider
          .create('payers-facility/outliers', {
            data: { ...value, service_ids, rules },
          })
          .then(({ data }) => {
            notify('form.created');
            handleClose();
            refresh();
          })
          .catch(error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          );
      }
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    if (name === 'is_percent_rate') {
      if (value === 'true') {
        setFormData({
          ...formData,
          percent: null,
          [name]: value,
        });
      } else {
        setFormData({
          ...formData,
          rate: null,
          [name]: value,
        });
      }
      return;
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                    name='line_of_businesses'
                    label='Lines of business'
                    choices={lobPickList}
                    style={{ marginRight: 0 }}
                    fullWidth
                    multiple
                    renderWith='chip'
                  />
                </div>

                <Typography className={classes.instructions}>
                  Enter categories and/or services which qualify as an outlier.
                  {<br />}
                  Each qualifying item (or combination of items) must be saved
                  individually.
                </Typography>
                <div style={{ marginLeft: 0, marginRight: 5 }}>
                  <ReferenceInput
                    fullWidth
                    reference='categories/list'
                    customOnChange={customOnChange}
                    name='category_id'
                    label='Category'
                    style={{ marginRight: 0 }}
                    resetOption={{ id: null, name: 'No category' }}
                    required={!services.length && !formData.category_id}
                  />
                </div>
                <div style={{ marginLeft: 0, marginRight: 5 }}>
                  <AutocompleteInput
                    key={values.category_id}
                    trimLength={1}
                    clearInputOnClick
                    fullWidth
                    reference='services/list'
                    customOnChange={customOnChange}
                    name='services'
                    label='Services'
                    openOnFocus
                    autocompleteProps={{ openOnFocus: true }}
                    style={{ marginRight: 0 }}
                    parentOnClick={onClick}
                    getName
                    multiSelect
                    viewAll
                    required={!services.length && !formData.category_id}
                    options={{
                      filter: { category_id: values.category_id },
                    }}
                    highlightItems={services?.map(value => value.value)}
                  />
                </div>
                <div>
                  {!!services.length && (
                    <div style={{ margin: 10 }}>
                      {services.map(row => {
                        return (
                          <Chip
                            key={row.value}
                            label={
                              <Typography
                                style={{
                                  whiteSpace: 'normal',
                                  fontSize: 13,
                                }}
                              >
                                {row.name}
                              </Typography>
                            }
                            onDelete={() => handleDelete(row)}
                            className={classes.chip}
                            style={{
                              height: '100%',
                              maxWidth: 350,
                            }}
                            size='small'
                            disabled={!services}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
                <Typography className={classes.instructions}>
                  {<br />}
                  Add any rules which the above service(s) must meet to qualify
                  as an outlier.
                </Typography>
                <div className={classes.secondaryActionContainer}>
                  {ruleArray[0] ? (
                    <Button
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setRuleArray(
                          ruleArray.filter((item, index) => index !== 0),
                        );
                      }}
                      classes={{
                        label: classes.removeIcon,
                      }}
                    >
                      Rule
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setCounter(counter + 1);
                        setRuleArray([...ruleArray, 0 + '_' + counter]);
                      }}
                      startIcon={<AddIcon />}
                      classes={{
                        root: classes.iconButton,
                      }}
                    >
                      Rule
                    </Button>
                  )}
                </div>
                {ruleArray.map((row, index) => {
                  const rule = index;
                  const name = row;
                  return (
                    <Fragment>
                      <div className={clsx(classes.inputContainerWrap)}>
                        <ReferenceInput
                          key={formData.category_id}
                          reference='rule-types/list'
                          customOnChange={customOnChange}
                          label='Rule type'
                          name={`${name}_rule_type_id`}
                          fullWidth
                          style={{ marginRight: 0 }}
                          options={{
                            filter: {
                              outlier: true,
                              category_id: formData.category_id,
                            },
                          }}
                        />
                      </div>
                      {formData[`${name}_rule_type_id`] && (
                        <div className={classes.inputContainerWrap}>
                          <FormTextField
                            name={`${name}_min`}
                            label='Min quantity'
                            className={classes.inputCell}
                            customOnChange={customOnChange}
                            type='number'
                            min={0}
                          />
                          <FormTextField
                            name={`${name}_max`}
                            label='Max quantity'
                            className={classes.inputCell}
                            customOnChange={customOnChange}
                            type='number'
                          />
                        </div>
                      )}
                      {formData[`${name}_rule_type_id`] &&
                        !noFrequencyRuleIds.includes(
                          formData[`${name}_rule_type_id`],
                        ) && (
                          //Show frequency (as optional field) if rule has a 'per'.
                          <Fragment>
                            <div className={clsx(classes.inputContainerWrap)}>
                              <ReferenceInput
                                reference='frequency-types/list'
                                customOnChange={customOnChange}
                                label='Frequency type'
                                name={`${name}_frequency_type_id`}
                                fullWidth
                                style={{ marginRight: 0 }}
                              />
                            </div>
                            <div className={classes.inputContainerWrap}>
                              <FormTextField
                                name={`${name}_frequency_min`}
                                label='Min frequency'
                                className={classes.inputCell}
                                customOnChange={customOnChange}
                                type='number'
                                min={0}
                              />
                              <FormTextField
                                name={`${name}_frequency_max`}
                                label='Max frequency'
                                className={classes.inputCell}
                                customOnChange={customOnChange}
                                type='number'
                              />
                            </div>
                          </Fragment>
                        )}
                      <div className={classes.secondaryActionContainer}>
                        {ruleArray[rule + 1] ? (
                          <Button
                            startIcon={<RemoveIcon />}
                            onClick={() => {
                              setRuleArray(
                                ruleArray.filter(
                                  (item, index) => index !== rule + 1,
                                ),
                              );
                            }}
                            classes={{
                              label: classes.removeIcon,
                            }}
                          >
                            Rule
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setCounter(counter + 1);
                              setRuleArray([
                                ...ruleArray,

                                rule + 1 + '_' + counter,
                              ]);
                            }}
                            startIcon={<AddIcon />}
                            classes={{
                              root: classes.iconButton,
                            }}
                          >
                            Rule
                          </Button>
                        )}
                      </div>
                    </Fragment>
                  );
                })}

                <div
                  className={classes.inputContainerWrap}
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <RadioInput
                    row
                    name='result'
                    className={classes.radio}
                    customOnChange={customOnChange}
                    value={formData.result}
                    validate={required()}
                    required
                    label='Result'
                    radios={[
                      { label: 'Is a carve-out', value: 'carve_out' },
                      {
                        label: 'Is a level increase',
                        value: 'level_increase',
                      },
                    ]}
                  />
                </div>
                {formData.result === 'carve_out' && (
                  <Fragment>
                    <div className={classes.inputContainerWrap}>
                      <SwitchInput
                        checked={formData.requires_auth}
                        name='requires_auth'
                        label='Requires authorization'
                        customOnChange={customOnChange}
                      />
                    </div>
                    <div className={classes.inputContainerWrap}>
                      <RadioInput
                        row
                        name='is_percent_rate'
                        customOnChange={customOnChange}
                        value={formData.is_percent_rate}
                        label=''
                        radios={[
                          {
                            label: 'Reimbursement per day',
                            value: 'false',
                          },
                          {
                            label: 'Percent of rate',
                            value: 'true',
                          },
                        ]}
                      />
                    </div>
                    <div className={classes.inputContainerWrap}>
                      {formData.is_percent_rate === 'true' ? (
                        <FormTextField
                          name='percent'
                          customOnChange={customOnChange}
                          type='number'
                          label='Percent of rate'
                          step='any'
                          style={{ marginRight: 0 }}
                          setFormData={setFormData}
                        />
                      ) : (
                        <FormTextField
                          name='rate'
                          label='Rate'
                          customOnChange={customOnChange}
                          style={{ marginRight: 0 }}
                        />
                      )}
                    </div>

                    <CheckboxInput
                      name='billed_by_facility'
                      customOnChange={customOnChange}
                      label='Billed by facility'
                      checked={formData.billed_by_facility}
                    />
                  </Fragment>
                )}
                <div style={{ marginLeft: 0, marginRight: 5 }}>
                  {isEdit && values.service && (
                    <FormTextField
                      name='service'
                      label='Previous notes'
                      disabled
                      fullWidth
                      multiline
                    ></FormTextField>
                  )}
                  <FormTextField
                    name='notes'
                    label='Notes'
                    customOnChange={customOnChange}
                    fullWidth
                    multiline
                    validate={minReasonLength()}
                  />
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
