import { authGet } from '../../server';

export const listTypes = {
  GET_LISTS: 'GET_LISTS',
};
export const listActions = {
  getStaticLists() {
    return async (dispatch, getState) => {
      const response = await authGet('/static-lists');
      if (response.error) {
        return null;
      }
      dispatch({
        type: listTypes.GET_LISTS,
        payload: response.data,
      });
    };
  },
};
