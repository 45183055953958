import { makeStyles } from '@material-ui/core';
import { styles } from '../design';
import { style } from '../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  actionHeader: {
    paddingTop: 8,
  },
  actionContainer: {
    width: '100%',
    padding: 12,
    ...styles.flexRow,
    ...styles.justifyBetween,
  },
  listItemLabel: {
    whiteSpace: 'nowrap',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 10,
  },

}));
