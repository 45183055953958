import React, { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import { authDelete } from '../../../server';
import { DeleteIcon } from '../../../design';

const useStyles = makeStyles(theme => ({
  deleteBtn: {
    background: theme.palette.error.main,
    color: '#FFFFFF',
    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  modalTitle: { padding: '18 24px', fontSize: 18, fontWeight: 600 },
  input: {
    fontWeight: 300,
  },
}));

export function DeleteModal({
  open,
  onClose,
  onDelete,
  url,
  id,
  name,
  successMessage = 'Successfully deleted the resource',
}) {
  const notify = useNotify();
  const classes = useStyles();
  const [pendingDelete, setPendingDelete] = useState(false);

  useEffect(() => {
    if (open) setPendingDelete(false);
  }, [open]);

  const handleDelete = useCallback(() => {
    (async function() {
      setPendingDelete(true);
      const response = await authDelete(url);
      const { error } = response;
      if (error) {
        setPendingDelete(false);
        return notify(error.message, 'warning');
      }
      notify(successMessage);
      onDelete && onDelete();
      onClose && onClose();
    })();
  }, [url, notify, successMessage, onDelete, onClose]);

  return (
    <Dialog
      open={open}
      onClose={reason => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle
        disableTypography
        id='form-dialog-title'
        align='left'
        className={classes.modalTitle}
      >
        Are you sure?
      </DialogTitle>
      <DialogContent
        className={classes.input}
        color='error'
      >{`Delete ${name}?`}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          endIcon={<DeleteIcon />}
          variant='contained'
          onClick={handleDelete}
          className={classes.deleteBtn}
          size='small'
          disabled={pendingDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
