import React, { useState, useEffect, Fragment } from 'react';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  AutocompleteInput,
  ReferenceInput,
  SelectComponent,
  CheckboxInput,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { constants } from '../../../../utils';
import { uiActions } from '../../../../state/actions';
import { useDispatch } from 'react-redux';
const { eventTypes } = constants;

export const AssignFaxForm = ({ record, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (isEdit && record) {
      setFormData(f => ({
        ...record,
      }));
    }
  }, [isEdit, record]);
  const onSubmit = value => {
    return dataProvider
      .update('fax-inbox', {
        id: record.id,
        data: { ...value },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        dispatch(uiActions.getFaxInboxCount());
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div style={{ paddingBottom: 15, marginRight: 2 }}>
                  <AutocompleteInput
                    key={record.facility_id}
                    reference='cases/list'
                    customOnChange={customOnChange}
                    name='case_id'
                    label='Select a case'
                    validate={required()}
                    required
                    fullWidth
                    openOnFocus
                    autocompleteProps={{ openOnFocus: true }}
                    options={{
                      filter: {
                        division: 'all',
                        active: 0,
                        facility_id: record.facility_id,
                      },
                    }}
                  />
                </div>

                <SelectComponent
                  fullWidth
                  customOnChange={customOnChange}
                  label='Event type'
                  validate={required()}
                  name='event_type'
                  choices={[
                    {
                      id: eventTypes.ADMISSION_NAME,
                      name: eventTypes.ADMISSION_NAME,
                    },
                    {
                      id: eventTypes.DENIAL_NAME,
                      name: eventTypes.DENIAL_NAME,
                    },
                    {
                      id: eventTypes.AUTHORIZATION_NAME,
                      name: eventTypes.AUTHORIZATION_NAME,
                    },
                    {
                      id: eventTypes.COMMUNICATION_NAME,
                      name: eventTypes.COMMUNICATION_NAME,
                    },
                  ]}
                />
                {formData.case_id && formData.event_type && (
                  <ReferenceInput
                    fullWidth
                    key={[formData.case_id, formData.event_type]}
                    reference='case_events/list'
                    customOnChange={customOnChange}
                    name='event_id'
                    label='Event'
                    options={{
                      filter: {
                        case_id: formData.case_id,
                        event_type: formData.event_type,
                      },
                    }}
                    style={{ marginRight: 0 }}
                  ></ReferenceInput>
                )}
                {formData.event_type === eventTypes.DENIAL_NAME && (
                  <div>
                    <CheckboxInput
                      name='payer_denial'
                      customOnChange={customOnChange}
                      label='Denial letter from plan'
                      checked={values.payer_denial}
                    />
                    <CheckboxInput
                      name='signed_facility_denial'
                      customOnChange={customOnChange}
                      label='Signed denial letter from facility'
                      checked={values.signed_facility_denial}
                    />
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
