import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import React, { Fragment, useEffect, useState } from 'react';
import {
  FunctionField,
  Labeled,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { DateField, TrackUser } from '../../components/common';
import { NotesModal } from '../../components/common/modals';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import { constants, limitStringSize } from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes } = constants;

export const CaseDischargeShow = ({
  closeAside,
  setRefreshTimeline,
  classes,
  residentId,
  caseId,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState('');
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record = {} } = showProps;

  useEffect(() => {
    // selectedNote can only change when user clicks on 'show more'
    // this will make sure that 'show modal' is called with the correct
    // note after the 'selectedNote' state changed since useState does
    // not accept a callback like setState does.
    // The above also applies to 'resourceRecord',
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);

  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    // needed if the user selects the same note again
    // since it only it will not show unless selectedNote changes.
    setSelectedNote(null);
  };

  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            title='Discharge'
            resourceRecord={record}
            eventForm={
              record && record.discharge_status === 'planned'
                ? 'plannedDischarge'
                : 'discharge'
            }
            eventId={record.id}
            eventType='Discharge'
            eventName={eventTypes.DISCHARGE_NAME}
            caseId={caseId}
            related_to_data={{
              related_to_type: 'Discharge',
              related_to_id: record.id,
              related_to_date: record.discharge_date,
            }}
            setRefreshTimeline={setRefreshTimeline}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <FunctionField
            source='discharge_status'
            label='Status'
            className={classes.showItem}
            render={record => startCase(record.discharge_status)}
          />
          <DateField
            source='discharge_date'
            className={classes.showItem}
            label='Discharge date'
            textAlign='right'
          />
          <TextField
            source='discharge_location.discharge_location'
            label='Location'
            className={classes.showItem}
          />
          <FunctionField
            source='community_support'
            label='Community support'
            className={classes.showItem}
            render={record => startCase(record.community_support)}
          />
          <FunctionField
            source='comment'
            label='Comments'
            textAlign='right'
            className={clsx(classes.showItem, classes.commentField)}
            render={record => {
              const { comment } = record;
              const truncated = limitStringSize(comment, maxNotesLength, true);
              return comment && comment.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          {Array.isArray(record.planned_discharge_dates) &&
            record.planned_discharge_dates.map((payload, i) => {
              const { new_date, new_comment, is_changed } = payload;
              const truncated = limitStringSize(new_comment, 30, true);
              return (
                <div key={i} style={{ display: 'flex' }}>
                  <Labeled
                    label='New date'
                    margin='none'
                    className={classes.showItem}
                  >
                    <DateField
                      record={{ ...record, new_date }}
                      source='new_date'
                      className={classes.showItem}
                      style={{
                        color: is_changed ? 'rgba(0, 0, 0, 0.40)' : undefined,
                      }}
                      textAlign='right'
                    />
                  </Labeled>
                  <Labeled
                    label='New comment'
                    margin='none'
                    className={classes.newComment}
                  >
                    <span
                      className={classes.showItem}
                      style={{
                        minWidth: 220,
                      }}
                    >
                      {new_comment && new_comment.length > 30 ? (
                        <span>
                          <span
                            style={{
                              color: is_changed
                                ? 'rgba(0, 0, 0, 0.40)'
                                : undefined,
                            }}
                          >
                            {truncated}
                          </span>
                          <Typography
                            color='primary'
                            component='span'
                            className={classes.showMore}
                            onClick={() => setSelectedNote(new_comment)}
                          >
                            Show more
                          </Typography>
                        </span>
                      ) : (
                        <span
                          style={{
                            color: is_changed
                              ? 'rgba(0, 0, 0, 0.40)'
                              : undefined,
                          }}
                        >
                          {truncated}
                        </span>
                      )}
                    </span>
                  </Labeled>
                </div>
              );
            })}
          <FunctionField
            source='dischargeCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { dischargeCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={dischargeCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='dischargeUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { dischargeUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={dischargeUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={handleNotesModalClose}
              note={selectedNote || record.comment}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      {!!residentId && (
        <Fragment>
          <Divider className={classes.residentDivider} />
          <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
            Resident info
          </p>
          <Show
            actions={
              <ResidentShowActions
                closeAside={closeAside}
                isResidentPage={false}
                setRefreshTimeline={setRefreshTimeline}
                isViewAdmin={isViewAdmin}
              />
            }
            {...rest}
            resource='residents'
            id={residentId}
          >
            <ResidentShowFields classes={classes} includeCases />
          </Show>
        </Fragment>
      )}
    </div>
  );
};
