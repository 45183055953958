import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Button } from '@material-ui/core';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  FunctionField,
  Pagination,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';
import { DateField, Datagrid, ToggleButton } from '../../components/common';
import { useGetUserDivision } from '../../hooks';

import { useStyles } from './reports.styles';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const { id, v_residents_secondary, vCaseAuths, ...rest } = field;
    const secondary_payer = get(v_residents_secondary, 'secondary_payer');
    return { secondary_payer, ...rest };
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'case_name',
        'admit_date',
        'primary_payer',
        'secondary_payer',
        'lob',
      ],
      rename: [
        'Case Name',
        'Admit Date',
        'Primary Payer',
        'Secondary Payer',
        'LOB',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `no-updates-report-${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  classes,
}) => {
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <div className={classes.listActionWrapper}>
        <div style={{ display: 'flex', paddingBottom: 20 }}>
          <span className={classes.title}>No updates report</span>
          {/* Needed for spacing */}
          <span style={{ marginRight: 25 }} />
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
          />
        </div>
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          className={classes.exportButton}
        />
      </div>
    </TopToolbar>
  );
};

export const NoUpdatesReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(userDivision);

  return (
    <List
      empty={false}
      {...props}
      bulkActionButtons={false}
      actions={<ListActions setDivision={setDivision} classes={classes} />}
      exporter={exporter}
      sort={{ field: 'case_id', order: 'ASC' }}
      filter={{ division }}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
    >
      <Datagrid>
        <FunctionField
          source='case_name'
          label='Case name'
          sortBy='last_name'
          render={record => {
            const { case_name, id } = record;
            if (!case_name || !id) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${id}/timeline`}
              >
                {case_name}
              </Button>
            );
          }}
        />
        <DateField source='admit_date' label='Admit date' sortable={false} />
        <TextField
          source='primary_payer'
          label='Primary payer'
          sortable={false}
        />
        <TextField
          source='v_residents_secondary.secondary_payer'
          label='Secondary payer'
          sortable={false}
        />
        <TextField source='lob' label='LOB' sortable={false} />
      </Datagrid>
    </List>
  );
};

export const noUpdatesReportResource = {
  name: 'no-updates-report',
  list: NoUpdatesReportList,
};

function getResourceProps(props) {
  const basePath = '/no-updates-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/no-updates-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!noUpdatesReportResource.show,
        hasCreate: !!noUpdatesReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
