import { makeStyles } from '@material-ui/core';
// import { styles } from '../design';
import { style } from './residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  funcShowItem: {
    fontSize: 11,
  },
  addContainer: {
    ...style(theme).addContainer,
    width: 350,
  },
  actionButton: {
    backgroundColor: '#EFF4FB',
    color: '#1061A0',
  },
  editIcon: {
    color:'#5C738E',
    fontSize: 18,
    marginLeft: 7,
    cursor: 'pointer',
  },
  fileIcons: {
    color: '#E32939', 
  },
  viewIcon: {
    color: '#5C738E'
  },
  capitalize: {
    textTransform: 'capitalize',
  },

}));
