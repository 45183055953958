import React, { Fragment, useState, useCallback } from 'react';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  Pagination,
  Filter,
  DateInput,
  // FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { useSelector } from 'react-redux';
import jsonExport from 'jsonexport/dist';
import { format, startOfYesterday } from 'date-fns';
import { useSidebarOpen } from '../../hooks';
import { Datagrid } from '../../components/common';
import { useStyles } from './reports.styles';
import { DownloadIcon, CustomButton } from '../../design';
import { stringify } from 'query-string';
import { getBaseReportUrl } from '../../utils';
import { Paper, Tabs, Tab } from '@material-ui/core';
import clsx from 'clsx';

const exporter = ({ groupType, fields, ...filter }) => {
  const fieldsForExport = fields.map(field => {
    const { id, is_subacute, progresses, facility_id, ...rest } = field;
    return rest;
  });
  const headers = [
    'first_name',
    'last_name',
    'division',
    'total_caseload',
    'active_caseload',
    'denied_caseload',
    'unknown_caseload',
  ];
  const rename = [
    'First Name',
    'Last Name',
    'Division',
    'Total Caseload',
    'Active Cases',
    'Denied Cases',
    'Unknown Cases',
  ];
  if (groupType === 'facility') {
    rename.push('Facility');
    headers.push('facility_name');
  }

  jsonExport(
    fieldsForExport,
    {
      headers,
      rename,
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `staff-productivity-report-${filter.start_date}-${filter.end_date}`,
      );
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  classes,
  groupType,
  ...rest
}) => {
  const baseUrl = getBaseReportUrl();
  const facilityId = useSelector(state => state.facility.id);
  const token = localStorage.getItem('token');
  return (
    <Fragment>
      <TopToolbar
        className={clsx(
          classes.listActionWrapper,
          classes.flexWrap,
          classes.tabsActionWrapper,
        )}
      >
        <div style={{ width: '50%' }} />
        <div style={{ display: 'flex' }}>
          <div style={{ paddingRight: 10 }}>
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={{
                ...filterValues,
                ...permanentFilter,
              }}
              exporter={fields =>
                exporter({ groupType, fields, ...filterValues })
              }
              className={classes.exportButton}
            />
          </div>
          <CustomButton
            Icon={DownloadIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Download caseload'
            type='button'
            variant='text'
            size='small'
            disabled={total === 0}
            onClick={() =>
              window.open(
                `${baseUrl}/download?${stringify({
                  _facilityId: facilityId,
                  token,
                  report: 'user-caseload',
                })}`,
                '_blank',
              )
            }
          />
        </div>
      </TopToolbar>
    </Fragment>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='From'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <DateInput
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard' }}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Filter>
  );
};

const ReportListView = ({
  formBasePath,
  groupType,
  setGroupType,
  search,
  ...props
}) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });

  return (
    <div className={classes.listContainer}>
      <GroupingTabs
        setGroupType={setGroupType}
        groupType={groupType}
        classes={classes}
        search={search}
      />
      <Datagrid {...props}>
        <TextField
          source='first_name'
          label='First name'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='last_name'
          label='Last name'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='division'
          label='Division'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        {groupType === 'facility' && (
          <TextField
            source='facility_name'
            label='Facility'
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        <TextField
          source='total_caseload'
          label='Total Caseload'
          sortable={false}
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='active_caseload'
          label='Active Cases'
          sortable={false}
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='denied_caseload'
          label='Denied Cases'
          sortable={false}
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='unknown_caseload'
          label='Unknown Cases'
          sortable={false}
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
      </Datagrid>
    </div>
  );
};

export const ProductivityReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const [groupType, setGroupType] = useState('case_manager');
  const { location: { search } = {} } = props;
  const setGroup = value => {
    setGroupType(value === 0 ? 'case_manager' : 'facility');
  };
  return (
    <Fragment>
      <div
        className={classes.title}
        style={{ paddingTop: 30, paddingBottom: 10 }}
      >
        Staff productivity report
      </div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions classes={classes} groupType={groupType} />}
        filterDefaultValues={{
          start_date: format(startOfYesterday(), 'yyyy-MM-dd'),
          end_date: format(startOfYesterday(), 'yyyy-MM-dd'),
        }}
        filter={{ group_type: groupType }}
        filters={<FiltersView classes={classes} />}
        exporter={exporter}
        pagination={
          <Pagination
            rowsPerPageOptions={[500]}
            style={{
              display: 'flex',
            }}
          />
        }
        perPage={500}
      >
        <ReportListView
          formBasePath={props}
          setGroupType={setGroup}
          groupType={groupType}
          search={search}
        />
      </List>
    </Fragment>
  );
};

export const productivityReportResource = {
  name: 'staff-productivity-report',
  list: ProductivityReportList,
};

function getResourceProps(props) {
  const basePath = '/staff-productivity-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/staff-productivity-report',
        hasList: true,
        hasEdit: false,
        hasShow: false,
        hasCreate: false,
      };
  return { ...props, basePath, ...resource };
}

const GroupingTabs = ({ setGroupType, groupType, classes, search }) => {
  const [value, setValue] = useState(groupType === 'case_manager' ? 0 : 1);

  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      setGroupType(newValue);
    },
    [setGroupType],
  );

  return (
    <Paper className={classes.tabContainer} square>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={handleChange}
      >
        <Tab
          label='Group By Case Manager'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
        <Tab
          label='Group by Facility'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
      </Tabs>
    </Paper>
  );
};
