import React, { useRef, useEffect, useState, Fragment } from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  // FunctionField,
  // BooleanField,
  useRefresh,
  Pagination,
  // NumberField,
  // ChipField,
  // Filter,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import {
  Toolbar,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Link,
} from '@material-ui/core';
import { limitStringSize } from '../../utils';
import { LaunchIcon } from '../../design';
import { NotesModal } from '../../components/common/modals';
import { useStyles } from './progress.styles';

const CaseLevelActions = ({ integration_id }) => {
  const classes = useStyles();
  return (
    <Toolbar
      className={classes.listActionWrapper}
      disableGutters
      style={{ minHeight: '100%' }}
    >
      <div className={classes.listActionWrapper} style={{ padding: 24 }}>
        <span />
        {!!integration_id && (
          <Button
            color='primary'
            component={Link}
            underline='none'
            href={`https://usnpint.pointclickcare.com/admin/client/cp_progressnotes.jsp?ESOLclientid=${integration_id}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ textTransform: 'none' }}
            startIcon={<LaunchIcon />}
          >
            View Progress Notes in PointClickCare
          </Button>
        )}
      </div>
    </Toolbar>
  );
};

const NotesList = ({ id, record, resource }) => {
  const classes = useStyles();
  const { sections = [] } = record;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);

  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    setSelectedNote('');
  };

  const maxNotesLength = 80;

  return (
    <Fragment>
      <Box margin={1} style={{ backgroundColor: '#E6DFDB' }}>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sections.map((row, i) => {
              const { name, value } = row;
              const truncated = limitStringSize(value, maxNotesLength, true);
              return (
                <TableRow key={i}>
                  <TableCell component='th' scope='row'>
                    {name}
                  </TableCell>
                  <TableCell>
                    {value?.length > maxNotesLength ? (
                      <span>
                        {truncated}
                        <Typography
                          color='primary'
                          component='span'
                          className={classes.showMore}
                          onClick={() => setSelectedNote(value)}
                        >
                          Show more
                        </Typography>
                      </span>
                    ) : (
                      truncated
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
        />
      )}
    </Fragment>
  );
};

export function ProgressNotesList({
  staticContext,
  integration_id,
  ..._props
}) {
  const { formBasePath, caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  return (
    <List
      empty={false}
      {...props}
      exporter={false}
      bulkActionButtons={false}
      className={classes.list}
      actions={<CaseLevelActions integration_id={integration_id} />}
      filter={{ fields: 'progress_notes' }}
      pagination={
        <Pagination
          rowsPerPageOptions={[]}
          rowsPerPage={1000}
          style={{
            display: 'flex',
          }}
        />
      }
    >
      <Datagrid expand={<NotesList />}>
        <DateField sortable={false} label='Date' source='effectiveDate' />
        <TextField sortable={false} label='Type' source='progressNoteType' />
      </Datagrid>
    </List>
  );
}

export const progressNotesResource = {
  name: 'clinical/progress_notes',
  list: ProgressNotesList,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'clinical/progress_notes',
        hasList: true,
        hasEdit: !!progressNotesResource.edit,
        hasShow: !!progressNotesResource.show,
        hasCreate: !!progressNotesResource.create,
      };
  const updatedProps = {
    ...props,
    basePath,
    caseId,
    ...resource,
  };
  return updatedProps;
}
