import React, { useState, cloneElement, Fragment, useCallback } from 'react';
import clsx from 'clsx';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  Filter,
  DateInput,
  FunctionField,
  Pagination,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { format, startOfMonth, subMonths, lastDayOfMonth } from 'date-fns';
import { useSidebarOpen } from '../../hooks';
import {
  ToggleButton,
  DateField,
  Datagrid,
  FilterChips,
  FilterMenu,
} from '../../components/common';
import { normalizePhone } from '../../components/common/modals/modalForms';
import { currencyFormat } from '../../utils';
import { useStyles } from './reports.styles';
import { ArrowDropDownIcon } from '../../design';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      id,
      first_name,
      last_name,
      case_id,
      days_of_coverage,
      discharge_date,
      discharge_location,
      is_mltc,
      is_primary,
      letter_received,
      payer_id,
      request_sent,
      user_id,
      resident_id,
      rate,
      gross_rate,
      rep_phone,
      rep_phone_ext,
      rep_fax,
      next_request_date,
      discharge_location_id,
      authorization_type_id,
      actual_end_date,
      ending_soon,
      readmitted_discharge_location,
      cases_payers_id,
      payer_changed_to_id,
      payer_changed_to_name,
      lob_changed_to_type,
      payer_change_date,
      readmitted_date,
      readmitted_discharge,
      facility_resident_id,
      insurance_auth_number,
      residents_insurance_id,
      payer_name,
      lob,
      user,
      ...rest
    } = field;
    const ext = rep_phone_ext ? `x${rep_phone_ext}` : '';
    rest.rep_phone = rep_phone ? `${normalizePhone(rep_phone)} ${ext}` : '';
    rest.rep_fax = rep_fax ? normalizePhone(rep_fax) : '';
    rest.rate = currencyFormat(rate);
    rest.gross_rate = currencyFormat(gross_rate);
    rest.payer_lob = `${payer_name}/${lob}`;
    //Need to do this so that Excel does not drop the leading zero
    rest.facility_resident_id = facility_resident_id
      ? `\t${facility_resident_id}`
      : '';
    rest.insurance_auth_number = insurance_auth_number
      ? `\t${insurance_auth_number}`
      : '';
    rest.residents_insurance_id = residents_insurance_id
      ? `\t${residents_insurance_id}`
      : '';
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'full_name',
        'dob',
        'facility_resident_id',
        'room_number',
        'admit_date',
        'payer_lob',
        'residents_insurance_id',
        'tax_id',
        'npi',
        'rep_name',
        'rep_phone',
        'rep_fax',
        'authorization_type',
        'rev_code',
        'rate',
        'insurance_auth_number',
        'authorization_status',
        'pending_reason',
        'approval_chance',
        'actual_start_date',
        'start_date',
        'end_date',
        'auth_end',
        'discharge',
        'total_days',
        'gross_rate',
        'flag',
        'case_manager',
        'team_lead',
      ],
      rename: [
        'Patient Name',
        'Date of Birth',
        'Patient Id',
        'RM#',
        'Admit Date',
        'Insurance/LOB',
        'Insurance Member ID',
        'Tax ID',
        'NPI',
        'Insurance Case Manager Name',
        'Insurance Case Manager Phone',
        'Insurance Case Manager Fax',
        'Level',
        'Rev Code',
        'Rate',
        'Authorization #',
        'Auth Status',
        'Pending Reason',
        'Likelihood of Approval',
        'Actual Auth Start Date',
        'Auth Start Date',
        'Auth Thru Date',
        'Auth Approved Thru',
        'Discharge Date',
        'Days',
        'Gross Rate',
        'Flag',
        'Case Manager',
        'Team Lead',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `triple-check-report-${format(new Date(), 'MM/dd/yyyy')}`,
      );
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  loading,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  classes,
  activeStatusFilters = {},
  filterActions,
  ...rest
}) => {
  return (
    <TopToolbar
      className={clsx(
        classes.listActionWrapper,
        classes.flexWrap,
        classes.tabsActionWrapper,
      )}
      style={{ paddingTop: 19 }}
    >
      <div style={{ display: 'flex' }}>
        <FilterMenu filterActions={filterActions} filters={reportFilters} />
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <FilterChips
          activeFilters={activeStatusFilters}
          filterActions={filterActions}
          disabled={loading}
        />
      </div>

      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />

      <div style={{ width: '100%', height: 10 }} />
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='From'
        source='start_date'
        alwaysOn
        options={{
          variant: 'standard',
          required: true,
        }}
        InputProps={{
          disableUnderline: true,
          inputProps: { max: format(new Date(), 'yyyy-MM-dd') },
        }}
        style={{ color: '#5C738E' }}
      />
      <DateInput
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
        InputProps={{
          disableUnderline: true,
          components: { OpenPickerIcon: ArrowDropDownIcon },
          inputProps: { max: format(new Date(), 'yyyy-MM-dd') },
        }}
        style={{ color: '#5C738E' }}
      />
    </Filter>
  );
};

const ReportListView = ({ formBasePath, ...props }) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  return (
    <div className={classes.listContainer}>
      <Datagrid {...props}>
        <TextField
          source='full_name'
          label='Resident name'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
          sortBy='last_name'
        />
        <TextField
          source='facility_resident_id'
          label='Resident ID'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='room_number'
          label='Room#'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <DateField
          source='admit_date'
          label='Admit date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='payer_name'
          label='Insurance'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='lob'
          label='LOB'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='residents_insurance_id'
          label='Insurance member ID'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='tax_id'
          label='Tax ID'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='npi'
          label='NPI'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='rep_name'
          label='Insurance case manager name'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <FunctionField
          source='rep_phone'
          label='Insurance case manager phone'
          headerClassName={classes.listItemLabel}
          className={classes.noLineBreak}
          render={record => {
            const ext = record.rep_phone_ext ? `x${record.rep_phone_ext}` : '';
            return record.rep_phone
              ? `${normalizePhone(record.rep_phone)} ${ext}`
              : null;
          }}
        />
        <FunctionField
          source='rep_fax'
          label='Insurance case manager fax'
          headerClassName={classes.listItemLabel}
          className={classes.noLineBreak}
          render={record => {
            return record.rep_fax ? normalizePhone(record.rep_fax) : null;
          }}
        />
        <TextField
          source='authorization_type'
          label='Level'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='rev_code'
          label='Rev code'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <FunctionField
          source='rate'
          label='Rate'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
          render={record => currencyFormat(record.rate)}
        />
        <TextField
          source='insurance_auth_number'
          label='Auth#'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='authorization_status'
          label='Auth status'
          sortable={false}
        />
        <TextField
          source='pending_reason'
          label='Pending reason'
          sortable={false}
        />
        <TextField
          source='approval_chance'
          label='Likelihood of approval'
          sortable={false}
        />
        <DateField
          source='actual_start_date'
          label='Actual start date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <DateField
          source='start_date'
          label='Start date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <DateField
          source='end_date'
          label='Thru date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <DateField
          source='auth_end'
          label='Projected end date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='discharge'
          label='Discharge date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
          sortable={false}
        />
        <TextField
          source='total_days'
          label='Total days'
          sortable={false}
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <FunctionField
          source='gross_rate'
          label='Gross rate'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
          render={record => currencyFormat(record.gross_rate)}
        />
      </Datagrid>
    </div>
  );
};

export const TripleCheckReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const [division, setDivision] = useState('subacute');
  const [activeStatusFilters, setActiveStatusFilters] = useState({});

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <div className={classes.title} style={{ paddingTop: 35 }}>
          Triple check report
        </div>
        <div style={{ padding: 30 }}>
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
            showBoth={false}
          />
        </div>
      </div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={
          <ListActions
            classes={classes}
            filterActions={filterActions}
            activeStatusFilters={activeStatusFilters}
          />
        }
        filter={{
          division,
          auth_type: Object.keys(activeStatusFilters),
        }}
        exporter={exporter}
        filters={<FiltersView classes={classes} />}
        filterDefaultValues={{
          start_date: format(
            startOfMonth(subMonths(new Date(), 1)),
            'yyyy-MM-dd',
          ),
          end_date: format(
            lastDayOfMonth(subMonths(new Date(), 1)),
            'yyyy-MM-dd',
          ),
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <ReportListView formBasePath={props} />
      </List>
    </Fragment>
  );
};

export const tripleCheckReportResource = {
  name: 'triple-check',
  list: TripleCheckReportList,
};

function getResourceProps(props) {
  const basePath = '/triple-check-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/triple-check-report',
        hasList: true,
        hasEdit: false,
        hasShow: false,
        hasCreate: false,
      };
  return { ...props, basePath, ...resource };
}

const reportFilters = [
  { id: '18', name: 'Part-B' },
  { id: '15', name: 'Pharmacy' },
];
