import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { required, email } from 'react-admin';
import {
  DialogContentText,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  composeValidators,
  minLength,
  minReasonLength,
  CheckboxInput,
  RadioInput,
} from '../../modalForms';
import { AddIcon, RemoveIcon, CloseIcon } from '../../../../../design';
import { useStyles } from '../../modal.styles';
import { HIGHEST_NO_AUTH_PAYER_ID, ToggleInsuranceNetwork } from './helpers';
import { AuthFields } from './authFields';
import { constants } from '../../../../../utils';
import { SearchIcon } from '../../../../../design';
import { CheckEligibility } from '../checkEligibility';
import { authGet } from '../../../../../server';
import { AutocompleteInput } from '../inputs';
import { useGetUserDivision } from '../../../../../hooks';
const { caseTypes } = constants;
const roles = localStorage.getItem('roles');
export const PrimaryInsuranceInputs = ({
  customOnChange,
  values,
  removeAuthFields,
  isEdit,
  lastCase,
  setFormData,
  formData,
  isInNetwork = true,
  setIsInNetwork,
  notify,
  primarySubLevelChoices,
  setFacilityLobMapping,
}) => {
  const [addAuth, setAddAuth] = useState(false);
  const userId = useSelector(state => state.user.profile.id);
  const coveredUserId = useSelector(state => state.facility.covered_user_id);
  const userDivision = useGetUserDivision();
  const [showEligibility, setShowEligibility] = useState(false);
  const [seekPayer, setSeekPayer] = useState(false);
  const openAuth = useCallback(() => {
    const fields = {
      update_type: [],
      update_weekday: [],
      schedule_type: '',
      case_type:
        formData.case_type ||
        (userDivision === 'all' ? 0 : userDivision === 'subacute' ? 0 : 1),
      user_id: formData.user_id || coveredUserId || userId,
    };
    if (values.is_readmission) {
      fields.start_date = values.admit_date;
    } else {
      fields.start_date = values.tracking_date;
    }
    setFormData(f => ({ ...f, ...fields }));
    setAddAuth(true);
  }, [
    setFormData,
    values.admit_date,
    values.is_readmission,
    values.tracking_date,
    coveredUserId,
    userId,
    userDivision,
    formData.case_type,
    formData.user_id,
  ]);

  useEffect(() => {
    setFormData(f => ({
      ...f,
      case_type:
        formData.case_type ||
        (userDivision === 'all' ? 0 : userDivision === 'subacute' ? 0 : 1),
      user_id: formData.user_id || coveredUserId || userId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, userId, setFormData, addAuth]);

  useEffect(() => {
    const payerId = values.insurance;
    async function checkSeekPayer() {
      const response = await authGet([
        `seek-payer-codes`,
        { payer_id: payerId },
      ]);
      if (response !== 'error') {
        setSeekPayer(response.data);
      }
    }
    checkSeekPayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.insurance]);

  const payerOnClick = useCallback(
    suggestion => {
      if (!setFacilityLobMapping) {
        return;
      }
      const lobs = suggestion?.payers_facilities?.[0]?.line_of_businesses;
      if (Array.isArray(lobs)) {
        const lobObj = lobs.reduce((acc, cur) => {
          acc[cur.id + ''] = cur.payer_facility_lobs?.payer_facility_id;
          return acc;
        }, {});
        setFacilityLobMapping(lobObj);
      }
    },
    [setFacilityLobMapping],
  );

  const classes = useStyles();
  const showInsuranceInputs = values.insurance === 0;
  const showInsuranceLists = !values.is_readmission && values.insurance >= 0;

  return (
    <Fragment>
      <ToggleInsuranceNetwork
        state={isInNetwork}
        setState={setIsInNetwork}
        name='isInNetworkPrimary'
        disabled={values.is_readmission}
      />
      {values.is_readmission ? (
        <ReferenceInput
          key={isInNetwork + ''}
          reference='facility-payers/list'
          perPage={1000}
          customOnChange={customOnChange}
          name='insurance'
          label='Insurance'
          validate={required()}
          getSelectedChoice={payerOnClick}
          required
          fullWidth
          viewAll
          options={{ filter: { in_network: isInNetwork } }}
          style={{ marginLeft: 0 }}
          disabled
        />
      ) : (
        <AutocompleteInput
          key={isInNetwork + ''}
          reference='facility-payers/list'
          perPage={1000}
          customOnChange={customOnChange}
          getSelectedChoice={payerOnClick}
          name='insurance'
          label='Insurance'
          validate={required()}
          required
          fullWidth
          viewAll
          openOnFocus
          autocompleteProps={{ openOnFocus: true }}
          options={{ filter: { in_network: isInNetwork } }}
          style={{ marginLeft: 0 }}
        />
      )}
      {showInsuranceLists && (
        <Fragment>
          {showInsuranceInputs && (
            <Fragment>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name='insurance_name'
                  validate={required()}
                  required
                  label='Company name'
                  customOnChange={customOnChange}
                />
                <ReferenceInput
                  key={[
                    `${values.insurance}_lob`,
                    values.tracking_date,
                    values.admit_date,
                    values.insurance,
                  ]}
                  reference='lob/list'
                  customOnChange={customOnChange}
                  name='line_of_business_id'
                  label='Type (LOB)'
                  validate={required()}
                  required
                  options={{
                    filter: {
                      payer_id: values.insurance,
                      effective_date: values.tracking_date || values.admit_date,
                      in_network: isInNetwork,
                    },
                  }}
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name='group_provider'
                  label='Group provider'
                  customOnChange={customOnChange}
                  style={{ marginRight: 0 }}
                />
                {/* Needed for spacing */}
                {/* <div style={{ flex: '1 0 160px' }} />
                <div style={{ flex: '1 0 160px' }} /> */}
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                <FormTextField
                  name='remit_address'
                  label='Remit to address'
                  fullWidth
                  customOnChange={customOnChange}
                  style={{ marginRight: 0 }}
                />
              </div>
            </Fragment>
          )}
          <DialogContentText
            align='left'
            variant='h6'
            className={classes.header}
          >
            Member details
          </DialogContentText>
          <Divider className={classes.divider} />
          <div className={clsx(classes.inputContainerWrap)}>
            <FormTextField
              name='residents_insurance_id'
              validate={
                values.insurance > HIGHEST_NO_AUTH_PAYER_ID
                  ? required()
                  : undefined
              }
              required={values.insurance > HIGHEST_NO_AUTH_PAYER_ID}
              label='Member ID #'
              customOnChange={customOnChange}
            />
            {!showInsuranceInputs ? (
              <ReferenceInput
                key={[
                  `${values.insurance}_lob`,
                  values.tracking_date,
                  values.admit_date,
                  values.insurance,
                ]}
                reference='lob/list'
                customOnChange={customOnChange}
                name='line_of_business_id'
                label='Type (LOB)'
                validate={required()}
                required
                options={{
                  filter: {
                    payer_id: values.insurance,
                    effective_date: values.tracking_date || values.admit_date,
                    in_network: isInNetwork,
                  },
                }}
              />
            ) : (
              <div style={{ flex: '1 0 160px' }} />
            )}
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            <FormTextField
              customOnChange={customOnChange}
              name='days_of_coverage'
              label='Total benefit days'
              type='number'
            />
            <FormTextField
              customOnChange={customOnChange}
              name='days_used'
              label='Prior days used'
              type='number'
            />
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            <FormTextField
              customOnChange={customOnChange}
              name='days_remaining'
              label='Days remaining'
              type='number'
              disabled
            />
            {/* Needed for spacing */}
            <div style={{ flex: '1 0 160px' }} />
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            <FormTextField
              customOnChange={customOnChange}
              name='copay'
              label='Copay info'
              style={{ marginRight: 0 }}
            />
          </div>
        </Fragment>
      )}
      {seekPayer && !showEligibility && (
        <div style={{ paddingTop: 20 }}>
          <Button
            styles={{ itemAlign: 'center' }}
            onClick={() => {
              values.first_name &&
              values.last_name &&
              values.dob &&
              values.gender_id &&
              values.insurance &&
              values.residents_insurance_id
                ? setShowEligibility(true)
                : notify(
                    'Missing required information. Please include name, date of birth, gender, payer, and member ID.',
                    'warning',
                  );
            }}
          >
            <SearchIcon className={clsx(classes.leftIcon, classes.icon)} />
            Check eligibility
          </Button>
        </div>
      )}
      {showEligibility && (
        <div
          style={{
            width: '100%',
            textAlign: 'right',
          }}
        >
          <IconButton
            onClick={() => {
              setShowEligibility(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {showEligibility && (
        <CheckEligibility
          setFormData={setFormData}
          params={{
            first_name: values.first_name,
            last_name: values.last_name,
            dob: values.dob,
            gender: values.gender_id,
            insurance: values.insurance,
            residents_insurance_id: values.residents_insurance_id,
          }}
        />
      )}
      {showInsuranceLists && (
        <Fragment>
          {(values.insurance === 0 ||
            values.insurance > HIGHEST_NO_AUTH_PAYER_ID) && (
            <Fragment>
              <DialogContentText
                align='left'
                variant='h6'
                className={classes.header}
              >
                Insurance case manager details
              </DialogContentText>
              <Divider className={classes.divider} />
              <div className={clsx(classes.inputContainerWrap)}>
                <ReferenceInput
                  key={`${values.insurance}_rep`}
                  reference='reps/list'
                  customOnChange={customOnChange}
                  name='payer_rep'
                  label='Insurance case manager name'
                  options={{
                    filter: { payer_id: values.insurance },
                  }}
                  resetOption={{
                    id: 0,
                    name: 'Create new insurance case manager',
                  }}
                  style={{ marginRight: 0 }}
                />
              </div>
              <div className={clsx(classes.inputContainerWrap)}>
                {values.payer_rep === 0 && (
                  <FormTextField
                    customOnChange={customOnChange}
                    name='rep_name'
                    validate={required()}
                    required
                    label='Insurance case manager name'
                    style={{ marginRight: 0 }}
                  />
                )}
              </div>
              {values.payer_rep >= 0 && (
                <Fragment>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      customOnChange={customOnChange}
                      name='rep_phone'
                      type='text'
                      format='phone'
                      validate={composeValidators([
                        required(),
                        minLength(10, 'Invalid phone number'),
                      ])}
                      required
                      label='Insurance case manager phone'
                      disabled={!!values.payer_rep}
                    />
                    <FormTextField
                      customOnChange={customOnChange}
                      name='rep_phone_ext'
                      label='Ext.'
                      disabled={!!values.payer_rep}
                    />
                  </div>
                  <div className={clsx(classes.inputContainerWrap)}>
                    <FormTextField
                      customOnChange={customOnChange}
                      name='rep_fax'
                      validate={composeValidators([
                        minLength(10, 'Invalid phone number'),
                      ])}
                      type='text'
                      format='phone'
                      label='Insurance case manager fax'
                      disabled={!!values.payer_rep}
                    />
                    <FormTextField
                      customOnChange={customOnChange}
                      name='rep_email'
                      validate={composeValidators([email()])}
                      label='Insurance case manager email'
                      type='email'
                      disabled={!!values.payer_rep}
                    />
                  </div>
                </Fragment>
              )}
              {/* Needed for spacing */}
              {/* <div style={{ flex: '1 0 160px' }} />
            <div style={{ flex: '1 0 160px' }} /> */}

              {values.payer_rep >= 0 && (
                <FormTextField
                  name='rep_comments'
                  label='Insurance case manager notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  disabled={!!values.payer_rep}
                  validate={minReasonLength()}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}

      {(values.insurance === 0 ||
        values.insurance > HIGHEST_NO_AUTH_PAYER_ID) &&
        !addAuth && (
          <div className={clsx(classes.inputContainerWrap)}>
            <SelectComponent
              customOnChange={customOnChange}
              label='Case type'
              validate={required()}
              required
              name='case_type'
              choices={caseTypes}
            />
            <ReferenceInput
              reference='facility-users/list'
              customOnChange={customOnChange}
              name='user_id'
              label='Case manager'
              validate={required()}
              required
              options={{
                filter: {
                  is_active: !isEdit,
                  case_manager: !!roles?.includes('case_manager'),
                  all_roles: true,
                },
                sort: { field: 'last_name', order: 'ASC' },
              }}
              style={{ marginRight: 0 }}
              shouldFetchMore
            />
          </div>
        )}
      {values.payer_rep >= 0 &&
        values.user_id &&
        (values.rep_fax || values.rep_email) && (
          <CheckboxInput
            name='send_notification'
            label='Auto-send admission notification'
            customOnChange={customOnChange}
            checked={formData.send_notification}
          />
        )}
      {formData.send_notification && values.rep_fax && values.rep_email && (
        <RadioInput
          row
          name='send_notification_type'
          customOnChange={customOnChange}
          value={formData.send_notification_type}
          label=''
          radios={[
            {
              label: 'Fax',
              value: 'fax',
            },
            {
              label: 'Email',
              value: 'email',
            },
          ]}
        />
      )}
      {(values.insurance === 0 ||
        values.insurance > HIGHEST_NO_AUTH_PAYER_ID) && (
        <div className={classes.secondaryActionContainer}>
          {!addAuth ? (
            <DialogContentText
              color='primary'
              onClick={openAuth}
              className={classes.secondaryAction}
            >
              <AddIcon /> Add auth for primary payer
            </DialogContentText>
          ) : (
            <DialogContentText
              color='primary'
              onClick={() => {
                removeAuthFields('primary');
                setAddAuth(false);
              }}
              className={classes.secondaryAction}
            >
              <RemoveIcon style={{ marginRight: 5 }} /> Remove auth for primary
              payer
            </DialogContentText>
          )}
        </div>
      )}
      {addAuth &&
        Array.isArray(values.update_weekday) &&
        Array.isArray(values.update_type) && (
          <AuthFields
            customOnChange={customOnChange}
            values={values}
            setFormData={setFormData}
            type='primary'
            lastCase={lastCase}
            subLevelChoices={primarySubLevelChoices}
          />
        )}
    </Fragment>
  );
};
