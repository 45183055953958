import React, { useState, cloneElement, Fragment, useCallback } from 'react';
import clsx from 'clsx';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  Filter,
  DateInput,
  Pagination,
  // FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { format, startOfMonth, subMonths, lastDayOfMonth } from 'date-fns';
import {
  ToggleButton,
  DateField,
  Datagrid,
  FilterChips,
  FilterMenu,
} from '../../components/common';
import { useGetUserDivision } from '../../hooks';

import { useStyles } from './reports.styles';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      id,
      first_name,
      last_name,
      case_id,
      days_of_coverage,
      discharge_date,
      discharge_location,
      discharge_location_id,
      actual_end_date,
      residents_insurance_id,
      is_mltc,
      is_primary,
      letter_received,
      payer_id,
      rate,
      request_sent,
      rev_code,
      room_number,
      user_id,
      resident_id,
      next_request_date,
      authorization_type_id,
      rep_name,
      rep_phone,
      rep_phone_ext,
      rep_fax,
      ending_soon,
      readmitted_discharge_location,
      payer_changed_to_name,
      lob_changed_to_type,
      cases_payers_id,
      payer_changed_to_id,
      auth_end,
      payer_change_date,
      facility_resident_id,
      insurance_auth_number,
      payer_name,
      lob,
      user,
      ...rest
    } = field;
    rest.rate = '';
    rest.totalRate = '';
    rest.payer_lob = `${payer_name}/${lob}`;
    //Need to do this so that Excel does not drop the leading zero
    rest.facility_resident_id = facility_resident_id
      ? `\t${facility_resident_id}`
      : '';
    rest.insurance_auth_number = insurance_auth_number
      ? `\t${insurance_auth_number}`
      : '';
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'facility_resident_id',
        'full_name',
        'dob',
        'admit_date',
        'payer_lob',
        'authorization_type',
        'insurance_auth_number',
        'authorization_status',
        'pending_reason',
        'approval_chance',
        'start_date',
        'end_date',
        'discharge',
        'total_days',
        'rate',
        'totalRate',
        'flag',
        'case_manager',
      ],
      rename: [
        'Patient Id',
        'Patient Name',
        'Date of Birth',
        'Admit Date',
        'Insurance/LOB',
        'Rate Type',
        'Auth Number',
        'Auth Status',
        'Pending Reason',
        'Likelihood of Approval',
        'Start Date',
        'Thru Date',
        'Discharge Date',
        'Total Days',
        '$ rate PPD',
        'Total Rate',
        'Flag',
        'Case Manager',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `billing-report-${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  loading,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  activeStatusFilters = {},
  filterActions,
  classes,
}) => {
  return (
    <TopToolbar
      className={clsx(
        classes.listActionWrapper,
        classes.flexWrap,
        classes.tabsActionWrapper,
      )}
      style={{ paddingTop: 19 }}
    >
      <div style={{ display: 'flex' }}>
        {/* <span className={classes.title}>Billing Report</span> */}
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <FilterMenu filterActions={filterActions} filters={reportFilters} />
        <FilterChips
          activeFilters={activeStatusFilters}
          filterActions={filterActions}
          disabled={loading}
        />
      </div>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
      <div style={{ width: '100%', height: 10 }} />
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='From'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
      <DateInput
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
    </Filter>
  );
};

export const BillingReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(
    userDivision && userDivision !== 'all' ? userDivision : 'subacute',
  );
  const [activeStatusFilters, setActiveStatusFilters] = useState({});

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <div className={classes.title} style={{ paddingTop: 35 }}>
          Billing report
        </div>
        <div style={{ padding: 30 }}>
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
            showBoth={false}
          />
        </div>
      </div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={
          <ListActions
            setDivision={setDivision}
            classes={classes}
            filterActions={filterActions}
            activeStatusFilters={activeStatusFilters}
          />
        }
        filter={{
          division,
          auth_type: Object.keys(activeStatusFilters),
        }}
        exporter={exporter}
        filters={<FiltersView classes={classes} />}
        sort={{ field: 'case_id', order: 'ASC' }}
        filterDefaultValues={{
          start_date: format(
            startOfMonth(subMonths(new Date(), 1)),
            'yyyy-MM-dd',
          ),
          end_date: format(
            lastDayOfMonth(subMonths(new Date(), 1)),
            'yyyy-MM-dd',
          ),
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField
            source='facility_resident_id'
            label='Resident ID'
            sortable={false}
          />
          <TextField
            source='authorization_type'
            label='Rate type'
            sortable={false}
          />
          <TextField
            source='insurance_auth_number'
            label='Auth number'
            sortable={false}
          />
          <TextField
            source='authorization_status'
            label='Auth status'
            sortable={false}
          />
          <TextField
            source='pending_reason'
            label='Pending reason'
            sortable={false}
          />
          <TextField
            source='approval_chance'
            label='Likelihood of approval'
            sortable={false}
          />
          <TextField
            source='full_name'
            label='Resident name'
            sortable={false}
          />
          <DateField source='admit_date' label='Admit date' sortable={false} />
          <TextField source='payer_name' label='Insurance' sortable={false} />
          <TextField source='lob' label='LOB' sortable={false} />
          <DateField source='start_date' label='Start date' sortable={false} />
          <DateField source='end_date' label='Thru date' sortable={false} />
          <TextField
            source='discharge'
            label='Discharge date'
            sortable={false}
          />
          <TextField source='total_days' label='Total days' sortable={false} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export const billingReportResource = {
  name: 'billing-report',
  list: BillingReportList,
};

function getResourceProps(props) {
  const basePath = '/billing-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/billing-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!billingReportResource.show,
        hasCreate: !!billingReportResource.create,
      };
  return { ...props, basePath, ...resource };
}

const reportFilters = [
  { id: '18', name: 'Part-B' },
  { id: '15', name: 'Pharmacy' },
];
