import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';

import { connect, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { uiActions } from '../../state/actions';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import Avatar from 'react-avatar';
import { usePermissions } from 'react-admin';
import {
  ListItem,
  List,
  Typography,
  Chip,
  ClickAwayListener,
} from '@material-ui/core';
import { useStyles } from './sidebar.styles';
import { authGet } from '../../server';
import { ArrowDropDownIcon } from '../../design';

const CaseItem = ({
  id: caseId,
  name: _name,
  admit_date,
  is_mltc,
  is_subacute,
  showArrowIcon,
  toggleList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nameOnly = _name && _name.split(' -');
  const name = nameOnly && nameOnly[0];

  function navigateToCase() {
    dispatch(push(`/cases/${caseId}/timeline`));
  }
  return (
    <ListItem
      classes={{
        root: classes.casesContainer,
        gutters: classes.gutters,
      }}
      onClick={
        showArrowIcon
          ? toggleList
          : () => {
              navigateToCase();
              toggleList();
            }
      }
    >
      <Avatar
        className={classes.avatar}
        name={name}
        initials={getInitials}
        maxInitials={1}
        size='49'
        round
      />
      <div>
        <Typography className={classes.caseTitle}>{name}</Typography>
        <Typography variant='caption' display='block'>
          {admit_date}
        </Typography>
        <div>
          {is_mltc && (
            <Chip
              className={clsx(classes.chip)}
              size='small'
              label='MLTC'
              style={{ marginRight: 3 }}
            />
          )}
          {is_subacute && (
            <Chip
              className={clsx(classes.chip, classes.approved)}
              size='small'
              label='Subacute'
            />
          )}
        </div>
      </div>
      {showArrowIcon && <ArrowDropDownIcon className={classes.dropdownIcon} />}
    </ListItem>
  );
};

const AllCasesDropdown = ({ toggleList }) => {
  const classes = useStyles();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  if (userPermissions.indexOf('contract_user') > -1) return null;
  return (
    <div style={{ color: '#5C738E', height: 50 }}>
      <ListItem onClick={toggleList}>
        <span className={classes.allCases}>All active cases</span>
        <ArrowDropDownIcon />
      </ListItem>
    </div>
  );
};
const SidebarCasesView = ({
  reduxActions,
  sidebarCase,
  facility,
  sidebarCases = {},
  adminCases,
  sidebarOpen,
  ...props
}) => {
  const dispatch = useDispatch();
  const [expandList, setExpandList] = useState(false);
  const [selectedCase, setSelectedCase] = useState(false);
  const classes = useStyles();
  function toggleList() {
    setExpandList(!expandList);
  }

  useEffect(() => {
    dispatch(reduxActions.getSidebarCases);
  }, [facility, dispatch, reduxActions.getSidebarCases]);

  useEffect(() => {
    async function setCase() {
      setSelectedCase(
        sidebarCases[sidebarCase]
          ? sidebarCases[sidebarCase]
          : await getSelectedCase(sidebarCase),
      );
    }
    setCase();
    if (!!sidebarCase) dispatch(uiActions.getResidentCasesCount(sidebarCase));
  }, [sidebarCase, sidebarCases, dispatch]);

  function displayCases(caseId) {
    const { [caseId]: _selectedCase, ...otherCases } = sidebarCases;
    return (
      <Fragment>
        {sidebarCase ? (
          <CaseItem {...selectedCase} showArrowIcon toggleList={toggleList} />
        ) : (
          <AllCasesDropdown toggleList={toggleList} />
        )}
        {expandList && (
          <ClickAwayListener onClickAway={() => setExpandList(false)}>
            <div>
              {Object.values(otherCases)
                .sort((a, b) => a.sort_id - b.sort_id)
                .map(row => {
                  return (
                    <CaseItem
                      key={row.id}
                      {...sidebarCases[row.id]}
                      toggleList={toggleList}
                    />
                  );
                })}
              <ListItem
                classes={{
                  root: classes.casesContainer,
                  gutters: classes.gutters,
                }}
                style={{ cursor: 'inherit' }}
              >
                <Typography
                  color='primary'
                  align='center'
                  className={classes.viewAll}
                  onClick={() => {
                    dispatch(push('/cases'));
                    toggleList();
                  }}
                >
                  View All Cases
                </Typography>
              </ListItem>
            </div>
          </ClickAwayListener>
        )}
      </Fragment>
    );
  }
  if (!sidebarOpen) return null;
  return (
    <div className={classes.casesAbsolute}>
      <List component='div' className={classes.casesList}>
        {displayCases(sidebarCase)}
      </List>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    facility,
    ui: { sidebarCases } = {},
    admin: {
      resources: { cases: { data: adminCases = {} } = {} } = {},
      ui: { sidebarOpen } = {},
    } = {},
  } = state;
  return {
    facility,
    sidebarCases,
    adminCases,
    sidebarOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  reduxActions: {
    ...bindActionCreators(uiActions, dispatch),
  },
});

export const SidebarCases = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(SidebarCasesView);

function getInitials(name, props) {
  const split = name.split(' ');
  if (split.length < 2) return '';
  return `${split[1].charAt(0)}`;
}

async function getSelectedCase(caseId) {
  if (!caseId) return null;
  const response = await authGet(`cases/${caseId}`);
  if (!response.data) return {};
  const { id, is_mltc, is_subacute, full_name, admit_date } = response.data;
  return {
    id,
    is_mltc,
    is_subacute,
    name: full_name,
    admit_date,
  };
}
