import { makeStyles, alpha } from '@material-ui/core/styles';
import { colors } from '../../design';

export const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 50,
    backgroundColor: '#1061a0',
    // needed to always show appbar even when scrolling
    transform: 'none !important',
    transition: 'none !important',
    visibility: 'visible !important',
    paddingRight: '0px !important',
    height: 56,
    boxShadow: '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)',
  },

  inactive: {
    fontSize: 25,
    color: '#ffffff',
    backgroundColor: '#F57C00',
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
    paddingRight: '0px !important',
    paddingTop: '5px',
    position: 'fixed',
    zIndex: 1,
  },
  title: {
    color: '#FFFFFF',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: 75,
    height: '22px',
  },
  spacer: {
    flex: 1,
  },
  selectRoot: {
    height: 34,
    backgroundColor: alpha(theme.palette.common.white, 0.17),
    width: 400,
  },
  selectFacilityRoot: {
    height: 34,
    borderRadius: 2,
    backgroundColor: alpha(theme.palette.common.white, 0.17),
    width: 400,
    fontWeight: 300,
    fontStyle: 'Nunito Sans',
  },
  dense: {
    marginTop: '2.5px',
    marginBottom: '2.5px',
  },
  searchInput: {
    color: '#FFFFFF',
    fontSize: 16,
    padding: '9px 10px 9px',
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      opacity: 0.87,
    },
  },
  dashboard: {
    color: colors.white,
    cursor: 'pointer',
  },
  logo: {
    height: 34,
    marginRight: 16,
    'user-drag': 'none',
    'user-select': 'none',
    '-moz-user-select': 'none',
    '-webkit-user-drag': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
  },
  avatar: {
    fontWeight: 300,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  focused: {
    transition: theme.transitions.create('width'),
    width: 550,
    backgroundColor: 'white',
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  'lost-focus': {
    width: 0,
    backgroundColor: 'currentColor',
  },
  searchIconFocused: {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
  },
  inputRoot: {
    flexGrow: 1,
    height: 34,
    fontWeight: 300,
  },
  notchedOutline: {
    border: 'none',
  },
  inputInput: {
    padding: '8px 8px 8px 5px',
    color: '#000000',
    fontWeight: 300,
    fontStyle: 'Nunito Sans',
  },
  paper: {
    width: 585,
    marginLeft: -34,
    borderRadius: 0,
    fontWeight: 300,
    fontStyle: 'Nunito Sans',
  },
  menuItem: {
    color: theme.palette.text.secondary,
  },
  icon: { minWidth: theme.spacing(5) },
  arrowIcon: { color: 'white' },
  searchBox: {
    marginLeft: -35,
    color: '#9FB4CB',
  },
  searchFocused: {
    marginRight: -13,
    padding: 5,
    backgroundColor: '#FFFFFF',
    color: '#5C738E',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
}));
