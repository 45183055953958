import React, { useState, Fragment } from 'react';
import { Form } from 'react-final-form';
import {
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
  required,
} from 'react-admin';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { handleNumbers, getDateInputValue, ReferenceInput } from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const CloneQualifyingCriteriaForm = ({
  payerFacilityId,
  meta,
  handleClose,
  record,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    id: record?.id,
    rate_ids: [],
  });

  const onSubmit = value => {
    return dataProvider
      .create('clone-qualifying-criteria', {
        data: {
          id: record?.id,
          rate_ids: value.rate_ids,
        },
      })
      .then(({ data }) => {
        notify(
          `Qualifying criteria details copied to ${data.updatedRates} rate(s)`,
          'form.created',
        );
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Typography className={classes.instructions}>
                  Select rates to add this set of qualifying criteria.
                </Typography>
                <ReferenceInput
                  reference='rates/list'
                  customOnChange={customOnChange}
                  name='rate_ids'
                  label='Rate'
                  validate={required()}
                  required
                  fullWidth
                  options={{
                    filter: { payer_facility_id: payerFacilityId },
                  }}
                  style={{ marginLeft: 0, marginRight: 0 }}
                  multiple
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
