import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Chip, TextField } from '@material-ui/core';
import { useStyles } from '../../modal.styles';
import { CheckboxInput } from '../inputs';

export const ExternalPayerHeader = ({
  payer_name,
  payer_type,
  is_primary,
  toggleAction,
  toggleStatus,
  indexRank,
  checkboxDisabled,
}) => {
  const classes = useStyles();
  const namePrefix = `insurances[${indexRank}].`;

  return (
    <Fragment>
      <div
        className={clsx(classes.inputContainerWrap)}
        style={{ alignItems: 'center' }}
      >
        <CheckboxInput
          margin='dense'
          disabled={checkboxDisabled}
          name={`${namePrefix}is_unignored`}
          title='Use this payer'
          checked={toggleStatus}
          customOnChange={toggleAction}
        />
        <TextField
          variant='outlined'
          margin='dense'
          className={classes.textField}
          value={payer_name}
          label='Payer name'
          disabled={true}
        />
        <TextField
          variant='outlined'
          margin='dense'
          className={classes.textField}
          value={payer_type}
          label='Payer type'
          disabled={true}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        />
        {is_primary && (
          <Chip
            label='Primary'
            // className={classes.chip}
            size='small'
          />
        )}
      </div>
    </Fragment>
  );
};
