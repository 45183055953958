import { facilityReducer } from './facility';
import { uiReducer } from './ui';
import { userReducer } from './user';
import { fileManagementReducer } from './file-management';
import { listReducer } from './lists';

export const reducers = {
  facility: facilityReducer,
  ui: uiReducer,
  user: userReducer,
  fileManagement: fileManagementReducer,
  lists: listReducer,
};
