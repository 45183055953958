import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import { required } from 'react-admin';
import { DialogContentText, Divider, FormGroup } from '@material-ui/core';
import {
  minDate,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  composeValidators,
  CheckboxInput,
  RadioInput,
  minReasonLength,
} from '../../modalForms';
import { weekDays, constants } from '../../../../../utils';
import { validateDate } from '../inputs';
import { MinuteUnitToggle } from '../authorization';
import { useStyles } from '../../modal.styles';
import _get from 'lodash/get';
import {
  getFieldName,
  UPDATES_ONLY_ID,
  AUTH_MISSING_ID,
  AUTH_ON_HOLD_ID,
  AUTH_UPDATES_STATUS_PENDING,
  PART_B_AUTH_ID,
  DENIAL_RECEIVED,
  DENIED,
} from './helpers';

const {
  updateTypes,
  caseTypes,
  pendingChances,
  authorization_type,
} = constants;

const roles = localStorage.getItem('roles');
function getEndDateValidators(values, type) {
  const validators = [
    validateDate(),
    minDate(
      _get(values, getFieldName('start_date', type)),
      'End date can not be before the start date',
    ),
  ];
  const authStatusId = _get(
    values,
    getFieldName('authorization_status_id', type),
  );
  if (
    authStatusId !== AUTH_UPDATES_STATUS_PENDING &&
    authStatusId !== AUTH_MISSING_ID &&
    authStatusId !== AUTH_ON_HOLD_ID &&
    _get(values, getFieldName('authorization_type_id', type)) !==
      UPDATES_ONLY_ID &&
    _get(values, getFieldName('authorization_type_id', type)) !== PART_B_AUTH_ID
  ) {
    validators.push(required());
  }
  return composeValidators(validators);
}

function getRecurringChoices(isRecurringDate) {
  const options = [
    { id: 'weekly', name: 'Weekly' },
    { id: 'biweekly', name: 'Biweekly' },
    { id: 'monthly', name: 'Monthly' },
  ];
  if (isRecurringDate) {
    options.push({ id: 'freq_days', name: 'Frequency of days' });
  }
  return options;
}

export const AuthFields = ({
  customOnChange,
  setFormData,
  values,
  type,
  lastCase = {},
  subLevelChoices,
}) => {
  const classes = useStyles();
  const [pt_option, setPtOption] = useState('minutes');
  const [ot_option, setOtOption] = useState('minutes');
  const [speech_option, setSpeechOption] = useState('minutes');
  const [s_pt_option, s_setPtOption] = useState('minutes');
  const [s_ot_option, s_setOtOption] = useState('minutes');
  const [s_speech_option, s_setSpeechOption] = useState('minutes');

  const otOption = type !== 'secondary' ? ot_option : s_ot_option;
  const ptOption = type !== 'secondary' ? pt_option : s_pt_option;
  const speechOption = type !== 'secondary' ? speech_option : s_speech_option;
  const schedule_type = _get(values, getFieldName('schedule_type', type));
  const authStatusId = _get(
    values,
    getFieldName('authorization_status_id', type),
  );
  return (
    <Fragment>
      {values.is_readmission && !!lastCase.id && (
        <div className={clsx(classes.inputContainerWrap)}>
          <ReferenceInput
            reference='auth-number/list'
            customOnChange={customOnChange}
            name={getFieldName('authorization_id', type)}
            label='Auth number'
            style={{
              marginRight:
                _get(values, getFieldName('authorization_id', type)) === 0
                  ? 20
                  : 0,
            }}
            resetOption={{ id: 0, name: 'Add new auth #' }}
            options={{
              filter: { case_id: lastCase.id },
            }}
          />
          {_get(values, getFieldName('authorization_id', type)) === 0 && (
            <FormTextField
              name={getFieldName('insurance_auth_number', type)}
              validate={required()}
              required
              customOnChange={customOnChange}
              label='Auth number'
              fullWidth
            />
          )}
        </div>
      )}

      <CheckboxInput
        name={getFieldName('is_retro', type)}
        customOnChange={customOnChange}
        label='Retro authorization'
        checked={values.is_retro}
      />
      {values.is_retro && (
        <div className={clsx(classes.inputContainerWrap)}>
          <FormTextField
            name={getFieldName('retro_close_date', type)}
            customOnChange={customOnChange}
            type='date'
            label='Retro close date'
          />
          <div style={{ flex: '1 0 160px' }} />
        </div>
      )}

      <div className={clsx(classes.inputContainerWrap)}>
        <SelectComponent
          customOnChange={customOnChange}
          label='Case Type'
          validate={required()}
          required
          name={getFieldName('case_type', type)}
          choices={caseTypes}
        />
        <ReferenceInput
          reference='facility-users/list'
          customOnChange={customOnChange}
          name={getFieldName('user_id', type)}
          label='Case manager'
          validate={required()}
          required
          style={{ marginRight: 0 }}
          options={{
            filter: {
              is_active: true,
              case_manager: !!roles?.includes('case_manager'),
              all_roles: true,
            },
            sort: { field: 'last_name', order: 'ASC' },
          }}
          shouldFetchMore
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name={getFieldName('start_date', type)}
          validate={composeValidators([required(), validateDate()])}
          required
          customOnChange={customOnChange}
          type='date'
          label='Start date'
        />
        <FormTextField
          name={getFieldName('days_approved', type)}
          customOnChange={customOnChange}
          validate={
            authStatusId !== AUTH_UPDATES_STATUS_PENDING &&
            authStatusId !== AUTH_MISSING_ID &&
            authStatusId !== AUTH_ON_HOLD_ID &&
            _get(values, getFieldName('authorization_type_id', type)) !==
              UPDATES_ONLY_ID &&
            _get(values, getFieldName('authorization_type_id', type)) !==
              PART_B_AUTH_ID
              ? required()
              : undefined
          }
          required={
            authStatusId !== AUTH_UPDATES_STATUS_PENDING &&
            authStatusId !== AUTH_MISSING_ID &&
            authStatusId !== AUTH_ON_HOLD_ID &&
            _get(values, getFieldName('authorization_type_id', type)) !==
              UPDATES_ONLY_ID &&
            _get(values, getFieldName('authorization_type_id', type)) !==
              PART_B_AUTH_ID
          }
          disabled={authStatusId === DENIED || authStatusId === DENIAL_RECEIVED}
          label='Days approved'
          type='number'
          style={{ marginRight: 0 }}
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <FormTextField
          name={getFieldName('end_date', type)}
          validate={getEndDateValidators(values, type)}
          required={
            authStatusId !== AUTH_UPDATES_STATUS_PENDING &&
            authStatusId !== AUTH_MISSING_ID &&
            authStatusId !== AUTH_ON_HOLD_ID &&
            _get(values, getFieldName('authorization_type_id', type)) !==
              UPDATES_ONLY_ID &&
            _get(values, getFieldName('authorization_type_id', type)) !==
              PART_B_AUTH_ID
          }
          min={_get(values, getFieldName('start_date', type))}
          customOnChange={customOnChange}
          type='date'
          label='End date'
        />
        <FormTextField
          name={getFieldName('days_available', type)}
          customOnChange={customOnChange}
          label='Days available'
          disabled
          style={{ marginRight: 0 }}
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        <ReferenceInput
          reference='auth-types/list'
          key={[
            _get(values, getFieldName('case_type', type)),
            _get(values, getFieldName('insurance', type)),
            _get(values, getFieldName('line_of_business_id', type)),
            _get(values, getFieldName('start_date', type)),
          ]}
          options={{
            filter: {
              mltc: _get(values, getFieldName('case_type', type)) ? 1 : [],
              subacute: _get(values, getFieldName('case_type', type)) ? [] : 1,
              insurance: _get(values, getFieldName('insurance', type)),
              line_of_business_id: _get(
                values,
                getFieldName('line_of_business_id', type),
              ),
              start_date: _get(values, getFieldName('start_date', type)),
              part_b: false,
            },
          }}
          customOnChange={customOnChange}
          name={getFieldName('authorization_type_id', type)}
          label='Rate type'
          validate={required()}
          required
        />
        {subLevelChoices.length ? (
          <SelectComponent
            customOnChange={customOnChange}
            label='Sub-level'
            name={getFieldName('sub_level', type)}
            choices={subLevelChoices}
            required
            style={{ marginRight: 20 }}
          />
        ) : null}
        <ReferenceInput
          customOnChange={customOnChange}
          label='Auth status'
          name={getFieldName('authorization_status_id', type)}
          reference='auths-status/list'
          validate={required()}
          required
          options={{
            filter: { create: !!!values.is_readmission },
          }}
          style={{ marginRight: 0 }}
        />
      </div>
      {_get(values, getFieldName('authorization_type_id', type)) ===
        authorization_type.SINGLE_CASE_AGREEMENT_RATE && (
        <FormTextField
          name={getFieldName('rate_details', type)}
          label='Rate details'
          multiline
          fullWidth
          customOnChange={customOnChange}
          required
        />
      )}
      {_get(values, getFieldName('authorization_type_id', type)) ===
        authorization_type.LEVEL_1 &&
        _get(values, getFieldName('authorization_status_id', type)) !==
          DENIED && (
          <div className={clsx(classes.inputContainerWrap)}>
            <ReferenceInput
              reference='level-one-reasons/list'
              customOnChange={customOnChange}
              name={getFieldName('level_one_reason_id', type)}
              label='Reason for Level 1 auth'
              required
              validate={required()}
              fullWidth
              style={{ marginRight: 0 }}
            />
            {_get(values, getFieldName('level_one_reason_id', type)) === 2 && (
              <ReferenceInput
                key={_get(values, getFieldName('level_one_reason_id', type))}
                reference='insufficient-criteria-reasons/list'
                customOnChange={customOnChange}
                name={getFieldName('insufficient_criteria_id', type)}
                label='Insufficient criteria details'
                required
                validate={required()}
                fullWidth
              />
            )}
            {_get(values, getFieldName('level_one_reason_id', type)) === 3 && (
              <ReferenceInput
                key={_get(values, getFieldName('level_one_reason_id', type))}
                reference='auth-types/list'
                customOnChange={customOnChange}
                name={getFieldName('qualifies_for_level', type)}
                label='Qualifying level'
                required
                validate={required()}
                fullWidth
                options={{
                  filter: {
                    levels: true,
                  },
                }}
              />
            )}
            <FormTextField
              name={getFieldName('level_one_reason', type)}
              label='Reason details'
              customOnChange={customOnChange}
              required={
                _get(values, getFieldName('level_one_reason_id', type)) === 4
              }
              validate={
                _get(values, getFieldName('level_one_reason_id', type)) === 4
                  ? composeValidators([required(), minReasonLength()])
                  : minReasonLength()
              }
              fullWidth
              multiline={
                _get(values, getFieldName('level_one_reason_id', type)) === 4
              }
            />
          </div>
        )}
      {authStatusId === AUTH_UPDATES_STATUS_PENDING && (
        <Fragment>
          <SelectComponent
            customOnChange={customOnChange}
            label='Likelihood of approval'
            validate={required()}
            required
            name={getFieldName('approval_chance', type)}
            choices={pendingChances}
            fullWidth
          />
          <FormTextField
            name={getFieldName('pending_reason', type)}
            label='Pending reason'
            multiline
            fullWidth
            customOnChange={customOnChange}
            validate={composeValidators([required(), minReasonLength()])}
            required
          />
        </Fragment>
      )}
      <div className={clsx(classes.inputContainerWrap)}>
        {(!values.is_readmission || !!!lastCase.id) && (
          <FormTextField
            name={getFieldName('insurance_auth_number', type)}
            validate={required()}
            required
            customOnChange={customOnChange}
            label='Auth number'
            fullWidth
            style={{ marginRight: 0 }}
          />
        )}
      </div>

      <div className={clsx(classes.inputContainerWrap)}>
        <ReferenceInput
          reference='auth-letter-status/list'
          customOnChange={customOnChange}
          name={getFieldName('auth_letter_status_id', type)}
          label='Auth letter status'
        />

        <FormTextField
          name={getFieldName('letter_received', type)}
          customOnChange={customOnChange}
          type='date'
          label='Auth letter received'
          validate={composeValidators([validateDate()])}
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        {(!values.is_readmission || !!!lastCase.id) && (
          <>
            <FormTextField
              name={getFieldName('primary_diagnosis', type)}
              customOnChange={customOnChange}
              label='Primary diagnosis'
            />
            <FormTextField
              name={getFieldName('billing_diagnosis', type)}
              customOnChange={customOnChange}
              label='Billing diagnosis'
            />
          </>
        )}
      </div>
      {values.is_readmission && !!lastCase.id && (
        <>
          <div className={clsx(classes.inputContainerWrap)}>
            <ReferenceInput
              reference='auth-diagnoses/list'
              customOnChange={customOnChange}
              name={getFieldName('primary_diagnosis_value', type)}
              label='Primary diagnosis'
              resetOption={{ id: 0, name: 'Add new diagnosis' }}
              options={{
                filter: { case_id: values.case_id },
              }}
              style={{
                marginRight: values.primary_diagnosis_value === 0 ? 8 : 0,
              }}
            />
            {_get(values, getFieldName('primary_diagnosis_value', type)) ===
              0 && (
              <FormTextField
                name={getFieldName('primary_diagnosis', type)}
                customOnChange={customOnChange}
                label='Primary diagnosis'
              />
            )}
          </div>
          <div className={clsx(classes.inputContainerWrap)}>
            <ReferenceInput
              reference='auth-diagnoses/list'
              customOnChange={customOnChange}
              name={getFieldName('billing_diagnosis_value', type)}
              label='Billing diagnosis'
              resetOption={{ id: 0, name: 'Add new diagnosis' }}
              options={{
                filter: { case_id: values.case_id },
              }}
              style={{
                marginRight: values.billing_diagnosis_value === 0 ? 8 : 0,
              }}
            />
            {_get(values, getFieldName('billing_diagnosis_value', type)) ===
              0 && (
              <FormTextField
                name={getFieldName('billing_diagnosis', type)}
                customOnChange={customOnChange}
                label='Billing diagnosis'
              />
            )}
          </div>
        </>
      )}
      <div className={clsx(classes.inputContainerWrap)}>
        {/* Needed for spacing */}
        <div style={{ flex: '1 0 160px' }} />
        {/* <div style={{ flex: '1 0 160px' }} /> */}
      </div>
      <FormTextField
        name={getFieldName('auth_notes', type)}
        label='Auth notes'
        multiline
        fullWidth
        customOnChange={customOnChange}
        validate={minReasonLength()}
      />
      {_get(values, getFieldName('authorization_type_id', type)) ===
        PART_B_AUTH_ID && (
        <Fragment>
          <DialogContentText
            align='left'
            variant='h6'
            className={classes.header}
          >
            Services
          </DialogContentText>
          <FormGroup row>
            <CheckboxInput
              name={getFieldName('pt', type)}
              customOnChange={customOnChange}
              label='PT'
              checked={_get(values, getFieldName('pt', type))}
            />
            <CheckboxInput
              name={getFieldName('ot', type)}
              customOnChange={customOnChange}
              label='OT'
              checked={_get(values, getFieldName('ot', type))}
            />
            <CheckboxInput
              name={getFieldName('speech', type)}
              customOnChange={customOnChange}
              label='Speech'
              checked={_get(values, getFieldName('speech', type))}
            />
          </FormGroup>
          {_get(values, getFieldName('pt', type)) && (
            <Fragment>
              <p className={classes.sectionSubHeader}>PT Requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle
                  setState={type === 'primary' ? setPtOption : s_setPtOption}
                  curValue={ptOption}
                />
              </div>
              <div
                className={clsx(classes.inputContainerWrap)}
                style={{ paddingTop: 10 }}
              >
                <FormTextField
                  name={getFieldName('pt_frequency', type)}
                  customOnChange={customOnChange}
                  label='Amount of visits'
                  type='number'
                  validate={required()}
                  required
                />
                {ptOption === 'minutes' ? (
                  <FormTextField
                    name={getFieldName('pt_minutes', type)}
                    customOnChange={customOnChange}
                    label='Minutes'
                    type='number'
                    style={{ marginRight: 0 }}
                  />
                ) : (
                  <FormTextField
                    name={getFieldName('pt_units', type)}
                    customOnChange={customOnChange}
                    label='Units'
                    type='number'
                    style={{ marginRight: 0 }}
                  />
                )}
              </div>
            </Fragment>
          )}
          {_get(values, getFieldName('ot', type)) && (
            <Fragment>
              <p className={classes.sectionSubHeader}>OT requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle
                  setState={type === 'primary' ? setOtOption : s_setOtOption}
                  curValue={otOption}
                />
              </div>
              <div
                className={clsx(classes.inputContainerWrap)}
                style={{ paddingTop: 10 }}
              >
                <FormTextField
                  name={getFieldName('ot_frequency', type)}
                  customOnChange={customOnChange}
                  label='Amount of visits'
                  type='number'
                  validate={required()}
                  required
                />
                {otOption === 'minutes' ? (
                  <FormTextField
                    name={getFieldName('ot_minutes', type)}
                    customOnChange={customOnChange}
                    label='Minutes'
                    type='number'
                    style={{ marginRight: 0 }}
                  />
                ) : (
                  <FormTextField
                    name={getFieldName('ot_units', type)}
                    customOnChange={customOnChange}
                    label='Units'
                    type='number'
                    style={{ marginRight: 0 }}
                  />
                )}
              </div>
            </Fragment>
          )}
          {_get(values, getFieldName('speech', type)) && (
            <Fragment>
              <p className={classes.sectionSubHeader}>Speech requested</p>
              <div style={{ marginTop: 15, marginBottom: -15 }}>
                <MinuteUnitToggle
                  setState={
                    type === 'primary' ? setSpeechOption : s_setSpeechOption
                  }
                  curValue={speechOption}
                />
              </div>
              <div
                className={clsx(classes.inputContainerWrap)}
                style={{ paddingTop: 10 }}
              >
                <FormTextField
                  name={getFieldName('speech_frequency', type)}
                  customOnChange={customOnChange}
                  label='Amount of visits'
                  type='number'
                  validate={required()}
                  required
                />
                {speechOption === 'minutes' ? (
                  <FormTextField
                    name={getFieldName('speech_minutes', type)}
                    customOnChange={customOnChange}
                    label='Minutes'
                    type='number'
                    style={{ marginRight: 0 }}
                  />
                ) : (
                  <FormTextField
                    name={getFieldName('speech_units', type)}
                    customOnChange={customOnChange}
                    label='Units'
                    type='number'
                    style={{ marginRight: 0 }}
                  />
                )}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
      <DialogContentText align='left' variant='h6' className={classes.header}>
        Updates
      </DialogContentText>
      <p className={classes.subHeader}>
        Set up a single or recurring updates schedule
      </p>
      <Divider className={classes.divider} />
      <div style={{ marginBottom: 10 }}>
        <RadioInput
          name={getFieldName('schedule_type', type)}
          className={classes.radio}
          customOnChange={customOnChange}
          value={schedule_type}
          validate={authStatusId !== DENIED && required()}
          required={authStatusId !== DENIED}
          label='Update schedule'
          radios={[
            {
              label: 'No update schedule',
              value: 'no_update',
            },
            { label: 'One time update', value: 'one_time' },
            { label: 'Recurring by date', value: 'recurring_date' },
            {
              label: 'Recurring by day/days of the week ',
              value: 'recurring_day',
            },
          ]}
        />
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        {schedule_type === 'no_update' && (
          <FormTextField
            name={getFieldName('no_update_reason', type)}
            label='No updates reason'
            multiline
            fullWidth
            customOnChange={customOnChange}
            validate={composeValidators([required(), minReasonLength()])}
            required
            style={{ marginRight: 0 }}
          />
        )}
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        {(schedule_type === 'one_time' ||
          schedule_type === 'recurring_date') && (
          <FormTextField
            name={getFieldName('initial_update_date', type)}
            customOnChange={customOnChange}
            type='date'
            label='Select date'
            validate={composeValidators([validateDate(), ...[required()]])}
          />
        )}
        {schedule_type === 'recurring_day' && (
          <SelectComponent
            customOnChange={customOnChange}
            label='Specific days of week'
            multiple
            renderWith='chip'
            formField={_get(values, getFieldName('update_type', type))}
            name={getFieldName('update_weekday', type)}
            choices={weekDays}
            validate={required()}
          />
        )}
        {schedule_type === 'recurring_date' ||
        schedule_type === 'recurring_day' ? (
          <SelectComponent
            customOnChange={customOnChange}
            label='Recurring frequency'
            name={getFieldName('update_frequency', type)}
            choices={getRecurringChoices(schedule_type === 'recurring_date')}
            validate={required()}
            required
            disabled={
              Array.isArray(
                _get(values, getFieldName('update_weekday', type)),
              ) && _get(values, getFieldName('update_weekday', type)).length > 1
            }
            style={{ marginRight: 0 }}
          />
        ) : (
          <div style={{ flex: '1 0 160px' }} />
        )}
      </div>
      <div className={clsx(classes.inputContainerWrap)}>
        {_get(values, getFieldName('update_frequency', type)) ===
          'freq_days' && (
          <FormTextField
            name={getFieldName('update_frequency_days', type)}
            customOnChange={customOnChange}
            label='Frequency days'
            type='number'
            validate={required()}
            required
          />
        )}
      </div>
      {schedule_type !== 'no_update' && schedule_type !== '' && (
        <SelectComponent
          customOnChange={customOnChange}
          label='Update type'
          multiple
          renderWith='chip'
          formField={_get(values, getFieldName('update_type', type))}
          name={getFieldName('update_type', type)}
          choices={updateTypes}
          fullWidth
          setFormData={setFormData}
          validate={required()}
        />
      )}
      {schedule_type !== 'no_update' && schedule_type !== '' && (
        <FormTextField
          name={getFieldName('update_notes', type)}
          label='Update notes'
          multiline
          fullWidth
          customOnChange={customOnChange}
          validate={minReasonLength()}
        />
      )}
    </Fragment>
  );
};
