import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  Pagination,
  // NumberField,
  // DateField,
} from 'react-admin';
import { useStyles } from './residents.styles';
import { ReportIcon } from '../design';

const ListActions = props => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <span className={classes.title}>Reports</span>
    </TopToolbar>
  );
};

const rowClick = (id, basePath, record) => {
  const { name = '' } = record;
  let url = `/${name.replace(/\s+/g, '-').toLowerCase()}`;
  if (name === 'Client Details Report') {
    url = url + '/report';
  }
  return url;
};

export const ReportsList = props => {
  return (
    <List
      empty={false}
      {...props}
      bulkActionButtons={false}
      exporter={false}
      actions={<ListActions />}
      pagination={
        <Pagination
          rowsPerPageOptions={[100, 500]}
          style={{
            display: 'flex',
          }}
        />
      }
      perPage={100}
    >
      <Datagrid rowClick={rowClick}>
        <TextField source='name' label='Report name' />
        <TextField source='description' label='Description' />
      </Datagrid>
    </List>
  );
};
export const reportsResource = {
  name: 'reports',
  list: ReportsList,
  // show: ResidentShow,
  icon: ReportIcon,
  options: { label: 'Reports' },
};
