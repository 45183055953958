import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import { authGet } from '../../../../../server';

export function getFieldName(name, type) {
  if (typeof type === 'undefined' || type === 'primary') return name;
  const prefix = type === 'secondary' ? 's_' : type;
  return `${prefix}${name}`;
}

export async function fetchResource(resource, value) {
  const response = await authGet(
    typeof value === 'object'
      ? [resource, { ...value }]
      : `/${resource}/${value}`,
  );
  if (response.error) return 'error';
  return response.data;
}

export async function fetchRateRevCode(data = {}) {
  const { insurance, ...rest } = data;
  const response = await authGet([
    'auth-level-rate',
    { ...rest, payer_id: insurance },
  ]);
  if (response.error) return 'error';
  return response.data;
}

export function setScheduleType({
  initial_update_date,
  update_frequency_days,
  update_frequency,
  update_weekday,
  no_update_reason,
}) {
  if (Array.isArray(update_weekday) && update_weekday.length) {
    return 'recurring_day';
  } else if (update_frequency_days || update_frequency) {
    return 'recurring_date';
  } else if (initial_update_date) {
    return 'one_time';
  } else if (no_update_reason) {
    return 'no_update';
  } else {
    return '';
  }
}

export const HIGHEST_NO_AUTH_PAYER_ID = 6;
export const UPDATES_ONLY_ID = 16;
export const AUTH_MISSING_ID = 6;
export const AUTH_ON_HOLD_ID = 8;
export const AUTH_UPDATES_STATUS_PENDING = 5;
export const PART_B_AUTH_ID = 18;
export const DENIAL_RECEIVED = 3;
export const DENIED = 4;

export function ToggleInsuranceNetwork({ state, setState, name, disabled }) {
  const handleChange = event => {
    setState(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={state}
          onChange={handleChange}
          name={name}
          color='primary'
          disabled={disabled}
        />
      }
      label='In network'
    />
  );
}
