import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { stringify } from 'query-string';
import { useRedirect } from 'react-admin';
import { Chart } from 'react-google-charts';
import { Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { authGet } from '../../server';
import { Loading } from '../../components/common';
import { useStyles } from './dashboard.styles';

export const SupervisorDashboard = ({
  title,
  facilityIds,
  groupId,
  formatColumnChartData,
  casesData,
  viewMultiple,
  userPermissions,
}) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const ui = useSelector(state => state.admin.ui);
  const dispatch = useDispatch();
  const [weClosedSidebar, setWeClosedSidebar] = useState(false);
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (smallDevice && ui.sidebarOpen === true && !weClosedSidebar) {
      setWeClosedSidebar(true);
      dispatch({ type: 'RA/TOGGLE_SIDEBAR' });
    }
  }, [dispatch, smallDevice, ui.sidebarOpen, weClosedSidebar]);

  const [tasksData, setTasksData] = useState([]);
  const [faxData, setFaxData] = useState([]);
  const [hourlyFaxData, setHourlyFaxData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [formattedCaseData, setFormattedCaseData] = useState([]);
  const [residentsData, setResidentsData] = useState([]);
  const [avgDailyRateData, setAvgDailyRateData] = useState([]);
  const [avgLosData, setAvgLosData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const [
        tasks,
        updates,
        faxes,
        hourly_faxes,
        residents,
        avg_daily_rate,
        avg_los,
      ] = await Promise.all([
        authGet([
          `/reports/dashboard/tasks`,
          { facility_ids: facilityIds, group_id: groupId },
        ]),
        authGet([
          `/reports/dashboard/updates`,
          { facility_ids: facilityIds, group_id: groupId },
        ]),
        authGet([
          `/reports/dashboard/faxes`,
          { facility_ids: facilityIds, group_id: groupId },
        ]),
        authGet([
          `/reports/dashboard/faxes`,
          { facility_ids: facilityIds, group_id: groupId, hourly: true },
        ]),
        authGet([
          `/reports/dashboard/residents`,
          { facility_ids: facilityIds, group_id: groupId },
        ]),
        authGet([
          `/reports/dashboard/avg-daily-rate`,
          {
            facility_ids: facilityIds,
            group_id: groupId,
            viewMultiple: viewMultiple,
          },
        ]),
        authGet([
          `/reports/dashboard/avg-los`,
          {
            facility_ids: facilityIds,
            group_id: groupId,
            viewMultiple: viewMultiple,
          },
        ]),
      ]);
      tasks.status === 200 && setTasksData(formatTaskColumnData(tasks.data));
      tasks.status === 200 &&
        updates.status === 200 &&
        setCombinedData(formatTaskUpdatesData(tasks.data, updates.data));
      faxes.status === 200 && setFaxData(formatFaxColumnData(faxes.data));
      hourly_faxes.status === 200 &&
        setHourlyFaxData(formatHourlyFaxColumnData(hourly_faxes.data));
      residents.status === 200 &&
        setResidentsData(formatColumnChartData(residents.data, true));
      avg_daily_rate.status === 200 &&
        setAvgDailyRateData(
          formatAvgDailyRateColumnData(avg_daily_rate.data, viewMultiple),
        );
      avg_los.status === 200 &&
        setAvgLosData(formatAvgLosColumnData(avg_los.data, viewMultiple));
    };
    facilityIds && fetchData();
  }, [facilityIds, groupId, formatColumnChartData, viewMultiple]);

  useEffect(() => {
    setFormattedCaseData(formatColumnChartData(casesData, true));
  }, [casesData, formatColumnChartData]);

  const combinedRef = useRef(null);
  const tasksRef = useRef(null);
  const faxRef = useRef(null);

  return (
    <div>
      <Typography className={classes.title}>{title}</Typography>
      <div>
        <div className={classes.sectionTitle}>Team Tasks</div>
        <Grid container spacing={4} style={{ height: '100%' }}>
          <Grid item md={12} xs={12}>
            <Paper ref={combinedRef} className={classes.section}>
              {!!combinedData.length ? (
                <Chart
                  height='280px'
                  width={combinedRef.current?.offsetWidth - 45 + 'px'}
                  // Note here we use Bar instead of ColumnChart to load the material design version
                  chartType='Bar'
                  data={combinedData}
                  options={teamTaskOptions}
                  chartEvents={[
                    {
                      eventName: 'select',
                      callback({ chartWrapper, google, props }) {
                        const position = chartWrapper
                          .getChart()
                          .getSelection()[0];
                        const user = combinedData[position?.row + 1]?.[0];
                        const type = combinedData[0][position?.column];
                        let path = '';
                        if (user && type && !viewMultiple) {
                          switch (type) {
                            case 'Tasks Due':
                              path = '/tasks/not-completed';
                              break;
                            case 'Tasks Done':
                              path = '/tasks/completed';
                              break;
                            case 'Updates Due':
                              path = '/updates';
                              break;
                            default:
                          }
                          if (path) {
                            redirect(
                              `${path}?${stringify({
                                'user-id': userObj[user],
                              })}`,
                            );
                          }
                        }
                      },
                    },
                  ]}
                />
              ) : (
                <Loading />
              )}
            </Paper>
          </Grid>
          <Grid item md={12} xs={12}>
            <Paper ref={tasksRef} className={classes.section}>
              {!!tasksData.length ? (
                <Chart
                  chartType='ColumnChart'
                  height='280px'
                  width='100%'
                  fontSize={11}
                  data={tasksData}
                  options={tasksOptions}
                  chartEvents={[
                    {
                      eventName: 'select',
                      callback({ chartWrapper, google, props }) {
                        const position = chartWrapper
                          .getChart()
                          .getSelection()[0];
                        const user = tasksData[position?.row + 1]?.[0];
                        const type = tasksData[0][position?.column];
                        const path = '/tasks/not-completed';
                        const filters = {
                          Denials: 'denial_tasks',
                          'Missing Auths': 'missing_auth',
                          'Retro Issues': 'retro_issue',
                          Overdue: 'overdue',
                        };
                        if (user && type && !viewMultiple) {
                          redirect(
                            `${path}?${stringify({
                              'user-id': userObj[user],
                            })}&filters=["${filters[type]}"]`, // need to do it like this due to existing code on tasks...
                          );
                        }
                      },
                    },
                  ]}
                />
              ) : (
                <Loading />
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12} style={{ height: '100%' }}>
          <div className={classes.sectionTitle}>Team Caseload</div>

          <Paper className={classes.section}>
            {!!formattedCaseData.length ? (
              <Chart
                chartType='ColumnChart'
                height='280px'
                width='100%'
                fontSize={11}
                data={formattedCaseData}
                options={breakDownOptions}
              />
            ) : (
              <Loading />
            )}
          </Paper>
        </Grid>
        <Grid item md={6} xs={12} style={{ height: '100%' }}>
          <div className={classes.sectionTitle}>Residents Requiring Action</div>
          <Paper className={classes.section}>
            <Chart
              height='280px'
              width='100%'
              chartType='ColumnChart'
              data={residentsData}
              options={teamResidentsOptions}
            />
          </Paper>
        </Grid>
        {userPermissions.indexOf('admin') > -1 && (
          <Grid item md={6} xs={12} style={{ height: '100%' }}>
            <div className={classes.sectionTitle}>Average Daily Rate</div>
            <Paper className={classes.section}>
              <Chart
                height='280px'
                width='100%'
                chartType={viewMultiple ? 'Bar' : 'LineChart'}
                data={avgDailyRateData}
                options={avgDailyRateOptions}
              />
            </Paper>
          </Grid>
        )}
        {userPermissions.indexOf('admin') > -1 && (
          <Grid item md={6} xs={12} style={{ height: '100%' }}>
            <div className={classes.sectionTitle}>
              Average Length of Stay by Discharge
            </div>
            <Paper className={classes.section}>
              <Chart
                height='280px'
                width='100%'
                chartType={viewMultiple ? 'Bar' : 'LineChart'}
                data={avgLosData}
                options={avgLosOptions}
              />
            </Paper>
          </Grid>
        )}
        <Grid item md={6} xs={12} style={{ height: '100%' }}>
          <div className={classes.sectionTitle}>
            Failed Faxes - Past 48 Hours
          </div>
          <Paper ref={faxRef} className={classes.section}>
            {!!hourlyFaxData.length ? (
              <Chart
                chartType='ColumnChart'
                width={'100%'}
                height='280px'
                fontSize={11}
                data={hourlyFaxData}
                options={hourlyFaxOptions}
                chartEvents={[
                  {
                    eventName: 'select',
                    callback({ chartWrapper, google, props }) {
                      if (!viewMultiple) redirect('/faxes-list');
                    },
                  },
                ]}
              />
            ) : (
              <Loading />
            )}
          </Paper>
        </Grid>
        <Grid item md={6} xs={12} style={{ height: '100%' }}>
          <div className={classes.sectionTitle}>Fax Status - Past 7 Days</div>
          <Paper ref={faxRef} className={classes.section}>
            {!!faxData.length ? (
              <Chart
                chartType='ColumnChart'
                width={'100%'}
                height='280px'
                fontSize={11}
                data={faxData}
                options={faxOptions}
                chartEvents={[
                  {
                    eventName: 'select',
                    callback({ chartWrapper, google, props }) {
                      if (!viewMultiple) redirect('/faxes-list');
                    },
                  },
                ]}
              />
            ) : (
              <Loading />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
// We need to use 'Material charts' to get dual stacked bars
// https://github.com/google/google-visualization-issues/issues/1270#issuecomment-279562884
const teamTaskOptions = {
  isStacked: true,
  series: {
    '2': { targetAxisIndex: 1, axis: '1' },
    '3': { targetAxisIndex: 2, axis: '1' },
  },
  stacked: true,
  axes: {
    y: {
      0: {
        label: 'Tasks & Updates',
      },
      all: {
        range: {
          max: 400,
          min: 0,
        },
      },
    },
  },
  colors: ['#AEDD45', 'rgba(174,221,69,0.28)', '#001543', 'rgba(0,21,67,0.28)'],
  bar: { groupWidth: '95%' },
  legend: { position: 'bottom' },
};

const teamResidentsOptions = {
  hAxis: { title: 'Case Managers', textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Residents',
    viewWindow: { min: 0 },
    textStyle: { fontSize: 11 },
    format: '0',
  },
  bar: { gap: '10%' },
  colors: ['#E91F31', '#F57C00', '#FBD834', '#001543'],
};
const tasksOptions = {
  hAxis: { title: 'Case Managers', textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Denials & Missing Auths',
    viewWindow: { min: 0 },
    textStyle: { fontSize: 11 },
    format: '0',
  },
  bar: { groupWidth: '75%' },
  colors: ['#E91F31', '#F57C00', '#FBD834', '#001543'],
  isStacked: true,
};

const faxOptions = {
  hAxis: { title: 'Case Managers', textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Fax Statuses',
    viewWindow: { min: 0 },
    textStyle: { fontSize: 11 },
    format: '0',
  },
  bar: { groupWidth: '50%' },
  colors: ['#FBD834', '#AEDD45', '#E91F31', '#F57C00'],
  isStacked: true,
};

const hourlyFaxOptions = {
  hAxis: { title: 'Case Managers', textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Fax Statuses',
    viewWindow: { min: 0 },
    textStyle: { fontSize: 11 },
    format: '0',
  },
  bar: { groupWidth: '50%' },
  colors: ['#E91F31'],
  isStacked: true,
};
const breakDownOptions = {
  title: 'Case Breakdown',
  hAxis: { title: 'Status', textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Cases',
    viewWindow: { min: 0 },
    textStyle: { fontSize: 11 },
    format: '0',
  },
  bar: { groupWidth: '75%' },
  colors: ['#E91F31', '#001543', '#66afdb', '#AEDD45'],
  isStacked: true,
};

const avgDailyRateOptions = {
  title: 'Average Daily Rate',
  hAxis: { textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Average Daily Rate',
    textStyle: { fontSize: 11 },
    format: '0.00',
  },
  series: {
    '2': { targetAxisIndex: 1, axis: '1' },
    '3': { targetAxisIndex: 2, axis: '1' },
  },
  bar: { groupWidth: '50%' },
  colors: ['#E91F31', '#001543', '#66afdb', '#AEDD45'],
  isStacked: true,
};

const avgLosOptions = {
  title: 'Average Length of Stay by Discharge',
  hAxis: { textStyle: { fontSize: 11 } },
  vAxis: {
    title: 'Average Length of Stay by Discharge',
    textStyle: { fontSize: 11 },
    format: '0.00',
  },
  series: {
    '2': { targetAxisIndex: 1, axis: '1' },
    '3': { targetAxisIndex: 2, axis: '1' },
  },
  bar: { groupWidth: '50%' },
  colors: ['#E91F31', '#001543', '#66afdb', '#AEDD45'],
  isStacked: true,
};

function formatTaskColumnData(data) {
  if (typeof data !== 'object') return [];
  const rv = [
    [
      'Genre',
      'Denials',
      'Missing Auths',
      'Retro Issues',
      'Overdue',
      { role: 'annotation' },
    ],
  ];

  Object.keys(data).forEach(key => {
    const row = data[key];
    const keys = Object.keys(row).filter(
      item => item !== 'user_id' && row[item] > 0,
    );
    if (keys.length > 0) {
      const { denial_tasks, missing_auth, retro_issue, overdue, user_id } =
        data[key] || {};
      rv.push([key, denial_tasks, missing_auth, retro_issue, overdue, '']);
      userObj[key] = user_id;
    }
  });
  if (rv.length === 1) {
    rv.push([' ', 0, 0, 0, 0, '']); //placeholder dummy data
    userObj[''] = 0;
  }
  return rv;
}

function formatTaskUpdatesData(tasksData, authData) {
  if (typeof tasksData !== 'object' || typeof authData !== 'object') return [];
  const rv = [
    ['Case Managers', 'Tasks Due', 'Tasks Done', 'Updates Due', 'Updates Done'],
  ];

  Object.keys(tasksData).forEach(key => {
    const mergedValues = {
      ...(tasksData[key] || {}),
      ...(authData[key] || {}),
    };

    const row = mergedValues;
    const keys = Object.keys(row).filter(
      item => item !== 'user_id' && row[item] > 0,
    );
    if (keys.length > 0) {
      const { pending, done, updates_pending, updates_done } = mergedValues;
      rv.push([key, pending, done, updates_pending, updates_done]);
    }
  });
  if (rv.length === 1) {
    rv.push([' ', 0, 0, 0, 0]); //placeholder dummy data
  }
  return rv;
}

function formatFaxColumnData(data) {
  if (typeof data !== 'object') return [];
  const rv = [
    [
      'Genre',
      'Queued',
      'Sent',
      'Sending Failed',
      'Resent',
      { role: 'annotation' },
    ],
  ];

  Object.keys(data).forEach(key => {
    if (!!Object.values(data[key]).filter(x => x !== 0).length > 0) {
      const { Queued, Sent, SendingFailed, Resent } = data[key] || {};
      rv.push([key, Queued, Sent, SendingFailed, Resent, '']);
    }
  });

  if (rv.length === 1) {
    rv.push([' ', 0, 0, 0, 0, '']); //placeholder dummy data
  }
  return rv;
}

function formatHourlyFaxColumnData(data) {
  if (typeof data !== 'object') return [];
  const rv = [['Genre', 'Sending Failed', { role: 'annotation' }]];

  Object.keys(data).forEach(key => {
    if (!!Object.values(data[key]).filter(x => x !== 0).length > 0) {
      const { SendingFailed } = data[key] || {};
      rv.push([key, SendingFailed, '']);
    }
  });

  if (rv.length === 1) {
    rv.push([' ', 0, '']); //placeholder dummy data
  }
  return rv;
}
function formatAvgDailyRateColumnData(avgDailyRateData, viewMultiple) {
  if (typeof avgDailyRateData !== 'object') return [];
  const rv = [['Facility', 'Subacute', 'MLTC']];
  if (viewMultiple) {
    Object.keys(avgDailyRateData).forEach(key => {
      if (
        !!Object.values(avgDailyRateData[key]).filter(x => x !== 0).length > 0
      ) {
        const { Facility, Subacute, MLTC } = avgDailyRateData[key] || {};
        rv.push([Facility, Subacute, MLTC]);
      }
    });
  } else {
    Object.keys(avgDailyRateData).forEach(key => {
      if (
        !!Object.values(avgDailyRateData[key]).filter(x => x !== 0).length > 0
      ) {
        const { Month, Subacute, MLTC } = avgDailyRateData[key] || {};
        rv.push([Month, Subacute, MLTC]);
      }
    });
  }
  if (rv.length === 1) {
    rv.push([' ', ' ', 0, 0, '']); //placeholder dummy data
  }
  return rv;
}

function formatAvgLosColumnData(avgLosData, viewMultiple) {
  if (typeof avgLosData !== 'object') return [];
  const rv = [['Facility', 'Subacute', 'MLTC']];
  if (viewMultiple) {
    Object.keys(avgLosData).forEach(key => {
      if (!!Object.values(avgLosData[key]).filter(x => x !== 0).length > 0) {
        const { Facility, Subacute, MLTC } = avgLosData[key] || {};
        rv.push([Facility, Subacute, MLTC]);
      }
    });
  } else {
    Object.keys(avgLosData).forEach(key => {
      if (!!Object.values(avgLosData[key]).filter(x => x !== 0).length > 0) {
        const { Month, Subacute, MLTC } = avgLosData[key] || {};
        rv.push([Month, Subacute, MLTC]);
      }
    });
  }

  if (rv.length === 1) {
    rv.push([' ', ' ', 0, 0, '']); //placeholder dummy data
  }
  return rv;
}
const userObj = {};
