import React, { Fragment, useEffect, useRef } from 'react';
import {
  ShowView,
  FunctionField,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  List,
  Datagrid,
  useShowController,
  useRefresh,
  usePermissions,
  useUnselectAll,
} from 'react-admin';
import { useStyles } from './eligibility.styles';
import { Divider, Chip } from '@material-ui/core';
import clsx from 'clsx';
import { getChip } from '../utils';
const ListActions = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Eligibility responses</span>
      </TopToolbar>
    </Fragment>
  );
};
const ShowActions = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Eligibility response</span>
      </TopToolbar>
    </Fragment>
  );
};

export const EligibilityList = ({ staticContext, ..._props }) => {
  const { caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const isFirstRender = useRef(true);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);
  const unselectAll = useUnselectAll('case-eligibility');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <List
        {...props}
        empty={false}
        actions={<ListActions />}
        bulkActionButtons={isViewAdmin ? false : undefined}
      >
        <Datagrid rowClick='show'>
          <FunctionField
            label='Status'
            source='seek_eligibility_response'
            sortable={false}
            render={record => {
              const { seek_eligibility_response } = record;
              return (
                <div>
                  <Chip
                    size='small'
                    label={seek_eligibility_response || 'Success'}
                    className={clsx(
                      classes.chip,
                      classes[getChip(seek_eligibility_response || 'Success')],
                    )}
                  />
                </div>
              );
            }}
          />
          <TextField
            source='date_of_service'
            label='Date checked'
            className={classes.showDetailedItem}
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='created_at'
            label='Created at'
            className={classes.showDetailedItem}
            headerClassName={classes.listItemLabel}
          />
        </Datagrid>
      </List>
    </Fragment>
  );
};
const EligibilityShow = ({ staticContext, ..._props }) => {
  const classes = useStyles();
  const { caseId, ...props } = getResourceProps(_props);
  const showProps = useShowController({ ...props });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ShowView actions={<ShowActions />} {...showProps}>
      <Fragment {...showProps}>
        <SimpleShowLayout className={classes.showLayout} {...showProps}>
          <FunctionField
            source='name'
            label='Name'
            className={classes.showItem}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { firstname, lastname } = record;
              return firstname + ' ' + lastname;
            }}
          />
          <FunctionField
            source='address'
            label='Address'
            className={classes.showItem}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { address_1, address_2, csz } = record;
              return (
                <div>
                  {address_1}
                  <br /> {address_2} <br /> {csz}
                </div>
              );
            }}
          />
          <TextField
            source='gender'
            label='Gender'
            className={classes.showItem}
            headerClassName={classes.showItemLable}
          />
          <TextField
            source='date_of_birth'
            label='Date of birth'
            className={classes.showItem}
            headerClassName={classes.showItemLable}
          />
          <TextField
            source='client_id'
            label='Client ID'
            className={classes.showItem}
            headerClassName={classes.showItemLable}
          />
        </SimpleShowLayout>
        <Divider />
        {showProps.record?.medicaid_eligibility_information && (
          <SimpleShowLayout className={classes.showLayout} {...showProps}>
            <TextField
              source='medicaid_eligibility_information'
              label='Medicaid eligibility information'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicaid_eligibility_status'
              label='Medicaid eligibility status'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='county'
              label='County'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='office'
              label='Office'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='anniversary_month'
              label='Anniversary month'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='recertification'
              label='Recertification'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='plan_date'
              label='Plan date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='recertification_date'
              label='Recertification date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_other_payer_name'
              label='Medicare other payer name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_id'
              label='Medicare ID'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicaid_exception_codes'
              label='Medicaid exception codes'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicaid_noncovered_codes'
              label='Medicaid noncovered codes'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicaid_managed_care_plan_code'
              label='Medicaid managed care plan code'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicaid_managed_care_plan_name'
              label='Medicaid managed care plan name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <FunctionField
              source='medicaid_managed_care_plan_address'
              label='Medicaid managed care plan address'
              className={classes.showDetailedItem}
              headerClassName={classes.listItemLabel}
              render={record => {
                const {
                  medicaid_managed_care_plan_address_1,
                  medicaid_managed_care_plan_address_2,
                } = record;
                return (
                  <div>
                    {medicaid_managed_care_plan_address_1}
                    <br /> {medicaid_managed_care_plan_address_2}
                  </div>
                );
              }}
            />
            <TextField
              source='medicaid_managed_care_plan_phone'
              label='Medicaid managed care plan phone'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='third_party_payer_plan_name'
              label='Third party payer plan Name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <FunctionField
              source='third_party_payer_plan_address'
              label='Third party payer plan address'
              className={classes.showDetailedItem}
              headerClassName={classes.listItemLabel}
              render={record => {
                const {
                  third_party_payer_plan_address_1,
                  third_party_payer_plan_address_2,
                } = record;
                return (
                  <div>
                    {third_party_payer_plan_address_1}
                    <br /> {third_party_payer_plan_address_2}
                  </div>
                );
              }}
            />
            <TextField
              source='third_party_payer_plan_phone'
              label='Third party payer plan phone'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_managed_care_plan_code'
              label='Medicare managed care plan code'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_managed_care_plan_name'
              label='Medicare managed care plan name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <FunctionField
              source='medicare_managed_care_plan_address'
              label='Medicare managed care plan address'
              className={classes.showDetailedItem}
              headerClassName={classes.listItemLabel}
              render={record => {
                const {
                  medicare_managed_care_plan_address_1,
                  medicare_managed_care_plan_address_2,
                } = record;
                return (
                  <div>
                    {medicare_managed_care_plan_address_1}
                    <br /> {medicare_managed_care_plan_address_2}
                  </div>
                );
              }}
            />
            <TextField
              source='medicare_managed_care_plan_phone'
              label='Medicare managed care plan phone'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='patient_responsibility_nami'
              label='Patient responsibility NAMI'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
          </SimpleShowLayout>
        )}
        {showProps.record?.part_a_start_date && (
          <SimpleShowLayout className={classes.showLayout} {...showProps}>
            <TextField
              source='part_a_start_date'
              label='Part A start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_a_end_date'
              label='Part A end date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_b_start_date'
              label='Part B start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_b_end_date'
              label='Part B end date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_c_medicare_hmo_start_date'
              label='Part C medicare HMO start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_c_medicare_hmo_end_date'
              label='Part C medicare HMO end date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_d_start_date'
              label='Part D start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_d_end_date'
              label='Part D end date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='hospice_start_date'
              label='Hospice start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='hospice_end_date'
              label='Hospice end date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='hospice_message'
              label='Hospice message'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_c_future_start_date'
              label='Part C future start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_c_future_payer_id'
              label='Part C future payer ID'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_c_future_payer_name'
              label='Part C future payer name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='primary_payor'
              label='Primary payor'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <FunctionField
              source='part_c_address'
              label='Part C address'
              className={classes.showDetailedItem}
              headerClassName={classes.listItemLabel}
              render={record => {
                const { part_c_address_1, part_c_address_2 } = record;
                return (
                  <div>
                    {part_c_address_1}
                    <br /> {part_c_address_2}
                  </div>
                );
              }}
            />
            <TextField
              source='part_c_telphone'
              label='Part C telephone'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_part_d_payer'
              label='Medicare Part D payer'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_d_payer_id'
              label='Part D payer ID'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <FunctionField
              source='part_d_address'
              label='Part D address'
              className={classes.showDetailedItem}
              headerClassName={classes.listItemLabel}
              render={record => {
                const { part_d_address_1, part_d_address_2 } = record;
                return (
                  <div>
                    {part_d_address_1}
                    <br /> {part_d_address_2}
                  </div>
                );
              }}
            />
            <TextField
              source='part_d_telphone'
              label='Part D telephone'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='number_of_snf_full_days_available'
              label='Number of SNF full days available'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='number_of_snf_partial_days_available'
              label='Number of SNF partial days available'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='date_of_earliest_billing_activity_doeba'
              label='Date of earliest billing activity (DOEBA)'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='date_of_last_billing_activity_dolba'
              label='Date of last billing activity (DOLBA)'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_b_deductible_amount'
              label='Part B deductible amount'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='part_b_remaining_deductible_amount'
              label='Part B remaining deductible amount'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='ot_amount_used'
              label='OT amount used'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='pt_amount_used'
              label='PT amount used'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='home_health_authorization_start_date'
              label='Home health authorization start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='home_health_authorization_termination_date'
              label='Home health authorization termination date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='home_health_billed_start_date'
              label='Home health billed start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='home_health_billed_end_date'
              label='Home health billed end date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_secondary_payer_type'
              label='Medicare secondary payer type'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_secondary_payer_name'
              label='Medicare secondary payer name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_secondary_payer_id'
              label='Medicare secondary payer ID'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_secondary_payer_start_date'
              label='Medicare secondary payer start date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicare_secondary_payer_end_date'
              label='Medicare secondary payer end date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
          </SimpleShowLayout>
        )}
        {showProps.record?.policy_group && (
          <SimpleShowLayout className={classes.showLayout} {...showProps}>
            <TextField
              source='policy_group'
              label='Policy group'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='insurance_effective_date'
              label='Insurance effective date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='insurance_termination_date'
              label='Insurance termination date'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='payer_name'
              label='Payer name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='payer_name_and_id'
              label='Payer name and ID'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='payer_plan_name'
              label='Payer plan name'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='network_id'
              label='Network ID'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='medicaid_id'
              label='Medicaid ID'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_in_network_covered'
              label='SNF in network covered'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_out_network_covered'
              label='SNF out of network covered'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_in_network_not_covered'
              label='SNF in network not covered'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_out_network_not covered'
              label='SNF out of network not covered'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_in_network_number_of_days'
              label='SNF in network number of days'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_out_network_number_of_days'
              label='SNF out of network number of days'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_in_network_days_remaining'
              label='SNF in network days remaining'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_out_network_days_remaining'
              label='SNF out of network days remaining'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_in_network_deductible'
              label='SNF in network deductible'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_out_network_deductible'
              label='SNF out of network deductible'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_in_network_coinsurance'
              label='SNF in network coinsurance'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_out_network_coinsurance'
              label='SNF out of network coinsurance'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_in_network_copay'
              label='SNF in network copay'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
            <TextField
              source='snf_out_network_copay'
              label='SNF out of network copay'
              className={classes.showDetailedItem}
              headerClassName={classes.showItemLable}
            />
          </SimpleShowLayout>
        )}
      </Fragment>
    </ShowView>
  );
};

export const eligibilityResource = {
  name: 'case-eligibility',
  list: EligibilityList,
  show: EligibilityShow,
};

function getResourceProps(props) {
  const { match: { params: { id: caseId, eligibilityId } = {} } = {} } = props;
  const basePath = `/cases/${caseId}/eligibility`;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-eligibility',
        hasList: true,
        hasEdit: !!eligibilityResource.edit,
        hasShow: !!eligibilityResource.show,
        hasCreate: !!eligibilityResource.create,
      };
  const updatedProps = {
    ...props,
    basePath,
    caseId,
    ...resource,
  };
  if (eligibilityId) {
    updatedProps.id = eligibilityId;
  }
  return updatedProps;
}
