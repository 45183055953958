import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Layout as AdminLayout, usePermissions } from 'react-admin';
import { AppBar } from './AppBar';
import { Sidebar } from './Sidebar';
import { Notification } from './Notification';
import { FileManagementDraw } from '../common/FileManagementDraw';
import { FrequentlyAccessed } from '../common/FrequentlyAccessed';
import get from 'lodash/get';
import { useGetUserRoles } from '../../hooks';

export const Layout = props => {
  const drawStatus = useSelector(
    state => state.fileManagement?.activeFile?.drawStatus,
  );
  const showFab = useSelector(state => get(state, 'ui.showFab', true));
  const { is_contract_user } = useGetUserRoles();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;

  return (
    <Fragment>
      <AdminLayout
        style={{ backgroundColor: '#FFFFFF' }}
        {...props}
        appBar={AppBar}
        notification={Notification}
        sidebar={Sidebar}
      />
      {showFab && !isViewAdmin && !is_contract_user && <FrequentlyAccessed />}
      {(drawStatus === 'open' || drawStatus === 'minimized') && (
        <FileManagementDraw />
      )}
      <div
        style={{
          textAlign: 'center',
          fontSize: 'small',
          padding: '5px',
          color: '#829CB5',
          backgroundColor: '#FFFFFF',
        }}
      >
        <form
          method='post'
          action='https://wordpress-561477-2073193.cloudwaysapps.com/wp-login.php'
          target='_blank'
        >
          <input
            type='hidden'
            name='log'
            value='bitbean@casemanagementsolutions.com'
          />
          <input type='hidden' name='pwd' value='89C*)U&68O)eX^^N3yECNu$A' />
          To view our knowledge base click
          <button
            type='submit'
            style={{
              background: 'none',
              border: 'none',
              color: '#1061a0',
              fontSize: 'small',
              cursor: 'pointer',
            }}
          >
            here
          </button>
        </form>
      </div>
    </Fragment>
  );
};
