import { Drawer } from '@material-ui/core';
import React, { cloneElement, Fragment, useState, useCallback } from 'react';
import {
  Button,
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  FunctionField,
  Pagination,
  Filter,
  DateInput,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
  usePermissions,
} from 'react-admin';
import clsx from 'clsx';
import jsonExport from 'jsonexport/dist';
import { format, parseISO, isValid, subWeeks } from 'date-fns';
import { Link } from 'react-router-dom';
import {
  DateField,
  Datagrid,
  FilterMenu,
  FilterChips,
} from '../../components/common';
import { useStyles } from '../reports/reports.styles';
import { DeleteModal, NewEvent } from '../../components/common/modals';
import { CensusIcon } from '../../design';
import { showResources } from '../timeline/showResources';
const exporter = rows => {
  const fieldsForExport = rows.map(row => {
    const { id, pcc_facility_id, pcc_patient_id, ...fields } = row;
    return { field: fields };
  });

  jsonExport(
    fieldsForExport,
    {
      headers: [
        'field.event_date',
        'field.event_name',
        'field.full_name',
        'field.primary_payer_type',
        'field.dob',
        'field.processed_by',
      ],
      rename: [
        'Effective Date',
        'Event Name',
        'Resident Name',
        'Payer Type',
        'DOB',
        'Processed by',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `census-${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};

const ListActions = ({
  currentSort,
  exporter,
  filterValues = {},
  permanentFilter = {},
  filterActions,
  activeFilters = {},
  allFilters = [],
  resource,
  total,
  loading,
  classes,
}) => {
  const unusedFilters = allFilters.filter(
    filter => !Object.values(activeFilters).includes(filter.name),
  );
  return (
    <TopToolbar className={clsx(classes.listActionWrapper)}>
      <div style={{ display: 'flex' }}>
        <FilterMenu filterActions={filterActions} filters={unusedFilters} />
        <FilterChips
          activeFilters={activeFilters}
          filterActions={filterActions}
          disabled={loading}
        />
      </div>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='From'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
      <DateInput
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
    </Filter>
  );
};
export const CensusEventList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const [activeEventNameFilters, setActiveEventNameFilters] = useState({});
  const [activeStatusFilters, setActiveStatusFilters] = useState({
    pending: 'Status: Pending',
    // processed: 'Status: Processed',
  });
  const [deleting, setDeleting] = useState({
    active: false,
    id: 0,
    name: '',
  });
  const [eventModal, setEventModal] = useState({
    active: false,
    modal: '',
    record_id: 0,
  });
  const [aside, setAside] = useState({ open: false, record: {} });
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = cacheDataProviderProxy(useDataProvider());

  const closeEventModal = useCallback(() => {
    setEventModal(false);
  }, []);

  /**
   * @param {number} id
   * @param {'processed'|'pending'} status
   */
  const updateRecordStatus = function(id, status) {
    dataProvider
      .update('census', {
        id,
        data: { status },
      })
      .then(({ data }) => {
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const ProcessButton = ({ record, isViewAdmin }) => {
    return hasProcessableEventForm(record) ? (
      <Button
        className={classes.referenceBtn}
        color='primary'
        onClick={() => {
          const event = censusEventNames.find(
            ev => ev.name === record.event_name,
          );
          setEventModal({
            active: true,
            modal: event?.modal,
            record_id: record.id,
          });
        }}
        disabled={
          (!isAdminOrSupervisor &&
            userPermissions.indexOf('case_manager') === -1) ||
          isViewAdmin
        }
        label='process'
      ></Button>
    ) : null;
  };

  const DismissButton = ({ record, isViewAdmin }) => {
    return (
      <Button
        className={classes.referenceBtn}
        color='primary'
        onClick={() => {
          setDeleting({
            active: true,
            id: record.id,
            name: `${record.event_name} for ${record.full_name}`,
          });
        }}
        disabled={
          (!isAdminOrSupervisor &&
            userPermissions.indexOf('case_manager') === -1) ||
          isViewAdmin
        }
        label='dismiss'
      ></Button>
    );
  };

  const UnDismissButton = ({ record, isViewAdmin }) => {
    return (
      <Button
        className={classes.referenceBtn}
        color='primary'
        onClick={() => updateRecordStatus(record.id, 'pending')}
        disabled={
          (!isAdminOrSupervisor &&
            userPermissions.indexOf('case_manager') === -1) ||
          isViewAdmin
        }
        label='unDismiss'
      ></Button>
    );
  };

  const ViewButton = ({ record }) => {
    return hasViewableEventForm(record) ? (
      <Button
        className={classes.referenceBtn}
        color='primary'
        onClick={() => {
          setAside({
            open: true,
            record: record,
          });
        }}
        label='view form'
      ></Button>
    ) : null;
  };

  const OpenCaseButton = ({ record }) => {
    return record && record.case_id ? (
      <Button
        className={classes.referenceBtn}
        color='primary'
        component={Link}
        to={`/cases/${record.case_id}/timeline`}
        label='open case'
      ></Button>
    ) : null;
  };

  // All the Action Buttons must be in the List component as they need to access the State setters such as setAside and setEventModal
  const censusEventStatuses = [
    // {id: 'cancelled', name:  'Status: Cancelled}',
    {
      id: 'pending',
      name: 'Status: Pending',
      actionButtons: [ProcessButton, DismissButton, OpenCaseButton],
    },
    {
      id: 'processed',
      name: 'Status: Processed',
      actionButtons: [ViewButton, OpenCaseButton],
    },
    // { id: 'error', name: 'Status: Error' },
    {
      id: 'dismissed',
      name: 'Status: Dismissed',
      actionButtons: [UnDismissButton],
    },
  ];

  const filterActions = ({ actionType, id, name }) => {
    /** @type {{list: Array<{id: string, names: string}>, setter: React.SetStateAction}} */
    const listFilterSetters = [
      { list: censusEventNames, setter: setActiveEventNameFilters },
      { list: censusEventStatuses, setter: setActiveStatusFilters },
    ];

    const listFilterSetter = listFilterSetters.find(
      elem => !!elem.list.find(en => en.id === id),
    );
    if (!listFilterSetter?.setter) return;

    if (actionType === 'removeFilter') {
      listFilterSetter.setter(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      listFilterSetter.setter(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  };

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        className={clsx(classes.list, {
          [classes.listWithDrawer]: aside.open,
        })}
        bulkActionButtons={false}
        actions={
          <ListActions
            classes={classes}
            filterActions={filterActions}
            activeFilters={{
              ...activeEventNameFilters,
              ...activeStatusFilters,
            }}
            allFilters={censusEventNames.concat(censusEventStatuses)}
          />
        }
        filter={{
          event_name: Object.keys(activeEventNameFilters),
          status: Object.keys(activeStatusFilters),
        }}
        exporter={exporter}
        filters={<FiltersView classes={classes} />}
        sort={{ field: 'event_date', order: 'ASC' }}
        filterDefaultValues={{
          start_date: format(subWeeks(new Date(), 1), 'yyyy-MM-dd'),
          end_date: format(new Date(), 'yyyy-MM-dd'),
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid
          rowStyle={(record, index) => {
            const style = {};
            if (record.status === 'processed') {
              style.opacity = 0.5;
            }
            if (record.status === 'dismissed') {
              style.fontStyle = 'italic';
            }
            if (aside.open && aside.record?.id === record?.id) {
              style.backgroundColor = '#EFF4FB';
            }
            return style;
          }}
        >
          <FunctionField
            source='event_date'
            label='Event date'
            sortable={true}
            render={record => {
              const date = parseISO(record.event_date);
              if (isValid(date)) return format(date, 'Pp');
            }}
          />
          <TextField source='status' label='Event status' sortable={true} />
          <TextField source='event_name' label='Event' sortable={false} />
          <TextField
            source='full_name'
            label='Resident name'
            sortBy='last_name'
            sortable={true}
          />
          <TextField
            source='patient_status'
            label='Current status'
            sortable={true}
          />
          <TextField
            source='primary_payer_type'
            label='Payer type'
            sortable={true}
          />
          <TextField
            source='primary_payer_name'
            label='Payer name'
            sortable={true}
          />
          <DateField source='dob' label='DOB' sortable={true} />
          <FunctionField
            sortable={false}
            source='event_name'
            label='Action'
            render={record => {
              return (
                <Fragment>
                  {censusEventStatuses
                    .find(st => st.id === record.status)
                    ?.actionButtons?.map((ActionButton, idx) => (
                      <ActionButton
                        key={`${record.id}_button_${idx}}`}
                        record={record}
                        isViewAdmin={isViewAdmin}
                      />
                    ))}
                </Fragment>
              );
            }}
          />
          <TextField
            source='processed_by'
            label='Processed by'
            sortable={false}
          />
        </Datagrid>
      </List>
      <Drawer
        variant='persistent'
        open={aside.open}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {aside.open && hasViewableEventForm(aside.record)
          ? cloneElement(showResources[aside.record.event_name].show, {
              ...props,
              id: aside.record.form_id,
              closeAside: () => setAside({ open: false, record: {} }),
              resource: showResources[aside.record.event_name].resource,
              classes: classes,
              residentId: aside.record.resident_id,
              caseId: aside.record.case_id,
              setRefreshTimeline: () => {
                /* census page is not dependant on any of the fields in these forms so no refresh is needed */
              },
              // refreshTimeline,
            })
          : null}
      </Drawer>
      {eventModal.active && (
        <NewEvent
          open={eventModal.active}
          handleClose={closeEventModal}
          form={eventModal.modal}
          //ensure that all forms used call onSuccess
          onSuccess={() =>
            updateRecordStatus(eventModal.record_id, 'processed')
          }
          refresh={refresh}
          meta={{ id: eventModal.record_id }}
          readmittable={true} //change to be based on event_name
        />
      )}
      <DeleteModal
        open={deleting.active}
        onClose={() => setDeleting({ active: false })}
        onDelete={refresh}
        url={'census/' + deleting.id}
        name={deleting.name}
        successMessage='Dismissed census record'
      />
    </Fragment>
  );
};

export const censusEventResource = {
  name: 'census-events',
  list: CensusEventList,
  icon: CensusIcon,
  options: { label: 'Census Events' },
};

function getResourceProps(props) {
  const basePath = '/census-events';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'census-events',
        hasList: true,
        hasEdit: false,
        hasShow: !!censusEventResource.show,
        hasCreate: !!censusEventResource.create,
      };
  return { ...props, basePath, ...resource };
}
const censusEventNames = [
  { id: 'Admission', name: 'Admission', modal: 'importAdmission' },
  { id: 'Cancel Admission', name: 'Cancel admission' },
  { id: 'Readmission', name: 'Readmission', modal: 'importReadmission' },
  { id: 'Discharge', name: 'Discharge', modal: 'importDischarge' },
  {
    id: 'Plan Discharge',
    name: 'Plan discharge',
    modal: 'importPlannedDischarge',
  },
  {
    id: 'Cancel Discharge',
    name: 'Cancel discharge',
    modal: 'importDeleteDischarge',
  },
  { id: 'Payer Change', name: 'Payer change' },
  { id: 'Update Account', name: 'Update account' },
  {
    id: 'Update Account On Return From Leave',
    name: 'Update account on return from leave',
  },
  { id: 'Outpatient To Inpatient', name: 'Outpatient to inpatient' },
  { id: 'Inpatient To Outpatient', name: 'Inpatient to outpatient' },
];

const hasViewableEventForm = record =>
  showResources?.[record?.event_name]?.show &&
  record?.form_id &&
  record?.case_id;

const hasProcessableEventForm = record =>
  !!censusEventNames.find(cen => cen.name === record.event_name)?.modal;
