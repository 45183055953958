import React, { useState } from 'react';
import {
  List,
  ExportButton,
  downloadCSV,
  TopToolbar,
  TextField,
  FunctionField,
  Pagination,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';
import { Datagrid, ToggleButton } from '../../components/common';
import { weekDaysObj } from '../../utils';
import { useGetUserDivision } from '../../hooks';
import { useStyles } from './reports.styles';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      id,
      update_weekday,
      update_type,
      auth_id,
      case_id,
      full_name,
      next_update_date,
      user_id,
      original_date_due,
      insurance_auth_number,
      ...rest
    } = field;
    rest.update_weekday = Array.isArray(update_weekday)
      ? update_weekday.map(u => weekDaysObj[u + '']).join(', ')
      : null;
    rest.update_type = Array.isArray(update_type)
      ? update_type.join(', ')
      : null;
    //Need to do this so that Excel does not drop the leading zero
    rest.insurance_auth_number = insurance_auth_number
      ? `\t${insurance_auth_number}`
      : '';
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'next_update',
        'status',
        'case_name',
        'update_weekday',
        'insurance_auth_number',
        'update_frequency',
        'update_type',
        'case_manager',
      ],
      rename: [
        'Next Update',
        'Status',
        'Case Name',
        'Days Of Week',
        'Auth Number',
        'Frequency',
        'Type',
        'Assigned To',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `updates-due-report-${format(new Date(), 'MM/dd/yyyy')}`,
      );
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  classes,
}) => {
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <div className={classes.listActionWrapper}>
        <div style={{ display: 'flex', paddingBottom: 20 }}>
          <span className={classes.title}>Updates due report</span>
          {/* Needed for spacing */}
          <span style={{ marginRight: 25 }} />
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
          />
        </div>
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          className={classes.exportButton}
        />
      </div>
    </TopToolbar>
  );
};

export const UpdatesDueReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(userDivision);

  return (
    <div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions setDivision={setDivision} classes={classes} />}
        exporter={exporter}
        filter={{ division }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField
            source='next_update'
            label='Next update'
            sortBy='next_update_date'
          />
          <TextField source='status' label='Status' />
          <TextField source='case_name' label='Case name' sortBy='last_name' />
          <FunctionField
            source='update_weekday'
            label='Days of week'
            sortable={false}
            render={record => {
              const { update_weekday } = record;
              return (
                Array.isArray(update_weekday) &&
                update_weekday.map(u => weekDaysObj[u + '']).join(', ')
              );
            }}
          />
          <TextField source='insurance_auth_number' label='Auth number' />
          <TextField source='update_frequency' label='Frequency' />
          <FunctionField
            sortable={false}
            source='update_type'
            label='Type'
            render={record =>
              record.update_type && record.update_type.join(', ')
            }
          />
          <TextField source='update_notes' label='Update notes' />
          <TextField source='case_manager' label='Assigned to' />
        </Datagrid>
      </List>
    </div>
  );
};

export const updatesDueReportResource = {
  name: 'updates-due-report',
  list: UpdatesDueReportList,
};

function getResourceProps(props) {
  const basePath = '/updates-due-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/updates-due-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!updatesDueReportResource.show,
        hasCreate: !!updatesDueReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
