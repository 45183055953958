import { Badge, Chip, Drawer, Tooltip, Divider } from '@material-ui/core';
import clsx from 'clsx';
import qs from 'query-string';
import React, {
  cloneElement,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ChipField,
  FunctionField,
  List,
  Pagination,
  TextField,
  TopToolbar,
  useRefresh,
  usePermissions,
} from 'react-admin';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Datagrid, DateField, StatusFilter } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { AddIcon, AlertIcon, EditIcon, InfoIcon } from '../design';
import { CustomButton } from '../design/material-ui';
import { useGetUserRoles } from '../hooks';
import { authGet } from '../server';
import { getChip } from '../utils';
import { useStyles } from './case_timeline.styles';
import { showResources } from './timeline/showResources';
import { format } from 'date-fns';
const TimelineListActions = ({
  caseId,
  setResident,
  showAll,
  setShowAll,
  showAncillary,
  setShowAncillary,
  refreshTimeline,
  setRefreshTimeline,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [editCaseOpen, setEditCaseOpen] = useState(false);
  const facilityId = useSelector(state => state.facility.id);
  const { is_admin, is_supervisor } = useGetUserRoles();
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [residentId, setResidentId] = useState('');
  const [caseStatus, setCaseStatus] = useState('');
  const [caseReadmittable, setCaseReadmittable] = useState(false);
  const [caseData, setCaseData] = useState({});
  const [flags, setFlags] = useState();
  const [openFlags, setOpenFlags] = useState(false);
  const fetchCase = useCallback(async () => {
    if (!facilityId || !caseId) return;
    const response = await authGet(`/case-data/${caseId}`);
    if (response.error) return;
    const flagResponse = await authGet(`/cases/${caseId}/timeline-flag-report`);
    const {
      id,
      first_name,
      last_name,
      case_name,
      case_status,
      resident_id,
      readmittable,
      length_of_stay,
      mltc_length_of_stay,
      subacute_length_of_stay,
      mltc_length_by_stay,
      subacute_length_by_stay,
    } = response.data;
    if (flagResponse.data.length) {
      setFlags(flagResponse.data);
    } else {
      setFlags();
    }
    setTitle(
      id
        ? `${last_name}, ${first_name} - ${format(
            new Date(case_name),
            'MM/dd/yyyy',
          )} `
        : 'Timeline',
    );
    // setSubtitle({
    //   los: `   Length of stay: ${length_of_stay} days`,
    //   subacute: `Subacute: ${subacute_length_of_stay}  days`,
    //   mltc: `MLTC:  ${mltc_length_of_stay}  days  `,
    // });
    setSubtitle({
      los: `   Length of stay:`,
      subacute: `Subacute: ${subacute_length_by_stay}`,
      mltc: `MLTC:  ${mltc_length_by_stay} `,
      old: `Length of stay: ${length_of_stay} days
            Subacute: ${subacute_length_of_stay}  days
            MLTC:  ${mltc_length_of_stay}  days  `,
    });
    setCaseStatus(case_status);
    setCaseReadmittable(readmittable);
    setResidentId(resident_id);
    setResident(resident_id);
    setCaseData(response.data);
  }, [caseId, facilityId, setResident]);

  const handleModalClose = () => {
    setEventModal(false);
    setRefreshTimeline(true);
  };

  useEffect(() => {
    fetchCase();
    setRefreshTimeline(false);
  }, [caseId, facilityId, refreshTimeline, setRefreshTimeline, fetchCase]);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <span className={classes.title}>
            {title && (
              <span>
                {title}
                <span style={{ marginLeft: 10 }} className={classes.subtitle}>
                  {subtitle.los}
                  <span
                    style={{ marginLeft: 10 }}
                    className={classes.subacute_los}
                  >
                    {subtitle.subacute}
                  </span>
                  <span style={{ marginLeft: 10 }} className={classes.mltc_los}>
                    {subtitle.mltc}
                  </span>
                  <span>
                    <Tooltip title={subtitle.old || ''} placement='right'>
                      <InfoIcon className={classes.stay} />
                    </Tooltip>
                  </span>
                </span>
              </span>
            )}
          </span>
          <div style={{ display: 'flex' }}>
            {caseStatus && (
              <span style={{ marginLeft: 10 }}>
                {
                  <Chip
                    size='small'
                    label={
                      <CustomButton
                        Icon={EditIcon}
                        label={caseStatus}
                        type='button'
                        variant='text'
                        size='small'
                        disabled={
                          (!is_admin &&
                            !is_supervisor &&
                            caseStatus !== 'Unknown') ||
                          isViewAdmin
                        }
                        className={clsx(
                          classes.chip,
                          classes[getChip(caseStatus)],
                        )}
                      />
                    }
                    className={clsx(classes.chip, classes[getChip(caseStatus)])}
                    onClick={() =>
                      is_admin ||
                      is_supervisor ||
                      (!isViewAdmin && caseStatus === 'Unknown')
                        ? setEditCaseOpen(true)
                        : null
                    }
                  ></Chip>
                }
              </span>
            )}
            <div style={{ flex: '1 0 5px' }} />
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Event'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
            />
          </div>
        </div>
        {flags && (
          <Fragment>
            <div
              className={clsx(classes.listActionWrapper, classes.flags)}
              style={{
                padding: 10,
                marginTop: 10,
                cursor: 'pointer',
              }}
              onClick={() => setOpenFlags(!openFlags)}
            >
              <span>{`${flags.length} Alert${
                flags.length > 1 ? 's' : ''
              }`}</span>
              <span>{openFlags ? 'Collapse' : 'View All'}</span>
            </div>
            {openFlags && (
              <div
                style={{
                  width: '100%',
                }}
              >
                <Divider className={classes.divider} />
                {flags.map((flag, index) => (
                  <div
                    key={index}
                    className={classes.flags}
                    style={{
                      padding: 10,
                    }}
                  >
                    {`${flag.flag}`}
                    {flag?.insurance_auth_number
                      ? ` - Auth Number:  ${flag.insurance_auth_number}`
                      : ``}
                    {flag?.denial_type ? ` - Denial:  ${flag.denial_type}` : ``}
                  </div>
                ))}
              </div>
            )}
          </Fragment>
        )}

        <div>
          <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 3 }}>
            {residentCaseCount > 1 && (
              <StatusFilter setShowAll={setShowAll} showAll={showAll} />
            )}
            <StatusFilter
              label='Include ancillary auths'
              setShowAll={setShowAncillary}
              showAll={showAncillary}
            />
          </div>
          <div
            style={{ paddingBottom: 10, paddingLeft: 3 }}
            className={classes.title}
          >
            Timeline
          </div>
        </div>
      </TopToolbar>

      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          refresh={refresh}
          residentId={residentId}
          readmittable={caseReadmittable}
        />
      )}
      {editCaseOpen && (
        <NewEvent
          open={editCaseOpen}
          handleClose={() => setEditCaseOpen(false)}
          record={caseData}
          form='editCase'
          isEdit
          refresh={fetchCase}
        />
      )}
    </Fragment>
  );
};

export const CaseTimelineList = ({ staticContext, ..._props }) => {
  const refresh = useRefresh();
  const { caseId, ...props } = getResourceProps(_props);
  const { location: { search } = {} } = props;
  const classes = useStyles();
  const [asideOpen, toggleAside] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [showComponent, setShowComponent] = useState();
  const [eventId, setEventId] = useState();
  const [showAll, setShowAll] = useState(true);
  const [showAncillary, setShowAncillary] = useState(false);
  const [refreshTimeline, setRefreshTimeline] = useState(false);
  const [residentId, setResidentId] = useState();
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const payerColors = [
    '#EFF4FB',
    'rgba(238, 248, 242)',
    'rgba(252, 246, 215)',
    'rgba(249,  233, 234)',
    'rgba(250, 237, 245)',
    'rgba(251,  240,  232)',
  ];

  useEffect(() => {
    toggleAside(false);
    refresh();
  }, [props.location.pathname, refresh]);

  useEffect(() => {
    const { event_id } = qs.parse(search);
    if (event_id) {
      setEventId(event_id);
    } else {
      setEventId(undefined);
    }
  }, [search]);

  const rowClick = (id, basePath, record) => {
    if (!showResources[record.event_type]) {
      console.error(`No resource for ${record.event_type}`);
      return null;
    }
    setShowComponent(showResources[record.event_type].show);
    setRowRecord({
      ...record,
      resource: showResources[record.event_type].resource,
    });
    toggleAside(true);
    return null;
  };

  const listRowStyle = (record, index, activeId) => {
    if (record?.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    } else if (parseInt(record?.case_id) !== parseInt(caseId)) {
      return {
        backgroundColor: '#EFF4FB',
        opacity: 0.5,
        fontStyle: 'italic',
      };
    } else if (record?.payer_name && record?.payers) {
      const payers = Array.from(record.payers);
      if (payers.length > 1) {
        const payerIndex = payers.findIndex(
          row =>
            row ===
            record.payer_name
              ?.replace('/Secondary', '')
              .replace('/Primary', ''),
        );
        return { backgroundColor: payerColors[payerIndex] };
      }
    }
  };

  const toggleShowAll = useCallback(value => {
    toggleAside(false);
    setShowAll(value);
  }, []);
  const toggleShowAncillary = useCallback(value => {
    toggleAside(false);
    setShowAncillary(value);
  }, []);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={false}
        className={clsx(classes.list, {
          [classes.listWithDrawer]: asideOpen,
        })}
        actions={
          <TimelineListActions
            caseId={caseId}
            showAncillary={showAncillary}
            showAll={showAll}
            setShowAncillary={toggleShowAncillary}
            setShowAll={toggleShowAll}
            setResident={setResidentId}
            setRefreshTimeline={setRefreshTimeline}
            refreshTimeline={refreshTimeline}
            residentCaseCount={residentCaseCount}
            isViewAdmin={isViewAdmin}
          />
        }
        sort={{ field: 'start_date', order: 'DESC' }}
        filter={{
          event_id: eventId,
          resident_id: showAll ? residentId : undefined,
          show_ancillary: showAncillary,
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid
          title='Timeline'
          rowClick={rowClick}
          rowStyle={(record, index) =>
            listRowStyle(record, index, rowRecord.id)
          }
        >
          <FunctionField
            source='event_type'
            label='Event'
            render={record => {
              const { event_type, ending_soon } = record;
              return (
                <div className={classes.infoContainer}>
                  <span style={{ marginRight: 3 }}>{event_type}</span>
                  {ending_soon ? (
                    <Tooltip title='Ending soon' placement='right'>
                      <InfoIcon className={classes.info} />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              );
            }}
          />
          <FunctionField
            label='Status'
            source='status'
            render={record => {
              return (
                <span>
                  {record.status && (
                    <ChipField
                      record={record}
                      source='status'
                      className={clsx(
                        classes.chip,
                        classes[getChip(record.status)],
                      )}
                    />
                  )}
                  {record.is_retro ? (
                    <Chip
                      size='small'
                      label='Retro Auth'
                      className={clsx(classes.chip, classes[getChip('Retro')])}
                    />
                  ) : null}
                </span>
              );
            }}
          />
          <FunctionField
            label='Case/rate type'
            source='case_type'
            render={record => {
              const { case_type, authorization_type, event_type } = record;
              if (event_type === 'Payer Change') {
                return case_type;
              } else {
                return [case_type, authorization_type]
                  .filter(x => !!x)
                  .join('/');
              }
            }}
          />
          <DateField source='start_date' label='Start/effective date' />
          <DateField source='end_date' label='End date' />
          <TextField source='payer_name' label='Payer' />
          <FunctionField
            label='Tasks'
            source='task_count'
            render={record => {
              const { task_count } = record;
              return task_count ? (
                <Badge
                  badgeContent={task_count}
                  classes={{ badge: classes.badge }}
                >
                  <AlertIcon className={classes.alertIcon} />
                </Badge>
              ) : null;
            }}
          />
        </Datagrid>
      </List>
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen
          ? cloneElement(showComponent, {
              ...props,
              id: rowRecord.record_id,
              closeAside: () => toggleAside(false),
              resource: rowRecord.resource,
              classes: classes,
              residentId: rowRecord.resident_id,
              caseId,
              setRefreshTimeline,
              isViewAdmin,
            })
          : null}
      </Drawer>
    </Fragment>
  );
};

export const caseTimelineResource = {
  name: 'case-timeline',
  list: CaseTimelineList,
  // show: CaseTimelineShow,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-timeline',
        hasList: true,
        hasEdit: !!caseTimelineResource.edit,
        hasShow: !!caseTimelineResource.show,
        hasCreate: !!caseTimelineResource.create,
      };
  return { ...props, basePath, caseId, ...resource };
}
