import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetNotification, useAuthProvider } from 'react-admin';
const defaultAuthParams = {
  loginUrl: '/login',
  afterLoginUrl: '/',
};
/**
 * Get a callback for calling the authProvider.login() method
 * and redirect to the previous authenticated page (or the home page) on success.
 *
 * @see useAuthProvider
 *
 * @returns {Function} login callback
 *
 * @example
 *
 * import { useLogin } from 'react-admin';
 *
 * const LoginButton = () => {
 *     const [loading, setLoading] = useState(false);
 *     const login = useLogin();
 *     const handleClick = {
 *         setLoading(true);
 *         login({ username: 'john', password: 'p@ssw0rd' }, '/posts')
 *             .then(() => setLoading(false));
 *     }
 *     return <button onClick={handleClick}>Login</button>;
 * }
 */
export const useLogin = function() {
  var authProvider = useAuthProvider();
  var location = useLocation();
  var locationState = location.state;
  var history = useHistory();
  var dispatch = useDispatch();
  var nextPathName = locationState && locationState.nextPathname;
  var nextSearch = locationState && locationState.nextSearch;
  var login = useCallback(
    function(params, pathName) {
      if (params === void 0) {
        params = {};
      }
      return authProvider.login(params).then(function(requiresMfa) {
        dispatch(resetNotification());
        var redirectUrl = pathName
          ? pathName
          : requiresMfa
          ? 'mfa-login'
          : nextPathName + nextSearch || defaultAuthParams.afterLoginUrl;
        history.push(redirectUrl);
        return;
      });
    },
    [authProvider, history, nextPathName, nextSearch, dispatch],
  );
  var loginWithoutProvider = useCallback(
    function(_, __) {
      dispatch(resetNotification());
      history.push(defaultAuthParams.afterLoginUrl);
      return Promise.resolve();
    },
    [history, dispatch],
  );
  return authProvider ? login : loginWithoutProvider;
};
