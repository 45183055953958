import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  focused: {
    width: 230,
    backgroundColor: '#FFFFFFF',

  },
  lost_focus: {
    overflow: 'hidden',
    width: 0,
    backgroundColor: '#FFFFFFF',
  },
  smooth_focusing: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  field: {
    flexGrow: 1,
    padding: '8px 8px 8px 5px',
    color: '#5C738E',
  },
}));
