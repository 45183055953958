import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  chipsContainer: {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -50
  },
  chips: {
    margin: 3,
    fontWeight: 500,
    backgroundColor: '#EFF4FB',
    color: '#1061A0'
  },
  filterDropdown:
  {  textTransform: 'capitalize',
      color: '#61728C',
      fontSize: 16,
      marginTop: 2,
      marginRight: 2,
      fontStyle: 'Nunito Sans'
  },
}));
