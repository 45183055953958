import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  // Filter,
  // SelectInput,
  FunctionField,
  useRefresh,
  useRedirect,
  Pagination,
} from 'react-admin';
import { DeleteRecord } from '../components/wrappers';
import { useSidebarOpen } from '../hooks';
import { NewEvent, NotesModal } from '../components/common/modals';
import { Datagrid } from '../components/common';
import { CustomButton } from '../design/material-ui';
import { useStyles } from './users.styles';
import { AddIcon, EditIcon } from '../design';
import { limitStringSize } from '../utils';
import { Typography } from '@material-ui/core';

const ListActions = ({ permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Facility Groupings</span>
        <div>
          {permissions.indexOf('view_admin') === -1 ? (
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
            />
          ) : null}
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='facilityGroups'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const View = ({ userPermissions, id, ...props }) => {
  const isSidebarOpen = useSidebarOpen();
  const refresh = useRefresh();
  const classes = useStyles({ isSidebarOpen });
  const [eventOpen, setEventModal] = useState(false);
  const [groupRecord, setGroupRecord] = useState();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    // see comment on case_communication
    if (groupRecord) {
      setEventModal(true);
    }
  }, [groupRecord]);
  const handleModalClose = () => {
    setEventModal(false);
    setGroupRecord(undefined);
  };

  useEffect(() => {
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);

  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    // needed if the user selects the same note again
    // since it only it will not show unless selectedNote changes.
    setSelectedNote('');
  };

  return (
    <div className={classes.listContainer}>
      <Datagrid {...props}>
        <TextField
          sortable={false}
          variant='outlined'
          source='name'
          label='Name'
          headerClassName={classes.listItemLabel}
        />

        <FunctionField
          sortable={false}
          source='facilities'
          label='Facilities'
          headerClassName={classes.listItemLabel}
          render={record => {
            const { facilities } = record;
            const facilityList = Array.isArray(facilities)
              ? facilities.map(f => f.name).join(', ')
              : null;
            const maxLength = 80;
            const truncated = limitStringSize(facilityList, maxLength, true);
            return facilityList && facilityList.length > maxLength ? (
              <span>
                {truncated}{' '}
                <Typography
                  color='primary'
                  component='span'
                  className={classes.showMore}
                  onClick={() => setSelectedNote(facilityList)}
                >
                  Show more
                </Typography>
              </span>
            ) : (
              truncated
            );
          }}
        />
        <FunctionField
          source=''
          label=''
          sortable={false}
          render={record => {
            return (
              Array.isArray(userPermissions) &&
              (userPermissions.indexOf('admin') > -1 ||
                userPermissions.indexOf('admin_assistant') > -1) && (
                <CustomButton
                  Icon={EditIcon}
                  color='#829CB5'
                  type='button'
                  variant='text'
                  size='small'
                  onClick={() => setGroupRecord(record)}
                  className={classes.noWrap}
                />
              )
            );
          }}
        />
        <FunctionField
          source=''
          label=''
          sortable={false}
          render={record => {
            const { facilities } = record;
            return (
              Array.isArray(userPermissions) &&
              (userPermissions.indexOf('admin') > -1 ||
                userPermissions.indexOf('admin_assistant') > -1) &&
              Array.isArray(facilities) &&
              facilities.length === 0 && (
                <DeleteRecord
                  resource='facility-groups'
                  id={record.id}
                  record={record}
                />
              )
            );
          }}
        />
      </Datagrid>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={groupRecord}
          form='facilityGroups'
          isEdit
          refresh={refresh}
        />
      )}
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
          title='Facilities'
        />
      )}
    </div>
  );
};

export const FacilityGroupsView = ({
  record,
  id,
  userPermissions,
  ...props
}) => {
  const controllerProps = useListController({
    ...props,
    resource: 'facility-groups',
    basePath: '/facility-groups',
    sort: { field: 'name', order: 'ASC' },
  });

  return (
    <ListView
      empty={false}
      {...controllerProps}
      bulkActionButtons={false}
      exporter={false}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
      actions={<ListActions permissions={userPermissions} />}
    >
      <View userPermissions={userPermissions} id={id} />
    </ListView>
  );
};

export const FacilityGroups = props => {
  const redirect = useRedirect();
  const { permissions = '' } = props;
  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (
    userPermissions.indexOf('admin') === -1 &&
    userPermissions.indexOf('supervisor') === -1 &&
    userPermissions.indexOf('view_admin') === -1 &&
    userPermissions.indexOf('admin_assistant') === -1
  ) {
    redirect('/');
    return null;
  }
  return <FacilityGroupsView {...props} userPermissions={userPermissions} />;
};
