import React, { useState, useEffect, Fragment } from 'react';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import {
  FormTextField,
  handleNumbers,
  getDateInputValue,
  validateDate,
  composeValidators,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const EditUpdateDate = ({
  caseId,
  record = {},
  isEdit,
  handleClose,
  isPlannedDischarge,
  closeAside,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (record) {
      const { original_date_due, auth_id: authorization_id } = record;
      setFormData(f => ({
        ...f,
        original_date_due,
        authorization_id,
      }));
    }
  }, [record]);

  const onSubmit = value => {
    return dataProvider
      .create('updates/date-due', { data: { ...value } })
      .then(({ data }) => {
        notify('form.created');
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormTextField
                  name='new_date_due'
                  validate={composeValidators([required(), validateDate()])}
                  required
                  customOnChange={customOnChange}
                  type='date'
                  label='Next Update'
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
