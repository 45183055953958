/* eslint-disable import/no-anonymous-default-export */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  adminReducer,
  adminSaga,
  // createAppReducer,
  USER_LOGOUT,
} from 'react-admin';
import { reducers } from './state/reducers';

let store;

export default ({ authProvider, dataProvider, history }) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    ...reducers,
  });

  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork),
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    compose(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        thunk,
        // add your own middlewares here
      ),
      typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f,
      // add your own enhancers here
    ),
  );
  sagaMiddleware.run(saga);
  return store;
};

export const reduxStore = () => store;
