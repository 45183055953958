import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Form } from 'react-final-form';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  formatDistanceStrict,
  isValid,
  isToday,
  isTomorrow,
  isBefore,
} from 'date-fns';
import clsx from 'clsx';
import {
  // required,
  useRefresh,
  ShowView,
  SimpleShowLayout,
  TextField,
  FunctionField,
  Labeled,
  TopToolbar,
  // ReferenceField,
  DateField as AdminDateField,
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  IconButton,
  Button,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SaveButton } from '../../design/material-ui';
import { TaskForm, Attachments } from '../../components/common/modals';
import { DateField } from '../../components/common';
import { TrackUser } from '../../components/wrappers';
import { authGet } from '../../server';
import {
  SelectComponent,
  FormTextField,
  handleNumbers,
  getDateInputValue,
  CheckboxInput,
  AddAttachmentModal,
  composeValidators,
  validateDate,
  minReasonLength,
} from '../../components/common/modals/modalForms';
import { constants } from '../../utils';
import { uiActions } from '../../state/actions';
import { useGetCaseId } from '../../hooks';
import { useStyles } from './tasks.styles';
import {
  CloseIcon,
  EditIcon,
  AttachmentIcon,
  AccessTimeIcon,
  CustomButton,
} from '../../design';
import { getChip } from '../../utils';
const { eventTypes } = constants;
const ADMISSION_NOTIFICATION_ID = 12;

export const TasksAside = ({
  showProps,
  showPropsRecord = {},
  closeAside,
  isViewAdmin,
}) => {
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const classes = useStyles();
  const caseIdFromUrl = useGetCaseId();
  const [formData, setFormData] = useState({});
  const [docsNeeded, setDocsNeeded] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const [record, setRecord] = useState({});
  const [selectedDate, handleDateChange] = useState(null);
  const [showDateError, setShowDateError] = useState(false);
  const [hasAttachments, setHasAttachments] = useState(false);
  const [documentCount, setDocumentCount] = useState(0);

  useEffect(() => {
    // need this because we were not getting the correct values after calling refresh
    // when adding or deleting an attachment because react-admin was updating
    // the record from the list which has no document_ids
    const { docs_needed, document_ids } = showPropsRecord;

    async function getTask(record) {
      if (record.id !== undefined) {
        const response = await authGet(`/tasks/${record.id}`);
        setRecord(response.data);
        setHasAttachments(
          Array.isArray(response.data.document_ids) &&
            !!response.data.document_ids.length,
        );
        setDocumentCount(response.data.document_ids?.length || 0);
      }
    }
    if (docs_needed) {
      setRecord(showPropsRecord);
      setHasAttachments(Array.isArray(document_ids) && !!document_ids.length);
      setDocumentCount(document_ids?.length || 0);
    } else {
      getTask(showPropsRecord);
    }
  }, [showPropsRecord]);

  useEffect(() => {
    if (record.date_due) {
      setFormData(f => ({
        date_due: record.date_due,
        status: record.status,
        insurance_update: !!record.insurance_update,
        notes: record.notes,
        date_sent: record.date_sent,
        completed_reason: record.completed_reason,
        ...getCheckdDocs(record.docs_checked),
      }));
      setDocsNeeded(record.docs_needed || []);
      handleDateChange(record.time_of_call);
    }
  }, [record]);

  const onSubmit = value => {
    const docs_checked = submitCheckedDocs(docsNeeded, value);
    return dataProvider
      .update('tasks', {
        id: record.id,
        data: { ...value, docs_checked, time_of_call: selectedDate },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        closeAside();
        if (caseIdFromUrl) {
          setTimeout(() => {
            dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
          }, 6000);
        }
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData(f => ({ ...f, [name]: value }));
  };

  const dateOnBlur = useCallback(() => {
    setShowDateError(true);
  }, []);

  return (
    <div className='row-wrapper'>
      <TopToolbar className={classes.header}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            // ...caseFields,
            ...formData,
          }}
          render={({
            submitError,
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent style={{ paddingTop: 0 }}>
                  <div className={classes.headerRow}>
                    <span className={classes.showTitle}>
                      {`Task ${record.id}`}
                    </span>
                    <IconButton
                      onClick={closeAside}
                      className={classes.closeButton}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>
                      <IconButton
                        className={classes.showBtn}
                        onClick={() => setEditOpen(true)}
                        disabled={!record.id || isViewAdmin}
                      >
                        <EditIcon />
                      </IconButton>
                      {'         '}
                      <CustomButton
                        Icon={AttachmentIcon}
                        className={classes.showBtn}
                        variant='text'
                        badgeContent={documentCount}
                        onClick={() => setAttchModal(true)}
                        disabled={!record.id}
                        style={{ height: '50px', width: '50px' }}
                        notRed
                        fullSize
                      />
                    </span>
                    <SelectComponent
                      customOnChange={customOnChange}
                      name='status'
                      classes={{
                        notchedOutline: classes.notchedOutline,
                      }}
                      className={classes[getChip(values.status)]}
                      style={{ maxWidth: 135 }}
                      choices={[
                        { id: 'to do', name: 'To do' },
                        { id: 'in progress', name: 'In progress' },
                        { id: 'completed', name: 'Completed' },
                        { id: 'dismissed', name: 'Dismissed' },
                      ]}
                      disabled={!record.id || isViewAdmin}
                    />
                  </div>
                  {(values.status === 'completed' ||
                    values.status === 'dismissed') && (
                    <FormTextField
                      name='completed_reason'
                      customOnChange={customOnChange}
                      label={`Reason for ${
                        values.status === 'completed'
                          ? 'completion'
                          : 'dismissal'
                      }`}
                      fullWidth
                      required={
                        record.status !== 'completed' &&
                        record.status !== 'dismissed'
                      }
                      validate={minReasonLength()}
                      disabled={isViewAdmin}
                    />
                  )}
                  <ShowView {...showProps}>
                    <SimpleShowLayout className={clsx(classes.showLayout)}>
                      <FunctionField
                        source='title'
                        label='Task name'
                        className={clsx(
                          classes.showItem,
                          classes.showFullWidth,
                        )}
                        render={record => {
                          const { title, task_type: taskType } = record;
                          const { task_type } = taskType || {};
                          return title || task_type;
                        }}
                      />
                      <FunctionField
                        source='description'
                        label='Task description'
                        className={clsx(
                          classes.showPaddedItem,
                          classes.showFullWidth,
                        )}
                        render={record => {
                          const { description, task_type: taskType } = record;
                          const { task_description } = taskType || {};
                          return description || task_description;
                        }}
                      />
                      <FunctionField
                        source='v_case.case_name'
                        label='Case'
                        className={clsx(
                          classes.showItem,
                          classes.showFullWidth,
                        )}
                        render={record => {
                          const {
                            v_case: { case_name, id: case_id } = {},
                          } = record;
                          if (!case_name || !case_id) {
                            return null;
                          }
                          return (
                            <Button
                              className={classes.referenceBtn}
                              color='primary'
                              onClick={e => e.stopPropagation()}
                              component={Link}
                              to={`/cases/${case_id}/timeline`}
                            >
                              {case_name}
                            </Button>
                          );
                        }}
                      />
                      <FunctionField
                        source='related_to_type'
                        label='Related to'
                        className={clsx(
                          classes.showItem,
                          classes.showFullWidth,
                        )}
                        render={record => {
                          const {
                            case_id,
                            related_to_id,
                            related_to_type,
                          } = record;
                          if (!case_id || !related_to_id) {
                            return null;
                          }
                          return (
                            <Button
                              className={classes.referenceBtn}
                              color='primary'
                              onClick={e => e.stopPropagation()}
                              component={Link}
                              to={
                                related_to_type === 'Failed Fax' ||
                                related_to_type?.includes('Email')
                                  ? `/cases/${case_id}/emails?direction=Outbound&${qs.stringify(
                                      {
                                        log_id: related_to_id,
                                      },
                                    )}`
                                  : `/cases/${case_id}/timeline?${qs.stringify({
                                      event_id: related_to_id,
                                    })}`
                              }
                            >
                              {related_to_type}
                            </Button>
                          );
                        }}
                      />
                      <TextField
                        source='taskAssignedTo.name'
                        label='Assigned to'
                        className={clsx(
                          classes.showItem,
                          classes.showFullWidth,
                        )}
                        textAlign='left'
                      />
                      {record.taskCompletedBy && (
                        <TextField
                          source='taskCompletedBy.name'
                          label={
                            record.status === 'dismissed'
                              ? 'Dismissed by'
                              : 'Completed by'
                          }
                          className={clsx(
                            classes.showItem,
                            classes.showFullWidth,
                          )}
                          textAlign='left'
                        />
                      )}

                      <DateField
                        label='Due date'
                        source='date_due'
                        className={clsx(
                          classes.showItem,
                          getTimeDistance(record.date_due) === 'Past due'
                            ? classes.overdue
                            : undefined,
                        )}
                        record={record}
                      />

                      {record.status !== 'completed' && (
                        <span className={classes.timeUntil}>
                          {getTimeDistance(record.date_due)}
                        </span>
                      )}
                      {record.date_due_reason && (
                        <TextField
                          source='date_due_reason'
                          label='Reason for changing due date'
                        />
                      )}
                      {(record.status === 'completed' ||
                        record.status === 'dismissed') && (
                        <Labeled
                          label={
                            record.status === 'dismissed'
                              ? 'Date dismissed'
                              : 'Date completed'
                          }
                        >
                          <AdminDateField
                            source='completed_at'
                            className={clsx(classes.showItem)}
                            textAlign='left'
                            record={record}
                            showTime
                            options={{
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            }}
                          />
                        </Labeled>
                      )}
                    </SimpleShowLayout>
                  </ShowView>

                  <div className={classes.sectionTitle}>Documents needed</div>
                  <Grid
                    container
                    justify='flex-start'
                    spacing={0}
                    alignItems='center'
                    direction='row'
                    style={{ marginBottom: 15 }}
                  >
                    {Array.isArray(record.docs_needed) &&
                      record.docs_needed.map((doc, i) => {
                        return (
                          <Grid key={i} item xs={6}>
                            <CheckboxInput
                              name={doc}
                              customOnChange={customOnChange}
                              label={doc}
                              checked={formData[doc]}
                              labelClasses={{ label: classes.checkBoxes }}
                              size='small'
                              disabled={
                                record.status === 'completed' &&
                                values.status === 'completed'
                              }
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                  {record.task_type_id === ADMISSION_NOTIFICATION_ID && (
                    <Typography style={{ color: '#F57C00' }}>
                      Confirm rate type, auth status, start date and days
                      approved. Enter reference # and rep name in note.
                    </Typography>
                  )}
                  <FormTextField
                    name='notes'
                    label='Communication notes'
                    multiline
                    fullWidth
                    customOnChange={customOnChange}
                    disabled={isViewAdmin}
                    validate={minReasonLength()}
                  />
                  <div
                    style={{ justifyContent: 'space-between', display: 'flex' }}
                  >
                    <div
                      className={classes.sectionTitle}
                      style={{ marginTop: 15 }}
                    >
                      Insurance update
                      <div style={{ marginTop: 15 }}>
                        <FormTextField
                          fullWidth
                          name='date_sent'
                          customOnChange={customOnChange}
                          type='date'
                          label='Sent to insurance'
                          validate={composeValidators([validateDate()])}
                          margin='dense'
                          disabled={isViewAdmin}
                        />
                        <span style={{ marginLeft: 4 }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                              fullWidth
                              value={selectedDate}
                              onChange={handleDateChange}
                              onBlur={dateOnBlur}
                              invalidDateMessage={
                                !showDateError ? '' : undefined
                              } // hide error message while typing
                              label='Time of call'
                              format='MM/dd/yyyy hh:mm a'
                              inputVariant='outlined'
                              keyboardIcon={
                                <AccessTimeIcon
                                  style={{
                                    color: '#5C738E',
                                  }}
                                />
                              }
                              margin='dense'
                              disabled={isViewAdmin}
                            />
                          </MuiPickersUtilsProvider>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classes.trackUserContainer}>
                    <Labeled label='Created by'>
                      <FunctionField
                        record={record}
                        source='taskCreatedBy.name'
                        style={{ fontSize: 13 }}
                        render={record => {
                          const { taskCreatedBy, created_at } = record;
                          return (
                            <TrackUser
                              userField={taskCreatedBy}
                              timestamp={created_at}
                            />
                          );
                        }}
                      />
                    </Labeled>
                    <Labeled label='Last modified'>
                      <FunctionField
                        record={record}
                        source='taskUpdatedBy.name'
                        style={{ fontSize: 13 }}
                        render={record => {
                          const { taskUpdatedBy, updated_at } = record;
                          return (
                            <TrackUser
                              userField={taskUpdatedBy}
                              timestamp={updated_at}
                            />
                          );
                        }}
                      />
                    </Labeled>
                  </div>
                </DialogContent>
                <DialogActions
                  className={classes.padding16}
                  style={{
                    paddingBottom: 16,
                    paddingRight: 25,
                    marginBottom: 150,
                  }}
                >
                  <div style={{ alignItems: 'right', display: 'flex' }}>
                    <SaveButton
                      className={classes.saveButton}
                      disabled={submitting || !record.id || isViewAdmin}
                      type='submit'
                    />
                  </div>
                </DialogActions>
              </form>
            );
          }}
        />
        {editOpen && (
          <TaskForm
            open={editOpen}
            handleClose={() => setEditOpen(false)}
            record={record}
            isEdit
            refresh={refresh}
          />
        )}
        {attchOpen && (
          <Fragment>
            {hasAttachments ? (
              <Attachments
                open={attchOpen}
                handleClose={() => setAttchModal(false)}
                document_ids={record.document_ids}
                title='Task'
                eventId={record.id}
                eventName={eventTypes.TASK_NAME}
                caseId={record.case_id}
                refresh={refresh}
                isViewAdmin={isViewAdmin}
              />
            ) : (
              <AddAttachmentModal
                open={attchOpen}
                handleClose={() => setAttchModal(false)}
                title='Task'
                eventId={record.id}
                eventName={eventTypes.TASK_NAME}
                caseId={record.case_id}
                refresh={refresh}
                isViewAdmin={isViewAdmin}
              />
            )}
          </Fragment>
        )}
      </TopToolbar>
    </div>
  );
};

function getTimeDistance(dateString) {
  const date = new Date(`${dateString} 00:00`);
  if (!isValid(date)) {
    return '';
  }
  if (isToday(date)) {
    return 'Today';
  }

  if (isBefore(date, new Date())) {
    return 'Past due';
  }

  if (isTomorrow(date)) {
    return 'Tomorrow';
  }

  return formatDistanceStrict(date, new Date(), {
    addSuffix: true,
    roundingMethod: 'ceil',
  });
}

function getCheckdDocs(docs = []) {
  return docs.reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {});
}

function submitCheckedDocs(docsNeeded = [], formValues = {}) {
  return docsNeeded.filter(doc => formValues[doc]);
}
