import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Loading } from '../Loading';
import clsx from 'clsx';

import { useStyles } from '../../common/modals/modal.styles';

export const CheckEligibilityDialog = ({ open, handleClose, html }) => {
  const classes = useStyles();
  const [seeking, setSeeking] = useState(true);

  useEffect(() => {
    html !== '' && setSeeking(false);
  }, [html]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <h2 className={classes.eventTitle}>Eligibility information</h2>
      </DialogTitle>
      <DialogContent>
        <Fragment>
          {seeking && <Loading style={{ height: 'initial' }} />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 20,
            }}
          >
            <div className={clsx(classes.inputContainerWrap)}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </Fragment>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
