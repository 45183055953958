import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const messages = {
  ...englishMessages,
  form: {
    created: 'Form successfully submitted',
    updated: 'Form successfully updated',
    change_password: 'Successfully changed password',
  },
};

export default polyglotI18nProvider(
  locale => {
    return messages;
  },
  'en',
  { allowMissing: true },
);
