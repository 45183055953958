import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  FunctionField,
  TextField,
  useRefresh,
  TopToolbar,
  useUnselectAll,
} from 'react-admin';
import { Datagrid } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { useStyles } from '../resources/payers/payers.styles';
import { EditIcon, AddIcon, CustomButton } from '../design';
import { IconButton } from '@material-ui/core';

const ListActions = ({ record, payerId, permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='NOMNC Appeal Address'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='nomncAppealAddresses'
          refresh={refresh}
          payerId={payerId}
        />
      )}
    </Fragment>
  );
};

export const NomncAppealAddresses = ({ payerId, ...props }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const { permissions } = props;
  const isAdminOrSupervisor =
    permissions.indexOf('admin') > -1 || permissions.indexOf('supervisor') > -1;
  const isContractUser = permissions.indexOf('contract_user') > -1;
  const [nomncAddressRecord, setNomncAddressRecord] = useState();
  const [eventOpen, setEventModal] = useState(false);
  const controllerProps = useListController({
    ...props,
    resource: 'nomnc-appeal-address',
    filter: { payer_id: payerId },
  });

  const unselectAll = useUnselectAll('nomnc-appeal-address');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // see comment on case_communication
    if (nomncAddressRecord) {
      setEventModal(true);
    }
  }, [nomncAddressRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setNomncAddressRecord(undefined);
  };
  return (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        bulkActionButtons={
          !isAdminOrSupervisor && !isContractUser
            ? false // disable delete
            : undefined
        }
        actions={
          isAdminOrSupervisor || isContractUser ? (
            <ListActions payerId={payerId} />
          ) : null
        }
        exporter={false}
      >
        <Datagrid>
          <TextField
            source='state'
            label='State'
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='nomnc_appeal_address'
            label='NOMNC appeal address'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source=''
            label=''
            sortable={false}
            render={record => {
              return isContractUser || isAdminOrSupervisor ? (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setNomncAddressRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={nomncAddressRecord}
          form='nomncAppealAddresses'
          isEdit
          refresh={refresh}
          payerId={payerId}
        />
      )}
    </div>
  );
};
