import React, { useEffect, useState } from 'react';
import { isValid, format } from 'date-fns';
import { Tooltip } from '@material-ui/core';
export const TrackUser = ({ userField, timestamp }) => {
  const [initials, setInitials] = useState('');
  const [formatedTime, setFormatedTime] = useState('');

  useEffect(() => {
    if (userField?.first_name || userField?.last_name) {
      const { first_name = '', last_name = '' } = userField;
      setInitials(
        `${first_name?.charAt(0)?.toUpperCase() ?? ''}${last_name
          ?.charAt(0)
          ?.toUpperCase() ?? ''}`,
      );
    } else {
      setInitials('');
    }
    const time = timestamp ? new Date(timestamp) : undefined;
    setFormatedTime(isValid(time) ? format(time, 'M/dd/yy p') : '');
  }, [timestamp, userField]);
  return (
    <div>
      <Tooltip title={userField?.name || ''} placement='top'>
        <span>{initials}</span>
      </Tooltip>
      {` ${formatedTime}`}
    </div>
  );
};
