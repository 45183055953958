import { facilityTypes } from '../actions/facility';
const initialState = {};
export function facilityReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case facilityTypes.GET_FACILITY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
