import React, { Fragment, useState, useEffect, useCallback } from 'react';
import jsonExport from 'jsonexport/dist';
import { format, isValid } from 'date-fns';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import clsx from 'clsx';
import {
  useRefresh,
  List,
  TextField,
  FunctionField,
  Pagination,
  // BooleanField,
  // NumberField,
  // ChipField,
  TopToolbar,
  ExportButton,
  downloadCSV,
  usePermissions,
  useUnselectAll,
  // Filter,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Typography, IconButton } from '@material-ui/core';
import { TrackUser, Datagrid, StatusFilter } from '../components/common';
import {
  NewCommunication,
  NotesModal,
  Attachments,
} from '../components/common/modals';
import { AddAttachmentModal } from '../components/common/modals/modalForms';
import { limitStringSize, constants } from '../utils';
import {
  InsertCommentIcon,
  AddIcon,
  EditIcon,
  CustomButton,
  LinkIcon,
  AttachmentIcon,
} from '../design';
import { useStyles } from './case_communications.styles';
const { eventTypes } = constants;

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      id,
      case: _case,
      case_id,
      communicationCreatedBy,
      communicationUpdatedBy,
      communication_outcome,
      communication_outcome_id,
      communication_type,
      communication_type_id,
      created_at,
      created_by,
      date: _date,
      deleted_at,
      deleted_by,
      notes_reference,
      notes_reference_id,
      updated_at,
      updated_by,
      user,
      user_id,
      link,
      ...rest
    } = field;
    const date = new Date(_date);
    if (isValid(date)) {
      rest.date = format(date, 'MM/dd/yyyy');
    }
    rest.user =
      !user && notes_reference
        ? `(From ${notes_reference.reference_type})`
        : `${user?.first_name} ${user?.last_name}`;
    rest.comm_type = communication_type?.communication_type;
    rest.comm_outcome = communication_outcome?.outcome;
    rest.notes_reference = notes_reference?.reference_type;
    rest.created_by = communicationCreatedBy?.name;
    rest.last_modified = communicationUpdatedBy?.name;
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'user',
        'date',
        'comm_type',
        'comm_outcome',
        'notes',
        'notes_reference',
        'created_by',
        'last_modified',
      ],
      rename: [
        'Case Manager',
        'Date',
        'Comm Type',
        'Comm Outcome',
        'Notes',
        'Notes Reference',
        'Created By',
        'Last Modified',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `Communications_${
          Array.isArray(fields) ? fields[0].facility_name : ''
        }_${format(new Date(), 'MM/dd/yyyy')}`,
      );
    },
  );
};

const ListActions = ({
  resource,
  total,
  currentSort,
  filterValues = {},
  permanentFilter = {},
  showAll,
  setShowAll,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [communicationOpen, setCommunicationModal] = useState(false);

  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <span className={classes.title}>Communications</span>
          <div>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setCommunicationModal(true)}
            />
            <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={{ ...filterValues, ...permanentFilter }}
              exporter={exporter}
              className={classes.exportButton}
            />
          </div>
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 3 }}>
          {residentCaseCount > 1 && (
            <StatusFilter setShowAll={setShowAll} showAll={showAll} />
          )}
        </div>
      </TopToolbar>
      {communicationOpen && (
        <NewCommunication
          open={communicationOpen}
          handleClose={() => setCommunicationModal(false)}
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const CommunicationsList = ({ staticContext, ..._props }) => {
  const { caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [communicationOpen, setCommunicationModal] = useState(false);
  const [resourceRecord, setRecord] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [attchOpen, setAttchModal] = useState(false);
  const [attachIds, setAttachIds] = useState(null);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const unselectAll = useUnselectAll('case-communications');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // selectedNote can only change when user clicks on 'show more'
    // this will make sure that 'show modal' is called with the correct
    // note after the 'selectedNote' state changed since useState does
    // not accept a callback like setState does.
    // The above also applies to 'resourceRecord',
    if (selectedNote) {
      setShowNotesModal(true);
    }
  }, [selectedNote]);

  useEffect(() => {
    if (resourceRecord) {
      setCommunicationModal(true);
    }
  }, [resourceRecord]);

  useEffect(() => {
    if (attachIds) {
      setAttchModal(true);
    }
  }, [attachIds]);

  const handleNotesModalClose = () => {
    setShowNotesModal(false);
    // needed if the user selects the same note again
    // since it only it will not show unless selectedNote changes.
    setSelectedNote('');
  };
  const handleCommClose = () => {
    setCommunicationModal(false);
    setRecord(null);
  };

  const handleAttachClose = () => {
    setAttchModal(false);
    setAttachIds(null);
  };

  const toggleShowAll = useCallback(value => {
    setShowAll(value);
  }, []);

  const listRowStyle = (record, index, activeId) => {
    if (caseId && parseInt(record?.case_id) !== parseInt(caseId)) {
      return { backgroundColor: '#EFF4FB', opacity: 0.5, fontStyle: 'italic' };
    }
  };

  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const maxNotesLength = 80;
  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;
  const isViewAdmin =
    permissions?.indexOf('view_admin') > -1 ||
    permissions?.indexOf('admin_assistant') > -1;
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        sort={{ field: 'date', order: 'DESC' }}
        exporter={exporter}
        bulkActionButtons={
          Array.isArray(userPermissions) && isAdminOrSupervisor
            ? undefined // will show default delete
            : false // disable delete
        }
        actions={
          <ListActions
            residentCaseCount={residentCaseCount}
            showAll={showAll}
            setShowAll={toggleShowAll}
            isViewAdmin={isViewAdmin}
          />
        }
        filter={{ all: showAll }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid
          isRowSelectable={record => record.user}
          rowStyle={(record, index) => listRowStyle(record, index)}
        >
          <FunctionField
            source='user'
            label='Case manager'
            sortable={false}
            render={record => {
              if (!record.user)
                return record.notes_reference
                  ? `(From ${record.notes_reference.reference_type})`
                  : null;
              const { user: { first_name, last_name } = {} } = record;
              return `${first_name} ${last_name}`;
            }}
          />
          <TextField source='date' label='Date' />
          <TextField
            source='communication_type.communication_type'
            label='Communication type'
            sortable={false}
          />
          <TextField
            source='communication_outcome.outcome'
            label='Communication outcome'
            sortable={false}
          />
          <FunctionField
            source='notes'
            label='Notes'
            cellClassName={classes.notesCell}
            sortable={false}
            render={record => {
              const { notes } = record;
              const truncated = limitStringSize(notes, maxNotesLength, true);
              return notes && notes.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setSelectedNote(record.notes)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <TextField
            source='notes_reference.reference_type'
            label='Notes reference'
            sortable={false}
          />
          <FunctionField
            source='communicationCreatedBy.name'
            label='Created by'
            sortable={false}
            render={record => {
              const { communicationCreatedBy, created_at } = record;
              if (record.user)
                return (
                  <TrackUser
                    userField={communicationCreatedBy}
                    timestamp={created_at}
                  />
                );
            }}
          />
          <FunctionField
            source='communicationUpdatedBy.name'
            label='Last modified'
            sortable={false}
            render={record => {
              const { communicationUpdatedBy, updated_at } = record;
              if (record.user)
                return (
                  <TrackUser
                    userField={communicationUpdatedBy}
                    timestamp={updated_at}
                  />
                );
            }}
          />
          <FunctionField
            source='notes'
            label=''
            render={record =>
              record.link ? (
                <IconButton
                  onClick={e => e.stopPropagation()}
                  component={Link}
                  to={record.link}
                  size='small'
                >
                  <LinkIcon />
                </IconButton>
              ) : (
                <IconButton size='small' onClick={() => setRecord(record)}>
                  <EditIcon style={{ color: '#5C738E' }} />
                </IconButton>
              )
            }
          />
          <FunctionField
            source='Attachments'
            label=''
            render={record => {
              const { document_ids = [], link } = record;
              return (
                !link && (
                  <CustomButton
                    Icon={AttachmentIcon}
                    color='#5C738E'
                    variant='text'
                    badgeContent={document_ids.length}
                    onClick={() => setAttachIds({ document_ids, record })}
                    notRed
                    fullSize
                  />
                )
              );
            }}
          />
        </Datagrid>
      </List>
      {showNotesModal && (
        <NotesModal
          open={showNotesModal}
          handleClose={handleNotesModalClose}
          note={selectedNote}
        />
      )}
      {communicationOpen && (
        <NewCommunication
          open={communicationOpen}
          handleClose={handleCommClose}
          isEdit
          record={resourceRecord}
          refresh={refresh}
        />
      )}
      {attchOpen && (
        <Fragment>
          {attachIds.document_ids.length ? (
            <Attachments
              open={attchOpen}
              handleClose={handleAttachClose}
              document_ids={attachIds.document_ids}
              title='Communications'
              eventId={attachIds.record.id}
              eventName={eventTypes.COMMUNICATION_NAME}
              caseId={attachIds.record.case_id}
              refresh={refresh}
              isViewAdmin={false}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={handleAttachClose}
              title='Communications'
              eventId={attachIds.record.id}
              eventName={eventTypes.COMMUNICATION_NAME}
              caseId={attachIds.record.case_id}
              refresh={refresh}
              isViewAdmin={false}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export const communicationsResource = {
  name: 'case-communications',
  list: CommunicationsList,
  icon: InsertCommentIcon,
};

function getResourceProps(props) {
  const {
    match: { params: { id: caseId, communicationId } = {} } = {},
  } = props;
  const basePath = `/cases/${caseId}/communications`;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-communications',
        hasList: true,
        hasEdit: false,
        hasShow: !!communicationsResource.show,
        hasCreate: !!communicationsResource.create,
      };
  const updatedProps = { ...props, basePath, caseId, ...resource };
  if (communicationId) {
    updatedProps.id = communicationId;
  }
  return updatedProps;
}
