import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useStyles } from './npiToggle.styles';

export const NpiToggle = ({ setIsFacilityNpi, isFacilityNpi }) => {
  useEffect(() => {
    const position = isFacilityNpi ? 'facility' : 'other';
    setPosition(position);
    typeof setIsFacilityNpi === 'function' &&
      setIsFacilityNpi(position === 'facility');
  }, [setIsFacilityNpi, isFacilityNpi]);
  const classes = useStyles({
    cursorPointer: typeof setIsFacilityNpi === 'function',
  });
  const [activePosition, setPosition] = useState('facility');
  const handleClick = position => {
    if (typeof setIsFacilityNpi !== 'function') {
      return null;
    }
    setPosition(position);
    setIsFacilityNpi(position === 'facility');
  };
  return (
    <div className={classes.toggleContainer}>
      <div
        style={{
          cursor:
            typeof setIsFacilityNpi === 'function' ? 'pointer' : 'default',
        }}
        className={clsx(classes.button, classes.leftButton, {
          [classes.active]: activePosition === 'facility',
        })}
        onClick={() => handleClick('facility')}
      >
        Facility NPI
      </div>
      <div
        style={{
          cursor:
            typeof setIsFacilityNpi === 'function' ? 'pointer' : 'default',
        }}
        className={clsx(classes.button, classes.rightButton, {
          [classes.active]: activePosition === 'other',
        })}
        onClick={() => handleClick('other')}
      >
        Other NPI
      </div>
    </div>
  );
};
