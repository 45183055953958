import React, { useState, useEffect, Fragment } from 'react';
import qs from 'query-string';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import {
  required,
  useDataProvider,
  useNotify,
  useRefresh,
  usePermissions,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
  FormGroup,
} from '@material-ui/core';
import { authGet } from '../../../../server';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  CheckboxInput,
  composeValidators,
  handleNumbers,
  getDateInputValue,
  minLength,
  validateSSNOnSubmit,
  validateDate,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import {
  SaveButton,
  AddIcon,
  SyncDisabledIcon,
  SyncIcon,
} from '../../../../design';
import { convertToObj } from '../../../../utils';
import { useGetUserRoles } from '../../../../hooks';
import { uiActions } from '../../../../state/actions';
import { Loading } from '../..';

export const EditResidentForm = ({ record = {}, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [formData, setFormData] = useState({});
  const [duplicateResidentId, setDuplicateResidentId] = useState();
  const [externalResident, setExternalResident] = useState({
    loading: false,
    name: '',
  });
  const { is_admin, is_case_manager, is_supervisor } = useGetUserRoles();

  async function checkDuplicateResident(values) {
    const { first_name, last_name, dob, ssn } = values;
    if ((first_name && last_name && dob) || ssn) {
      const response = await authGet(['/residents-validate', values]);
      const { data } = response;
      if (data) {
        const { resident_id } = data;
        setDuplicateResidentId(resident_id ?? null);
      }
    }
  }

  const [integrationObj, setIntegrationObj] = useState({});
  const [showIntegrationFields, setShowIntegrationFields] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const element = document.getElementById('duplicate-msg');
    if (!element) return;
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }, [duplicateResidentId]);

  useEffect(() => {
    if (record) {
      const {
        id,
        case_id,
        discharge_date,
        facility_id,
        facility_name,
        full_name,
        payer_name,
        payer_type,
        rep_name,
        admit_date,
        created_by,
        updated_by,
        created_at,
        updated_at,
        vResidentCreatedBy,
        vResidentUpdatedBy,
        ...rest
      } = record;
      setFormData(f => ({
        ...f,
        ...rest,
      }));
    } else {
      setFormData(f => ({
        ...f,
        integration_isactive: true,
      }));
    }
  }, [record]);

  useEffect(() => {
    (async function() {
      const response = await authGet('external-integrations/list');
      const { data } = response;
      if (data) {
        setIntegrationObj(convertToObj(data));
      }
    })();
  }, []);

  useEffect(() => {
    if (
      record.id &&
      record.integration_type &&
      record.integration_id /*  &&
      record.integration_isactive
      The Integration Id field is expected to show the resident name for disabled integrations too
      */
    ) {
      (async function() {
        setExternalResident(data => ({ ...data, loading: true }));
        const response = await authGet(`/residents/${record.id}/external_data`);
        const { data, error } = response;
        if (error) {
          notify('Unable to get residents external data', 'warning');
          return;
        }
        setExternalResident({ loading: false, name: data?.name });
      })();
    }
  }, [
    notify,
    record.id,
    record.integration_id,
    record.integration_isactive,
    record.integration_type,
  ]);

  const onSubmit = value => {
    const { ssn, resident_override } = value;
    return dataProvider
      .update('residents', {
        id: record.id,
        data: {
          ...value,
          ssn: validateSSNOnSubmit(ssn),
          resident_override: !!duplicateResidentId
            ? resident_override
            : undefined,
        },
        previousData: { ...record },
      })
      .then(({ data }) => {
        notify('form.updated');
        dispatch(uiActions.getSidebarCases());
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  function toggleIntegrationFields() {
    if (showIntegrationFields) {
      setFormData(f => ({
        ...f,
        integration_id: record.integration_id ?? null,
        integration_type: record.integration_type ?? null,
        integration_isactive: record.integration_isactive ?? true,
      }));
    }
    setShowIntegrationFields(cur => !cur);
  }

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'integration_type') {
      setExternalResident(data => ({ ...data, name: '' }));
      setFormData(f => ({ ...f, [name]: value, integration_id: '' }));
      return;
    }

    if (name === 'integration_id') {
      if (!record.integration_id && !!value) {
        setFormData(f => ({ ...f, [name]: value, integration_isactive: true }));
        return;
      }
    }

    setFormData(f => ({ ...f, [name]: value }));
  };

  const customOnBlur = async event => {
    const { name, value } = event.target;
    if (
      (name === 'first_name' || name === 'last_name' || name === 'dob') &&
      value !== record[name]
    ) {
      const values = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        dob: formData.dob,
        [name]: value,
      };
      await checkDuplicateResident(values);
    }
    if (name === 'ssn') {
      const ssn = validateSSNOnSubmit(value);
      if (ssn !== record.ssn && ssn.length === 9) {
        await checkDuplicateResident({ ssn });
      }
    }
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='first_name'
                    validate={required()}
                    required
                    customOnChange={customOnChange}
                    customOnBlur={customOnBlur}
                    label='First name'
                    style={{ marginRight: 10 }}
                  />
                  <FormTextField
                    name='middle_name'
                    label='Middle name'
                    customOnChange={customOnChange}
                    style={{ marginRight: 10 }}
                  />
                  <FormTextField
                    name='last_name'
                    validate={required()}
                    required
                    customOnChange={customOnChange}
                    customOnBlur={customOnBlur}
                    label='Last name'
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='dob'
                    customOnChange={customOnChange}
                    customOnBlur={customOnBlur}
                    type='date'
                    label='DOB'
                    validate={composeValidators([validateDate()])}
                  />
                  <FormTextField
                    name='ssn'
                    validate={composeValidators([
                      minLength(9, 'Please enter a 9 digit SSN'),
                    ])}
                    customOnChange={customOnChange}
                    customOnBlur={customOnBlur}
                    label='SSN#'
                    format='ss'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='gender/list'
                    customOnChange={customOnChange}
                    name='gender_id'
                    label='Gender'
                    validate={required()}
                    required
                  />
                  <FormTextField
                    name='room_number'
                    customOnChange={customOnChange}
                    label='Room#'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='medicare_id'
                    customOnChange={customOnChange}
                    label='Medicare ID #'
                  />
                  <FormTextField
                    name='medicaid_id'
                    customOnChange={customOnChange}
                    label='Medicaid ID #'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='facility_resident_id'
                    label='Resident ID'
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='original_admit_date'
                    type='date'
                    label='Original admit date'
                    validate={composeValidators([validateDate()])}
                    disabled
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='last_pne_shot'
                    customOnChange={customOnChange}
                    type='date'
                    label='PNA shot'
                    validate={composeValidators([validateDate()])}
                  />
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='PNA shot status'
                    name='pne_shot_status'
                    choices={flu_status_types}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='last_flu_shot'
                    customOnChange={customOnChange}
                    type='date'
                    label='Flu shot'
                    validate={composeValidators([validateDate()])}
                  />
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Flu shot status'
                    name='flu_shot_status'
                    choices={flu_status_types}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='expired_date'
                    type='date'
                    label='Expired date'
                    validate={composeValidators([validateDate()])}
                  />
                  {userPermissions.indexOf('admin') > -1 ||
                  userPermissions.indexOf('supervisor') > -1 ||
                  formData.resident_status === 'Unknown' ? (
                    <ReferenceInput
                      reference='resident-statuses/list'
                      customOnChange={customOnChange}
                      name='resident_status_id'
                      label='Resident status'
                    />
                  ) : (
                    <div style={{ flex: '1 0 160px' }} />
                  )}
                </div>
                {!!duplicateResidentId && !values.resident_override && (
                  <Fragment>
                    <div id='duplicate-msg' />
                    <Typography color='error'>
                      This resident already exists. Click{' '}
                      <Typography color='primary' component='span'>
                        <a
                          style={{ textDecoration: 'none' }}
                          href={`${
                            window.location.origin
                          }/#/residents-list?${qs.stringify({
                            resident_id: duplicateResidentId,
                          })}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          here
                        </a>
                      </Typography>{' '}
                      to view the existing resident.
                    </Typography>
                  </Fragment>
                )}
                {!!duplicateResidentId && (
                  <FormGroup>
                    <CheckboxInput
                      name='resident_override'
                      customOnChange={customOnChange}
                      label='Add this resident again?'
                      checked={values.resident_override}
                      required
                      validate={required()}
                    />
                  </FormGroup>
                )}
                {(is_admin || is_case_manager || is_supervisor) && (
                  <Fragment>
                    <DialogContentText
                      align='left'
                      variant='h6'
                      className={classes.header}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      Integration -
                      {!!record?.integration_type &&
                      !!record?.integration_id ? (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'normal',
                            marginLeft: 5,
                          }}
                        >
                          synced <SyncIcon />
                        </span>
                      ) : (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'normal',
                            marginLeft: 5,
                          }}
                        >
                          not synced <SyncDisabledIcon />
                        </span>
                      )}
                    </DialogContentText>
                    <Divider className={classes.divider} />

                    <DialogContentText
                      color='primary'
                      onClick={toggleIntegrationFields}
                      className={classes.secondaryAction}
                    >
                      <AddIcon />{' '}
                      {`${
                        showIntegrationFields ? 'Hide' : 'Show'
                      } integration fields`}
                    </DialogContentText>
                    {showIntegrationFields && (
                      <Fragment>
                        <div className={clsx(classes.inputContainerWrap)}>
                          <ReferenceInput
                            reference='external-integrations/list'
                            customOnChange={customOnChange}
                            name='integration_type'
                            label='Integration type'
                          />
                          {!!values.integration_type ? (
                            <div
                              className={clsx(
                                classes.input,
                                classes.smallInput,
                                classes.textField,
                              )}
                              style={{ marginTop: 5 }}
                            >
                              {externalResident.loading ? (
                                <Loading />
                              ) : (
                                <AutocompleteInput
                                  key={values.integration_type}
                                  reference={
                                    integrationResidentData[
                                      integrationObj[+values.integration_type]
                                        .type
                                    ].residentIdURL
                                  }
                                  customOnChange={customOnChange}
                                  name='integration_id'
                                  label='Integration resident ID'
                                  options={{
                                    filter: {
                                      integration_id: values.integration_type,
                                      is_synced: false,
                                    },
                                    query_param: 'name',
                                  }}
                                  autocompleteProps={{
                                    openOnFocus: true,
                                    initialInputValue: externalResident.name,
                                    otherInputProps: {
                                      margin: 'dense',
                                      style: { marginRight: 0, minWidth: 250 },
                                    },
                                  }}
                                />
                              )}
                            </div>
                          ) : (
                            <div style={{ flex: '1 0 160px' }} />
                          )}
                        </div>
                        <div className={clsx(classes.inputContainerWrap)}>
                          <CheckboxInput
                            name='integration_isactive'
                            customOnChange={customOnChange}
                            label='Is active'
                            checked={formData.integration_isactive}
                          />
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={
                    (!!duplicateResidentId && !values.resident_override) ||
                    submitting
                  }
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

const flu_status_types = [
  { id: 'current', name: 'Current' },
  { id: 'unknown', name: 'Unknown' },
  { id: 'refused', name: 'Refused' },
  { id: 'N/A', name: 'N/A' },
];

const integrationResidentData = {
  pcc: { residentIdURL: 'pcc/patients' },
};
