import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useStyles } from './toggleButton.styles';

export const FaxEmailToggle = ({
  selectFaxEmail,
  selectFaxEmailToggle,
  defaultSetting,
}) => {
  useEffect(() => {
    const position = selectFaxEmail ? 'fax/email' : 'file';
    setPosition(position);
    typeof selectFaxEmailToggle === 'function' &&
      selectFaxEmailToggle(position === 'fax/email');
  }, [selectFaxEmailToggle, selectFaxEmail]);
  const classes = useStyles({
    cursorPointer: typeof selectFaxEmailToggle === 'function',
  });
  const [activePosition, setPosition] = useState('file');
  const handleClick = position => {
    if (typeof selectFaxEmailToggle !== 'function') {
      return null;
    }
    setPosition(position);
    selectFaxEmailToggle(position === 'fax/email');
  };
  return (
    <div className={classes.toggleContainer}>
      <div
        className={clsx(classes.button, classes.leftButton, {
          [classes.active]: activePosition === 'file',
        })}
        onClick={() => handleClick('file')}
      >
        Fax/Email
      </div>
      <div
        className={clsx(classes.button, classes.rightButton, {
          [classes.active]: activePosition === 'fax/email',
        })}
        onClick={() => handleClick('fax/email')}
      >
        File Management
      </div>
    </div>
  );
};
