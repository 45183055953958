import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRedirect } from 'react-admin';
import { Chart } from 'react-google-charts';
import {
  Grid,
  Paper,
  Typography,
  Card,
  useMediaQuery,
} from '@material-ui/core';
import get from 'lodash/get';
import {
  UpdateIcon,
  AuthIcon,
  DenialIcon,
  ErrorIcon,
  KeyboardBackspaceIcon,
  MoreHorizIcon,
  FiberManualRecordIcon,
} from '../../design';
import { authGet } from '../../server';
import { Loading } from '../../components/common';
import { useStyles } from './dashboard.styles';

export const CaseManagerDashboard = ({
  title,
  facilityIds,
  groupId,
  userId,
  viewMultiple,
}) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const ui = useSelector(state => state.admin.ui);
  const dispatch = useDispatch();
  const [weClosedSidebar, setWeClosedSidebar] = useState(false);
  const smallDevice = useMediaQuery(theme => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (smallDevice && ui.sidebarOpen === true && !weClosedSidebar) {
      setWeClosedSidebar(true);
      dispatch({ type: 'RA/TOGGLE_SIDEBAR' });
    }
  }, [dispatch, smallDevice, ui.sidebarOpen, weClosedSidebar]);

  const [tasksData, setTasksData] = useState([]);
  const [updatesData, setUpdatesData] = useState({});
  const [faxData, setFaxData] = useState({});
  const [hourlyFaxData, setHourlyFaxData] = useState({});
  const [residentsData, setResidentsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [
        tasks,
        updates,
        faxes,
        hourly_faxes,
        residents,
      ] = await Promise.all([
        authGet([
          `/reports/dashboard/tasks`,
          { facility_ids: facilityIds, group_id: groupId, user_id: userId },
        ]),
        authGet([
          `/reports/dashboard/updates`,
          { facility_ids: facilityIds, group_id: groupId, user_id: userId },
        ]),
        authGet([
          `/reports/dashboard/faxes`,
          { facility_ids: facilityIds, group_id: groupId, user_id: userId },
        ]),
        authGet([
          `/reports/dashboard/faxes`,
          {
            facility_ids: facilityIds,
            group_id: groupId,
            user_id: userId,
            hourly: true,
          },
        ]),
        authGet([
          `/reports/dashboard/residents`,
          { facility_ids: facilityIds, group_id: groupId, user_id: userId },
        ]),
      ]);
      tasks.status === 200 && setTasksData(tasks.data);
      updates.status === 200 && setUpdatesData(updates.data);
      faxes.status === 200 && setFaxData(faxes.data);
      hourly_faxes.status === 200 && setHourlyFaxData(hourly_faxes.data);
      residents.status === 200 && setResidentsData(residents.data);
    };
    facilityIds && fetchData();
  }, [facilityIds, groupId, userId]);

  const {
    total_done_today = '?',
    initial_evals,
    auth_requests,
    missing_auth,
    denial_tasks,
    other_tasks,
  } = tasksData;
  const fax_inbox = useSelector(state => get(state, 'ui.faxInboxCount', 0));
  const { Queued, Resent, SendingFailed, Sent } = faxData;

  return (
    <div>
      <Typography className={classes.title}>{title}</Typography>
      <h3 className={classes.sectionTitle}>My Tasks</h3>
      <Grid container spacing={4}>
        <Grid item md={5} xs={12}>
          <Paper className={classes.section}>
            <div>
              <Grid container spacing={4}>
                <Grid item md={4} xs={12}>
                  <DonutChart
                    title='Tasks'
                    color='#66afdb'
                    data={tasksData}
                    viewMultiple={viewMultiple}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <DonutChart
                    title='Updates'
                    color='#F57C00'
                    data={updatesData}
                    viewMultiple={viewMultiple}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <div
                    className={classes.donutChart}
                    style={{ position: 'relative' }}
                  >
                    <Typography className={classes.residentsRequiringAction}>
                      Residents Requiring Action
                    </Typography>
                    <Chart
                      style={{ cursor: viewMultiple ? 'auto' : 'pointer' }}
                      width='100%'
                      height='167'
                      chartType='PieChart'
                      data={[
                        ['Residents', 'Amount'],
                        ['', residentsData.length > 0 ? residentsData : 1], //formatting purposes
                      ]}
                      options={{
                        pieSliceText: 'none',
                        pieHole: 0.85,
                        colors: ['lightGray'],
                        chartArea: { width: '170', height: '170' },
                        legend: { position: 'none' },
                      }}
                    ></Chart>
                    <div className='pie-text'>
                      <Typography variant='h4'>
                        {residentsData.length > 0 ? residentsData : 0}
                      </Typography>
                      <Typography className={classes.tasksToday}>
                        Residents
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.bottomText}>
                <FiberManualRecordIcon style={{ color: '#AEDD45' }} />
                <div style={{ marginLeft: 4 }}>
                  {total_done_today} Total tasks done today
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2} style={{ height: '50%' }}>
            <Grid item md={4} xs={12}>
              <SmallCard
                IconComp={UpdateIcon}
                title='Initial Evals'
                backgroundColor='#001543'
                count={initial_evals}
                redirect='["initial_evals","my_tasks", "due_today"]'
                viewMultiple={viewMultiple}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SmallCard
                IconComp={AuthIcon}
                title='Auth Requests'
                backgroundColor='#AEDD45'
                count={auth_requests}
                redirect='["auth_requests","my_tasks", "due_today"]'
                viewMultiple={viewMultiple}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SmallCard
                IconComp={ErrorIcon}
                title='Missing Auth'
                backgroundColor='#E91F31'
                count={missing_auth}
                redirect='["missing_auth","my_tasks", "due_today"]'
                viewMultiple={viewMultiple}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SmallCard
                IconComp={DenialIcon}
                title='Denial Tasks'
                backgroundColor='#F57C00'
                count={denial_tasks}
                redirect='["denial_tasks","my_tasks", "due_today"]'
                viewMultiple={viewMultiple}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SmallCard
                IconComp={MoreHorizIcon}
                title='Other Tasks'
                backgroundColor='#829CB5'
                count={other_tasks}
                redirect='["my_tasks", "due_today"]'
                viewMultiple={viewMultiple}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SmallCard
                IconComp={KeyboardBackspaceIcon}
                title='Fax Inbox'
                backgroundColor='#FBD834'
                count={fax_inbox}
                redirect='/fax-inbox'
                viewMultiple={viewMultiple}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <div className={classes.sectionTitle}>
            Failed Faxes - Past 48 Hours
          </div>

          <Paper>
            {!!Object.keys(hourlyFaxData).length ? (
              <Chart
                chartType='ColumnChart'
                height='310px'
                fontSize={12}
                data={[
                  ['Year', '', { role: 'style' }],
                  [
                    'Sending Failed',
                    hourlyFaxData?.SendingFailed,
                    'color: #E91F31',
                  ],
                ]}
                options={{
                  title: 'Fax Statuses',
                  hAxis: {
                    title: 'Fax Status',
                    textStyle: { fontSize: 11 },
                  },
                  //  vAxis: {
                  //    title: 'Auths',
                  //    viewWindow: { min: 0 },
                  //  },
                  // legend: { position: 'bottom', maxLines: 1 },
                  legend: 'none',
                  bar: { groupWidth: '50%' },
                  colors: ['#E91F31'],
                }}
                chartEvents={[
                  {
                    eventName: 'select',
                    callback({ chartWrapper, google, props }) {
                      if (!viewMultiple) redirect('/faxes-list');
                    },
                  },
                ]}
              />
            ) : (
              <Loading />
            )}
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.sectionTitle}>Fax Status - Past 7 Days</div>

          <Paper>
            {!!Object.keys(faxData).length ? (
              <Chart
                style={{ cursor: viewMultiple ? 'auto' : 'pointer' }}
                chartType='ColumnChart'
                height='310px'
                fontSize={12}
                data={[
                  ['Year', '', { role: 'style' }],
                  ['Queued', Queued, 'color: #FBD834'],
                  ['Sent', Sent, 'color: #AEDD45'],
                  ['Sending Failed', SendingFailed, 'color: #E91F31'],
                  ['Resent', Resent, 'color: #F57C00'],
                ]}
                options={{
                  title: 'Fax Statuses',
                  hAxis: {
                    title: 'Fax Status',
                    textStyle: { fontSize: 11 },
                  },
                  //  vAxis: {
                  //    title: 'Auths',
                  //    viewWindow: { min: 0 },
                  //  },
                  // legend: { position: 'bottom', maxLines: 1 },
                  legend: 'none',
                  bar: { groupWidth: '50%' },
                  colors: ['#66afdb'],
                }}
                chartEvents={[
                  {
                    eventName: 'select',
                    callback({ chartWrapper, google, props }) {
                      if (!viewMultiple) redirect('/faxes-list');
                    },
                  },
                ]}
              />
            ) : (
              <Loading />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

function DonutChart({ data, color, title, viewMultiple }) {
  const _redirect = useRedirect();
  const { total = '?', pending = '?', percent = 100 } = data;
  const chartData = [
    ['Task', 'Hours per Day'],
    ['To Do', 100 - percent],
    ['Done', percent],
  ];
  const classes = useStyles();
  const todoRedirect = _ => {
    const urlBase =
      title === 'Updates'
        ? '/updates'
        : '/tasks/not-completed?filters=["my_tasks", "due_today"]';
    if (!viewMultiple) _redirect(`${urlBase}`);
  };
  return (
    <div
      className={classes.donutChart}
      style={{
        position: 'relative',
        cursor: viewMultiple ? 'auto' : 'pointer',
      }}
    >
      <Typography className={classes.tasksToday}>{title}</Typography>
      <Chart
        width='100%'
        height='167'
        chartType='PieChart'
        data={chartData}
        enableInteractivity={!viewMultiple}
        chartEvents={[
          {
            eventName: 'select',
            callback({ chartWrapper }) {
              const data = chartWrapper.getChart().getSelection()[0];
              if (data && data.row === 0 && !viewMultiple) {
                todoRedirect();
              } else if (data && data.row === 1 && !viewMultiple) {
                const urlBase =
                  title === 'Updates'
                    ? '/updates'
                    : '/tasks/completed?filters=["my_tasks", "due_today"]';
                _redirect(`${urlBase}`);
              }
            },
          },
        ]}
        options={{
          pieSliceText: 'none',

          // title: 'My Daily Activities',
          pieHole: 0.85,
          slices: {
            0: { color },
            1: { color: 'lightGray' },
          },
          chartArea: { width: '170', height: '170' },
          legend: { position: 'none' },
        }}
      ></Chart>
      <div
        className='pie-text'
        onClick={() => todoRedirect()}
        style={{ cursor: viewMultiple ? 'auto' : 'pointer' }}
      >
        <Typography variant='h4'>{pending}</Typography>
        <Typography variant='h6'>To Do</Typography>
        <Typography variant='body2'>out of {total}</Typography>
      </div>
    </div>
  );
}

function SmallCard({
  IconComp,
  title,
  backgroundColor,
  count = '?',
  redirect,
  viewMultiple,
}) {
  const _redirect = useRedirect();
  const classes = useStyles();
  const [paperElevation, setPaperElevation] = useState(1);

  return (
    <Paper
      className={classes.taskSmallCard}
      onMouseOver={() => !viewMultiple && setPaperElevation(5)}
      onMouseOut={() => !viewMultiple && setPaperElevation(1)}
      elevation={paperElevation}
      onClick={() => {
        if (!viewMultiple)
          title === 'Fax Inbox'
            ? _redirect(redirect)
            : _redirect(`/tasks/not-completed?filters=${redirect}`);
      }}
      style={{ cursor: viewMultiple ? 'auto' : 'pointer' }}
    >
      <Card className={classes.smallCardBox} style={{ backgroundColor }}>
        <IconComp className={classes.smallCardIcon} />
      </Card>
      <div className={classes.smallCardContent}>
        <Typography variant='subtitle1' className='title'>
          {title}
        </Typography>
        <Typography variant='h3'>{count}</Typography>
      </div>
    </Paper>
  );
}
