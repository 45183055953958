import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  ReferenceField,
  TextField,
  TopToolbar,
  FunctionField,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import { Datagrid } from '../../components/common';
import { NewEvent } from '../../components/common/modals';
import { CustomButton } from '../../design/material-ui';
import { useStyles } from './payers.styles';
import { AddIcon, EditIcon } from '../../design';
import { IconButton } from '@material-ui/core';

const ListActions = ({
  payer_facility_id,
  line_of_businesses,
  permissions,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Integration details'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='payerIntegrationDetails'
          meta={{ line_of_businesses: line_of_businesses }}
          refresh={refresh}
          payerFacilityId={parseInt(payer_facility_id)}
        />
      )}
    </Fragment>
  );
};

export const PayerIntegrationDetails = ({
  record,
  id,
  permissions = [],
  isViewAdmin,
  ...props
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [integration, setIntegrationRecord] = useState();
  useEffect(() => {
    if (integration) {
      setEventModal(true);
    }
  }, [integration]);

  const unselectAll = useUnselectAll('payers-facility/integration-details');
  useEffect(() => {
    return () => {
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setEventModal(false);
    setIntegrationRecord(undefined);
  };

  const controllerProps = useListController({
    ...props,
    resource: 'payers-facility/integration-details',
    filter: { id },
  });
  return (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        exporter={false}
        bulkActionButtons={isViewAdmin ? false : undefined}
        actions={
          Array.isArray(permissions) &&
          (permissions.indexOf('admin') > -1 ||
            permissions.indexOf('supervisor') > -1 ||
            permissions.indexOf('contract_user') > -1) ? (
            <ListActions
              payer_facility_id={id}
              line_of_businesses={record.line_of_businesses}
            />
          ) : null
        }
      >
        <Datagrid>
          <TextField
            source='integration_payer_id'
            label='Integration ID'
            headerClassName={classes.listItemLabel}
          />
          <TextField
            source='integration_payer_description'
            label='Description'
            headerClassName={classes.listItemLabel}
          />
          <ReferenceField
            source='line_of_business_id'
            label='Matching LOB'
            headerClassName={classes.listItemLabel}
            reference='lob/list'
            link={false}
            sortable={false}
          >
            <TextField source='name' />
          </ReferenceField>
          {
            <FunctionField
              source='status'
              label=''
              sortable={false}
              render={record => {
                return Array.isArray(permissions) &&
                  (permissions.indexOf('admin') > -1 ||
                    permissions.indexOf('supervisor') > -1 ||
                    permissions.indexOf('contract_user') > -1) ? (
                  <IconButton
                    style={{ color: '#5C738E' }}
                    onClick={() => setIntegrationRecord(record)}
                    size='small'
                  >
                    <EditIcon />
                  </IconButton>
                ) : null;
              }}
            />
          }
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={integration}
          meta={{ line_of_businesses: record.line_of_businesses }}
          form='payerIntegrationDetails'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
    </div>
  );
};
