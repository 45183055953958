import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useStyles } from './toggleButton.styles';

export const GroupFacilityToggle = ({
  selectGroup,
  selectGroupToggle,
  facilityLabel,
  groupLabel,
  disabled,
}) => {
  useEffect(() => {
    const position = selectGroup ? 'group' : 'facility';
    setPosition(position);
    typeof selectGroupToggle === 'function' &&
      selectGroupToggle(position === 'group');
  }, [selectGroupToggle, selectGroup]);
  const classes = useStyles({
    cursorPointer: typeof selectGroupToggle === 'function',
  });
  const [activePosition, setPosition] = useState('facility');
  const handleClick = position => {
    if (typeof selectGroupToggle !== 'function') {
      return null;
    }
    setPosition(position);
    selectGroupToggle(position === 'group');
  };
  return (
    <div className={classes.toggleContainer} style={{ minWidth: 150 }}>
      <div
        className={clsx(classes.button, classes.leftButton, {
          [classes.active]: activePosition === 'facility',
          [classes.disabled]: activePosition === 'facility' && disabled,
        })}
        onClick={() => !disabled && handleClick('facility')}
      >
        {facilityLabel}
      </div>
      <div
        className={clsx(classes.button, classes.rightButton, {
          [classes.active]: activePosition === 'group',
          [classes.disabled]: activePosition === 'group' && disabled,
        })}
        onClick={() => !disabled && handleClick('group')}
      >
        {groupLabel}
      </div>
    </div>
  );
};
