import React, { useState, useEffect, Fragment } from 'react';

import { Form } from 'react-final-form';
import {
  useDataProvider,
  useRefresh,
  useNotify,
  required,
  cacheDataProviderProxy,
} from 'react-admin';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { DialogActions, DialogContent } from '@material-ui/core';
import clsx from 'clsx';
import {
  ReferenceInput,
  FormTextField,
  handleNumbers,
  getDateInputValue,
} from '.';
export const FacilityGroups = ({ handleClose, isEdit, record }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({ facility_ids: [] });

  useEffect(() => {
    if (isEdit && record) {
      const facilityIds = record.facilities.map(f => f.id);
      setFormData(f => ({
        facility_ids: facilityIds,
        ...record,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('facility-groups', {
          id: record.id,
          data: {
            ...value,
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('facility-groups', {
          data: {
            ...value,
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='name'
                    customOnChange={customOnChange}
                    label='Group Name'
                    required
                    validate={required()}
                  />
                </div>

                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='facilities/list'
                    customOnChange={customOnChange}
                    name='facility_ids'
                    label='Select facilities'
                    validate={required()}
                    required
                    multiple
                    fullWidth
                    setFormData={setFormData}
                    perPage={500}
                    selectAll
                    options={{
                      filter: {},
                      sort: { field: 'name', order: 'ASC' },
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
