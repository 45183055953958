import React, { Fragment, useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  ListView,
  useRedirect,
  useListController,
  TopToolbar,
  FunctionField,
  ChipField,
  useListContext,
  useRefresh,
  useNotify,
  useDataProvider,
  useUnselectAll,
} from 'react-admin';
import { DialogContentText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useSidebarOpen } from '../hooks';
import { DateField } from '../components/common';
import { Datagrid } from '../components/wrappers';
import { useStyles } from './residents.styles';
import { CustomButton, MergeIcon } from '../design';
import { ReferenceInput } from '../components/common/modals/modalForms/inputs';
import { ListSearch } from '../components/common/ListSearch';
import { ConfirmedActionModal } from '../components/common/ConfirmedActionModal';
import { getChip } from '../utils';
import { Loader } from '../components/common/Loading';
export const MergeResidents = props => {
  const facilityId = useSelector(state => state.facility.id);
  const redirect = useRedirect();
  const [formData, setFormData] = useState({ facility_id: facilityId });
  const isSidebarOpen = useSidebarOpen();
  const [searchText, setSearchText] = useState('');
  const classes = useStyles({ isSidebarOpen });
  const controllerProps = useListController({
    ...props,
    resource: 'merge-residents',
    basePath: '/merge-residents',
    filter: { q: searchText, facility_id: formData.facility_id },
  });
  const { loading } = controllerProps;
  const { selectedIds } = useListContext();
  const { permissions = '' } = props;

  const unselectAll = useUnselectAll('merge-residents');
  useEffect(() => {
    return () => {
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (
    userPermissions.indexOf('admin') === -1 &&
    userPermissions.indexOf('supervisor') === -1 &&
    userPermissions.indexOf('view_admin') === -1 &&
    userPermissions.indexOf('admin_assistant') === -1
  ) {
    redirect('/');
    return null;
  }

  const onSubmit = value => {};
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className={classes.listContainer}>
      <TopToolbar className={classes.listActionWrapper}>
        <div style={{ display: 'block' }}>
          <div className={classes.title}>Merge Residents</div>
          <div className={classes.subtitle} style={{ paddingTop: 10 }}>
            Select two residents in a facility to merge their cases
          </div>
        </div>
      </TopToolbar>
      <Fragment>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...formData,
          }}
          render={() => {
            return (
              <div style={{ display: 'flex' }}>
                <ReferenceInput
                  style={{
                    maxWidth: 500,
                  }}
                  reference='facilities/list'
                  name='facility_id'
                  label='Select facility'
                  required
                  perPage={500}
                  options={{
                    sort: { field: 'name', order: 'ASC' },
                  }}
                  customOnChange={customOnChange}
                  setFormData={setFormData}
                />
                <div>
                  <ListSearch
                    doSearch={setSearchText}
                    placeholder='search residents'
                  />
                </div>
              </div>
            );
          }}
        />
      </Fragment>

      {searchText && (
        <Fragment>
          <DialogContentText
            color={'error'}
            variant='subtitle2'
            style={{ marginBottom: -50 }}
          >
            Note: Only two residents can be merged at once.
          </DialogContentText>

          <ListView
            empty={false}
            {...controllerProps}
            exporter={false}
            bulkActionButtons={
              <MergeResidentsButton
                selectedIds={selectedIds}
                data={controllerProps.data}
              />
            }
            pagination={false}
          >
            {searchText && loading ? (
              <Loader open={loading && searchText} />
            ) : (
              <Datagrid {...props}>
                <FunctionField
                  label='Status'
                  source='resident_status'
                  className={classes.noWrap}
                  headerClassName={classes.noWrap}
                  render={record => {
                    return (
                      <span>
                        <ChipField
                          record={record}
                          source='resident_status'
                          className={clsx(
                            classes.chip,
                            classes.smallStatusChip,
                            classes[getChip(record.resident_status)],
                          )}
                        />
                      </span>
                    );
                  }}
                />
                <FunctionField
                  source='full_name'
                  label='Resident name'
                  sortBy='last_name'
                  render={record => {
                    const { full_name, id } = record;
                    if (!full_name || !id) {
                      return full_name;
                    }
                    return (
                      <Button
                        className={classes.referenceBtn}
                        color='primary'
                        onClick={e => e.stopPropagation()}
                        component={Link}
                        to={`/residents-list?resident_id=${id}`}
                      >
                        {full_name}
                      </Button>
                    );
                  }}
                />
                <FunctionField
                  source='dob'
                  label='Date of birth'
                  className={classes.noWrap}
                  headerClassName={classes.noWrap}
                  render={record => <DateField record={record} source='dob' />}
                />
              </Datagrid>
            )}
          </ListView>
        </Fragment>
      )}
    </div>
  );
};
const MergeResidentsButton = ({ selectedIds, data }) => {
  const records = Object.values(data)?.filter(row =>
    selectedIds.includes(row.id),
  );
  const [open, setOpen] = useState(false);
  const [confirmMiddleName, setConfirmMiddleName] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll('merge-residents');
  const onSuccess = () => {
    refresh();
    notify('Residents merged');
    unselectAll();
    setOpen(false);
    refresh();
  };
  const checkValidation = value => {
    //If both names are identical, making a Set from the array will only return 1 value.
    const bothDobs = value.filter(row => !!row.dob).length > 1;
    const validateNamesDob =
      new Set(
        value.map(row => `${row.dob} - ${row.first_name} - ${row.last_name}`),
      ).size === 1;
    const validateNames =
      new Set(value.map(row => `${row.first_name} - ${row.last_name}`)).size ===
      1;
    const validateMiddleName =
      new Set(value.map(row => row.middle_name)).size === 1;
    if (bothDobs && !validateNamesDob) {
      unselectAll();
      notify(
        'Cannot merge residents with different names and dates of birth.',
        'warning',
      );
    } else if (!bothDobs && !validateNames) {
      unselectAll();
      notify('Cannot merge residents with different names.', 'warning');
    } else if (!validateMiddleName) {
      setConfirmMiddleName(true);
    } else {
      submit(value);
    }
  };
  const submit = value => {
    dataProvider
      .create('merge-residents', {
        data: {
          records: value,
        },
      })
      .then(() => typeof onSuccess === 'function' && onSuccess())
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
    unselectAll();
  };
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <Fragment>
      {selectedIds?.length === 2 && (
        <>
          <CustomButton
            Icon={MergeIcon}
            style={{ cursor: 'pointer' }}
            variant='text'
            onClick={handleClick}
            label='Merge residents'
          />
          <ConfirmedActionModal
            title='Merge Residents'
            text={
              <div>
                Are you sure you want to merge the following residents?
                {records?.map(row => (
                  <div style={{ paddingTop: 10 }}>
                    {`${row.last_name}, ${row.first_name} - DOB:  ${row.dob}`}
                  </div>
                ))}
              </div>
            }
            confirmButtonText='Merge residents'
            onClose={() => handleDialogClose()}
            onConfirm={() => checkValidation(records)}
            open={open}
          />
          <ConfirmedActionModal
            text='Residents have different middle names. Merge anyway?'
            confirmButtonText='OK'
            open={confirmMiddleName}
            onClose={() => setConfirmMiddleName(false)}
            onConfirm={() => submit(records)}
          />
        </>
      )}
    </Fragment>
  );
};
