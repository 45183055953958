import { makeStyles } from '@material-ui/core';
import { styles } from '../../design';
import { style } from '../../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  actionHeader: {
    paddingTop: 8,
  },
  actionContainer: {
    width: '100%',
    padding: 12,
    ...styles.flexRow,
    ...styles.justifyBetween,
  },
  showLayout: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0',
    '& div:nth-child(n)': {
      maxWidth: 200,
    },
    '& div:nth-child(3n)': {
      marginRight: 35,
    },
  },
  padding50: { paddingBottom: 50 },
  padding100: { paddingBottom: 100 },
  twoColumnLayout: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0',
    '& div:nth-child(n)': {
      maxWidth: 200,
    },
    '& div:nth-child(2n)': {
      marginRight: 35,
    },
  },
  listItemLabel: {
    whiteSpace: 'nowrap',
  },
  showItemLabel: {
    whiteSpace: 'nowrap',
  },
  showItem: {
    color: 'black',
    flex: '1 0 20vw',
    fontSize: '0.885rem',
    marginRight: 45,
    padding: '10px 0px 10px 0px',
    height: 63,
  },
  noBorder: {
    borderBottom: 'none !important',
  },
  showFullWidth: {
    maxWidth: '100%',
  },
  email: {
    wordWrap: 'break-word',
  },
  height1: {
    height: 63,
  },
  showMore: {
    ...styles.cursorPointer,
    fontSize: '0.875rem',
  },
  notesCell: {
    maxWidth: '15vw',
  },
  userTracking: {
    marginRight: '100px !important',
    minWidth: 150,
  },
  addContainer: {
    ...style(theme).addContainer,
    width: 'initial',
  },
  adminAddContainer: {
    width: 500,
  },
  inactiveItem: {
    color: '#F57C00',
  },
}));
