import React, { useState, Fragment } from 'react';
// import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Button, Chip, Menu, MenuItem, Fade } from '@material-ui/core';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  Filter,
  DateInput,
  Pagination,
  // FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';
import { useGetUserDivision } from '../../hooks';
import { constants } from '../../utils';
import { ToggleButton, DateField, Datagrid } from '../../components/common';
import { useStyles } from './reports.styles';
import { ArrowDropDownIcon } from '../../design';
const { authReportFilters: authFilters } = constants;

const exporter = ({ fields, facilityName }) => {
  const fieldsForExport = fields.map(field => {
    const { id, 'authorization.created_at': createdAt, ...rest } = field;
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'first_name',
        'last_name',
        'start_date',
        'end_date',
        'payer_name',
        'lob',
        'authorization_status',
        'authorization_type',
        'created_at',
      ],
      rename: [
        'First Name',
        'Last Name',
        'Start Date',
        'End Date',
        'Payer',
        'LOB',
        'Auth Status',
        'Rate Type',
        'Created At',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `filtered-auth-report-${facilityName}-${format(
          new Date(),
          'MM/dd/yyyy',
        )}`,
      );
    },
  );
};

const ListActions = ({
  currentSort,
  exporter,
  filterValues = {},
  permanentFilter = {},
  resource,
  total,
  setDivision,
  filters = [{ id: '6', name: 'Missing' }],
  classes,
  loading,
  filterActions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const addFilter = filter => {
    filterActions({ actionType: 'addFilter', filter });
    setAnchorEl(null);
  };
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <div style={{ display: 'flex' }}>
        <Button
          disableElevation
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          className={classes.filterDropdown}
        >
          <span style={{ fontWeight: 300 }}> Filter</span>
        </Button>
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {authFilters.map(f => (
            <MenuItem key={f.id} onClick={_ => addFilter(f)}>
              {f.name}
            </MenuItem>
          ))}
        </Menu>
        {!!filters.length && (
          <div
            style={{
              padding: 10,
            }}
          >
            <div style={{ display: 'flex' }}>
              {filters.map(f => {
                return (
                  <Chip
                    style={{
                      width: 'auto',
                      marginLeft: 15,
                      textTransform: 'capitalize',
                    }}
                    className={classes.chips}
                    key={f.id}
                    label={f.name}
                    clickable
                    disabled={loading}
                    size='small'
                    onDelete={() =>
                      filterActions({
                        actionType: 'removeFilter',
                        filter: f,
                      })
                    }
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='From'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard' }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
      <DateInput
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard' }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
    </Filter>
  );
};

export const FilteredAuthReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const facilityName = useSelector(state => state.facility.name);
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(userDivision);
  const [filters, setFilters] = useState([{ id: '6', name: 'Missing' }]);
  const filterActions = ({ actionType, filter }) => {
    if (actionType === 'removeFilter') {
      const _filters = filters.filter(f => f.name !== filter.name);
      setFilters(_filters);
    } else if (actionType === 'addFilter') {
      setFilters(curFilter => Array.from(new Set([...curFilter, filter])));
    }
  };

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <div className={classes.title} style={{ paddingTop: 30 }}>
          Auth report
        </div>
        <div style={{ padding: 30 }}>
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
          />
        </div>
      </div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={
          <ListActions
            setDivision={setDivision}
            division={division}
            filterActions={filterActions}
            filters={filters}
            classes={classes}
          />
        }
        filter={{ division, filtered: filters.map(f => [f.id]) }}
        exporter={fields => exporter({ fields, facilityName })}
        filters={<FiltersView classes={classes} />}
        sort={{ field: 'last_name', order: 'ASC' }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField source='first_name' label='First name' />
          <TextField source='last_name' label='Last name' />
          <DateField source='start_date' label='Start date' />
          <DateField source='end_date' label='End date' />
          <TextField source='payer_name' label='Payer name' />
          <TextField source='lob' label='LOB' />
          <TextField source='authorization_status' label='Auth status' />
          <TextField source='authorization_type' label='Rate type' />
          <TextField source='created_at' label='Created at' sortable={false} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export const filteredAuthReportResource = {
  name: 'filtered-auth-report',
  list: FilteredAuthReportList,
};

function getResourceProps(props) {
  const basePath = '/filtered-auth-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/filtered-auth-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!filteredAuthReportResource.show,
        hasCreate: !!filteredAuthReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
