import React, { useState, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import { useNotify } from 'react-admin';
import { authGet } from '../../../../server';
import { Loading } from '../../Loading';
import { useStyles } from '../modal.styles';

export const CheckEligibility = ({ params }) => {
  const [seeking, setSeeking] = useState(false);
  const [htmlResponse, setHtmlResponse] = useState();
  const notify = useNotify();
  const classes = useStyles();

  useEffect(() => {
    const checkEligibility = async () => {
      setSeeking(true);
      const response = await authGet([
        '/call-seek-eligibility',
        {
          ...params,
        },
      ]);
      if (response.error) {
        notify(response.error.message, 'warning');
        setSeeking(false);
      } else {
        const { fields, html } = response.data;
        if (fields) {
          setHtmlResponse(html);
          notify('Seek eligibility information saved.');
        } else
          setHtmlResponse(
            `<p>Oops, something wrong with your request:</p><p> ${response.data.message}</p>`,
          );
        setSeeking(false);
      }
    };
    checkEligibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {seeking && <Loading style={{ height: 'initial' }} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 20,
        }}
      >
        <div className={clsx(classes.inputContainerWrap)}>
          <div dangerouslySetInnerHTML={{ __html: htmlResponse }} />
        </div>
      </div>
    </Fragment>
  );
};
