import inflection from 'inflection';
import {
  useVersion,
  useCheckMinimumRequiredProps,
  useGetOne,
  useTranslate,
  useNotify,
  useRedirect,
  CRUD_GET_ONE,
} from 'react-admin';

/**
 * Prepare data for the Show view
 *
 * @param {Object} props The props passed to the Show component.
 *
 * @return {Object} controllerProps Fetched data and callbacks for the Show view
 *
 * @example
 *
 * import { useShowController } from 'react-admin';
 * import ShowView from './ShowView';
 *
 * const MyShow = props => {
 *     const controllerProps = useShowController(props);
 *     return <ShowView {...controllerProps} {...props} />;
 * }
 */
// We need to overrride the default showController because it calls
// refresh on error which causes infinite fetch calls to the api
// when the aside is open
export const useShowController = ({ closeAside, ...props }) => {
  useCheckMinimumRequiredProps('Show', ['basePath', 'resource'], props);
  const { basePath, id, resource } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const version = useVersion();
  const { data: record, loading, loaded } = useGetOne(resource, id, {
    version, // used to force reload
    action: CRUD_GET_ONE,
    onFailure: () => {
      notify('ra.notification.item_doesnt_exist', 'warning');
      typeof closeAside === 'function' && closeAside();
      redirect('list', basePath);
    },
  });

  const resourceName = translate(`resources.${resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(resource)),
  });
  const defaultTitle = translate('ra.page.show', {
    name: `${resourceName}`,
    id,
    record,
  });

  return {
    loading,
    loaded,
    defaultTitle,
    resource,
    basePath,
    record,
    version,
  };
};
