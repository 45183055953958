import React, { useState, memo, Fragment } from 'react';
import { AppBar as AdminAppBar, useRefresh } from 'react-admin';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { NewEvent, RoleDescriptions } from '../common/modals';
import { push } from 'connected-react-router';
import { useStyles } from './appbar.styles';
import { Search } from './search';
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import { SearchFacility, RedirectOnError } from './searchFacility';
import { UserMenu } from './UserMenu';
import { DashboardIcon } from '../../design';
import { useGetUserRoles } from '../../hooks';

export const AppBar = memo(props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const {
    is_admin,
    is_contract_user,
    is_supervisor,
    is_view_admin,
    is_admin_assistant,
    profileLoaded,
  } = useGetUserRoles();
  const [addFacilityOpen, setAddFacilityModal] = useState(false);
  const [bulkReportModal, setBulkReportModal] = useState(false);
  const [autoTaskSettingsModal, setAutoTaskSettingsModal] = useState(false);
  const [roleDescriptionsModal, setRoleDescriptionsModal] = useState(false);
  const is_inactive = useSelector(state => state.facility.inactive);
  const { profile, is_mltc, is_subacute } = useSelector(state => {
    const {
      user: { profile, profile: { is_mltc, is_subacute } = {} } = {},
    } = state;

    return {
      profile,
      is_mltc,
      is_subacute,
    };
  }, shallowEqual);

  return (
    <Fragment>
      <AdminAppBar
        userMenu={
          <UserMenu
            is_mltc={is_mltc}
            is_subacute={is_subacute}
            profile={profile}
            classes={classes}
            setAddFacilityModal={setAddFacilityModal}
            setBulkReportModal={setBulkReportModal}
            setAutoTaskSettingsModal={setAutoTaskSettingsModal}
            setRoleDescriptionsModal={setRoleDescriptionsModal}
          />
        }
        {...props}
        className={classes.root}
      >
        <img
          src='/images/logo.png'
          alt='logo'
          className={classes.logo}
          onClick={() =>
            !is_admin &&
            !is_supervisor &&
            !is_view_admin &&
            !is_admin_assistant &&
            !is_contract_user &&
            dispatch(push('/dashboard-home'))
          }
        />
        <Typography classes={{ root: classes.title }}>MCO Manager</Typography>
        <RedirectOnError
          isAdmin={
            is_admin || is_supervisor || is_view_admin || is_admin_assistant
          }
        />

        {profileLoaded && (
          <SearchFacility
            profile={profile}
            isAdmin={
              is_admin || is_supervisor || is_view_admin || is_admin_assistant
            }
          />
        )}
        <span className={classes.spacer} />
        <Search className={classes.dashboard} />
        {!is_contract_user && (
          <Tooltip title='Dashboard' placement='bottom'>
            <IconButton onClick={() => dispatch(push('/dashboard'))}>
              <DashboardIcon className={classes.dashboard} />
            </IconButton>
          </Tooltip>
        )}
      </AdminAppBar>
      {is_inactive && (
        <div className={classes.inactive}> ** INACTIVE FACILITY **</div>
      )}
      {addFacilityOpen && (
        <NewEvent
          open={addFacilityOpen}
          handleClose={() => setAddFacilityModal(false)}
          form='facility'
          refresh={refresh}
        />
      )}
      {bulkReportModal && (
        <NewEvent
          open={bulkReportModal}
          handleClose={() => setBulkReportModal(false)}
          form='bulkReportsForm'
          refresh={refresh}
        />
      )}

      {autoTaskSettingsModal && (
        <NewEvent
          open={autoTaskSettingsModal}
          handleClose={() => setAutoTaskSettingsModal(false)}
          form='autoTaskSettingsForm'
          refresh={refresh}
        />
      )}
      {roleDescriptionsModal && (
        <RoleDescriptions
          open={roleDescriptionsModal}
          handleClose={() => setRoleDescriptionsModal(false)}
        />
      )}
    </Fragment>
  );
});
