import React from 'react';
import { Datagrid as AdminDatagrid } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerCell: {
    position: 'inherit',
    top: 0,
    zIndex: 0,
  },
}));
// Need this to remove position sticky that react-admin added to datagrid
// which is effecting the aside.
export function Datagrid({ children, ...rest }) {
  const classes = useStyles();
  return (
    <AdminDatagrid {...rest} classes={classes}>
      {children}
    </AdminDatagrid>
  );
}
