import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  // TextField,
  EmailField,
  BooleanField,
  // Filter,
  // SelectInput,
  FunctionField,
  // NumberField,
  // DateField,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import get from 'lodash/get';
import { Datagrid } from '../../components/common';
import { NewEvent } from '../../components/common/modals';
import { normalizePhone } from '../../components/common/modals/modalForms';
import { AddIcon, EditIcon, CustomButton } from '../../design';
import { useStyles } from './facility.styles';
import { IconButton } from '@material-ui/core';

const ListActions = () => {
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <CustomButton
        Icon={AddIcon}
        color='#1061A0'
        backgroundColor='#EFF4FB'
        label='contact'
        type='button'
        variant='text'
        size='small'
        onClick={() => setEventModal(true)}
      />
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='facilityContacts'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

export const FacilityContacts = ({
  record,
  id,
  isAdmin,
  isViewAdmin,
  isAdminAssistant,
  ...props
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [contactRecord, setContactRecord] = useState();

  useEffect(() => {
    // see comment on case_communication
    if (contactRecord) {
      setEventModal(true);
    }
  }, [contactRecord]);

  const unselectAll = useUnselectAll('facility-contacts');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = () => {
    setEventModal(false);
    setContactRecord(undefined);
  };
  const controllerProps = useListController({
    ...props,
    resource: 'facility-contacts',
  });
  return (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        bulkActionButtons={
          !isAdmin && !isAdminAssistant
            ? false // disable delete
            : undefined
        }
        exporter={false}
        actions={isAdmin || isAdminAssistant ? <ListActions /> : null}
      >
        <Datagrid>
          <FunctionField
            source='first_name'
            label='Name'
            headerClassName={classes.listItemLabel}
            render={record => `${record.last_name}, ${record.first_name}`}
            sortBy='last_name'
          />
          <FunctionField
            source='contact_role.role'
            label='Role'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { contact_role_other } = record;
              return contact_role_other || get(record, 'contact_role.role');
            }}
          />
          <FunctionField
            source='is_main'
            label='Main contact?'
            headerClassName={classes.listItemLabel}
            render={record => {
              const updatedRecord = {
                ...record,
                is_main: !!record.is_main,
              };
              return (
                <BooleanField
                  record={updatedRecord}
                  source='is_main'
                  headerClassName={classes.listItemLabel}
                  style={{
                    color: updatedRecord.is_main ? '#44C87A' : '#5C738E',
                  }}
                />
              );
            }}
          />
          <FunctionField
            source='phone'
            label='Office Phone'
            headerClassName={classes.listItemLabel}
            render={record => {
              const ext = record.phone_ext ? `x${record.phone_ext}` : '';
              return record.phone
                ? `${normalizePhone(record.phone)} ${ext}`
                : null;
            }}
          />
          <FunctionField
            source='cell'
            label='Cell Phone'
            headerClassName={classes.listItemLabel}
            render={record =>
              record.cell ? `${normalizePhone(record.cell)}` : null
            }
          />
          <FunctionField
            source='fax'
            label='Fax'
            headerClassName={classes.listItemLabel}
            render={record =>
              record.fax ? `${normalizePhone(record.fax)}` : null
            }
          />
          <EmailField
            source='email'
            label='Email'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='gets_nomnc'
            label='Gets NOMNC?'
            headerClassName={classes.listItemLabel}
            render={record => {
              const updatedRecord = {
                ...record,
                gets_nomnc: !!record.gets_nomnc,
              };
              return (
                <BooleanField
                  record={updatedRecord}
                  source='gets_nomnc'
                  headerClassName={classes.listItemLabel}
                  style={{
                    color: updatedRecord.gets_nomnc ? '#44C87A' : '#5C738E',
                  }}
                />
              );
            }}
          />
          <FunctionField
            source='status'
            label=''
            sortable={false}
            render={record => {
              return isAdmin || isAdminAssistant ? (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setContactRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={contactRecord}
          form='facilityContacts'
          isEdit
          refresh={refresh}
          payerFacilityId={id}
        />
      )}
    </div>
  );
};
