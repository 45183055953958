import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';

export const ConfirmedActionModal = ({
  text,
  confirmButtonText,
  open,
  onClose,
  onConfirm,
  maxWidth = 'xs',
  disabled,
}) => {
  const confirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullWidth
      onClose={reason => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogContent>
        <Typography variant='body1' style={{ margin: '30px 0' }}>
          {text}
        </Typography>
        <DialogActions>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={confirm}
            disabled={disabled}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
