import React, { useState, forwardRef, useCallback } from 'react';
import clsx from 'clsx';
import Avatar from 'react-avatar';

import { MenuItemLink, useLogout, useTranslate } from 'react-admin';
import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Divider,
} from '@material-ui/core';
import { getInitials } from '../../utils';
import { useStyles } from './appbar.styles';
import { useGetUserRoles } from '../../hooks';

const AddFacilityMenu = forwardRef(({ onClick, setAddFacilityModal }, ref) => {
  const classes = useStyles();
  const handleClick = useCallback(
    e => {
      setAddFacilityModal(true);
      onClick(e);
    },
    [onClick, setAddFacilityModal],
  );
  return (
    <MenuItem className={classes.menuItem} onClick={handleClick} ref={ref}>
      Create new facility
    </MenuItem>
  );
});

const BulkReportMenu = forwardRef(({ onClick, setBulkReportModal }, ref) => {
  const classes = useStyles();
  const handleClick = useCallback(
    e => {
      setBulkReportModal(true);
      onClick(e);
    },
    [onClick, setBulkReportModal],
  );
  return (
    <MenuItem className={classes.menuItem} onClick={handleClick} ref={ref}>
      Reports
    </MenuItem>
  );
});

const AutoTaskSettingsMenu = forwardRef(
  ({ onClick, setAutoTaskSettingsModal }, ref) => {
    const classes = useStyles();
    const handleClick = useCallback(
      e => {
        setAutoTaskSettingsModal(true);
        onClick(e);
      },
      [onClick, setAutoTaskSettingsModal],
    );
    return (
      <MenuItem className={classes.menuItem} onClick={handleClick} ref={ref}>
        Task settings
      </MenuItem>
    );
  },
);

const RoleDescriptionsMenu = forwardRef(
  ({ onClick, setRoleDescriptionsModal }, ref) => {
    const classes = useStyles();
    const handleClick = useCallback(
      e => {
        setRoleDescriptionsModal(true);
        onClick(e);
      },
      [onClick, setRoleDescriptionsModal],
    );
    return (
      <MenuItem className={classes.menuItem} onClick={handleClick} ref={ref}>
        Role descriptions
      </MenuItem>
    );
  },
);

const LogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const translate = useTranslate();
  const classes = useStyles();
  const handleClick = () => logout();
  return (
    <MenuItem
      className={clsx('logout', classes.menuItem)}
      onClick={handleClick}
      ref={ref}
    >
      {translate('ra.auth.logout')}
    </MenuItem>
  );
});

export const UserMenu = ({
  classes,
  profile,
  is_mltc,
  is_subacute,
  setAddFacilityModal,
  setBulkReportModal,
  setAutoTaskSettingsModal,
  setRoleDescriptionsModal,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    is_admin,
    is_supervisor,
    is_view_admin,
    is_admin_assistant,
    is_contract_user,
  } = useGetUserRoles();
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title='Profile'>
        <IconButton
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <Avatar
            className={classes.avatar}
            name={profile.name}
            initials={getInitials}
            maxInitials={1}
            fgColor='#FFFFFF' /*** This will be changed.  */
            color={
              is_mltc && is_subacute
                ? '#5D79D0'
                : is_mltc
                ? '#66afdb'
                : is_subacute
                ? '#673BB8'
                : undefined
            }
            size='24'
            textSizeRatio={3}
            round
          />
        </IconButton>
      </Tooltip>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        {(is_admin || is_supervisor || is_view_admin || is_admin_assistant) && (
          <MenuItemLink
            to='/users'
            primaryText='Users'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        {(is_admin ||
          is_view_admin ||
          is_admin_assistant ||
          is_contract_user) && (
          <MenuItemLink
            to='/global-payers'
            primaryText='Global payers'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        {is_admin && (
          <MenuItemLink
            to='/categories'
            primaryText='Categories'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        {(is_admin ||
          is_view_admin ||
          is_admin_assistant ||
          is_contract_user) && (
          <MenuItemLink
            to='/services'
            primaryText='Services'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        {(is_admin ||
          is_view_admin ||
          is_admin_assistant ||
          is_contract_user) && (
          <MenuItemLink
            to='/connections'
            primaryText='Connections'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}

        {(is_admin || is_view_admin || is_admin_assistant) && (
          <MenuItemLink
            to='/user-coverage'
            primaryText='User coverage'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        <MenuItemLink
          to='/change-password'
          primaryText='Change password'
          sidebarIsOpen
          onClick={handleClose}
        />
        {(is_admin || is_view_admin || is_admin_assistant) && (
          <RoleDescriptionsMenu
            setRoleDescriptionsModal={setRoleDescriptionsModal}
            onClick={handleClose}
          />
        )}
        {(is_admin || is_admin_assistant) && (
          <AddFacilityMenu
            setAddFacilityModal={setAddFacilityModal}
            onClick={handleClose}
          />
        )}
        {(is_admin || is_view_admin || is_admin_assistant) && (
          <MenuItemLink
            to='/facility-groups'
            primaryText='Facility groupings'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        {(is_admin || is_supervisor || is_view_admin || is_admin_assistant) && (
          <MenuItemLink
            to='/merge-residents'
            primaryText='Merge residents'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        {is_admin && (
          <MenuItemLink
            to='/communication-templates'
            primaryText='Communication templates'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        {(is_admin || is_supervisor) && (
          <AutoTaskSettingsMenu
            setAutoTaskSettingsModal={setAutoTaskSettingsModal}
            onClick={handleClose}
          />
        )}
        {(is_admin || is_supervisor || is_view_admin || is_admin_assistant) && (
          <BulkReportMenu
            setBulkReportModal={setBulkReportModal}
            onClick={handleClose}
          />
        )}

        {is_admin && (
          <MenuItemLink
            to='/admin-reports-builder'
            primaryText='Custom Report Builder'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        <MenuItemLink
          to='/admin-reports'
          primaryText='Global Reports'
          sidebarIsOpen
          onClick={handleClose}
        />
        {is_admin && (
          <MenuItemLink
            to='/task-builder'
            primaryText='Task Builder'
            sidebarIsOpen
            onClick={handleClose}
          />
        )}
        <Divider style={{ margin: 5, backgroundColor: '#C5DAF1' }} />
        <LogoutButton />
      </Menu>
    </div>
  );
};
