import { useLocation } from 'react-router-dom';

export function useGetCaseId() {
  const location = useLocation();
  const { pathname } = location;
  const routeArr = pathname.split('/');
  const routeId = routeArr[2];
  return routeArr[1] === 'cases' && routeId && !isNaN(routeId)
    ? parseInt(routeId)
    : undefined;
}
