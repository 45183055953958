import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { useNotify } from 'react-admin';
import uniq from 'lodash/uniq';
import { Button, IconButton } from '@material-ui/core';
import { authGet } from '../../../../../server';
import { CloseIcon, SearchIcon } from '../../../../../design';
import { CheckEligibility } from '../checkEligibility';

export const EligibilitySeeker = ({
  params: {
    first_name,
    last_name,
    dob,
    gender,
    insurance,
    residents_insurance_id,
  },
  classes,
}) => {
  const [showEligibility, setShowEligibility] = useState(false);
  const [seekPayer, setSeekPayer] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    async function checkSeekPayer() {
      const response = await authGet([
        `seek-payer-codes`,
        { payer_id: insurance },
      ]);
      if (!response.error) {
        setSeekPayer(response.data);
      }
    }
    setShowEligibility(false);
    checkSeekPayer();
  }, [insurance]);

  const params = {
    first_name,
    last_name,
    dob,
    gender,
    insurance,
    residents_insurance_id,
  };

  const tryShowEligibility = () => {
    const userReadableFieldMap = {
      first_name: 'name',
      last_name: 'name',
      dob: 'date of birth',
      gender: 'gender',
      insurance: 'payer',
      residents_insurance_id: 'member ID',
    };
    const emptyFields = Object.entries(params)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => userReadableFieldMap[key]);

    if (emptyFields.length) {
      const fieldList = uniq(emptyFields).reduce((text, value, i, array) => {
        const lastIndex = array.length - 1;
        const separator = i < lastIndex ? ', ' : ', and ';
        return text + separator + value;
      });

      notify(
        `Missing some required information. Please include ${fieldList}.`,
        'warning',
      );
    } else {
      setShowEligibility(true);
    }
  };

  if (!seekPayer) {
    return null;
  } else if (!showEligibility) {
    return (
      <div style={{ paddingTop: 20 }}>
        <Button onClick={tryShowEligibility}>
          <SearchIcon className={clsx(classes.leftIcon, classes.icon)} />
          Check Eligibility
        </Button>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div style={{ width: '100%', textAlign: 'right' }}>
          <IconButton onClick={() => setShowEligibility(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <CheckEligibility params={params} />
      </Fragment>
    );
  }
};
