import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  useDataProvider,
  useNotify,
  useRefresh,
  required,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent, Grid } from '@material-ui/core';
import { FormTextField, handleNumbers, getDateInputValue } from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { format, startOfWeek, addDays } from 'date-fns';
import { authGet } from '../../../../server';
export const TherapyForm = ({ caseId, record = {}, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    case_id: caseId,
  });

  useEffect(() => {
    if (isEdit && record) {
      setFormData(f => ({
        ...record,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('therapy', {
          id: record.id,
          data: { ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('therapy', {
          data: { ...value },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const weekDays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });

    if (name === 'week_picker') {
      const response = await authGet([
        `/check-therapy`,
        { case_id: formData.case_id, date: value },
      ]);

      if (!response.error) {
        const date = getDateInputValue(value);
        const newDate = format(
          startOfWeek(new Date(`${date} 00:00`)),
          'yyyy-MM-dd',
        );
        value = newDate;

        weekDays.forEach((day, i) => {
          const newName = `${day}_date`;
          setFormData(f => ({
            ...f,
            week_picker: value,
            [newName]: format(addDays(new Date(newDate), +i + 1), 'EEE, MMM d'),
          }));
        });
      } else {
        weekDays.forEach(day => {
          const newName = `${day}_date`;
          setFormData(f => ({
            ...f,
            [newName]: '',
          }));
        });
        notify(
          typeof response.error === 'string'
            ? response.error
            : response.error.message || 'ra.notification.http_error',
          'warning',
        );
      }
    }
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  {!!!isEdit && (
                    <FormTextField
                      name='week_picker'
                      customOnChange={customOnChange}
                      type='date'
                      label='Set week starting'
                      validate={required()}
                      required
                    />
                  )}
                  {/* Needed for spacing */}
                  <div style={{ flex: '1 0 160px' }} />
                </div>
                <div className={clsx(classes.therapyContainerWrap)}>
                  {!!!isEdit ? (
                    weekDays.map(day => {
                      return (
                        <Fragment>
                          <Grid container direction='row'>
                            <Grid item xs={3}>
                              <FormTextField
                                name={`${day}_date`}
                                customOnChange={customOnChange}
                                label='Date'
                                className={classes.inputCell}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FormTextField
                                name={`${day}_ot_minutes`}
                                customOnChange={customOnChange}
                                label='OT minutes'
                                className={classes.inputCell}
                                type='number'
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FormTextField
                                name={`${day}_pt_minutes`}
                                customOnChange={customOnChange}
                                label='PT minutes'
                                className={classes.inputCell}
                                type='number'
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <FormTextField
                                name={`${day}_slp_minutes`}
                                customOnChange={customOnChange}
                                label='SLP minutes'
                                className={classes.inputCell}
                                type='number'
                              />
                            </Grid>
                          </Grid>
                        </Fragment>
                      );
                    })
                  ) : (
                    <Fragment>
                      <Grid container direction='row'>
                        <Grid item xs={3}>
                          <FormTextField
                            name='date'
                            customOnChange={customOnChange}
                            label='Date'
                            value={record}
                            className={classes.inputCell}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormTextField
                            name='ot_minutes'
                            customOnChange={customOnChange}
                            label='OT minutes'
                            className={classes.inputCell}
                            type='number'
                            value={record}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormTextField
                            name='pt_minutes'
                            customOnChange={customOnChange}
                            label='PT minutes'
                            className={classes.inputCell}
                            type='number'
                            value={record}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormTextField
                            name='slp_minutes'
                            customOnChange={customOnChange}
                            label='SLP minutes'
                            className={classes.inputCell}
                            type='number'
                            value={record}
                          />
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </div>
              </DialogContent>

              <DialogActions>
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
