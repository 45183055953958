import { useSelector } from 'react-redux';

export function useGetUserDivision(showBoth = true) {
  const is_subacute = !!useSelector(state => state.user.profile.is_subacute);
  const is_mltc = !!useSelector(state => state.user.profile.is_mltc);
  if (!showBoth) {
    return 'subacute';
  }
  if (is_mltc && is_subacute) {
    return 'all';
  }
  if (is_mltc) {
    return 'mltc';
  } else {
    return 'subacute';
  }
}
