import React, { Fragment, useEffect, useState } from 'react';
import qs from 'query-string';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  useRefresh,
  useRedirect,
  // NumberField,
  // ChipField,
  Pagination,
  ExportButton,
  downloadCSV,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Tooltip, Button, Link, IconButton } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, startOfMonth, subMonths, lastDayOfMonth } from 'date-fns';
import { CustomButton, AddIcon, EditIcon, InfoIcon } from '../../design';
import { useStyles } from './progress.styles';
import { NewEvent } from '../../components/common/modals';
import { DateField } from '../../components/common';
import jsonExport from 'jsonexport/dist';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      id,
      case_id,
      authorization,
      slp_approved,
      ot_approved,
      pt_approved,
      auth_rate,
      auth_rate_type,
      threshold,
      ...rest
    } = field;
    return { ...rest };
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'auth_id',
        'authorization_type',
        'payers_name',
        'date',
        'ot_minutes',
        'pt_minutes',
        'slp_minutes',
        'total',
        'weekly_total',
      ],
      rename: [
        'Auth #',
        'Level',
        'Primary Payer',
        'Date',
        'OT Minutes',
        'PT Minutes',
        'SLP Minutes',
        'Total Daily Minutes',
        'Weekly Total by Auth',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `therapy-minutes-${fields[0].case_id}-${format(
          new Date(),
          'MM/dd/yyyy',
        )}`,
      );
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  loading,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  activeStatusFilters = {},
  filterActions,
  caseId,
  isViewAdmin,
  ...rest
}) => {
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const classes = useStyles();
  return (
    <TopToolbar>
      <div>
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Add'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
          disabled={isViewAdmin}
        />
      </div>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='therapyForm'
          refresh={refresh}
        />
      )}
    </TopToolbar>
  );
};
const FiltersView = ({ classes, month, setMonth, ...props }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ marginRight: 25 }}>
        <DatePicker
          InputProps={{
            disableUnderline: true,
          }}
          margin='dense'
          views={['year', 'month']}
          label='Select a month'
          openTo='month'
          value={month}
          onChange={setMonth}
          required
          autoOk
          style={{ marginRight: 25, width: 171 }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};
export function TherapyList({ staticContext, isViewAdmin, ..._props }) {
  const { formBasePath, caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  const [therapyRecord, setTherapyRecord] = useState();
  const redirect = useRedirect();
  const [month, setMonth] = useState(startOfMonth(subMonths(new Date(), 0)));
  useEffect(() => {
    // see comment on case_communication
    if (therapyRecord) {
      setEventModal(true);
    }
  }, [therapyRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setTherapyRecord(undefined);
  };

  const getAuths = (caseId, id) => {
    redirect(
      `/cases/${caseId}/timeline?${qs.stringify({
        event_id: id,
      })}`,
    );
  };

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        filters={
          <FiltersView classes={classes} month={month} setMonth={setMonth} />
        }
        exporter={exporter}
        bulkActionButtons={false}
        className={classes.list}
        actions={<ListActions caseId={caseId} isViewAdmin={isViewAdmin} />}
        filter={{
          start_date: format(startOfMonth(month), 'yyyy-MM-dd'),
          end_date: format(lastDayOfMonth(month), 'yyyy-MM-dd'),
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <FunctionField
            sortable={false}
            label='Auth number'
            headerClassName={classes.noWrap}
            render={record => {
              const { auth_id, authorization } = record;
              if (!caseId || !authorization) {
                return null;
              }
              return (
                <Button
                  className={classes.referenceBtn}
                  color='primary'
                  onClick={() => getAuths(caseId, authorization.id)}
                  component={Link}
                >
                  {auth_id}
                </Button>
              );
            }}
          />

          <TextField
            sortable={false}
            label='Level'
            source='authorization_type'
          />
          <TextField
            sortable={false}
            label='Primary payer'
            source='payers_name'
          />
          <DateField sortable={false} label='Date' source='date' />
          <FunctionField
            sortable={false}
            source='ot_minutes'
            label='OT minutes'
            render={record => {
              const { ot_minutes, ot_approved, ot_message, ot_type } = record;
              return (
                <div className={classes.infoContainer}>
                  <span style={{ marginRight: 3 }}>{ot_minutes}</span>
                  {ot_minutes > 0 &&
                    ot_type === 'daily' &&
                    (!!!ot_approved || ot_message) && (
                      <Tooltip
                        title={!!!ot_approved ? `OT not approved` : ot_message}
                        placement='right'
                      >
                        <InfoIcon
                          style={{ color: 'red', fontSize: 14 }}
                          className={classes.info}
                        />
                      </Tooltip>
                    )}
                </div>
              );
            }}
          />
          <FunctionField
            sortable={false}
            source='pt_minutes'
            label='PT minutes'
            render={record => {
              const { pt_minutes, pt_approved, pt_message, pt_type } = record;
              return (
                <div className={classes.infoContainer}>
                  <span style={{ marginRight: 3 }}>{pt_minutes}</span>
                  {pt_minutes > 0 &&
                    pt_type === 'daily' &&
                    (!!!pt_approved || pt_message) && (
                      <Tooltip
                        title={!!!pt_approved ? `PT not approved` : pt_message}
                        placement='right'
                      >
                        <InfoIcon
                          style={{ color: 'red', fontSize: 14 }}
                          className={classes.info}
                        />
                      </Tooltip>
                    )}
                </div>
              );
            }}
          />
          <FunctionField
            sortable={false}
            source='slp_minutes'
            label='SLP minutes'
            render={record => {
              const {
                slp_minutes,
                slp_approved,
                slp_message,
                slp_type,
              } = record;
              return (
                <div className={classes.infoContainer}>
                  <span style={{ marginRight: 3 }}>{slp_minutes}</span>
                  {slp_minutes > 0 &&
                    slp_type === 'daily' &&
                    (!!!slp_approved || slp_message) && (
                      <Tooltip
                        title={
                          !!!slp_approved ? `SLP not approved` : slp_message
                        }
                        placement='right'
                      >
                        <InfoIcon
                          style={{ color: 'red', fontSize: 14 }}
                          className={classes.info}
                        />
                      </Tooltip>
                    )}
                </div>
              );
            }}
          />

          <FunctionField
            sortable={false}
            source='total'
            label='Total daily minutes'
            render={record => {
              const { total, message, auth_rate_type } = record;
              return (
                <div className={classes.infoContainer}>
                  <span style={{ marginRight: 3 }}>{total}</span>
                  {(auth_rate_type === 'daily' ||
                    auth_rate_type === 'none' ||
                    auth_rate_type === '') &&
                    message && (
                      <Tooltip
                        title={
                          <div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
                            {message}
                          </div>
                        }
                        placement='right'
                      >
                        <InfoIcon
                          style={{ color: 'red' }}
                          className={classes.info}
                        />
                      </Tooltip>
                    )}
                </div>
              );
            }}
          />

          <FunctionField
            sortable={false}
            source='weekly_total'
            label='Weekly total by auth'
            render={record => {
              const { weekly_total, message, auth_rate_type } = record;
              return (
                <div className={classes.infoContainer}>
                  <span style={{ marginRight: 3 }}>
                    {weekly_total !== 0 ? weekly_total : null}
                  </span>
                  {auth_rate_type === 'weekly' &&
                    weekly_total !== 0 &&
                    message && (
                      <Tooltip
                        title={
                          <div style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
                            {message}
                          </div>
                        }
                        placement='right'
                      >
                        <InfoIcon className={classes.info} />
                      </Tooltip>
                    )}
                </div>
              );
            }}
          />

          <FunctionField
            source=''
            label=''
            render={record => {
              return (
                <IconButton
                  className={classes.showBtn}
                  size='small'
                  onClick={() => setTherapyRecord(record)}
                  disabled={isViewAdmin}
                >
                  <EditIcon />
                </IconButton>
              );
            }}
          />
        </Datagrid>
      </List>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          form='therapyForm'
          refresh={refresh}
          record={therapyRecord}
          isEdit
        />
      )}
    </Fragment>
  );
}

export const therapyResource = {
  name: 'therapy',
  list: TherapyList,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-therapy',
        hasList: true,
        hasEdit: !!therapyResource.edit,
        hasShow: !!therapyResource.show,
        hasCreate: !!therapyResource.create,
      };
  const updatedProps = {
    ...props,
    basePath,
    caseId,
    ...resource,
  };
  return updatedProps;
}
