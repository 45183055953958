import { makeStyles } from '@material-ui/core';
import { style } from '../../resources/residents.styles';

export const useStyles = makeStyles(theme => ({
  ...style(theme),
  tabContainer: {
    minWidth: '100%',
    marginTop: 20,
  },
  toolbarContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 24,
  },
  topContent: {
    width: 400,
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));
