import React, { Fragment, useState } from 'react';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  Pagination,
  Filter,
  DateInput,
  FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';
import { DateField, Datagrid, ToggleButton } from '../../components/common';
import { useGetUserDivision } from '../../hooks';
import { format, startOfMonth, subMonths, lastDayOfMonth } from 'date-fns';
import { useStyles } from './reports.styles';
import { useSidebarOpen } from '../../hooks';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const {
      case_name = field['v_case.case_name'],
      payers_name = field['v_case.payers_name'],
      insurance_auth_number = field['v_case.vCaseAuths.insurance_auth_number'],
      date,
      ot_minutes,
      pt_minutes,
      slp_minutes,
      total_daily_minutes,
      level = field[
        'v_case.vCaseAuths.cases_payer.rates.authorization_type.type'
      ],
      level_details = field[
        'v_case.vCaseAuths.cases_payer.rates.level_details'
      ],
      next_level_details,
      next_level,
    } = field;

    return {
      case_name,
      payers_name,
      insurance_auth_number,
      date,
      ot_minutes,
      pt_minutes,
      slp_minutes,
      total_daily_minutes,
      level,
      level_details,
      next_level,
      next_level_details,
    };
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'case_name',
        'payers_name',
        'insurance_auth_number',
        'date',
        'ot_minutes',
        'pt_minutes',
        'slp_minutes',
        'total_daily_minutes',
        'level',
        'level_details',
        'next_level',
        'next_level_details',
      ],
      rename: [
        'Case Name',
        'Payer',
        'Auth #',
        'Date',
        'OT Minutes',
        'PT Minutes',
        'SLP Minutes',
        'Total Daily Minutes',
        'Current Level',
        'Level Details',
        'Next Level',
        'Next Level Details',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, `therapy-report-${format(new Date(), 'MM/dd/yyyy')}`);
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  classes,
}) => {
  return (
    <TopToolbar
      className={clsx(
        classes.listActionWrapper,
        classes.flexWrap,
        classes.tabsActionWrapper,
      )}
      style={{ paddingTop: 19 }}
    >
      <ToggleButton setDivision={setDivision} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='From'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
      />
      <DateInput
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
      />
    </Filter>
  );
};

const ReportListView = ({ formBasePath, ...props }) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  return (
    <div className={classes.listContainer}>
      <Datagrid {...props}>
        <FunctionField
          source='case_name'
          label='Case name'
          sortBy='last_name'
          render={record => {
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${record['v_case.id']}/timeline`}
              >
                {record['v_case.case_name']}
              </Button>
            );
          }}
        />
        <TextField
          source='v_case.vCaseAuths.insurance_auth_number'
          label='Auth #'
        />
        <TextField source='v_case.payers_name' label='Payer' />
        <DateField source='date' label='Date' />
        <TextField source='ot_minutes' label='OT minutes' />
        <TextField source='pt_minutes' label='PT minutes' />
        <TextField source='slp_minutes' label='SLP minutes' />
        <TextField source='total_daily_minutes' label='Total daily minutes' />
        <TextField
          source='v_case.vCaseAuths.cases_payer.rates.authorization_type.type'
          label='Current level'
        />
        <TextField
          source='v_case.vCaseAuths.cases_payer.rates.level_details'
          label='Level details'
        />
        <TextField source='next_level' label='Next level' />
        <TextField source='next_level_details' label='Next level details' />
      </Datagrid>
    </div>
  );
};

export const TherapyReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(
    userDivision && userDivision !== 'all' ? userDivision : 'subacute',
  );

  return (
    <Fragment>
      <div className={classes.title}>Therapy report</div>

      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions setDivision={setDivision} classes={classes} />}
        exporter={exporter}
        sort={{ field: 'case_id', order: 'ASC' }}
        filter={{ division }}
        filters={<FiltersView classes={classes} />}
        filterDefaultValues={{
          start_date: format(
            startOfMonth(subMonths(new Date(), 0)),
            'yyyy-MM-dd',
          ),
          end_date: format(
            lastDayOfMonth(subMonths(new Date(), 0)),
            'yyyy-MM-dd',
          ),
        }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
        <ReportListView formBasePath={props} />
      </List>
    </Fragment>
  );
};

export const therapyReportResource = {
  name: 'therapy-report',
  list: TherapyReportList,
};

function getResourceProps(props) {
  const basePath = '/therapy-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/therapy-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!therapyReportResource.show,
        hasCreate: !!therapyReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
