import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import {
  makeStyles,
  TextField,
  MenuItem,
  Paper,
  Popper,
} from '@material-ui/core';
import { getChip } from '../../utils';
import { ArrowDropDownIcon } from '../../design';
function renderInput(inputProps) {
  const {
    InputProps,
    classes,
    ref,
    onChange,
    autoFocus,
    isAppBar,
    ...other
  } = inputProps;
  return (
    <TextField
      variant='outlined'
      autoFocus={autoFocus}
      InputProps={{
        inputRef: ref,
        onChange,
        classes: {
          root: classes.root ? classes.root : undefined,
          input: classes.input ? classes.input : undefined,
          focused: classes.cssFocused ? classes.cssFocused : undefined,
          notchedOutline: isAppBar
            ? classes.notchedOutline || undefined
            : undefined,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    highlightItems,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    parentOnClick,
    getSelectedChoice,
    displayListItem,
    getName,
    classes,
    isResidentSearch,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.id) > -1;
  const { onClick, ...rest } = itemProps;
  const handleClick = e => {
    if (getName) {
      parentOnClick([{ value: suggestion.id, name: suggestion.name }]);
    } else {
      typeof parentOnClick === 'function' && parentOnClick(suggestion.id);
    }
    typeof getSelectedChoice === 'function' && getSelectedChoice(suggestion);
    onClick(e);
  };
  return (
    <MenuItem
      {...rest}
      onClick={handleClick}
      key={suggestion.id}
      selected={
        highlightItems ? highlightItems.includes(suggestion.id) : isHighlighted
      }
      component='div'
      style={{
        fontWeight: isSelected ? 400 : 300,
        minHeight: 33,
      }}
    >
      {displayListItem ? (
        displayListItem(suggestion)
      ) : suggestion.name === '' ? (
        ''
      ) : isResidentSearch && suggestion.status ? (
        <span>
          <span>
            {suggestion.name}
            {'   '}
          </span>
          <span className={classes[getChip(suggestion.status)]}>
            {suggestion.status}
          </span>
        </span>
      ) : (
        suggestion.name
      )}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  notchedOutline: { border: 'none' },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: 350,
    overflowY: 'auto',
    color: '#000000',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    color: '#1061A0',
    backgroundColor: 'rgba(240, 244,  250)',
  },
  divider: {
    height: theme.spacing(2),
  },
  popper: {
    zIndex: theme.zIndex.modal + 1,
  },
  popperDisablePortal: {
    position: 'absolute',
  },
  active: {
    backgroundColor: 'rgba(238, 248,  242)',
    color: '#44C87A',
    fontWeight: 300,
  },
  approved: {
    backgroundColor: 'rgba(238, 248,  242)',
    color: '#44C87A',
    fontWeight: 300,
  },
  mltcSidebar: {
    backgroundColor: '#66afdb',
    color: '#FFFFFF',
    fontWeight: 300,
  },
  closed: {
    backgroundColor: 'rgba(252, 246, 215)',
    color: '#F6CF16',
    fontWeight: 300,
  },
  denial: {
    backgroundColor: 'rgba(249, 233, 234)',
    color: '#E32939',
    fontWeight: 300,
  },
  discharged: {
    backgroundColor: 'rgba(250, 237, 245)',
    color: '#F14FA0',
    fontWeight: 300,
  },
  pending: {
    backgroundColor: 'rgba(251, 240,  232)',
    color: '#ED731F',
    fontWeight: 300,
  },
}));

export const Autocomplete = ({
  modalRef,
  suggestions: _suggestions = [],
  label,
  placeholder,
  onChange,
  parentOnClick,
  getSelectedChoice,
  getName,
  multiSelect,
  clearable,
  otherInputProps = {},
  initialInputValue,
  paperClassName,
  classes: classesOverrides = {},
  openOnFocus,
  autoFocus,
  displayListItem,
  fullWidth = true,
  clearInputOnClick,
  disabled,
  isFacilitySearch,
  isAppBar,
  isResidentSearch,
  highlightItems,
  initialSelectedItem,
  onInputValueChange,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const suggestions = clearable
    ? [{ id: null, name: '' }, ..._suggestions]
    : _suggestions;
  return (
    <div className={classes.root}>
      <Downshift
        initialSelectedItem={initialSelectedItem}
        onInputValueChange={onInputValueChange}
        initialInputValue={initialInputValue}
        stateReducer={
          multiSelect
            ? multiSelectStateReducer
            : isResidentSearch
            ? stateReducer
            : defaultStateReducer
        }
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          openMenu,
          clearSelection,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: placeholder || '',
            onChange: e => {
              if (e.target.value === '') {
                clearSelection();
              }
            },
          });
          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth,
                autoFocus,
                classes: { ...classes, ...classesOverrides },
                label: label || '',
                InputLabelProps: getLabelProps(),
                InputProps: {
                  onBlur,
                  onFocus: openOnFocus ? openMenu : onFocus,
                  ref: setAnchorEl,
                },
                inputProps,
                ...otherInputProps,
                onChange,
                disabled,
                isAppBar,
              })}
              {isFacilitySearch && (
                <span className={classes.container} {...getToggleButtonProps()}>
                  <ArrowDropDownIcon
                    style={{
                      marginLeft: -30,
                      marginTop: 5,
                      color: 'white',
                    }}
                  />
                </span>
              )}

              <div {...getMenuProps()}>
                <Popper
                  open={isOpen && !!anchorEl}
                  className={clsx(classes.popper, classes.disablePortal)}
                  style={{
                    width: anchorEl ? anchorEl.clientWidth : null,
                  }}
                  role='presentation'
                  anchorEl={anchorEl}
                  disablePortal={isFacilitySearch}
                >
                  <Paper
                    className={clsx(classes.paper, paperClassName)}
                    ref={modalRef}
                  >
                    {suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: clearInputOnClick ? '' : suggestion.name,
                          value: suggestion.id,
                          index: clearInputOnClick && multiSelect ? -1 : index,
                        }),
                        highlightedIndex,
                        selectedItem,
                        parentOnClick,
                        getSelectedChoice,
                        displayListItem,
                        getName,
                        highlightItems,
                        classes,
                        isResidentSearch,
                      }),
                    )}
                  </Paper>
                </Popper>
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

function multiSelectStateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        isOpen: true,
      };
    default:
      return changes;
  }
}

function stateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.mouseUp:
      return {
        ...changes,
        isOpen: true,
      };
    default:
      return changes;
  }
}
function defaultStateReducer(state, changes) {
  return changes;
}
