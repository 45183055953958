import { authGet, authPost } from '../../server';
export const userTypes = {
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  CLEAR_USER_PROFILE: 'CLEAR_USER_PROFILE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
};

export const userActions = {
  getUserProfile(profile) {
    return async dispatch => {
      const response = await authGet('/users/profile');
      if (response.error) {
        return null;
      }

      if (window.__insp) window.__insp.push(['tagSession', response.data]);

      dispatch({
        type: userTypes.GET_USER_PROFILE,
        payload: response.data,
      });
    };
  },
  clearUserProfile() {
    return {
      type: userTypes.CLEAR_USER_PROFILE,
    };
  },
  refreshToken() {
    return async dispatch => {
      const response = await authPost('/refresh-token');
      if (response.error) {
        return null;
      }
      await dispatch({
        type: userTypes.REFRESH_TOKEN,
        payload: response.data,
      });
      dispatch(userActions.getUserProfile());
    };
  },
  logout() {
    return async dispatch => {
      authPost('/users/logout');
    };
  },
  checkUsersTime() {
    return async (dispatch, getState) => {
      const response = await authGet('/server-time');
      if (!response.data) {
        return null;
      }
      const internetTime = new Date(response.data).getTime();
      const currTime = new Date().getTime();
      if (Math.abs(internetTime - currTime) > 1000 * 60 * 2) {
        alert(
          'Seems like your computer clock is off. Please fix. If you already updated your clock and are still getting this message, you may need to refresh your browser',
        );
      }
    };
  },
};
