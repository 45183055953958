import React, { Fragment } from 'react';
import clsx from 'clsx';
import { createTheme, makeStyles, Button, Badge } from '@material-ui/core';
import red from '@material-ui/core/colors/red';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1061A0',
      text: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#5C738E',
      text: '#EFF4FB',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#00000',
      secondary: '#5C738E',
      fontWeight: 300,
      fontStyle: 'Nunito Sans',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },

  overrides: {
    MuiMenuItem: {
      root: {
        fontSize: 15,
        minHeight: 42,
        fontStyle: 'Nunito Sans',
        fontWeight: 300,
      },
    },
    MuiTableRow: {
      root: {
        fontStyle: 'Nunito Sans',
        fontWeight: 300,
        minHeight: 45,
        height: 50,
        maxHeight: 55,
        '&$hover:hover': {
          backgroundColor: '#EFF4FB',
        },
      },
    },

    MuiInputLabel: {
      root: {
        fontStyle: 'Nunito Sans',
        fontWeight: 300,
      },
    },
    RaDatagrid: {
      expanded: { backgroundColor: '#EFF4FB' },
      headerCell: {
        fontWeight: 600,
        fontStyle: 'Nunito Sans',
      },
    },
    RaShow: {
      card: {
        color: 'black',
        fontWeight: 300,
        fontStyle: 'Nunito Sans',
      },
    },

    RaLabeled: {
      label: {
        color: 'black',
        fontWeight: 600,
        fontStyle: 'Nunito Sans',
        fontSize: 16,
      },
    },

    RaLayout: {
      content: {
        backgroundColor: '#FFFFFF',
        marginTop: -16,
        marginLeft: -8,
        zIndex: 0,
        padding: '24px !important',
        paddingLeft: '32px !important',
      },
    },

    RaAppBar: {
      toolbar: {
        paddingLeft: 8,
      },
    },

    RaBulkActionsToolbar: {
      toolbar: { zIndex: 0 },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#EFF4FB',
          color: '#1061A0',
        },
        '&:Mui-focused': {
          backgroundColor: '#FFFFFF',
        },
        '&.Mui-selected': {
          backgroundColor: '#EFF4FB',
          color: '#1061A0',
        },
      },
    },

    MuiTypography: {
      body1: {
        fontWeight: 300,
        fontStyle: 'Nunito Sans',
      },
      body2: {
        fontWeight: 300,
        fontStyle: 'Nunito Sans',
      },
    },

    MuiButton: {
      text: {
        fontStyle: 'Nunito Sans',
      },
      root: {
        minWidth: '20px',
        minHeight: '30px',
      },
    },
    MuiOutlinedInput: {
      input: {
        fontStyle: 'Nunito Sans',
        fontWeight: 300,
      },
    },
  },
});

const useSaveButtonStyles = makeStyles(({ spacing }) => ({
  button: {
    backgroundColor: '#3B94D1',
    textTransform: 'capitalize',
    borderRadius: 2,
    textStyle: 'Nunito Sans',
  },
  leftIcon: {
    marginRight: spacing(2),
  },
  icon: {
    fontSize: 18,
  },
}));

export const SaveButton = ({ type, onClick, disabled, label, style }) => {
  const classes = useSaveButtonStyles();
  return (
    <Button
      disableElevation
      type={type}
      className={classes.button}
      variant='contained'
      color='primary'
      disabled={disabled}
      onClick={onClick || undefined}
      style={style}
      size='medium'
    >
      {`${label || 'Save'}`}
    </Button>
  );
};
const useCustomButtonStyles = makeStyles(({ spacing }) => ({
  button: {
    position: 'relative',
    backgroundColor: props => props.backgroundColor,
    color: props => props.color,
    '&:hover': {
      backgroundColor: props => props.backgroundColor,
      color: props => props.color,
    },
    textTransform: 'capitalize',
  },
  leftIcon: {
    marginRight: spacing(1),
  },
  icon: {
    fontSize: props => !props.fullSize && 18,
  },
  badge: {
    width: 20,
    minWidth: 20,
    height: 20,
    fontSize: 9,
    right: 10,
    backgroundColor: props => !props.notRed && '#E32939',
    color: '#FFFFFF',
    fontWeight: 300,
    zIndex: 0,
  },
}));

export const CustomButton = ({
  type,
  onClick,
  label,
  variant = 'contained',
  color,
  materialColor = 'primary',
  backgroundColor,
  Icon,
  size = 'medium',
  className,
  badgeContent,
  notRed,
  fullSize,
  ...rest
}) => {
  const classes = useCustomButtonStyles({
    backgroundColor,
    color,
    notRed,
    fullSize,
  });
  return (
    <Button
      type={type}
      className={clsx(classes.button, className)}
      variant={variant}
      color={materialColor}
      size={size}
      onClick={onClick || undefined}
      {...rest}
    >
      {Icon && (
        <Fragment>
          {badgeContent ? (
            <Badge
              color='secondary'
              badgeContent={badgeContent}
              classes={{ badge: classes.badge }}
            >
              <Icon className={clsx(classes.leftIcon, classes.icon)} />
            </Badge>
          ) : (
            <Icon className={clsx(classes.leftIcon, classes.icon)} />
          )}
        </Fragment>
      )}
      {label}
    </Button>
  );
};
