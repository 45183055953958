import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import React, { useState } from 'react';
import {
  FunctionField,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { DateField, TrackUser } from '../../components/common';
import { NotesModal } from '../../components/common/modals';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import { constants, limitStringSize } from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes } = constants;

export const CaseDenialShow = ({
  closeAside,
  setRefreshTimeline,
  classes,
  residentId,
  caseId,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record = {} } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            resourceRecord={record}
            eventForm='denialAppeal'
            title='Denials'
            eventId={record.id}
            eventType='Denial'
            eventName={eventTypes.DENIAL_NAME}
            setRefreshTimeline={setRefreshTimeline}
            caseId={caseId}
            related_to_data={{
              related_to_type: 'Denial',
              related_to_id: record.id,
              related_to_date: record.resolved_date || record.verbal_received,
            }}
            isViewAdmin={isViewAdmin}
            data={record}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='denial_type'
            label='Denial type'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='verbal_received'
            className={classes.showItem}
            label='Verbal received'
            textAlign='right'
          />
          <DateField
            source='denial_received'
            className={classes.showItem}
            label='Letter received'
            textAlign='right'
          />
          <DateField
            source='last_covered'
            className={classes.showItem}
            label='Last covered'
            textAlign='right'
          />
          <TextField
            source='post_lcd_option'
            label='Post LCD'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='v_authorization.insurance_auth_number'
            label='Auth number'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='signature_required'
            label='Signature required'
            className={classes.showItem}
            render={record => {
              const { signature_required } = record;
              return signature_required ? 'Yes' : 'No';
            }}
          />
          <FunctionField
            source='signature_today'
            label='Same day signature'
            className={classes.showItem}
            render={record => {
              const { signature_today } = record;
              return signature_today ? 'Yes' : 'No';
            }}
          />
          <DateField
            source='signature_required_by'
            className={classes.showItem}
            label='Signature required by'
            textAlign='right'
          />
          <DateField
            source='sent_to_facility'
            className={classes.showItem}
            label='Denial sent to facility'
            textAlign='right'
          />
          <DateField
            source='signed_denial_received'
            className={classes.showItem}
            label='Signed denial received'
            textAlign='right'
          />
          <DateField
            source='sent_to_insurance'
            className={classes.showItem}
            label='Signed denial sent to ins.'
            textAlign='right'
          />
          <TextField
            source='appeal_type_name_l1'
            label='Level 1 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l1'
            className={classes.showItem}
            label='Level 1 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l1'
            label='Level 1 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='appeal_type_name_l2'
            label='Level 2 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l2'
            className={classes.showItem}
            label='Level 2 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l2'
            label='Level 2 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='appeal_type_name_l3'
            label='Level 3 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l3'
            className={classes.showItem}
            label='Level 3 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l3'
            label='Level 3 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='appeal_type_name_l4'
            label='Level 4 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l4'
            className={classes.showItem}
            label='Level 4 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l4'
            label='Level 4 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='appeal_type_name_l5'
            label='Level 5 appeal'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='date_sent_l5'
            className={classes.showItem}
            label='Level 5 sent'
            textAlign='right'
          />
          <TextField
            source='appeal_status_l5'
            label='Level 5 status'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='resolution_name'
            label='Resolution'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='resolved_date'
            label='Resolved'
            className={classes.showItem}
            render={record =>
              !!record.resolved_date ? (
                <DateField
                  record={record}
                  source='resolved_date'
                  textAlign='right'
                  className={classes.funcShowItem}
                />
              ) : null
            }
          />
          {record && record.discharge_location ? (
            <FunctionField
              source='discharge_location'
              label='Location'
              className={classes.showItem}
              render={record => startCase(record.discharge_location)}
            />
          ) : (
            <div className={classes.showItem} />
          )}
          <FunctionField
            source='comment'
            label='Comments'
            textAlign='right'
            className={clsx(classes.showItem, classes.commentField)}
            render={record => {
              const { comment } = record;
              const truncated = limitStringSize(comment, maxNotesLength, true);
              return comment && comment.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <FunctionField
            source='vDenialCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { vDenialCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={vDenialCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='vDenialUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { vDenialUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={vDenialUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {/* <div className={classes.showItem} /> */}
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.comment}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            setRefreshTimeline={setRefreshTimeline}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};
