import React, {
  Fragment,
  cloneElement,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import clsx from 'clsx';
import qs from 'query-string';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { differenceInCalendarDays, isBefore } from 'date-fns';
import {
  useRefresh,
  List,
  Show,
  ShowView,
  SimpleShowLayout,
  BulkDeleteButton,
  TextField,
  FunctionField,
  BooleanField,
  ChipField,
  TopToolbar,
  ReferenceField,
  Pagination,
  Labeled,
  usePermissions,
  // ShowGuesser,
} from 'react-admin';
import {
  Drawer,
  IconButton,
  Chip,
  Divider,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { CustomButton } from '../design/material-ui';
import { getChip, constants, weekDaysObj, limitStringSize } from '../utils';
import {
  DateField,
  TrackUser,
  Datagrid,
  FilterMenu,
  FilterChips,
  CaseManagerSelect,
  StatusFilter,
} from '../components/common';
import {
  TaskForm,
  NewEvent,
  Attachments,
  NotesModal,
} from '../components/common/modals';
import { AddAttachmentModal } from '../components/common/modals/modalForms';
import { ResidentShowFields, ResidentShowActions } from '.';
import {
  useGetCaseId,
  useShowController,
  useGetUserDivision,
  useSidebarOpen,
} from '../hooks';
import { uiActions } from '../state/actions';
import { useStyles } from './auths.styles';
import {
  CloseIcon,
  AttachmentIcon,
  AncillaryIcon,
  AddIcon,
  EditIcon,
  AlertIcon,
  InfoIcon,
  AddAlertIcon,
} from '../design';
const { eventTypes } = constants;
const filters = [
  { id: 'part-b', name: 'Part B' },
  { id: 'pharmacy', name: 'Pharmacy' },
];

const FacilityLevelActions = ({
  setDivision,
  filterActions,
  activeStatusFilters = {},
  filterManager,
  loading,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex', paddingBottom: 20 }}>
            <span className={classes.title}>Ancillary Authorizations</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
          </div>
          <CustomButton
            Icon={AddIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Add'
            type='button'
            variant='text'
            size='small'
            onClick={() => setEventModal(true)}
            disabled={isViewAdmin}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <CaseManagerSelect filterManager={filterManager} />
          <FilterMenu filterActions={filterActions} filters={filters} />
          <FilterChips
            activeFilters={activeStatusFilters}
            filterActions={filterActions}
            disabled={loading}
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='authorization'
          refresh={refresh}
          meta={{ part_b: true }}
        />
      )}
    </Fragment>
  );
};

const CaseLevelActions = ({
  basePath,
  data = {},
  resource,
  showAll,
  setShowAll,
  residentCaseCount,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <span className={classes.title}>Auths</span>
          <div>
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
              disabled={isViewAdmin}
            />
          </div>
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 3 }}>
          {residentCaseCount > 1 && (
            <StatusFilter setShowAll={setShowAll} showAll={showAll} />
          )}
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='authorization'
          refresh={refresh}
          meta={{ part_b: true }}
        />
      )}
    </Fragment>
  );
};

const AuthsShowActions = ({
  basePath,
  data = {},
  title,
  resource,
  closeAside,
  resourceRecord,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const { is_retro, authorization_status: { status } = {} } = data || {};
  return (
    <div>
      <TopToolbar className={classes.header}>
        <div className={classes.headerRow}>
          <span className={classes.title}>{title}</span>{' '}
          <span className={classes.statusChipWrapper}>
            <Chip
              size='small'
              label={status}
              className={clsx(classes.chip, classes[getChip(status)])}
            />
            {is_retro ? (
              <span style={{ marginLeft: 2 }}>
                <Chip
                  size='small'
                  label='Retro Auth'
                  className={clsx(classes.chip, classes[getChip('Retro')])}
                />
              </span>
            ) : null}
          </span>
          <IconButton onClick={closeAside} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <ActionButtons
          resourceRecord={resourceRecord}
          isViewAdmin={isViewAdmin}
        />
      </TopToolbar>
    </div>
  );
};

const ActionButtons = ({ resourceRecord = {}, isViewAdmin }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [addTaskOpen, setAddTaskModal] = useState(false);
  const [eventOpen, setEventModal] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const taskCount = get(resourceRecord, 'v_event_task.task_count', 0);
  const hasAttachments =
    Array.isArray(resourceRecord.document_ids) &&
    !!resourceRecord.document_ids.length;
  const documentCount = resourceRecord?.document_ids?.length;
  return (
    <Fragment>
      <div className={classes.actionContainer}>
        <span style={{ contentAlign: 'left' }}>
          <IconButton
            className={classes.showBtn}
            onClick={() => setEventModal(true)}
            size='small'
            disabled={isViewAdmin}
          >
            <EditIcon />
          </IconButton>
          {'         '}
          <IconButton
            className={classes.showBtn}
            onClick={() => setAddTaskModal(true)}
            size='small'
            disabled={isViewAdmin}
          >
            <AddAlertIcon />
          </IconButton>
          {'         '}
          <CustomButton
            Icon={AttachmentIcon}
            className={classes.showBtn}
            variant='text'
            badgeContent={documentCount}
            onClick={() => setAttchModal(true)}
            size='small'
            notRed
            fullSize
          />
        </span>
        <CustomButton
          Icon={AlertIcon}
          color='#829CB5'
          label='TASKS'
          type='button'
          variant='text'
          size='small'
          className={classes.showBtn}
          badgeContent={taskCount}
          component={Link}
          disabled={!taskCount}
          to={`/cases/${
            resourceRecord.case_id
          }/tasks/not-completed?${qs.stringify({
            event_type: 'Auth',
            event_id: resourceRecord.id,
          })}`}
        />
      </div>
      {addTaskOpen && (
        <TaskForm
          open={addTaskOpen}
          handleClose={() => setAddTaskModal(false)}
          caseId={resourceRecord.case_id}
          refresh={refresh}
          related_to_data={{
            related_to_type: 'Auth',
            related_to_id: resourceRecord.id,
            related_to_date: resourceRecord.start_date,
            insurance_auth_number: resourceRecord.insurance_auth_number,
          }}
        />
      )}
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          record={resourceRecord}
          form='authorization'
          isEdit
          refresh={refresh}
          meta={{ part_b: true }}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={resourceRecord.document_ids}
              title='Ancillary Authorizations'
              eventId={resourceRecord.id}
              eventName={eventTypes.AUTHORIZATION_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Ancillary Authorizations'
              eventId={resourceRecord.id}
              eventName={eventTypes.AUTHORIZATION_NAME}
              caseId={resourceRecord.case_id}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const BulkActionButtons = ({ toggleAside, recordOpenId, ...props }) => {
  const { selectedIds = [] } = props;
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  function onClick() {
    if (
      typeof toggleAside === 'function' &&
      selectedIds.indexOf(recordOpenId) > -1
    ) {
      toggleAside(false);
    }
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }
  return <BulkDeleteButton {...props} onClick={onClick} />;
};

const ListView = ({
  rowClick,
  listRowStyle,
  division,
  rowRecord,
  ...props
}) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  return (
    <div className={classes.listContainer}>
      <Datagrid
        {...props}
        rowClick={rowClick}
        rowStyle={(record, index) => listRowStyle(record, index, rowRecord.id)}
      >
        <FunctionField
          source='insurance_auth_number'
          label='Number'
          render={record => {
            const { insurance_auth_number, ending_soon } = record;
            return (
              <div className={classes.infoContainer}>
                <span style={{ marginRight: 3 }}>{insurance_auth_number}</span>
                {ending_soon ? (
                  <Tooltip title='Ending soon' placement='right'>
                    <InfoIcon className={classes.info} />
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            );
          }}
        />
        <TextField source='authorization_type.type' label='Auth type' />
        <FunctionField
          source='case_name'
          label='Case name'
          sortBy='last_name'
          render={record => {
            const { case_name, case_id } = record;
            if (!case_name || !case_id) {
              return null;
            }
            return (
              <Button
                className={classes.referenceBtn}
                color='primary'
                onClick={e => e.stopPropagation()}
                component={Link}
                to={`/cases/${case_id}/timeline`}
              >
                {case_name}
              </Button>
            );
          }}
        />
        <TextField source='service' label='Service' />
        <FunctionField
          label='Status'
          render={record => {
            const { is_retro } = record;
            return (
              <ReferenceField
                source='authorization_status_id'
                reference='auths-status/list'
                link={false}
              >
                <FunctionField
                  source='name'
                  render={record => {
                    return (
                      <div style={{ width: 180 }}>
                        <ChipField
                          record={record}
                          source='name'
                          className={clsx(
                            classes.chip,
                            classes[getChip(record.name)],
                          )}
                        />
                        {is_retro ? (
                          <Chip
                            size='small'
                            label='Retro Auth'
                            className={clsx(
                              classes.chip,
                              classes[getChip('Retro')],
                            )}
                          />
                        ) : null}
                      </div>
                    );
                  }}
                />
              </ReferenceField>
            );
          }}
        />
        <DateField source='start_date' label='Start date' />
        <FunctionField
          source='end_date'
          label='End date'
          render={record => (
            <DateField
              record={record}
              source={record.actual_end_date ? 'actual_end_date' : 'end_date'}
              label='End date'
            />
          )}
        />
        <TextField source='payer_name' label='Payer' />
        <FunctionField
          label='Payer priority'
          render={record => {
            return record && record.is_primary !== null
              ? record.is_primary
                ? 'Primary'
                : 'Secondary'
              : null;
          }}
        />
        <FunctionField
          label='Attachments'
          render={record => {
            const { document_ids } = record;
            return Array.isArray(document_ids) && document_ids.length ? (
              <CustomButton
                Icon={AttachmentIcon}
                className={classes.attchListIcon}
                variant='text'
                badgeContent={document_ids.length}
                size='small'
                notRed
                fullSize
              />
            ) : null;
          }}
        />
      </Datagrid>
    </div>
  );
};

export const AncillaryAuthsList = ({ staticContext, ..._props }) => {
  const refresh = useRefresh();
  const userDivision = useGetUserDivision();
  const { caseId, ...props } = getResourceProps(_props);
  const { location: { search } = {} } = props;
  const facilityLevel = props.resource === 'ancillary-authorizations';
  const classes = useStyles();
  const [division, setDivision] = useState(userDivision);
  const [activeStatusFilters, setActiveStatusFilters] = useState();
  const [asideOpen, toggleAside] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [caseManager, setCaseManager] = useState();
  const [showAll, setShowAll] = useState(true);
  const firstRender = useRef(true);
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const { filters: filtersQuery, 'user-id': userId } = qs.parse(search);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;

  useEffect(() => {
    toggleAside(false);
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    refresh();
  }, [props.location.pathname, refresh]);

  useEffect(() => {
    if (Array.isArray(filtersQuery)) {
      filtersQuery.forEach(filterId => {
        const filterObj = filters.filter(f => f.id === filterId);
        setActiveStatusFilters(curFilters => ({ ...curFilters, ...filterObj }));
      });
    } else {
      setActiveStatusFilters({
        'part-b': 'Part B',
        pharmacy: 'Pharmacy',
      });
    }
    if (userId) {
      setCaseManager(userId);
    }
  }, [filtersQuery, userId]);

  const rowClick = (id, basePath, record) => {
    setRowRecord({
      ...record,
    });
    toggleAside(true);
    return null;
  };

  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId && asideOpen) {
      return { backgroundColor: '#EFF4FB' };
    } else if (caseId && parseInt(record?.case_id) !== parseInt(caseId)) {
      return { backgroundColor: '#EFF4FB', opacity: 0.5, fontStyle: 'italic' };
    }
  };

  const filterActions = useCallback(({ actionType, id, name }) => {
    if (actionType === 'removeFilter') {
      setActiveStatusFilters(currentFilters => {
        const { [id]: filterToRemove, ...otherFilters } = currentFilters;
        return otherFilters;
      });
    } else if (actionType === 'addFilter') {
      setActiveStatusFilters(currentFilters => ({
        ...currentFilters,
        [id]: name,
      }));
    }
  }, []);

  const filterManager = useCallback(value => {
    toggleAside(false);
    setCaseManager(value);
  }, []);

  const toggleShowAll = useCallback(value => {
    toggleAside(false);
    setShowAll(value);
  }, []);

  if (!activeStatusFilters) return null;
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={
          !isViewAdmin ? (
            <BulkActionButtons
              toggleAside={toggleAside}
              recordOpenId={rowRecord.id}
            />
          ) : (
            false
          )
        }
        className={clsx(classes.list, {
          [classes.listWithDrawer]: asideOpen,
        })}
        actions={
          facilityLevel ? (
            <FacilityLevelActions
              setDivision={setDivision}
              filterActions={filterActions}
              activeStatusFilters={activeStatusFilters}
              filterManager={filterManager}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <CaseLevelActions
              residentCaseCount={residentCaseCount}
              showAll={showAll}
              setShowAll={toggleShowAll}
              isViewAdmin={isViewAdmin}
            />
          )
        }
        filter={{
          division: facilityLevel ? division : undefined,
          filtered: Object.keys(activeStatusFilters),
          caseManagerId: caseManager,
          all: showAll,
          part_b_pharmacy: true,
        }}
        sort={{
          field: caseId ? 'default_case' : 'start_date',
          order: 'DESC',
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <ListView
          empty={false}
          rowClick={rowClick}
          listRowStyle={listRowStyle}
          rowRecord={rowRecord}
          division={division}
        />
      </List>
      <Drawer
        variant='persistent'
        open={asideOpen}
        anchor='right'
        elevation={16}
        PaperProps={{ square: true }}
        classes={{
          paperAnchorDockedRight: clsx(classes.aside),
        }}
      >
        {asideOpen
          ? cloneElement(<AuthShow />, {
              ...props,
              id: rowRecord.id,
              closeAside: () => toggleAside(false),
              resource: 'ancillary-authorizations',
              classes: classes,
              residentId: rowRecord.v_case.resident_id,
              isViewAdmin: isViewAdmin,
            })
          : null}
      </Drawer>
    </Fragment>
  );
};

export const AuthShow = ({
  closeAside,
  classes,
  residentId,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <AuthsShowActions
            closeAside={closeAside}
            title='Auth'
            resourceRecord={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='payer_name'
            label='Payer'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='insurance_auth_number'
            label='Auth number'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            label='Case manager'
            source='user.name'
            className={classes.showItem}
            textAlign='right'
          />
          <FunctionField
            source='is_mltc'
            label='Case type'
            className={classes.showItem}
            render={record => (record.is_mltc ? 'MLTC' : 'Subacute')}
          />
          <TextField
            source='authorization_type.type'
            label='Auth type'
            textAlign='right'
            className={classes.showItem}
          />
          {record?.authorization_type?.type === 'Level 1' && (
            <ReferenceField
              label='Reason for Level 1 auth'
              source='level_one_reason_id'
              reference='level-one-reasons/list'
              textAlign='right'
              className={classes.showItem}
              link={false}
            >
              <TextField
                source='name'
                textAlign='right'
                className={classes.showItem}
              />
            </ReferenceField>
          )}
          {record?.level_one_reason_id === 2 && (
            <ReferenceField
              label='Insufficient criteria details'
              source='insufficient_criteria_id'
              reference='insufficient-criteria-reasons/list'
              textAlign='right'
              className={classes.showItem}
              link={false}
            >
              <TextField
                source='name'
                textAlign='right'
                className={classes.showItem}
              />
            </ReferenceField>
          )}
          {record?.level_one_reason_id === 3 && (
            <ReferenceField
              label='Higher qualifying level'
              source='qualifies_for_level'
              reference='auth-types/list'
              textAlign='right'
              className={classes.showItem}
              link={false}
            >
              <TextField
                source='name'
                textAlign='right'
                className={classes.showItem}
              />
            </ReferenceField>
          )}
          {record?.authorization_type?.type === 'Level 1' && (
            <TextField
              source='level_one_reason'
              label='Level one reason details'
              textAlign='right'
              className={classes.showItem}
            />
          )}

          {record?.authorization_type?.type === 'Level 1' && (
            <FunctionField
              source='higher_level_requested'
              label='Higher level requested'
              textAlign='right'
              className={classes.showItem}
              render={record => {
                const updatedRecord = {
                  ...record,
                  higher_level_requested: !!record.higher_level_requested,
                };
                return (
                  <BooleanField
                    record={updatedRecord}
                    source='higher_level_requested'
                    style={{
                      color: updatedRecord.higher_level_requested
                        ? '#44C87A'
                        : '#5C738E',
                    }}
                  />
                );
              }}
            />
          )}

          {record?.authorization_type?.type === 'Level 1' &&
            record?.higher_level_requested === false && (
              <TextField
                source='no_request_reason'
                label='Reason higher level was not requested'
                textAlign='right'
                className={classes.showItem}
              />
            )}
          <TextField
            source='rev_code'
            label='Rev code'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='start_date'
            label='Start date'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='is_mltc'
            label='Days approved'
            className={classes.showItem}
            render={record => {
              const { start_date, end_date } = record;
              return (
                start_date &&
                end_date &&
                differenceInCalendarDays(
                  new Date(end_date),
                  new Date(start_date),
                ) + 1
              );
            }}
          />
          <DateField
            label='Initial end date'
            source='end_date'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            label='Actual end date'
            source='actual_end_date'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='is_mltc'
            label='Days available'
            className={classes.showItem}
            render={record => {
              const { end_date } = record;
              const now = new Date();
              return end_date && isBefore(now, new Date(end_date))
                ? differenceInCalendarDays(new Date(end_date), now)
                : 0;
            }}
          />
          <DateField
            source='letter_received'
            className={classes.showItem}
            label='Received date'
            textAlign='right'
          />
          <ReferenceField
            label='Letter status'
            source='auth_letter_status_id'
            reference='auth-letter-status/list'
            textAlign='right'
            className={classes.showItem}
            link={false}
          >
            <TextField
              source='name'
              textAlign='right'
              className={classes.showItem}
            />
          </ReferenceField>
          {record?.approval_chance !== null && (
            <FunctionField
              source='approval_chance'
              className={classes.showItem}
              label='Approval likelihood'
              textAlign='right'
              render={record => `${record.approval_chance}%`}
            />
          )}
          {record?.pending_reason !== null && (
            <TextField
              source='pending_reason'
              label='Pending reason'
              textAlign='right'
              className={classes.showItem}
            />
          )}
          {/* needed for spacing */}
          <div className={classes.showItem} />
          <FunctionField
            source='auth_notes'
            label='Notes'
            textAlign='right'
            className={clsx(classes.showItem, classes.showFullWidth)}
            render={record => {
              const { auth_notes } = record;
              const truncated = limitStringSize(
                auth_notes,
                maxNotesLength,
                true,
              );
              return auth_notes && auth_notes.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          {Array.isArray(record?.outpatient_services) &&
            !!record.outpatient_services.length && (
              <Fragment>
                <div className={classes.showSectionTitle}>
                  Outpatient services
                </div>
                {record.outpatient_services.map(os => {
                  const { id, service, minutes, frequency } = os;
                  return (
                    <div style={{ display: 'flex' }} key={id}>
                      <Labeled
                        label='Service'
                        margin='none'
                        className={classes.showItem}
                      >
                        <TextField
                          record={{ ...record, service }}
                          source='service'
                          className={classes.showItem}
                          label='Service'
                          textAlign='right'
                        />
                      </Labeled>
                      <Labeled
                        label='Frequency'
                        margin='none'
                        className={classes.showItem}
                      >
                        <TextField
                          record={{ ...record, frequency }}
                          source='frequency'
                          className={classes.showItem}
                          label='Frequency'
                          textAlign='right'
                        />
                      </Labeled>
                      <Labeled
                        label='Minutes/units'
                        margin='none'
                        className={classes.showItem}
                      >
                        <FunctionField
                          record={record}
                          source='minutes'
                          className={classes.showItem}
                          label='Minutes/units'
                          textAlign='right'
                          render={() => (
                            <span>{`${minutes}/${minutes / 15}`}</span>
                          )}
                        />
                      </Labeled>
                    </div>
                  );
                })}
              </Fragment>
            )}
          <div className={classes.showSectionTitle}>Updates</div>
          <FunctionField
            source='initial_update_date'
            className={classes.showItem}
            label='Schedule type'
            render={record => getScheduleType(record)}
          />
          <DateField
            source='initial_update_date'
            className={classes.showItem}
            label='Initial update'
            textAlign='right'
          />
          <FunctionField
            source='update_weekday'
            label='Days of week'
            className={classes.showItem}
            render={record => {
              const { update_weekday } = record;
              return (
                Array.isArray(update_weekday) &&
                update_weekday.map(u => weekDaysObj[u + '']).join(', ')
              );
            }}
          />
          <TextField
            source='update_frequency'
            className={classes.showItem}
            label='Frequency'
            textAlign='right'
          />
          <FunctionField
            source='inactive_schedule'
            label='Disable sched'
            textAlign='right'
            className={classes.showItem}
            render={record => {
              const updatedRecord = {
                ...record,
                inactive_schedule: !!record.inactive_schedule,
              };
              return (
                <BooleanField
                  record={updatedRecord}
                  source='inactive_schedule'
                  className={classes.showItem}
                  style={{
                    color: updatedRecord.inactive_schedule
                      ? '#44C87A'
                      : '#5C738E',
                  }}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {/* <div className={classes.showItem} /> */}
          <FunctionField
            source='update_type'
            label='Update type'
            className={clsx(classes.showItem, classes.showFullWidth)}
            render={record => {
              const { update_type } = record;
              return Array.isArray(update_type) && update_type.join(', ');
            }}
          />
          <TextField
            source='update_notes'
            className={classes.showItem}
            label='Update notes'
            textAlign='right'
          />
          <FunctionField
            source='authorizationCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { authorizationCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={authorizationCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='authorizationUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { authorizationUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={authorizationUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.auth_notes}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};

export const ancillaryAuthsResource = {
  name: 'ancillary-authorizations',
  list: AncillaryAuthsList,
  icon: AncillaryIcon,
  // show: CaseTimelineShow,
};

function getResourceProps(props) {
  if (props.basePath === '/ancillary-authorizations') return props;
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-ancillary-authorizations',
        hasList: true,
        hasEdit: !!ancillaryAuthsResource.edit,
        hasShow: !!ancillaryAuthsResource.show,
        hasCreate: !!ancillaryAuthsResource.create,
      };
  return { ...props, basePath, caseId, ...resource };
}

function getScheduleType({
  initial_update_date,
  update_frequency_days,
  update_frequency,
  update_weekday,
}) {
  if (Array.isArray(update_weekday) && update_weekday.length) {
    return 'Recurring day';
  } else if (update_frequency_days || update_frequency) {
    return 'Recurring date';
  } else if (initial_update_date) {
    return 'One time';
  } else {
    return '';
  }
}
