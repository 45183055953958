import { makeStyles } from '@material-ui/core';
import { styles } from '../design';
import { style } from '../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 0,
  },
  title: {
    padding: 0,
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  listItemLabel: {
    whiteSpace: 'nowrap',
  },
  showItemLable: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  showLayout: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    margin: 'auto',
    maxWidth: 1200,
  },

  showItem: {
    flex: '1 0 10%',
    fontSize: 13,
    marginTop: 25,
    marginRight: 25,
   paddingBottom: 0,
   maxWidth: 175
  },
  showDetailedItem: {
    flex: '1 0 15%',
    fontSize: 13,
    marginTop: 25,
    marginRight: 25,
    marginBottom: 0,
    maxWidth: 175
  },
  
}));
