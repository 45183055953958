import React, { useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/tinymce';

export function TextEditor({
  setEditorState,
  editorState,
  height = '100%',
  disabled,
}) {
  const handleEditorChange = useCallback(
    (content, editor) => {
      setEditorState(content);
    },
    [setEditorState],
  );

  return (
    <Editor
      disabled={disabled}
      value={editorState}
      init={{
        branding: false,
        height,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: `undo redo | formatselect | bold italic underline forecolor backcolor | 
             alignleft aligncenter alignright alignjustify | 
             bullist numlist outdent indent | removeformat | help`,
      }}
      onEditorChange={handleEditorChange}
    />
  );
}
