import React, { Fragment, useEffect, useRef } from 'react';
import {
  ShowView,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  List,
  Datagrid,
  useShowController,
  useRefresh,
  FunctionField,
  DateField as AdminDateField,
} from 'react-admin';
import startCase from 'lodash/startCase';
import { useStyles } from './eligibility.styles';
import { constants } from '../utils/constants';
const { caseTypes } = constants;
const ListActions = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Case history</span>
      </TopToolbar>
    </Fragment>
  );
};
const ShowActions = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Case history</span>
      </TopToolbar>
    </Fragment>
  );
};

export const HistoryList = ({ staticContext, ..._props }) => {
  const { caseId, ...props } = getResourceProps(_props);
  const refresh = useRefresh();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);
  return (
    <Fragment>
      <List
        {...props}
        empty={false}
        actions={<ListActions />}
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField source='table_name' label='Event' />
          <AdminDateField
            source='created_at'
            label='Update date'
            showTime
            options={{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }}
          />
          <FunctionField
            source='updated_by'
            label='Updated by'
            render={record => {
              return `${record['user.last_name']}, ${record['user.first_name']}`;
            }}
          />
          <FunctionField
            source='prev_value'
            label='Previous values'
            render={record => {
              const { prev_value } = record;
              return Object.entries(prev_value).map(row => {
                const { field, value } = parseReadableValues(row);
                return (
                  <div>
                    {startCase(field)}: {!!value ? startCase(value) : 'N/A'}
                  </div>
                );
              });
            }}
          />
          <FunctionField
            source='value'
            label='New values'
            render={record => {
              const { value } = record;
              return Object.entries(value).map(row => {
                const { field, value } = parseReadableValues(row);
                return (
                  <div>
                    {startCase(field)}: {!!value ? startCase(value) : 'N/A'}
                  </div>
                );
              });
            }}
          />
        </Datagrid>
      </List>
    </Fragment>
  );
};
const HistoryShow = ({ staticContext, ..._props }) => {
  const classes = useStyles();
  const { caseId, ...props } = getResourceProps(_props);
  const showProps = useShowController({ ...props });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ShowView actions={<ShowActions />} {...showProps}>
      <Fragment {...showProps}>
        <SimpleShowLayout className={classes.showLayout} {...showProps} />
      </Fragment>
    </ShowView>
  );
};

export const historyResource = {
  name: 'case-history',
  list: HistoryList,
  show: HistoryShow,
};

function getResourceProps(props) {
  const { match: { params: { id: caseId } = {} } = {} } = props;
  const basePath = `/cases/${caseId}/history`;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-history',
        hasList: true,
        hasEdit: !!historyResource.edit,
        hasShow: !!historyResource.show,
        hasCreate: !!historyResource.create,
      };
  const updatedProps = {
    ...props,
    basePath,
    caseId,
    ...resource,
  };
  return updatedProps;
}

function parseReadableValues(row) {
  const rowValue = row[1];
  let field = row[0];
  let value;
  let match;

  if (field.includes('case_type') || field.includes('is_mltc')) {
    field = 'case_type';
    match = caseTypes.find(caseType => caseType.id === rowValue)?.name;
  } else if (
    field.includes('is_primary') ||
    field.includes('no_auths_required')
  ) {
    field = field.includes('is_primary') ? 'primary' : 'no_auths_required';
    match = rowValue === 1 ? 'true' : rowValue === 2 ? 'false' : 'unknown';
  } else if (field === 'end_resident_status_id') {
    field = 'case_status';
  } else if (field.includes('resident_status')) {
    field = 'resident_status';
  } else if (field.includes('authorization_type')) {
    field = 'authorization_type';
  } else if (field.includes('authorization_status')) {
    field = 'authorization_status';
  } else if (field.includes('appeal_type')) {
    field = 'appeal_type';
  } else if (field.includes('appeal_statuses')) {
    field = 'appeal_status';
  } else if (field.includes('denial_type')) {
    field = 'denial_type';
  } else if (field.includes('denial_status')) {
    field = 'denial_status';
  } else if (field.includes('task_type')) {
    field = 'task_type';
  } else if (
    field.includes('resolution_type') ||
    field.includes('resolution_id')
  ) {
    field = 'resolution_type';
  } else if (field.includes('discharge_location')) {
    field = 'discharge_location';
  } else if (field.includes('line_of_business') || field.includes('lob')) {
    field = 'line_of_business';
  } else if (field.includes('post_lcd_option')) {
    field = 'post_lcd_option';
  }
  if (field && !match) {
    const object = constants[field];
    if (object)
      match = Object.keys(object).find(key => object[key] === rowValue);
  }
  if (field === 'resident_status') field = 'status';
  if (match) {
    value = match.toLowerCase();
  } else {
    if (field === 'case_status' && !value) {
      value = 'Active';
    } else {
      value = rowValue;
    }
  }
  return { field, value };
}
