import React, { useState, useEffect, Fragment, useCallback } from 'react';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { useDataProvider, useNotify, required } from 'react-admin';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  FileInput,
  handleNumbers,
  getDateInputValue,
  maxTextLength,
  validateDate,
  composeValidators,
  minReasonLength,
} from './modalForms';
import { useStyles } from './modal.styles';
import { useGetCaseId, useGetTaskPermissions } from '../../../hooks';
import { SaveButton, CloseIcon } from '../../../design';
import { uiActions } from '../../../state/actions';

export const TaskForm = ({
  open,
  handleClose,
  caseId: _caseId,
  isEdit,
  record,
  refresh,
  related_to_data,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const caseIdFromUrl = useGetCaseId();
  const caseId = _caseId ? _caseId : caseIdFromUrl;
  const [formData, setFormData] = useState({
    case_id: caseId,
    docs_needed_ids: [],
  });

  // Set if the form is opened from the FAB or from an event
  const [isEventLevel, setIsEventLevel] = useState(false);

  useEffect(() => {
    if (isEdit && record) {
      const props = sanitizeProps(record);
      setFormData(f => ({
        ...f,
        ...props,
      }));
    } else if (related_to_data) {
      setIsEventLevel(true);
      setFormData(f => ({
        ...f,
        ...related_to_data,
      }));
    }
  }, [isEdit, record, related_to_data]);

  useEffect(() => {
    if (formData.task_type_id) {
      setFormData(f => ({
        ...f,
        task_description: 1,
      }));
    }
  }, [formData.task_type_id]);
  const updateTasksCount = useCallback(() => {
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }, [caseIdFromUrl, dispatch]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('tasks', {
          id: record.id,
          data: { ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          updateTasksCount();
          if (typeof refresh === 'function') refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('tasks', { data: { ...value } })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          updateTasksCount();
          if (typeof refresh === 'function') refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'task_type_id') {
      if (value !== 0) {
        setFormData(f => ({ ...f, [name]: value, docs_needed_ids: [] }));
      } else {
        setFormData(f => ({ ...f, [name]: value }));
      }
      return;
    }
    setFormData(f => ({ ...f, [name]: value }));
  };

  const removeEvent = () => {
    setFormData(f => ({ ...f, related_to_data: undefined }));
  };
  const {
    assignToRoles,
    isLoaded: permissionsIsLoaded,
  } = useGetTaskPermissions();
  return (
    <Dialog
      open={open}
      onClose={reason => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogTitle
        disableTypography
        id='form-dialog-title'
        align='left'
        className={classes.modalTitle}
        style={{ root: { fontWeight: 'bold' } }}
      >
        {isEdit ? 'Edit task' : 'New task'}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ paddingTop: 5 }}>
                {!isEdit && !caseId && (
                  <div
                    className={classes.inputContainerWrap}
                    style={{ paddingBottom: 15, width: 590 }}
                  >
                    <AutocompleteInput
                      class={classes.input}
                      reference='cases/list'
                      customOnChange={customOnChange}
                      name='case_id'
                      label='Case'
                      validate={required()}
                      required
                      fullWidth
                      openOnFocus
                      autocompleteProps={{ openOnFocus: true }}
                      options={{
                        filter: { division: 'all', active: 0 },
                      }}
                      style={{ marginRight: 0 }}
                    />
                  </div>
                )}
                {!isEventLevel && values.case_id && (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      {!isEdit ? (
                        <ReferenceInput
                          key={values.case_id}
                          reference='related-options/list'
                          customOnChange={customOnChange}
                          name='related_to_data'
                          label='Related to'
                          options={{
                            filter: { case_id: values.case_id },
                          }}
                          style={{ marginRight: 0 }}
                        />
                      ) : (
                        <FormTextField
                          label='Related to'
                          name='related_to_type'
                          disabled={true}
                          style={{ marginRight: 0 }}
                        />
                      )}
                    </div>
                    {!!values.related_to_data && (
                      <Typography
                        color='primary'
                        className={classes.removeEvent}
                        onClick={removeEvent}
                      >
                        Remove event
                      </Typography>
                    )}
                  </Fragment>
                )}{' '}
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='task-types/list'
                    customOnChange={customOnChange}
                    validate={required()}
                    required
                    name='task_type_id'
                    label='Task name'
                    resetOption={{ id: 0, name: 'Other' }}
                    options={{
                      filter: { all: false },
                    }}
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  {values.task_type_id === 0 ? (
                    <Fragment>
                      <FormTextField
                        name='title'
                        validate={composeValidators([
                          required(),
                          maxTextLength(
                            100,
                            'Please enter an amount less than 100 characters',
                          ),
                        ])}
                        required
                        customOnChange={customOnChange}
                        label='Task name'
                        style={{ marginRight: 0 }}
                        fullWidth
                      />
                      <FormTextField
                        name='description'
                        required
                        customOnChange={customOnChange}
                        label='Task description'
                        style={{ marginRight: 0 }}
                        fullWidth
                      />
                    </Fragment>
                  ) : (
                    <ReferenceInput
                      key={formData.task_type_id}
                      reference='task-descriptions/list'
                      customOnChange={customOnChange}
                      name='task_description'
                      label='Task description'
                      options={{
                        filter: { id: formData.task_type_id },
                      }}
                      style={{ marginRight: 0 }}
                      disabled
                    />
                  )}
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Status'
                    name='status'
                    validate={required()}
                    required
                    choices={[
                      { id: 'to do', name: 'To do' },
                      { id: 'in progress', name: 'In progress' },
                      { id: 'completed', name: 'Completed' },
                      { id: 'dismissed', name: 'Dismissed' },
                    ]}
                    style={{
                      marginRight:
                        (formData.status === 'dismissed' ||
                          formData.status === 'completed') &&
                        0,
                    }}
                  />
                  {(values.status === 'completed' ||
                    values.status === 'dismissed') && (
                    <FormTextField
                      name='completed_reason'
                      required={
                        !isEdit ||
                        (record?.status !== 'completed' &&
                          record?.status !== 'dismissed')
                      }
                      customOnChange={customOnChange}
                      label={`Reason for  ${
                        values.status === 'completed'
                          ? 'completion'
                          : 'dismissal'
                      }`}
                      fullWidth
                      validate={minReasonLength()}
                    />
                  )}
                  <FormTextField
                    name='date_due'
                    validate={composeValidators([required(), validateDate()])}
                    required
                    customOnChange={customOnChange}
                    type='date'
                    label='Due date'
                    style={{ marginRight: 0 }}
                  />
                  {isEdit && formData.date_due !== record.date_due && (
                    <FormTextField
                      name='date_due_reason'
                      required={isEdit && formData.date_due !== record.date_due}
                      customOnChange={customOnChange}
                      label='Reason for changing due date'
                      style={{ marginRight: 0 }}
                      fullWidth
                      validate={minReasonLength()}
                    />
                  )}
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  {permissionsIsLoaded && (
                    <ReferenceInput
                      reference='facility-users/list'
                      customOnChange={customOnChange}
                      validate={required()}
                      required
                      name='assigned_to_id'
                      label='Assigned to'
                      fullWidth
                      options={{
                        filter: {
                          all: true,
                          is_active: !isEdit,
                          roles: assignToRoles,
                        },
                      }}
                      addlOptions={
                        record?.taskAssignedTo
                          ? [
                              {
                                id: record.taskAssignedTo.id,
                                name: record.taskAssignedTo.name,
                              },
                            ]
                          : null
                      }
                      shouldFetchMore
                      style={{ marginRight: 0 }}
                    />
                  )}
                </div>
                {values.task_type_id >= 0 && (
                  <ReferenceInput
                    key={values.task_type_id}
                    reference='task-documents/list'
                    customOnChange={customOnChange}
                    name='docs_needed_ids'
                    label='Documents needed'
                    multiple
                    renderWith='chip'
                    fullWidth
                    options={{
                      filter: { id: values.task_type_id },
                    }}
                  />
                )}
                <FormTextField
                  name='notes'
                  label='Communication notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  validate={minReasonLength()}
                />
                {!isEdit && (
                  <div className={classes.insuranceTitle}>
                    Attachments
                    <FileInput
                      title='title'
                      name='attachments'
                      setFormData={setFormData}
                      formData={formData}
                      classes={{ dropZone: classes.dropZone }}
                    />
                  </div>
                )}
                <div className={classes.insuranceTitle}>Insurance update</div>
                <div>
                  <FormTextField
                    name='date_sent'
                    customOnChange={customOnChange}
                    type='date'
                    label='Sent to insurance'
                    style={{ maxWidth: 200 }}
                    validate={composeValidators([validateDate()])}
                  />
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

function sanitizeProps(props) {
  const {
    completed_by_id,
    date_completed,
    created_at,
    deleted_at,
    updated_at,
    created_by,
    updated_by,
    taskCreatedBy,
    taskUpdatedBy,
    taskAssignedTo,
    taskCompletedBy,
    task_type_id,
    id,
    v_case,
    task_type,
    TaskDocs,
    document_ids,
    docs_needed,
    docs_checked,
    related_to_id,
    related_to_type,
    related_to_date,
    insurance_update,
    ...rest
  } = props;
  return {
    ...rest,
    insurance_update: !!insurance_update,
    related_to_data: related_to_type
      ? `${related_to_type}||${related_to_id}`
      : undefined,
    related_to_type,
    task_type_id: task_type_id || 0,
  };
}
