import { useRedirect } from 'react-admin';
import { parse, stringify } from 'query-string';

export function useUpdatePageQueryParams() {
  const redirect = useRedirect();
  return (newQueryParams = {}) => {
    const { hash, search } = window.location;
    const currentParams = parse(search);
    const newParams = { ...currentParams, ...newQueryParams };
    const query = Object.keys(newParams).length
      ? `?${stringify(newParams, { skipNull: true })}`
      : '';
    // window.location.pathname doesn't worl because of the hash
    const curPath = hash.split('#')[1];
    const pathname = curPath.split('?')[0];
    redirect(`${pathname}${query}`);
  };
}
