import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List as MuiList,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import { parse, stringify } from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import {
  ChipField,
  DateField,
  FunctionField,
  List,
  RefreshButton,
  TextField,
  TopToolbar,
  useNotify,
  useRedirect,
  useRefresh,
  usePermissions,
} from 'react-admin';
import { Datagrid, StatusFilter } from '../components/common';
import { FaxEmailToggle } from '../components/common/faxEmailFileToggle';
import * as design from '../design';
import { useUpdatePageQueryParams } from '../hooks';
import { authGet, authPost } from '../server';
import { getFacilityId } from '../server/ra-data-json-server';
import { uiActions } from '../state/actions';
import { getBaseReportUrl, getChip } from '../utils';
import { useStyles } from './case_emails.styles';

const { CustomButton, CloseIcon } = design;

const EmailsListActions = ({
  showAll,
  setShowAll,
  caseId,
  log_id,
  residentCaseCount,
}) => {
  const classes = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const [selectFaxEmail, selectFaxEmailToggle] = useState(false);
  const redirect = useRedirect();

  useEffect(() => {
    if (selectFaxEmail) {
      redirect(`/cases/${caseId}/file-management`);
    }
  }, [selectFaxEmail, caseId, redirect]);

  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex' }}>
            <span className={classes.title}>Fax/email log</span>
            {/* Needed for spacing */}
            <span style={{ marginRight: 25 }} />
            <FaxEmailToggle
              selectFaxEmail={selectFaxEmail}
              selectFaxEmailToggle={selectFaxEmailToggle}
            />
          </div>
          <div>
            {!!log_id && (
              <Button
                className={classes.emailLogBtn}
                onClick={() => updatePageQueryParams({ log_id: null })}
              >
                View List
              </Button>
            )}
            <RefreshButton className={classes.emailLogBtn} />
          </div>
        </div>
        <div style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 8 }}>
          {residentCaseCount > 1 && (
            <StatusFilter setShowAll={setShowAll} showAll={showAll} />
          )}
        </div>
      </TopToolbar>
    </Fragment>
  );
};

const ResultStatusActions = ({ record, isViewAdmin }) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const { id, fax_result, email_addresses } = record;

  async function handleResend() {
    const { docs, fax_number, fax_name, case_id, label, html } = record;
    if (fax_number && !docs) return;
    notify('Resending...');
    const response = await authPost(`/cases/${case_id}/send-communication`, {
      docs,
      fax_number:
        fax_number && fax_name
          ? {
              fax: fax_number,
              name: fax_name,
            }
          : undefined,
      email_addresses: email_addresses
        ? email_addresses.split(', ')
        : undefined,
      subject: label,
      text: html,
      log_id: id,
    });
    const { error } = response;
    if (error) {
      return notify(error.message, 'warning');
    }
    notify(fax_number ? 'Fax sent' : 'Email sent');
    setTimeout(() => {
      dispatch(uiActions.getCaseTasksCount(case_id));
    }, 6000);
    refresh();
  }

  async function handleGetConfirmation() {
    const dateOffset = new Date().getTimezoneOffset() / -60;
    const getParams = stringify({
      _facilityId: getFacilityId(),
      token: localStorage.getItem('token'),
      report: 'fax-confirmation',
      email_id: id,
      time_zone_diff: dateOffset,
    });
    window.open(
      `${getBaseReportUrl()}/download/get-confirmation/${id}?${getParams}`,
      '_blank',
    );
  }

  const statusActivities = {
    SendingFailed: [{ title: 'Resend', action: handleResend }],
    Unknown: [{ title: 'Resend', action: handleResend }],
    Sent: [
      { title: 'Get Confirmation', action: handleGetConfirmation },
      { title: 'Resend', action: handleResend },
    ],
    Email: [
      { title: 'Get Confirmation', action: handleGetConfirmation },
      { title: 'Resend', action: handleResend },
    ],
    ResendNOMNC: [{ title: 'Resend', action: handleResend }],
  };
  const Email = !!email_addresses ? 'Email' : undefined;
  const ResendNOMNC =
    record.sent_to_facility === '1' &&
    record.denial?.signed_denial_received === null
      ? 'ResendNOMNC'
      : undefined;
  const activities =
    statusActivities[fax_result.status || Email || ResendNOMNC] ?? [];

  return (
    <Fragment>
      {activities.map(activity => (
        <Button
          className={classes.resend}
          color='primary'
          onClick={activity.action}
          disabled={isViewAdmin}
        >
          {activity.title}
        </Button>
      ))}
    </Fragment>
  );
};

const handleViewClick = async (document_id, notify) => {
  const response = await authGet(`/documents/url/${document_id}`);
  if (response.error) {
    notify('ra.notification.http_error', 'warning');
  }
  const win = window.open(response.data.url, '_blank');
  win.focus();
};

const EmailDocumentsList = ({ id, record, resource }) => {
  const notify = useNotify();
  const classes = useStyles();
  const { docs } = record;
  const documents = docs || [];

  return (
    <MuiList dense style={{ maxWidth: 448 }}>
      {documents.map(e => {
        const { document_name, document_type, id } = e || {};
        const documentName = `${document_name}${document_type || ''}`;
        return (
          id !== null && (
            <ListItem
              key={id}
              onClick={() => handleViewClick(id, notify)}
              classes={{ dense: classes.docsList }}
            >
              <ListItemText
                primary={documentName}
                color='primary'
                className={classes.attText}
              />
            </ListItem>
          )
        );
      })}
    </MuiList>
  );
};

export const CaseEmailsList = ({ staticContext, ..._props }) => {
  const { caseId, ...props } = getResourceProps(_props);
  const classes = useStyles();
  const refresh = useRefresh();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { location: { search } = {} } = props;
  const { log_id, direction } = parse(search);
  const [showAll, setShowAll] = useState(true);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState();
  const residentCaseCount = useSelector(state =>
    get(state, 'ui.residentCaseCount', 0),
  );
  const isFirstRender = useRef(true);
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId]);

  useEffect(() => {
    if (selectedEmail) {
      setShowEmailModal(true);
    }
  }, [selectedEmail]);

  const handleNotesModalClose = () => {
    setShowEmailModal(false);
    setSelectedEmail(null);
  };

  const toggleShowAll = useCallback(
    value => {
      setShowAll(value);
      if (log_id && value === true) {
        updatePageQueryParams({ log_id: null });
      }
    },
    [log_id, updatePageQueryParams],
  );

  const listRowStyle = (record, index, activeId) => {
    if (record && record.id === activeId) {
      return { backgroundColor: '#EFF4FB' };
    } else if (caseId && parseInt(record?.case_id) !== parseInt(caseId)) {
      return {
        backgroundColor: '#EFF4FB',
        opacity: 0.5,
        fontStyle: 'italic',
      };
    }
  };
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        className={clsx(classes.list)}
        actions={
          <EmailsListActions
            residentCaseCount={residentCaseCount}
            showAll={showAll}
            setShowAll={toggleShowAll}
            caseId={caseId}
            log_id={log_id}
          />
        }
        bulkActionButtons={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        filter={{ all: showAll, log_id, direction }}
      >
        <Datagrid
          expand={<EmailDocumentsList />}
          rowStyle={(record, index) => listRowStyle(record, index)}
        >
          <TextField source='label' label='Name' />
          <TextField source='direction' label='Direction' sortable={false} />
          <TextField source='fax_name' label='Sender/ Recipient' />
          <TextField source='email_addresses' label='Email Recipients' />
          <FunctionField
            label='Status'
            source='fax_result.status'
            sortable={false}
            render={record => {
              const { fax_result, direction, email_addresses } = record;
              const displayStatus = startCase(fax_result?.status);
              return direction === 'Inbound' ? (
                <ChipField
                  record={{ ...record, direction }}
                  source='direction'
                  className={clsx(classes.chip, classes[getChip('Received')])}
                />
              ) : (
                (fax_result?.status || email_addresses) && (
                  <div>
                    {fax_result?.status && (
                      <ChipField
                        record={{ ...record, displayStatus }}
                        source='displayStatus'
                        className={clsx(
                          classes.chip,
                          classes[getChip(fax_result.status)],
                        )}
                      />
                    )}
                    <ResultStatusActions
                      record={record}
                      isViewAdmin={isViewAdmin}
                    ></ResultStatusActions>
                  </div>
                )
              );
            }}
          />
          <FunctionField
            source=''
            label='Created by'
            render={record => {
              const { emailLogCreatedBy } = record;
              return emailLogCreatedBy?.last_name !== ''
                ? emailLogCreatedBy?.last_name +
                    ', ' +
                    emailLogCreatedBy?.first_name
                : '';
            }}
          />
          <DateField source='created_at' label='Created at' showTime />
          <FunctionField
            source=''
            label='View email'
            render={record => {
              const { email_addresses, html, label } = record;
              return !!email_addresses?.length && !!html ? (
                <Button
                  color='primary'
                  onClick={() => setSelectedEmail({ html, label })}
                  className={classes.viewEmail}
                >
                  View email
                </Button>
              ) : null;
            }}
          />
        </Datagrid>
      </List>
      {showEmailModal && (
        <EmailsModal
          open={showEmailModal}
          handleClose={handleNotesModalClose}
          email={selectedEmail}
        />
      )}
    </Fragment>
  );
};

function EmailsModal({ email, open, handleClose }) {
  const { label, html } = email;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
    >
      <DialogContent>
        <DialogTitle>{label}</DialogTitle>
        <DialogContentText>
          <span dangerouslySetInnerHTML={{ __html: html }} />
        </DialogContentText>
        <DialogActions>
          <CustomButton
            Icon={CloseIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Close'
            type='button'
            variant='text'
            size='small'
            onClick={handleClose}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export const caseEmailsResource = {
  name: 'case-emails',
  list: CaseEmailsList,
};

function getResourceProps(props) {
  const {
    location: { pathname } = {},
    match: { params: { id: caseId } = {} } = {},
  } = props;
  const basePath = pathname || '/cases';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'case-emails',
        hasList: true,
        hasEdit: !!caseEmailsResource.edit,
        hasShow: !!caseEmailsResource.show,
        hasCreate: !!caseEmailsResource.create,
      };
  return { ...props, basePath, caseId, ...resource };
}
