import React, { useState, Fragment, useEffect } from 'react';

import { Form } from 'react-final-form';
import {
  useDataProvider,
  useRefresh,
  useNotify,
  cacheDataProviderProxy,
  required,
} from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useStyles } from '../modal.styles';
import { handleNumbers, getDateInputValue } from '.';
import { ReferenceInput, FormTextField, SwitchInput } from '../modalForms';
import { SaveButton } from '../../../../design';
import { authGet } from '../../../../server';
import { constants } from '../../../../utils';
import { TrackUser } from '../../../../components/wrappers';

const { auto_task_settings } = constants;

export const AutoTaskSettingsForm = ({ handleClose }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    is_active: true,
  });

  useEffect(() => {
    const getSettings = async () => {
      const response = await authGet([
        `/auto-task-settings`,
        { task_id: formData.task_id },
      ]);
      const { data } = response;
      if (data) {
        setFormData(f => ({ ...f, ...data, is_active: !!data.is_active }));
      }
    };
    if (formData.task_id !== undefined) getSettings();
  }, [formData.task_id]);
  const onSubmit = value => {
    return dataProvider
      .update('auto-task-settings', {
        id: value.task_id,
        data: {
          ...value,
        },
      })
      .then(({ data }) => {
        notify('form.updated');
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={classes.inputContainerWrap}>
                  <ReferenceInput
                    reference='auto-tasks/list'
                    customOnChange={customOnChange}
                    name='task_id'
                    label='Tasks'
                    required
                    validate={required()}
                  />
                  <SwitchInput
                    name='is_active'
                    customOnChange={customOnChange}
                    label={formData.is_active ? 'Active' : 'Inactive'}
                    checked={formData.is_active}
                  />
                </div>
                {formData.task_id !== auto_task_settings.ENDING_AUTH &&
                  formData.task_id !== auto_task_settings.AUTH_REQUEST && (
                    <div className={classes.inputContainerWrap}>
                      <FormTextField
                        name='additional_days'
                        customOnChange={customOnChange}
                        label='Days due after trigger event'
                        type='number'
                      />
                      <div style={{ flex: '1 0 160px' }} />
                    </div>
                  )}
                <div style={{ display: 'flex' }}>
                  {formData['autoTaskSettingsUpdatedBy.first_name'] && (
                    <div
                      className={classes.subtitle}
                      style={{ display: 'flex' }}
                    >
                      <span>
                        Last modified by{' '}
                        {formData['autoTaskSettingsUpdatedBy.first_name']}{' '}
                        {formData['autoTaskSettingsUpdatedBy.last_name']} at
                      </span>
                      <span style={{ paddingLeft: 5 }}>
                        <TrackUser timestamp={formData.updated_at} />
                      </span>
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
