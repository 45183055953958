import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  useRefresh,
  useRedirect,
  useDataProvider,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  facilityActions,
  uiActions,
  fileManagementActions,
} from '../../state/actions';
import { Autocomplete } from '../wrappers';
import { useStyles } from './appbar.styles';

export const SearchFacility = memo(({ profile, isAdmin }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const facilityName = useSelector(state => state.facility?.name);
  const { user_preference: { last_facility } = {} } = profile;
  const [facilityId, setFacilityId] = useState('');
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const debounceFilter = debounce(value => {
    setFilter(value);
  }, 300);

  useEffect(() => {
    dataProvider
      .getList('facilities/list', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'name', order: 'ASC' },
        filter: { facility_ids: last_facility || undefined, q: filter }, // don't pass in 0
      })
      .then(response => {
        if (!response) return;
        const { data } = response;
        setData(data);
      })
      .catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (!last_facility) return;
    setFacilityId(last_facility);
    const fetchData = async () => {
      await dispatch(facilityActions.getFacility(last_facility));
      refresh();
    };
    fetchData();
  }, [dispatch, refresh, last_facility]);

  const onChange = e => {
    debounceFilter(e.target.value);
  };

  const onClick = value => {
    if (value === null) return;
    const fetchData = async () => {
      const response = await dispatch(facilityActions.getFacility(value));
      if (response === 'error') return;
      // close any open emails
      dispatch(fileManagementActions.resetFileManagementData());
      setFacilityId(value);
      redirect(isAdmin ? '/' : '/residents-list');
    };
    fetchData();
  };

  useEffect(() => {
    if (facilityId && last_facility && last_facility !== +facilityId) {
      setFacilityId(last_facility);
    }
  }, [facilityId, last_facility]);
  return (
    <Autocomplete
      key={facilityName}
      onChange={onChange}
      suggestions={data}
      parentOnClick={onClick}
      classes={{
        root: clsx(classes.selectRoot),
        input: classes.searchInput,
      }}
      otherInputProps={{ variant: 'outlined' }}
      fullWidth={false}
      placeholder={facilityName || 'Select Facility'}
      isFacilitySearch
      isAppBar
    />
  );
});

export const RedirectOnError = isAdmin => {
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const shouldRedirect = useSelector(state => state.ui.redirect);

  useEffect(() => {
    if (shouldRedirect) {
      redirect(isAdmin ? '/' : '/residents-list');
      refresh();
      dispatch(uiActions.clearRedirect());
    }
  }, [shouldRedirect, dispatch, redirect, refresh, isAdmin]);
  return null;
};
