import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import {
  FunctionField,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { isValid } from 'date-fns';
import { DateField, TrackUser } from '../../components/common';
import { NotesModal } from '../../components/common/modals';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import { constants, limitStringSize } from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes } = constants;

export const CaseAdmissionShow = ({
  closeAside,
  classes,
  residentId,
  caseId,
  setRefreshTimeline,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const maxNotesLength = 160;
  const showProps = useShowController({ ...props });
  const { record = {} } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            title='Admissions'
            resourceRecord={{ ...record, select_resident: residentId }}
            eventForm='admission'
            eventId={record.id}
            eventType='Admission'
            eventName={eventTypes.ADMISSION_NAME}
            caseId={caseId}
            setRefreshTimeline={setRefreshTimeline}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <DateField
            source='admit_date'
            label='Admit date'
            textAlign='right'
            className={classes.showItem}
          />

          {record.tracking_date !== 'Invalid date' &&
            record.tracking_date !== record.admit_date && (
              <FunctionField
                source='tracking_date'
                label={`${
                  record.tracking_reason
                    ? record.tracking_reason
                        .replace(/_/g, ' ')
                        .split()
                        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
                    : 'Pickup of skilled'
                }
              date`}
                textAlign='right'
                className={classes.showItem}
                render={record => {
                  const { tracking_date, admit_date } = record;
                  const formatDate = new Date(`${tracking_date} 00:00`);
                  const updatedRecord = {
                    tracking_date: isValid(formatDate)
                      ? tracking_date
                      : admit_date,
                  };
                  return (
                    <DateField
                      record={updatedRecord}
                      source='tracking_date'
                      textAlign='right'
                      className={classes.showItem}
                    />
                  );
                }}
              />
            )}
          <DateField
            source='paperwork_sent'
            label='Paperwork sent'
            textAlign='right'
            className={classes.showItem}
          />
          <TextField
            source='room_number'
            label='Room number'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='notes'
            label='Notes'
            textAlign='right'
            className={clsx(classes.showItem, classes.showFullWidth)}
            render={record => {
              const { notes } = record;
              const truncated = limitStringSize(notes, maxNotesLength, true);
              return notes && notes.length > maxNotesLength ? (
                <span>
                  {truncated}{' '}
                  <Typography
                    color='primary'
                    component='span'
                    className={classes.showMore}
                    onClick={() => setShowNotesModal(true)}
                  >
                    Show more
                  </Typography>
                </span>
              ) : (
                truncated
              );
            }}
          />
          <FunctionField
            source='admissionCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { admissionCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={admissionCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='admissionUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { admissionUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={admissionUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          {showNotesModal && (
            <NotesModal
              open={showNotesModal}
              handleClose={() => setShowNotesModal(false)}
              note={record.notes}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      {!!residentId && (
        <Fragment>
          <Divider className={classes.residentDivider} />
          <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
            Resident info
          </p>
          <Show
            actions={
              <ResidentShowActions
                closeAside={closeAside}
                isResidentPage={false}
                setRefreshTimeline={setRefreshTimeline}
                isViewAdmin={isViewAdmin}
              />
            }
            {...rest}
            resource='residents'
            id={residentId}
          >
            <ResidentShowFields classes={classes} includeCases />
          </Show>
        </Fragment>
      )}
    </div>
  );
};
