import { useEffect } from 'react';
import throttle from 'lodash/throttle';
import { subMinutes, addMinutes, getUnixTime } from 'date-fns';
import { useLogout } from 'react-admin';
import { useDispatch } from 'react-redux';
import { userActions } from './state/actions';
import { useInterval } from './hooks';

const DEFAULT_ACTIVITY_EVENTS = [
  'click',
  'mousemove',
  'keydown',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'focus',
];

export default function HandleTokenRefresh() {
  const logout = useLogout();
  const dispatch = useDispatch();

  const handleActions = () => {
    const lastActiveStorage = localStorage.getItem('lastActive');
    const lastActive = lastActiveStorage
      ? parseInt(lastActiveStorage)
      : lastActiveStorage;
    const jwtExpiry = localStorage.getItem('jwtExpiry');

    if (!jwtExpiry) return;
    const now = new Date();
    const halfHourAgo = subMinutes(now, 30);
    const fourMinAhead = addMinutes(now, 4);
    if (
      (!lastActive || lastActive > getUnixTime(halfHourAgo)) &&
      parseInt(jwtExpiry) < getUnixTime(fourMinAhead)
    ) {
      dispatch(userActions.refreshToken());
    } else if (lastActive < getUnixTime(halfHourAgo)) {
      logout();
    }
  };

  const updateActivityTime = throttle(() => {
    setLastActive(getUnixTime(new Date()));
  }, 5000);

  window.updateActivityTime = updateActivityTime;

  useInterval(handleActions, 1000 * 60);

  useEffect(() => {
    DEFAULT_ACTIVITY_EVENTS.forEach(e => {
      document.addEventListener(e, updateActivityTime);
    });
    // setLastActive and run handleActions on initial load
    setLastActive(getUnixTime(new Date()));
    handleActions();
    return () => {
      DEFAULT_ACTIVITY_EVENTS.forEach(e => {
        document.removeEventListener(e, updateActivityTime);
      });
      localStorage.removeItem('jwtExpiry');
      clearLastActive();
      updateActivityTime.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

function setLastActive(time) {
  return localStorage.setItem('lastActive', time + '');
}

function clearLastActive() {
  return localStorage.removeItem('lastActive');
}
