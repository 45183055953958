import { makeStyles } from '@material-ui/core';
// import { styles } from '../../design';
import { style } from '../../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  noLineBreak: {
    whiteSpace: 'nowrap',
  },
  title: {
    padding: 0,
    fontSize: 20,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  filterContainer: {
    flexWrap: 'nowrap',
  },
  filterDropdown: {
    textTransform: 'capitalize',
    color: '#61728C',
    fontSize: 16,
    marginTop: -5,
    marginRight: 2,
    marginLeft: 5,
    fontStyle: 'Nunito Sans',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    fontWeight: 500,
    backgroundColor: '#EFF4FB',
    color: '#1061A0',
  },
  exportWrapper: {
    width: 367,
    textAlign: 'right',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  exportContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 160,
  },
  authFilterContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: 255,
  },
  selectedTab: {
    fontSize: 15,
    color: '#3B94D1',
    textTransform: 'capitalize',
  },
  tab: {
    textTransform: 'capitalize',
    fontSize: 15,
    color: '#5C738E',
  },
  tabContainer: {
    minWidth: '100%',
    marginTop: 20,
  },
  notchedOutline: {
    border: 'none',
  },
}));
