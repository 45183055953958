import React, { useState, Fragment } from 'react';
import { Form } from 'react-final-form';
import {
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
  required,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { handleNumbers, getDateInputValue, ReferenceInput } from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const CloneContractForm = ({
  payerFacilityId,
  payerId,
  meta,
  handleClose,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    id: payerFacilityId,
    facilities: [],
  });

  const onSubmit = value => {
    return dataProvider
      .create('clone-contract', {
        data: {
          id: payerFacilityId,
          facilities: value.facilities,
          in_network: meta.in_network,
        },
      })
      .then(({ data }) => {
        notify(
          `Contract details copied to ${data.updatedFacilities} faciliti(es)`,
          'form.created',
        );
        handleClose();
        refresh();
      })
      .catch(error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      );
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText color={'info'} variant='subtitle2'>
                  Select facilities to update. Available facilities are
                  contracted with the same payer and have the same network
                  status as this contract.
                </DialogContentText>
                <ReferenceInput
                  reference='facilities/list'
                  customOnChange={customOnChange}
                  name='facilities'
                  label='Select contracted facilities'
                  validate={required()}
                  required
                  multiple
                  fullWidth
                  setFormData={setFormData}
                  perPage={500}
                  selectAll
                  options={{
                    sort: { field: 'name', order: 'ASC' },
                    filter: {
                      payer_id: payerId,
                      in_network: meta.in_network,
                      payer_facility_id: payerFacilityId,
                    },
                  }}
                  style={{ marginRight: 0 }}
                />
                <DialogContentText color={'error'} variant='subtitle2'>
                  Note: Existing rates, qualifying criteria, and outliers in the
                  selected contracts will be overwritten and replaced by the
                  details in this contract.
                </DialogContentText>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
