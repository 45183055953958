import React, { useState, useEffect, Fragment } from 'react';

import { Form } from 'react-final-form';
import {
  // required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  cacheDataProviderProxy,
} from 'react-admin';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import {
  FormTextField,
  // SelectComponent,
  handleNumbers,
  getDateInputValue,
} from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';
import { SelectComponent } from './inputs';

export const PayerIntegrationDetailsForm = ({
  record = {},
  meta,
  isEdit,
  payerFacilityId,
  handleClose,
}) => {
  const lobPickList = (meta.line_of_businesses || []).map(lob => ({
    id: lob.id,
    name: lob.payer_type,
  }));
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [formData, setFormData] = useState({
    payer_facility_id: payerFacilityId,
  });
  useEffect(() => {
    if (isEdit && record) {
      const { id, ...rest } = record;
      setFormData(f => ({
        ...f,
        ...rest,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('payers-facility/integration-details', {
          id: record.id,
          data: { ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('payers-facility/integration-details', { data: { ...value } })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormTextField
                  name='integration_payer_id'
                  label='Integration ID'
                  type='number'
                  customOnChange={customOnChange}
                  fullWidth
                />

                <div style={{ marginLeft: 10, width: '95%' }}>
                  <Typography variant='caption'>
                    Enter an easily to understand caption for this ID. For
                    example, the payer name as it's shown in the external app.
                  </Typography>
                </div>
                <FormTextField
                  name='integration_payer_description'
                  label='Description'
                  fullWidth
                  customOnChange={customOnChange}
                />
                <SelectComponent
                  customOnChange={customOnChange}
                  name='line_of_business_id'
                  label='Matching LOB'
                  choices={lobPickList}
                  fullWidth
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
