import { makeStyles } from '@material-ui/core';

const flex = {
  display: 'flex',
  alignItems: 'center',
};

const flexStyles = {
  flexRow: {
    ...flex,
  },
  flexColumn: {
    ...flex,
    flexDirection: 'column',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifystart: {
    justifyContent: 'flex-start',
  },
};

export const chipStyles = {
  active: {
    backgroundColor: 'rgba(238, 248,	242)',
    color: '#44C87A',
    fontWeight: 300,
  },
  approved: {
    backgroundColor: 'rgba(238, 248,	242)',
    color: '#44C87A',
    fontWeight: 300,
  },
  mltcSidebar: {
    backgroundColor: '#66afdb',
    color: '#FFFFFF',
    fontWeight: 300,
  },
  closed: {
    backgroundColor: 'rgba(252, 246, 215)',
    color: '#F6CF16',
    fontWeight: 300,
  },
  denial: {
    backgroundColor: 'rgba(249,	233, 234)',
    color: '#E32939',
    fontWeight: 300,
  },
  discharged: {
    backgroundColor: 'rgba(250, 237, 245)',
    color: '#F14FA0',
    fontWeight: 300,
  },
  pending: {
    backgroundColor: 'rgba(251,	240,	232)',
    color: '#ED731F',
    fontWeight: 300,
  },
  appeal: {
    backgroundColor: 'rgba(245, 237, 600)',
    color: '#F14FB0',
    fontWeight: 300,
  },
};

export const styles = {
  ...flexStyles,
  ...chipStyles,
  cursorPointer: {
    cursor: 'pointer',
  },
};

export const useGlobalStyles = makeStyles(theme => ({
  ...styles,
}));

export const colors = {
  yellow: '#AEDD45',
  white: '#FFFFFF',
  black: 'rgba(0,0,0,0.87)',
};
