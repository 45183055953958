import React, { Fragment, useState, useCallback } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import startCase from 'lodash/startCase';
import {
  List,
  TextField,
  FunctionField,
  // ReferenceField,
  ChipField,
  TopToolbar,
  DateField,
  RefreshButton,
  Pagination,
} from 'react-admin';
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Input,
} from '@material-ui/core';
import { ToggleButton, Datagrid } from '../components/common';
import { getChip, constants } from '../utils';
import { useGetUserDivision } from '../hooks';
import { FolderIcon } from '../design';
import { useStyles } from './facility-faxLogs.styles';
const { faxLogsStatusFilters } = constants;

const FacilityLevelActions = ({ setDivision, filter, setFilter }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <TopToolbar className={clsx(classes.listActionWrapper, classes.flexWrap)}>
        <div className={classes.listActionWrapper}>
          <div style={{ display: 'flex' }}>
            <span className={classes.title} style={{ paddingRight: 20 }}>
              Fax/email log
            </span>

            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
            />
          </div>{' '}
          <RefreshButton className={classes.emailLogBtn} />
        </div>

        <StatusFilter filter={filter} setFilter={setFilter} />
      </TopToolbar>
    </Fragment>
  );
};

export const FaxLogsList = props => {
  const userDivision = useGetUserDivision();
  const classes = useStyles();
  const [division, setDivision] = useState(userDivision);
  const [filter, setFilter] = useState('');

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        exporter={false}
        bulkActionButtons={false}
        className={clsx(classes.list)}
        actions={
          <FacilityLevelActions
            setDivision={setDivision}
            filter={filter}
            setFilter={setFilter}
          />
        }
        filter={{ division, filtered: filter }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        sort={{
          field: 'created_at',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <FunctionField
            source=''
            label='Case name'
            render={record => {
              const { v_authorizations } = record;
              return (
                v_authorizations?.last_name +
                ', ' +
                v_authorizations?.first_name +
                ' - ' +
                v_authorizations?.admit_date
              );
            }}
          />
          <FunctionField
            source='label'
            label='Name'
            render={record => {
              const { id, direction, label, case_id } = record;
              return (
                <Button
                  color='primary'
                  className={classes.viewEmail}
                  component={Link}
                  to={`/cases/${case_id}/emails?${stringify({
                    log_id: id,
                    direction: direction,
                  })}`}
                >
                  {label}
                </Button>
              );
            }}
          />
          <TextField source='direction' label='Direction' />
          <TextField source='fax_name' label='Sender/Recipient' />
          <FunctionField
            label='Status'
            source='fax_result.status'
            render={record => {
              const { fax_result, direction } = record;
              const displayStatus = startCase(fax_result?.status);
              return direction === 'Inbound' ? (
                <ChipField
                  record={{ ...record, direction }}
                  source='direction'
                  className={clsx(classes.chip, classes[getChip('Received')])}
                />
              ) : (
                fax_result?.status && (
                  <ChipField
                    record={{ ...record, displayStatus }}
                    source='displayStatus'
                    className={clsx(
                      classes.chip,
                      classes[getChip(fax_result.status)],
                    )}
                  />
                )
              );
            }}
          />
          <FunctionField
            source=''
            label='Created by'
            render={record => {
              const { emailLogCreatedBy } = record;
              return emailLogCreatedBy?.last_name !== ''
                ? emailLogCreatedBy?.last_name +
                    ', ' +
                    emailLogCreatedBy?.first_name
                : '';
            }}
          />
          <DateField source='created_at' label='Created at' showTime />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export const facilityFaxLogsResource = {
  name: 'faxes-list',
  list: FaxLogsList,
  icon: FolderIcon,
};

function StatusFilter({ filter, setFilter }) {
  const handleChange = useCallback(
    e => {
      setFilter(e.target.value);
    },
    [setFilter],
  );

  return (
    <FormControl style={{ minWidth: 151, marginLeft: 2 }}>
      <InputLabel id='auth-filter'>Status</InputLabel>
      <Select
        labelId='auth-filter'
        id='auth-filter-select'
        value={filter}
        onChange={handleChange}
        input={<Input disableUnderline></Input>}
      >
        {faxLogsStatusFilters.map(f => {
          const { id, name } = f;
          return (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          );
        })}
        <MenuItem value=''>
          <Typography color='secondary'>Clear filter</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
