import React, { useMemo, useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import {
  required,
  email,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
  usePermissions,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormGroup,
} from '@material-ui/core';
import {
  ReferenceInput,
  FormTextField,
  SelectComponent,
  composeValidators,
  handleNumbers,
  getDateInputValue,
  CheckboxInput,
  minLength,
  validateEmailOnSubmit,
  validatePhoneOnSubmit,
  SwitchInput,
  minReasonLength,
} from '../modalForms';
import { useStyles } from '../modal.styles';
import {
  SaveButton,
  AddIcon,
  SyncDisabledIcon,
  SyncIcon,
} from '../../../../design';
import { constants, convertToObj } from '../../../../utils';
import { DeleteRecord } from '../../../wrappers';
import { authGet } from '../../../../server';
import { uiActions } from '../../../../state/actions';
const { states, client_group_choices } = constants;

export const FacilityForm = ({
  caseId,
  record: _record,
  isEdit,
  handleClose,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const dispatch = useDispatch();
  const notify = useNotify();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const [formData, setFormData] = useState({
    case_id: caseId,
  });
  const [integrationObj, setIntegrationObj] = useState({});
  const [showIntegrationFields, setShowIntegrationFields] = useState(false);

  const record = useMemo(() => _record || {}, [_record]);

  useEffect(() => {
    if (isEdit && record) {
      const fields = sanitizeFacility(record);
      const { active_case_types, inactive, last_active_date } = record;
      setFormData(f => ({
        ...f,
        ...fields,
        is_mltc: active_case_types !== 'subacute',
        is_subacute: active_case_types !== 'mltc',
        last_active_date: inactive ? last_active_date : null,
      }));
    } else {
      setFormData(f => ({
        ...f,
        integration_isactive: true,
      }));
    }
  }, [isEdit, record]);

  useEffect(() => {
    (async function() {
      const response = await authGet('external-integrations/list');
      const { data } = response;
      if (data) {
        setIntegrationObj(convertToObj(data));
      }
    })();
  }, []);

  const onSubmit = value => {
    if (!value.is_subacute && !value.is_mltc) {
      notify('You must select at least one case type', 'warning');
      return 'error';
    }
    const { phone, fax, pharmacy_email, integration_id } = value;
    if (isEdit) {
      return dataProvider
        .update('facilities', {
          id: record.id,
          data: {
            ...value,
            phone: validatePhoneOnSubmit(phone),
            fax: validatePhoneOnSubmit(fax),
            pharmacy_email: validateEmailOnSubmit(pharmacy_email),
            active_case_types:
              value.is_subacute && value.is_mltc
                ? 'all'
                : value.is_subacute
                ? 'subacute'
                : 'mltc',
            integration_id: integration_id
              ? integration_id + ''
              : integration_id, // we may have an issue with id 0
          },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('facilities', {
          data: {
            ...value,
            phone: validatePhoneOnSubmit(phone),
            fax: validatePhoneOnSubmit(fax),
            pharmacy_email: validateEmailOnSubmit(pharmacy_email),
            active_case_types:
              value.is_subacute && value.is_mltc
                ? 'all'
                : value.is_subacute
                ? 'subacute'
                : 'mltc',
            integration_id: integration_id
              ? integration_id + ''
              : integration_id,
          },
        })
        .then(({ data: newData }) => {
          notify('form.created');
          dispatch(uiActions.updateFacilityList(newData));
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };

  function toggleIntegrationFields() {
    if (showIntegrationFields) {
      setFormData(f => ({
        ...f,
        integration_id: record.integration_id ?? null,
        integration_type: record.integration_type ?? null,
        integration_isactive: record.integration_isactive ?? true,
      }));
    }
    setShowIntegrationFields(cur => !cur);
  }

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'integration_type') {
      setFormData(f => ({ ...f, [name]: value, integration_id: '' }));
      return;
    }

    setFormData(f => ({ ...f, [name]: value }));
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          // ...caseFields,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormTextField
                  name='name'
                  customOnChange={customOnChange}
                  label='Facility name'
                  validate={required()}
                  required
                  fullWidth
                />
                <FormTextField
                  name='legal_name'
                  customOnChange={customOnChange}
                  label='NOMNC facility name'
                  fullWidth
                />
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='address'
                    customOnChange={customOnChange}
                    label='Address'
                    validate={required()}
                    required
                    style={{ marginRight: 0 }}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='city'
                    customOnChange={customOnChange}
                    label='City'
                    validate={required()}
                    required
                  />
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='State'
                    validate={required()}
                    required
                    name='state'
                    choices={states}
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='zip'
                    customOnChange={customOnChange}
                    label='Zip'
                    validate={required()}
                    required
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='phone'
                    type='text'
                    format='phone'
                    validate={composeValidators([
                      required(),
                      minLength(10, 'Invalid phone number'),
                    ])}
                    required
                    label='Phone'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    customOnChange={customOnChange}
                    name='phone_ext'
                    label='Extension'
                  />
                  <FormTextField
                    customOnChange={customOnChange}
                    name='fax'
                    type='text'
                    format='phone'
                    validate={composeValidators([
                      minLength(10, 'Invalid phone number'),
                    ])}
                    label='Case management fax'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='start_date'
                    customOnChange={customOnChange}
                    type='date'
                    label='Start date'
                  />
                  <FormTextField
                    name='contract_end_date'
                    customOnChange={customOnChange}
                    type='date'
                    label='Contract end date'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='last_active_date'
                    type='date'
                    label='Deactivated date'
                    disabled
                  />
                  <div style={{ flex: '1 0 160px' }} />
                </div>
                {/* <FormTextField
                  name='email'
                  customOnChange={customOnChange}
                  label='Email'
                  validate={composeValidators([email(), required()])}
                  required
                  fullWidth
                  type='email'
                /> */}
                <FormTextField
                  name='note'
                  label='Notes'
                  multiline
                  fullWidth
                  customOnChange={customOnChange}
                  validate={minReasonLength()}
                />
                <ReferenceInput
                  reference='facility-groups'
                  customOnChange={customOnChange}
                  name='group_id'
                  label='Facility group'
                  renderWith='chip'
                  fullWidth
                  setFormData={setFormData}
                  perPage={500}
                  selectAll
                  resetOption={
                    values.group_id
                      ? { id: null, name: 'Remove from group' }
                      : undefined
                  }
                  options={{
                    filter: {},
                    sort: { field: 'name', order: 'ASC' },
                  }}
                />
                <div className={clsx(classes.inputContainerWrap)}>
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Client group'
                    name='client_group'
                    choices={client_group_choices}
                  />
                  <FormTextField
                    name='billing_group'
                    customOnChange={customOnChange}
                    label='Billing group'
                  />
                </div>
                <SwitchInput
                  checked={values.is_mltc}
                  name='is_mltc'
                  label='Contracted for MLTC'
                  customOnChange={customOnChange}
                />
                <SwitchInput
                  checked={values.is_subacute}
                  name='is_subacute'
                  label='Contracted for subacute'
                  customOnChange={customOnChange}
                />
                <div className={clsx(classes.inputContainerWrap)}>
                  {values.is_mltc && (
                    <ReferenceInput
                      reference='facility-users/list'
                      customOnChange={customOnChange}
                      name='main_mltc_user'
                      label='MLTC case manager'
                      options={{
                        filter: { division: 'mltc', is_active: !isEdit },
                      }}
                      resetOption={{ id: 0, name: 'Clear case manager' }}
                      shouldFetchMore
                    />
                  )}
                  {values.is_subacute && (
                    <ReferenceInput
                      reference='facility-users/list'
                      customOnChange={customOnChange}
                      name='main_subacute_user'
                      label='Subacute case manager'
                      style={{ marginRight: 0 }}
                      options={{
                        filter: { division: 'subacute', is_active: !isEdit },
                      }}
                      resetOption={{ id: 0, name: 'Clear case manager' }}
                      shouldFetchMore
                    />
                  )}
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <ReferenceInput
                    reference='facility-users/list'
                    customOnChange={customOnChange}
                    name='quality_reviewer'
                    label='Quality reviewer'
                    options={{
                      filter: { is_active: !isEdit },
                    }}
                    resetOption={{ id: 0, name: 'Clear quality reviewer' }}
                    shouldFetchMore
                  />

                  <ReferenceInput
                    reference='facility-users/list'
                    customOnChange={customOnChange}
                    name='appeal_reviewer'
                    label='Appeal reviewer'
                    style={{ marginRight: 0 }}
                    options={{
                      filter: { is_active: !isEdit },
                    }}
                    resetOption={{ id: 0, name: 'Clear appeal reviewer' }}
                    shouldFetchMore
                  />
                </div>
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                >
                  Details
                </DialogContentText>
                <Divider className={classes.divider} />
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='tax_id'
                    customOnChange={customOnChange}
                    label='Tax ID #'
                    validate={required()}
                    required
                  />
                  <FormTextField
                    name='medicare_id'
                    customOnChange={customOnChange}
                    label='Medicare ID #'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='medicaid_id'
                    customOnChange={customOnChange}
                    label='Medicaid ID #'
                  />
                  <FormTextField
                    name='num_beds'
                    customOnChange={customOnChange}
                    label='Number of beds'
                    type='number'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='npi'
                    customOnChange={customOnChange}
                    label='NPI #'
                    validate={required()}
                    required
                  />
                  <FormTextField
                    name='emr_accurate'
                    customOnChange={customOnChange}
                    label='Is EMR accurate for payer source'
                  />
                </div>
                <FormGroup>
                  <CheckboxInput
                    name='high_cost_med'
                    customOnChange={customOnChange}
                    label='High cost medication alert'
                    checked={formData.high_cost_med}
                    style={{ pointerEvents: 'none' }}
                  />
                  <CheckboxInput
                    name='handles_appeals'
                    customOnChange={customOnChange}
                    label='MCO Manager handling appeals for HMO'
                    checked={formData.handles_appeals}
                    style={{ pointerEvents: 'none' }}
                  />
                  <CheckboxInput
                    name='is_vent'
                    customOnChange={customOnChange}
                    label='Vent facility'
                    checked={formData.is_vent}
                    style={{ pointerEvents: 'none' }}
                  />
                  <CheckboxInput
                    name='save_nri'
                    customOnChange={customOnChange}
                    label='Save NRI for new admissions'
                    checked={formData.save_nri}
                    style={{ pointerEvents: 'none' }}
                  />
                </FormGroup>
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                >
                  Pharmacy information
                </DialogContentText>
                <Divider className={classes.divider} />
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='pharmacy_name'
                    customOnChange={customOnChange}
                    label='Pharmacy name'
                  />
                  <FormTextField
                    name='pharmacy_contact'
                    customOnChange={customOnChange}
                    label='Pharmacy contact'
                  />
                </div>
                <FormTextField
                  name='pharmacy_email'
                  customOnChange={customOnChange}
                  label='Pharmacy email'
                  validate={composeValidators([email()])}
                  fullWidth
                  type='email'
                />
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                >
                  Physician information
                </DialogContentText>
                <Divider className={classes.divider} />
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='physician'
                    customOnChange={customOnChange}
                    label='Physician name'
                  />
                  <FormTextField
                    name='physician_address'
                    customOnChange={customOnChange}
                    label='Physician address'
                  />
                </div>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='physician_tax_id'
                    customOnChange={customOnChange}
                    label='Physician tax ID'
                  />
                  <FormTextField
                    name='physician_npi'
                    customOnChange={customOnChange}
                    label='Physician NPI'
                  />
                </div>
                <DialogContentText
                  align='left'
                  variant='h6'
                  className={classes.header}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Integration -
                  {!!record?.integration_type && !!record?.integration_id ? (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'normal',
                        marginLeft: 5,
                      }}
                    >
                      synced <SyncIcon />
                    </span>
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'normal',
                        marginLeft: 5,
                      }}
                    >
                      not synced <SyncDisabledIcon />
                    </span>
                  )}
                </DialogContentText>
                <Divider className={classes.divider} />

                <DialogContentText
                  color='primary'
                  onClick={toggleIntegrationFields}
                  className={classes.secondaryAction}
                >
                  <AddIcon />{' '}
                  {`${
                    showIntegrationFields ? 'Hide' : 'Show'
                  } Integration Fields`}
                </DialogContentText>
                {showIntegrationFields && (
                  <Fragment>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <ReferenceInput
                        reference='external-integrations/list'
                        customOnChange={customOnChange}
                        name='integration_type'
                        label='Integration type'
                      />
                      {!!values.integration_type ? (
                        <ReferenceInput
                          key={values.integration_type}
                          reference={
                            integrationFacilityData[
                              integrationObj[+values.integration_type].type
                            ].facilityIdURL
                          }
                          customOnChange={customOnChange}
                          name='integration_id'
                          label='Integration facility ID'
                          style={{ marginRight: 0 }}
                          options={{
                            filter: { integration_id: values.integration_type },
                          }}
                        />
                      ) : (
                        <div style={{ flex: '1 0 160px' }} />
                      )}
                    </div>
                    <div className={clsx(classes.inputContainerWrap)}>
                      <CheckboxInput
                        name='integration_isactive'
                        customOnChange={customOnChange}
                        label='Active'
                        checked={formData.integration_isactive}
                        style={{ pointerEvents: 'none' }}
                      />
                    </div>
                  </Fragment>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16, justifyContent: 'space-between' }}
              >
                {isEdit && userPermissions.indexOf('admin') > -1 ? (
                  <DeleteRecord
                    handleClose={handleClose}
                    resource='facilities'
                    id={record.id}
                    record={record}
                  />
                ) : (
                  <span />
                )}
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

function sanitizeFacility(record) {
  const {
    id,
    FacilitiesMainContact,
    pharmacy = {},
    created_by,
    updated_by,
    facilityCreatedBy,
    facilityUpdatedBy,
    ...rest
  } = record;
  const { name: pharmacy_name, pharmacies_contact } = pharmacy;
  const {
    name: pharmacy_contact,
    email: pharmacy_email,
    id: pharmacies_contact_id,
  } = pharmacies_contact || {};
  return {
    ...rest,
    pharmacy_name,
    pharmacy_contact,
    pharmacy_email,
    pharmacies_contact_id,
  };
}

const integrationFacilityData = {
  pcc: { facilityIdURL: 'pcc/facilities' },
};
