import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  FunctionField,
  TextField,
  ReferenceField,
  useRefresh,
  TopToolbar,
  useUnselectAll,
} from 'react-admin';
import { Datagrid } from '../components/common';
import { NewEvent } from '../components/common/modals';
import { useStyles } from '../resources/payers/payers.styles';
import { EditIcon, AddIcon, CustomButton } from '../design';
import { IconButton } from '@material-ui/core';

const ListActions = ({ record, payerId, permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span />
        <CustomButton
          Icon={AddIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Rev Codes'
          type='button'
          variant='text'
          size='small'
          onClick={() => setEventModal(true)}
        />
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='globalRevCodes'
          refresh={refresh}
          payerId={payerId}
        />
      )}
    </Fragment>
  );
};

export const GlobalRevCodes = ({ payerId, ...props }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const { permissions } = props;
  const isAdminOrSupervisor =
    permissions.indexOf('admin') > -1 || permissions.indexOf('supervisor') > -1;
  const isContractUser = permissions.indexOf('contract_user') > -1;
  const [revCodeRecord, setRevCodeRecord] = useState();
  const [eventOpen, setEventModal] = useState(false);
  const controllerProps = useListController({
    ...props,
    resource: 'global-rev-codes',
    filter: { payer_id: payerId },
  });

  const unselectAll = useUnselectAll('global-rev-codes');
  useEffect(() => {
    return () => unselectAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // see comment on case_communication
    if (revCodeRecord) {
      setEventModal(true);
    }
  }, [revCodeRecord]);

  const handleModalClose = () => {
    setEventModal(false);
    setRevCodeRecord(undefined);
  };
  return (
    <div className='show-list'>
      <ListView
        empty={false}
        {...controllerProps}
        bulkActionButtons={
          !isAdminOrSupervisor && !isContractUser
            ? false // disable delete
            : undefined
        }
        actions={
          isAdminOrSupervisor || isContractUser ? (
            <ListActions payerId={payerId} />
          ) : null
        }
        exporter={false}
      >
        <Datagrid>
          <ReferenceField
            source='level'
            label='Level'
            headerClassName={classes.listItemLabel}
            reference='auth-types/list'
            link={false}
            sortable={false}
          >
            <TextField source='name' />
          </ReferenceField>

          <FunctionField
            source='rev_codes'
            label='Rev codes'
            headerClassName={classes.listItemLabel}
            render={record => {
              const { rev_codes: _rev_codes } = record;
              // could be null...
              const rev_codes = _rev_codes ? _rev_codes : null;
              return Array.isArray(rev_codes) ? rev_codes.join(', ') : null;
            }}
          />
          <FunctionField
            source=''
            label=''
            sortable={false}
            render={record => {
              return isContractUser || isAdminOrSupervisor ? (
                <IconButton
                  style={{ color: '#5C738E' }}
                  onClick={() => setRevCodeRecord(record)}
                  size='small'
                >
                  <EditIcon />
                </IconButton>
              ) : null;
            }}
          />
        </Datagrid>
      </ListView>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={revCodeRecord}
          form='globalRevCodes'
          isEdit
          refresh={refresh}
          payerId={payerId}
        />
      )}
    </div>
  );
};
