import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import qs from 'query-string';
import { facilityActions } from '../../state/actions';
import {
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useStyles } from './appbar.styles';
import { Autocomplete } from '../wrappers';
import { SearchIcon } from '../../design';

export const Search = ({ className }) => {
  const classes = useStyles();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const dispatch = useDispatch();
  const notify = useNotify();
  const [focused, setFocus] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [searchAll, setSearchAll] = useState(false);
  const debounceFilter = debounce(value => {
    setFilter(value);
  }, 300);
  useEffect(() => {
    if (filter.trim().length > 2) {
      dataProvider
        .getList('residents-search', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'last_name', order: 'ASC' },
          filter: { q: filter, search_all: searchAll ? true : undefined },
        })
        .then(response => {
          if (response) {
            const { data } = response;
            setData(data);
          }
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchAll]);

  const onChange = e => {
    debounceFilter(e.target.value);
  };

  const onClick = value => {
    if (value === null) return;
    dataProvider
      .getOne('residents-search', {
        id: value,
      })
      .then(async ({ data }) => {
        const { facility_id, id } = data;
        const resident_id = id;
        if (!facility_id || !resident_id) return null;
        await dispatch(facilityActions.getFacility(facility_id));
        dispatch(push(`/residents-list?${qs.stringify({ resident_id })}`));
        setFocus(false);
      });
  };

  return (
    <Fragment>
      <Tooltip title='Search' placement='bottom'>
        <IconButton onClick={() => setFocus(!focused)}>
          <SearchIcon
            className={focused ? classes.searchFocused : classes.dashboard}
          />
        </IconButton>
      </Tooltip>

      <div
        className={clsx(className, {
          [classes.focused]: focused,
          [classes['lost-focus']]: !focused,
        })}
      >
        {focused && (
          <Autocomplete
            onChange={onChange}
            suggestions={data}
            parentOnClick={onClick}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
              notchedOutline: classes.notchedOutline,
            }}
            paperClassName={classes.paper}
            otherInputProps={{ variant: 'outlined' }}
            autoFocus
            placeholder='search residents'
            isAppBar
            isResidentSearch
          />
        )}
      </div>
      {focused && (
        <Tooltip title='Search all facilities' placement='bottom'>
          <FormControlLabel
            className={classes.searchBox}
            label={<div style={{ fontSize: 12 }}>All</div>}
            control={
              <Checkbox
                color='primary'
                className={classes.searchBox}
                style={{
                  transform: 'scale(.9)',
                }}
                checked={searchAll}
                onChange={() => setSearchAll(!searchAll)}
                size='small'
              />
            }
          />
        </Tooltip>
      )}
    </Fragment>
  );
};
