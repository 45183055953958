import React, {
  useCallback,
  useEffect,
  useState,
  isValidElement,
  cloneElement,
} from 'react';
import { useRedirect } from 'react-admin';
import { Toolbar, Tabs, Tab } from '@material-ui/core';
import { CensusEventList } from './censusPage';
import { CensusFileList } from './census';
import { useStyles } from '../clinical/clinical.styles';
import { useSelector } from 'react-redux';
import { authGet } from '../../server';
import { Loading } from '../../components/common';
import { useGetUserRoles } from '../../hooks';

const tabs = {
  manual: { component: <CensusFileList />, title: 'Manual', key: 'manual' },
  digital: { component: <CensusEventList />, title: 'Digital', key: 'digital' },
};

export function CensusPage(props) {
  const classes = useStyles();
  const redirect = useRedirect();
  const facilityId = useSelector(state => state.facility.id);
  const [facilityIsIntegrated, setFacilityIsIntegrated] = useState();
  const position = props.match.params.page;
  const {
    is_admin,
    is_case_manager,
    is_supervisor,
    is_quality_reviewer,
    is_view_admin,
    is_admin_assistant,
  } = useGetUserRoles();

  const facilityIntegrationKnown = facilityIsIntegrated !== undefined;

  useEffect(() => {
    if (facilityId !== undefined && !facilityIntegrationKnown) {
      authGet(`/facilities/${facilityId}`).then(response => {
        const {
          integration_id,
          integration_isactive,
          integration_type,
        } = response.data;
        const isIntegrated =
          integration_id && integration_isactive && integration_type;
        setFacilityIsIntegrated(isIntegrated);
      });
    }
  }, [facilityId, facilityIntegrationKnown]);

  useEffect(() => {
    const unlistedPosition = !Object.values(tabs)
      .map(t => t.key)
      .includes(position);

    const disallowedDigitalSelected =
      !facilityIsIntegrated && position === tabs.digital.key;

    const positionNotValid = unlistedPosition || disallowedDigitalSelected;

    if (positionNotValid && facilityIntegrationKnown) {
      const defaultTab = facilityIsIntegrated ? tabs.digital : tabs.manual;
      redirect(`/census/${defaultTab.key}`);
    }
  }, [facilityIntegrationKnown, facilityIsIntegrated, position, redirect]);

  const handleChange = useCallback(
    (event, newValue) => {
      redirect(`/census/${newValue}`);
    },
    [redirect],
  );

  const allowsDigitalPage =
    (is_admin ||
      is_case_manager ||
      is_supervisor ||
      is_quality_reviewer ||
      is_view_admin ||
      is_admin_assistant) &&
    facilityIsIntegrated;
  const component = allowsDigitalPage
    ? tabs?.[position]?.component
    : tabs.manual.component; // non admin only sees manual list

  return (
    <div>
      <Toolbar disableGutters>
        <div className={classes.toolbarContent}>
          <div className={classes.tabContainer}>
            <span className={classes.title}>Census</span>
            {allowsDigitalPage && (
              <Tabs
                value={position || false}
                indicatorColor='primary'
                textColor='inherit'
                onChange={handleChange}
                centered
              >
                <Tab
                  label={tabs.manual.title}
                  classes={{ root: classes.tab, selected: classes.selectedTab }}
                  value={tabs.manual.key}
                />
                <Tab
                  label={tabs.digital.title}
                  classes={{ root: classes.tab, selected: classes.selectedTab }}
                  value={tabs.digital.key}
                />
              </Tabs>
            )}
          </div>
        </div>
      </Toolbar>
      {(facilityIntegrationKnown &&
        isValidElement(component) &&
        cloneElement(component, {
          ...props,
        })) || <Loading />}
    </div>
  );
}
