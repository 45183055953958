import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {
  FunctionField,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { DateField, TrackUser } from '../../components/common';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import { constants } from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes } = constants;

export const CasePayerChangeShow = ({
  closeAside,
  classes,
  residentId,
  caseId,
  setRefreshTimeline,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const showProps = useShowController({ ...props });
  const { record = {} } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            title='Payer Change'
            resourceRecord={record}
            caseId={caseId}
            eventForm='editPayerChange'
            eventId={record.cases_payer_id}
            eventName={eventTypes.CASE_PAYER_NAME}
            setRefreshTimeline={setRefreshTimeline}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <TextField
            source='change_type'
            label='Change type'
            textAlign='right'
            className={classes.showItem}
          />
          <DateField
            source='effective_date'
            label='Effective date'
            textAlign='right'
            className={classes.showItem}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          <FunctionField
            source='payerChangeCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { payerChangeCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={payerChangeCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='payerChangeUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { payerChangeUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={payerChangeUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            setRefreshTimeline={setRefreshTimeline}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};
