import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { Tooltip, IconButton, TextField } from '@material-ui/core';
import { useStyles } from './ListSearch.styles';
import { SearchIcon } from '../../design';

export const ListSearch = ({
  className = '',
  styles = {},
  doSearch,
  debounceRate = 300,
  placeholder,
}) => {
  const classes = useStyles();
  const [focused, setFocus] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (focused) {
      ref.current.focus();
    } else {
      doSearch('');
      ref.current.value = '';
      ref.current.blur();
    }
  }, [focused, doSearch]);

  const debounceDoSearch = debounce(value => {
    doSearch(value);
  }, debounceRate);

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...styles }}>
      <Tooltip title='Search' placement='bottom'>
        <IconButton onClick={() => setFocus(!focused)}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <TextField
        placeholder={placeholder}
        focused={focused}
        inputRef={ref}
        onChange={e => debounceDoSearch(e.target.value)}
        size='small'
        color='secondary'
        variant='outlined'
        className={classes.field}
        InputProps={{
          className: clsx(className, classes.smooth_focusing, {
            [classes.focused]: focused,
            [classes.lost_focus]: !focused,
          }),
        }}
      />
    </div>
  );
};
