import React from 'react';
import { FunctionField } from 'react-admin';
import { format, isValid } from 'date-fns';

// Need this because of https://stackoverflow.com/a/39224282
export const DateField = ({
  record = {},
  source,
  className,
  headerClassName,
  style,
}) => {
  const value = record[source];
  if (!value) {
    return null;
  }

  const date = new Date(`${value} 00:00`);
  return isValid(date) ? (
    <FunctionField
      record={record}
      source={source}
      className={className}
      headerClassName={headerClassName}
      render={record => format(date, 'M/d/yyyy')}
      style={style}
    />
  ) : null;
};
DateField.defaultProps = {
  addLabel: true,
};
