import { useEffect } from 'react';
import startCase from 'lodash/startCase';

const { NODE_ENV } = process.env;

/**
 * Converts an array to a object with id as the key.
 * Optional sortIds array to get an array of sorted ids.
 * @param {array} array
 * @param {array} [sortIds]
 */
export const convertToObj = (array, sortIds) => {
  return array.reduce((acc, cur) => {
    if (sortIds) {
      sortIds.push(cur.id);
    }
    acc[cur.id + ''] = cur;
    return acc;
  }, {});
};

export const removeDupesObjArray = (curValues, newValues) => {
  if (!Array.isArray(curValues) || !Array.isArray(newValues)) {
    return [];
  }
  const curIds = curValues.map(v => v.id);
  const newIds = newValues.map(v => v.id);
  const removeDupesIds = Array.from(new Set([...curIds, ...newIds]));
  const toObjById = convertToObj([...curValues, ...newValues]);
  return removeDupesIds.map(id => toObjById[id]);
};

/**
 * Displays a case name as "LastName FirstName-AdmitDate"
 * @param {Object} props
 */
export const DisplayName = ({ record = {}, source, parentRecord }) => {
  const { admit_date } = parentRecord;
  const { first_name, last_name } = record;
  return `${last_name}, ${first_name}-${admit_date}`;
};

export const calculateDaysUsed = record => {
  const {
    days_used: _days_used,
    active_days_used: _active_days_used,
    bedhold_days_used: _bedhold_days_used,
  } = record;
  // need to check for null...
  const days_used = _days_used && !isNaN(_days_used) ? parseInt(_days_used) : 0;
  const active_days_used =
    _active_days_used && !isNaN(_active_days_used)
      ? parseInt(_active_days_used)
      : 0;
  const bedhold_days_used =
    _bedhold_days_used && !isNaN(_bedhold_days_used)
      ? parseInt(_bedhold_days_used)
      : 0;
  const activeDaysUsed = active_days_used - bedhold_days_used;
  return days_used + activeDaysUsed;
};

export const getChip = _status => {
  const status = startCase(_status);
  if (status.includes('Wrong')) return 'denial';
  switch (status) {
    case 'Active':
    case 'Approved':
    case 'Carve Out':
    case 'New Payer':
    case 'New Primary Payer':
    case 'Auth Extended':
    case 'Success':
    case 'Sent':
    case 'Received':
    case 'Completed':
    case 'Inbound':
      return 'active';
    case 'Denied Disenrolled':
    case 'Denial Not Appealing':
    case 'Denied Subacute':
    case 'Denied MLTC':
    case 'Denied Subacute/MLTC':
    case 'Denied Subacute MLTC':
    case 'Denied':
    case 'Missing':
    case 'Sending Failed':
    case 'Payer Unable To Respond At The Current T':
    case 'Insured Not Found':
      return 'denial';
    case 'To Do':
      return 'denial';
    case 'Discharged Community':
    case 'Discharged Hospital':
    case 'Discharged AMA':
    case 'Discharged ALF':
    case 'Discharged Another SNF':
    case 'Discharged':
    case 'Denial Received':
    case 'Not Started':
    case 'NOMNC – MCR HMO Denial':
    case 'Commercial HMO Denial':
    case 'Level Denial':
    case 'Carve Out Denial':
    case 'MCD HMO Skilled Stay Denial':
    case 'MCD HMO ALOC Denial':
    case 'MCD HMO Custodial Denial':
    case 'MLTC Custodial Denial':
    case 'MLTC Vent Denial':
    case 'MLTC Coinsurance Denial':
    case 'Bed Hold Denial':
    case 'Therapeutic Leave Denial':
    case 'Retro Request Denial':
    case 'Resent':
      return 'discharged';
    case 'Expired':
    case 'Terminated':
    case 'Approved Ended':
    case 'Closed':
    case 'Inactive':
    case 'Dismissed':
    case 'N/A':
    case 'N A': // 'N/A' becomes 'N A' after passing it to 'startCase'
      return 'closed';
    case 'Queued':
    case 'Pending':
    case 'Medicaid Pending':
    case 'In Progress':
    case 'Planned':
    case 'Leave Of Absence':
    case 'Denial Overturned':
    case 'Denial Upheld':
    case 'Payer Changed':
      return 'pending';
    case 'Higher Level':
      return 'mltcSidebar';
    case 'Appeal Sent':
    case 'Appeal In Review':
    case 'In Review':
    case 'Posted On Trello':
    case 'Claim Appeal':
      return 'appeal';
    default:
  }
};

export const getAttchIcon = type => {
  switch (type) {
    case '.pdf':
      return 'PdfIcon';
    case '.docx':
      return 'DocxIcon';
    case '.xlsx':
      return 'XlsxIcon';
    default:
      return 'FileIcon';
  }
};

export const getIntBoolValue = value => {
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  return value;
};

/**
 * Limit the length of a string with an optional parameter
 * to show an ellipsis at the end of the string.
 * @param {string} string
 * @param {number} limit
 * @param {boolean} showEllipsis [n]
 * @return {string} The truncated string
 */
export const limitStringSize = (string, limit, showEllipsis) => {
  if (typeof string !== 'string' || string.length <= limit) {
    return string;
  }
  return showEllipsis
    ? string.substr(0, limit - 2) + '...'
    : string.substr(0, limit);
};

/**
 * Returns the integer value of a string.
 * For example "20" will return 20
 * @param {string} value
 */
export const getIntValue = value => {
  if (!value || isNaN(value)) {
    return value;
  }
  const intValue = parseInt(value);
  return intValue > 0 ? intValue : 0;
};

export const currencyFormat = value => {
  if (!value || isNaN(value)) {
    return value;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};

export const getInitials = (name, props) => {
  if (!name) return name;
  const split = name.split(', ');
  if (split.length < 2) return '';
  return `${split[1].charAt(0)}`;
};

export const getFieldsByPrefix = (object = {}, prefix = '') => {
  return Object.keys(object).reduce((acc, cur) => {
    if (cur.substring(0, prefix.length) === prefix) {
      acc[cur] = object[cur];
    }
    return acc;
  }, {});
};

export const getBaseReportUrl = () => {
  if (NODE_ENV === 'development') {
    return 'http://localhost:10010';
  }
  return window.location.origin;
};

export function ensureUrlProtocol(url) {
  if (!url) return url;
  let validUrl = url;
  if (!url.startsWith('http')) {
    validUrl = 'http://' + url;
  }
  return validUrl;
}

export function isValidEmail(email) {
  if (typeof email !== 'string') return email;
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
