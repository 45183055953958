import React, { useState, useCallback, Fragment, useEffect } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  Button,
  Chip,
  Typography,
} from '@material-ui/core';
import {
  ReferenceInput,
  AutocompleteInput,
  FormTextField,
  handleNumbers,
  getDateInputValue,
} from '.';
import { useStyles } from '../modal.styles';
import { SaveButton, AddIcon, RemoveIcon } from '../../../../design';
import { constants } from '../../../../utils';
const { ruleTypes } = constants;
const {
  INCLUDE_ITEMS_LOWER_LEVELS,
  ADL_SCORE,
  MENTAL_STATUS_SCORE,
  DEPRESSION,
  COGNITIVE_SCORE,
  PERCENTAGE,
} = ruleTypes;
export const QualifyingCriteriaForm = ({
  record = {},
  isEdit,
  payerFacilityId,
  handleClose,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [formData, setFormData] = useState({
    payer_facility_id: payerFacilityId,
  });
  const [services, setServices] = useState([]);
  const [includeServices, setIncludeServices] = useState([]);
  const [ruleArray, setRuleArray] = useState([]);
  const [counter, setCounter] = useState(0);
  const noFrequencyRuleIds = [
    INCLUDE_ITEMS_LOWER_LEVELS,
    ADL_SCORE,
    MENTAL_STATUS_SCORE,
    DEPRESSION,
    COGNITIVE_SCORE,
    PERCENTAGE,
  ];
  const includeLowerLevelRuleId = INCLUDE_ITEMS_LOWER_LEVELS;

  useEffect(
    () => {
      if (isEdit && record) {
        const {
          service_ids,
          rate,
          rule_ids,
          included_rates_services,
          ...rest
        } = record;
        const { id } = rate;
        if (service_ids) {
          const serviceArray = service_ids.split('!');
          const services = serviceArray.map(service => JSON.parse(service));
          setServices(services);
        }
        if (included_rates_services) {
          const includeServiceArray = included_rates_services.split(';');
          const services = includeServiceArray
            .map(service => JSON.parse(service))
            .filter(service => service.value !== 0);
          //combine multiple services in the same rate-service
          const merged = services.reduce((a, v) => {
            if (a[v.value]) {
              a[v.value].name = [a[v.value].name, v.name].join(' and ');
            } else {
              a[v.value] = v;
            }
            return a;
          }, {});
          setIncludeServices(Object.values(merged));
        }
        let mappedRules;
        if (rule_ids) {
          const rulesArray = rule_ids.split(';');
          const rules = rulesArray.map(rule => JSON.parse(rule));
          setCounter(rules.length);
          setRuleArray([
            ...ruleArray,
            ...rules.map((rule, index) => index + '_' + index),
          ]);
          const formRules = rules.map((rule, index) => {
            const name = index + '_' + index;
            return {
              [`${name}_id`]: rule.id,
              [`${name}_rule_type_id`]: rule.rule_type_id,
              [`${name}_min`]: rule.min || null,
              [`${name}_max`]: rule.max || null,
              [`${name}_frequency_type_id`]: rule.frequency_type_id || null,
              [`${name}_frequency_min`]: rule.frequency_min || null,
              [`${name}_frequency_max`]: rule.frequency_max || null,
              [`${name}_include_rate`]: rule.include_rate || null,
              [`${name}_include_rate_service_ids`]:
                rule.include_rate_service_ids || null,
            };
          });
          mappedRules = Object.assign(...formRules);
        }
        setFormData(f => ({
          ...f,
          rate_id: id,
          ...mappedRules,
          ...rest,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEdit, record],
  );

  const handleDelete = useCallback(value => {
    setServices(cur => cur.filter(c => c !== value));
  }, []);
  const onClick = useCallback(
    value => {
      value[0].name = value[0].name.split(' - ')[0].split(' , ')[0];
      setServices(curServices => [...curServices, ...value]);
    },
    [setServices],
  );

  const onIncludeClick = useCallback(
    value => {
      setIncludeServices(curServices => [...curServices, ...value]);
    },
    [setIncludeServices],
  );

  const handleIncludeDelete = useCallback(value => {
    setIncludeServices(cur => cur.filter(c => c !== value));
  }, []);

  const validateRules = rawRules => {
    const rules = rawRules.map(item =>
      item.reduce(
        (acc, [key, val]) => ({
          ...acc,
          [key
            .split('_')
            .slice(2)
            .join('_')]: val,
        }),
        {},
      ),
    );

    const validated = rules.map(row => {
      if (
        noFrequencyRuleIds.includes(row.rule_type_id) &&
        row.rule_type_id !== includeLowerLevelRuleId
      ) {
        return {
          id: row.id,
          rule_type_id: row.rule_type_id,
          min: row.min,
          max: row.max,
        };
      } else if (row.rule_type_id === includeLowerLevelRuleId) {
        return {
          id: row.id,
          rule_type_id: row.rule_type_id,
          min: row.min,
          max: row.max,
          include_rate: row.include_rate,
          include_rate_service_ids: includeServices.length
            ? Array.from(new Set(includeServices.map(row => row.value)))
            : undefined,
        };
      } else return row;
    });
    const unique = [
      ...new Map(validated.map(v => [v.rule_type_id, v])).values(),
    ];
    if (unique.length !== validated.length) {
      notify(
        'Duplicate rule types cannot be entered in the same set of criteria.',
      );
      return;
    }
    return unique;
  };

  const onSubmit = value => {
    const { rate_id, category_id } = value;
    const service_ids = Array.from(new Set(services.map(row => row.value)));
    const rawRules = ruleArray.map((row, index) =>
      Object.entries(value).filter(name => name[0].includes(row)),
    );
    const rules = validateRules(rawRules);
    if (rawRules && rules) {
      if (isEdit) {
        return dataProvider
          .update('payers-facility/rates-services', {
            id: record.id,
            data: { rate_id, service_ids, rules, category_id },
            previousData: { ...record },
          })
          .then(({ data }) => {
            notify('form.updated');
            handleClose();
            refresh();
          })
          .catch(error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          );
      } else {
        return dataProvider
          .create('payers-facility/rates-services', {
            data: { rate_id, service_ids, rules, category_id },
          })
          .then(({ data }) => {
            notify('form.created');
            handleClose();
            refresh();
          })
          .catch(error =>
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            ),
          );
      }
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    if (name.includes('include_rate')) {
      setIncludeServices([]);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <Typography className={classes.instructions}>
                    Select a rate linked to this payer's contract.
                  </Typography>
                  <ReferenceInput
                    reference='rates/list'
                    customOnChange={customOnChange}
                    name='rate_id'
                    label='Rate'
                    validate={required()}
                    required
                    fullWidth
                    options={{
                      filter: { payer_facility_id: payerFacilityId },
                    }}
                    style={{ marginLeft: 0, marginRight: 0 }}
                  />
                </div>
                <Typography className={classes.instructions}>
                  Enter categories and/or services which qualify for this rate.
                  {<br />}
                  Each qualifying item (or combination of items) must be saved
                  as an individual set of criteria.
                </Typography>
                <div style={{ marginLeft: 0, marginRight: 5 }}>
                  <ReferenceInput
                    fullWidth
                    reference='categories/list'
                    customOnChange={customOnChange}
                    name='category_id'
                    label='Category'
                    style={{ marginRight: 0 }}
                    resetOption={{ id: null, name: 'No category' }}
                  />
                </div>
                <div style={{ marginLeft: 0, marginRight: 5 }}>
                  <AutocompleteInput
                    key={values.category_id}
                    trimLength={1}
                    clearInputOnClick
                    fullWidth
                    reference='services/list'
                    customOnChange={customOnChange}
                    name='services'
                    label='Qualifying services'
                    openOnFocus
                    autocompleteProps={{ openOnFocus: true }}
                    parentOnClick={onClick}
                    getName
                    multiSelect
                    viewAll
                    options={{
                      filter: { category_id: values.category_id },
                    }}
                    highlightItems={services?.map(value => value.value)}
                  />
                </div>
                <div>
                  {!!services.length && (
                    <div style={{ margin: 10 }}>
                      {services.map(row => {
                        return (
                          <Chip
                            key={row.value}
                            label={
                              <Typography
                                style={{
                                  whiteSpace: 'normal',
                                  fontSize: 13,
                                }}
                              >
                                {row.name}
                              </Typography>
                            }
                            onDelete={() => handleDelete(row)}
                            className={classes.chip}
                            style={{
                              height: '100%',
                              maxWidth: 350,
                            }}
                            size='small'
                            disabled={!services}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
                <Typography className={classes.instructions}>
                  {<br />}
                  Add any rules which the above service(s) must meet to qualify
                  for this rate.
                </Typography>
                <div className={classes.secondaryActionContainer}>
                  {ruleArray[0] ? (
                    <Button
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setRuleArray(
                          ruleArray.filter((item, index) => index !== 0),
                        );
                      }}
                      classes={{
                        label: classes.removeIcon,
                      }}
                    >
                      Rule
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setCounter(counter + 1);
                        setRuleArray([...ruleArray, 0 + '_' + counter]);
                      }}
                      startIcon={<AddIcon />}
                      classes={{
                        root: classes.iconButton,
                      }}
                      disabled={!values.rate_id}
                    >
                      Rule
                    </Button>
                  )}
                </div>
                {ruleArray.map((row, index) => {
                  const rule = index;
                  const name = row;
                  return (
                    <Fragment>
                      <div className={clsx(classes.inputContainerWrap)}>
                        <ReferenceInput
                          key={formData.category_id}
                          reference='rule-types/list'
                          customOnChange={customOnChange}
                          label='Rule type'
                          name={`${name}_rule_type_id`}
                          fullWidth
                          style={{ marginRight: 0 }}
                          options={{
                            filter: {
                              category_id: formData.category_id,
                            },
                          }}
                        />
                      </div>
                      {formData[`${name}_rule_type_id`] && (
                        <div className={classes.inputContainerWrap}>
                          <FormTextField
                            name={`${name}_min`}
                            label='Min quantity'
                            className={classes.inputCell}
                            customOnChange={customOnChange}
                            type='number'
                            min={0}
                          />
                          <FormTextField
                            name={`${name}_max`}
                            label='Max quantity'
                            className={classes.inputCell}
                            customOnChange={customOnChange}
                            type='number'
                          />
                        </div>
                      )}

                      {formData[`${name}_rule_type_id`] &&
                        !noFrequencyRuleIds.includes(
                          formData[`${name}_rule_type_id`],
                        ) && (
                          //Show frequency (as optional field) if rule has a 'per'.
                          <Fragment>
                            <div className={clsx(classes.inputContainerWrap)}>
                              <ReferenceInput
                                reference='frequency-types/list'
                                customOnChange={customOnChange}
                                label='Frequency type'
                                name={`${name}_frequency_type_id`}
                                fullWidth
                                style={{ marginRight: 0 }}
                              />
                            </div>
                            <div className={classes.inputContainerWrap}>
                              <FormTextField
                                name={`${name}_frequency_min`}
                                label='Min frequency'
                                className={classes.inputCell}
                                customOnChange={customOnChange}
                                type='number'
                                min={0}
                              />
                              <FormTextField
                                name={`${name}_frequency_max`}
                                label='Max frequency'
                                className={classes.inputCell}
                                customOnChange={customOnChange}
                                type='number'
                              />
                            </div>
                          </Fragment>
                        )}
                      {formData[`${name}_rule_type_id`] ===
                        includeLowerLevelRuleId && (
                        //looking for lower rates and lower treatments to qualify for this rate.
                        <Fragment>
                          <div className={clsx(classes.inputContainerWrap)}>
                            <Typography className={classes.instructions}>
                              Select a lower level which can qualify for this
                              rate.
                            </Typography>
                            <ReferenceInput
                              key={formData.rate_id}
                              reference='rates/list'
                              customOnChange={customOnChange}
                              name={`${name}_include_rate`}
                              label='Lower level'
                              validate={required()}
                              fullWidth
                              options={{
                                filter: {
                                  payer_facility_id: payerFacilityId,
                                  rate_id: formData.rate_id,
                                },
                              }}
                              style={{ marginRight: 0 }}
                            />
                            <Typography className={classes.instructions}>
                              Select all items/categories in the lower level
                              which can qualify for this rate. (If any item can
                              qualify, leave the selection empty.)
                            </Typography>
                            <AutocompleteInput
                              key={formData[`${name}_include_rate`]}
                              trimLength={1}
                              clearInputOnClick
                              fullWidth
                              reference='rates-services/list'
                              customOnChange={customOnChange}
                              name={`${name}_include_rate_service_ids`}
                              label='Items in lower levels'
                              openOnFocus
                              options={{
                                filter: {
                                  rate_id: formData[`${name}_include_rate`], //All rates_services selections with this lower rate.
                                },
                              }}
                              autocompleteProps={{ openOnFocus: true }}
                              style={{ marginLeft: 0 }}
                              parentOnClick={onIncludeClick}
                              getName
                              multiSelect
                              viewAll
                              highlightItems={includeServices?.map(
                                value => value.value,
                              )}
                            />
                          </div>
                          <div>
                            {!!includeServices.length && (
                              <div style={{ margin: 10 }}>
                                {includeServices.map(row => {
                                  return (
                                    <Chip
                                      key={row.value}
                                      label={
                                        <Typography
                                          style={{
                                            whiteSpace: 'normal',
                                            fontSize: 13,
                                          }}
                                        >
                                          {row.name}
                                        </Typography>
                                      }
                                      onDelete={() => handleIncludeDelete(row)}
                                      className={classes.chip}
                                      style={{
                                        height: '100%',
                                        maxWidth: 350,
                                      }}
                                      size='small'
                                      disabled={!includeServices}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </Fragment>
                      )}
                      <div className={classes.secondaryActionContainer}>
                        {ruleArray[rule + 1] ? (
                          <Button
                            startIcon={<RemoveIcon />}
                            onClick={() => {
                              setRuleArray(
                                ruleArray.filter(
                                  (item, index) => index !== rule + 1,
                                ),
                              );
                            }}
                            classes={{
                              label: classes.removeIcon,
                            }}
                          >
                            Rule
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setCounter(counter + 1);
                              setRuleArray([
                                ...ruleArray,

                                rule + 1 + '_' + counter,
                              ]);
                            }}
                            startIcon={<AddIcon />}
                            classes={{
                              root: classes.iconButton,
                            }}
                          >
                            Rule
                          </Button>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
