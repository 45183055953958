import { authGet } from '../../server';
import { convertToObj } from '../../utils';
export const uiTypes = {
  GET_SIDEBAR_CASES: 'GET_SIDEBAR_CASES',
  UPDATE_FACILITY_LIST: 'UPDATE_FACILITY_LIST',
  CLEAR_REDIRECT: 'CLEAR_REDIRECT',
  GET_CASE_TASKS_COUNT: 'GET_CASE_TASKS_COUNT',
  GET_FAX_INBOX_COUNT: 'GET_FAX_INBOX_COUNT',
  GET_RESIDENT_CASE_COUNT: 'GET_RESIDENT_CASE_COUNT',
  UPDATE_SHOW_FAB: 'UPDATE_SHOW_FAB',
};

export const uiActions = {
  getSidebarCases() {
    return async dispatch => {
      const response = await authGet(['/cases/list', { status_id: 1 }]);
      if (response.error) {
        return null;
      }
      // const sortIds = [];
      dispatch({
        type: uiTypes.GET_SIDEBAR_CASES,
        payload: convertToObj(response.data /*, sortIds */),
      });
    };
  },
  updateFacilityList(newFacility) {
    return dispatch => {
      dispatch({
        type: uiTypes.UPDATE_FACILITY_LIST,
        payload: newFacility,
      });
    };
  },
  clearRedirect() {
    return dispatch => {
      dispatch({
        type: uiTypes.CLEAR_REDIRECT,
      });
    };
  },
  getCaseTasksCount(caseId) {
    return async dispatch => {
      const response = await authGet(`/cases/${caseId}/tasks-count`);
      if (response.error) {
        return null;
      }
      dispatch({
        type: uiTypes.GET_CASE_TASKS_COUNT,
        payload: response.data,
      });
    };
  },
  getFaxInboxCount() {
    return async dispatch => {
      const response = await authGet(`/fax-inbox-count`);
      if (response.error) {
        return null;
      }
      dispatch({
        type: uiTypes.GET_FAX_INBOX_COUNT,
        payload: response.data,
      });
    };
  },
  getResidentCasesCount(caseId) {
    return async dispatch => {
      const response = await authGet(`/cases/${caseId}/case-count`);
      if (response.error) {
        return null;
      }
      dispatch({
        type: uiTypes.GET_RESIDENT_CASE_COUNT,
        payload: response.data,
      });
    };
  },
  updateCaseTasksCount(newTasksAmount) {
    return dispatch => {
      dispatch({
        type: uiTypes.GET_CASE_TASKS_COUNT,
        payload: newTasksAmount,
      });
    };
  },
  updateShowFab(showFab) {
    return async dispatch => {
      dispatch({
        type: uiTypes.UPDATE_SHOW_FAB,
        payload: showFab,
      });
    };
  },
};
