import { makeStyles } from '@material-ui/core';
import { styles, colors } from '../../design';
export const useStyles = makeStyles(theme => ({
  toggleContainer: {
    ...styles.flexRow,
    width: 221,
  },
  fullToggleContainer: {
    ...styles.flexRow,
    width: 250,
  },
  button: {
    borderTop: '1px solid #9FB4CB',
    borderBottom: '1px solid #9FB4CB',
    borderRight: '1px solid #9FB4CB',
    borderLeft: '1px solid #9FB4CB',
    flex: 1,
    fontSize: 13,
    color: '#5C738E',
    backgroundColor: colors.white,
    fontWeight: 300,
    height: 30,
    ...styles.flexRow,
    ...styles.justifyCenter,
    ...styles.cursorPointer,
  },
  leftButton: {
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  rightButton: {
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  active: {
    backgroundColor: '#3B94D1',
    color: colors.white,
  },
  disabled: {
    backgroundColor: 'rgba(157,173,194)',
    color: colors.white,
  },
}));
