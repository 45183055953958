import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { useNotify, DateInput, useRedirect, useRefresh } from 'react-admin';
import { format, startOfMonth, lastDayOfMonth, subMonths } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { stringify } from 'query-string';
import { Divider, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { authGet } from '../../server';
import { getBaseReportUrl } from '../../utils';
import { NewEvent, DeleteModal } from '../../components/common/modals';
import {
  ReferenceInput,
  RadioInput,
  SwitchInput,
  SelectComponent,
  CheckboxInput,
  AutocompleteInput,
} from '../../components/common/modals/modalForms';
import {
  ToggleButton,
  GroupFacilityToggle,
  ToggleCaseManagerButton,
  Loader,
} from '../../components/common';
import { useStyles } from '../../resources/residents.styles';
import {
  ArrowDropDownIcon,
  CustomButton,
  AddIcon,
  Visibility,
  SaveIcon,
  DeleteIcon,
  DownloadIcon,
  PdfIcon,
} from '../../design';
import { constants } from '../../utils/constants';
import { differenceInMonths, differenceInYears } from 'date-fns';

const { report_types, client_group_choices } = constants;

const noDateFilter = [
  report_types.STAFF_CASELOAD,
  report_types.UPDATES_DUE,
  report_types.NO_UPDATES,
  report_types.CHANGED_PLANNED_DISCHARGES,
  report_types.PAYERS,
  report_types.NO_AUTHS,
  report_types.TASKS_DUE,
  report_types.PLANNED_DISCHARGE,
  report_types.PAYERS_BY_STATE,
  report_types.UR_REPORT,
  report_types.FACILITIES_REPORT,
];
const noFacilityGroup = [
  report_types.STAFF_CASELOAD,
  report_types.RETRO_STAFF_CASELOAD,
  report_types.FACILITIES_REPORT,
];
const caseloadReports = [
  report_types.STAFF_CASELOAD,
  report_types.RETRO_STAFF_CASELOAD,
];

const allFacilitiesReports = [
  report_types.STAFF_CASELOAD,
  report_types.RETRO_STAFF_CASELOAD,
  report_types.FACILITIES_REPORT,
];
const taskFilters = [report_types.BULK_TASKS_REPORT];
const denialFilters = [report_types.DENIAL_REPORT];

const hideShowBothDivisionsReports = [
  report_types.TRIPLE_CHECK,
  report_types.BILLING,
  report_types.UPDATES_SCHEDULE,
  report_types.CLIENT_DETAILS,
  report_types.DENIAL_REPORT,
];
const noDivisionReports = [
  report_types.PAYERS,
  report_types.STAFF_CASELOAD,
  report_types.RETRO_STAFF_CASELOAD,
  report_types.HIGHER_LEVEL_ADMISSIONS_CREATED,
  report_types.HIGHER_LEVEL_AUTHS_CREATED,
  report_types.HIGHER_LEVEL_DAYS_COVERED_REVENUE,
  report_types.HIGHER_LEVEL_RETRO_AUTHS_CREATED,
  report_types.RETRO_STAFF_CASELOAD,
  report_types.PLANNED_DISCHARGE,
  report_types.DISMISSED_PCC_CENSUS,
  report_types.PAYERS_BY_STATE,
  report_types.UR_REPORT,
  report_types.PRE_ADMIT_REPORT,
  report_types.FACILITIES_REPORT,
  report_types.PHARM_LOG_REPORT,
];
const showAncillaryReports = [
  report_types.TRIPLE_CHECK,
  report_types.BILLING,
  report_types.DENIAL_REPORT,
  report_types.TASKS_DUE,
  report_types.FILTERED_AUTH,
  report_types.RETRO_AUTHS_REPORT,
  report_types.BULK_TASKS_REPORT,
];
const weekFilterReports = [report_types.UPDATES_SCHEDULE];
const monthFilterReports = [
  report_types.LOS_DISCHARGE,
  report_types.CLIENT_DETAILS,
];
const detailSummaryReports = [report_types.CLIENT_DETAILS];
const pdfReportExports = [
  report_types.UPDATES_SCHEDULE,
  report_types.PAYERS,
  report_types.CLIENT_DETAILS,
];

export const CustomReportsTool = ({ isBuilder, isBulk, ...props }) => {
  const ui = useSelector(state => state.admin.ui);
  const notify = useNotify();
  const dispatch = useDispatch();
  const [weClosedSidebar, setWeClosedSidebar] = useState(false);
  const [gridData, setGridData] = useState();
  const [gridLayout, setGridLayout] = useState();
  const [reportId, setReportId] = useState();
  const [reportType, setReportType] = useState(isBulk && 'bulk');
  const [bulkLevel, setBulkLevel] = useState(isBulk);
  const [presetId, setPresetId] = useState();
  const [presetName, setPresetName] = useState();
  const [division, setDivision] = useState(isBulk ? 'all' : 'subacute');
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadGrid, setLoadGrid] = useState(false);
  const [eventOpen, setEventModal] = useState(false);
  const [facilityIds, setFacilityIds] = useState();
  const [groupIds, setGroupIds] = useState();
  const [userIds, setUserIds] = useState();
  const [clientGroups, setClientGroups] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [saveNew, setSaveNew] = useState(false);
  const [facilitySelection, setFacilitySelection] = useState('facility');
  const [refreshPresetList, setRefreshPresetList] = useState(false);
  const classes = useStyles();
  const refresh = useRefresh();
  const maxWidth = getScreenWidth();

  const [formData, setFormData] = useState({
    details: 'false',
    is_completed: 'false',
    facility_ids: [],
    group_ids: [],
    user_ids: [],
    client_groups: [],
    start_date: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
    end_date: format(lastDayOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
    active: true,
    inactive: false,
    new_report: isBuilder ? 'true' : undefined,
    all_facilities: false,
  });

  const clearPresetData = () => {
    setShowGrid(false);
    setPresetId();
    setDivision(
      bulkLevel || !hideShowBothDivisionsReports.includes(reportId)
        ? 'all'
        : 'subacute',
    );
    setFacilitySelection('facility');
    setFacilityIds(); //check if this is necessary
    setGroupIds();
    setUserIds();
    setClientGroups();
    setFormData({
      ...formData,
      preset_id: undefined,
      details: 'false',
      is_completed: 'false',
      facility_ids: [],
      group_ids: [],
      user_ids: [],
      start_date: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
      end_date: format(lastDayOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
      active: true,
      inactive: false,
      all_facilities: false,
    });
  };

  useEffect(() => {
    //used to clear out grids when leaving page
    return () => {
      window.DisposeGrids();
    };
  }, []);

  useEffect(() => {
    if (ui.sidebarOpen === true && !weClosedSidebar) {
      setWeClosedSidebar(true);
      dispatch({ type: 'RA/TOGGLE_SIDEBAR' });
    }
  }, [dispatch, ui.sidebarOpen, weClosedSidebar]);

  useEffect(() => {
    if (loadGrid) {
      const grid = window.Grids[0];
      if (grid) {
        grid.Source.Layout.Data = gridLayout;
        grid.Source.Data.Data = gridData;
        grid.Reload();
      } else {
        window.StartTreeGrid();
      }
    }
  }, [gridData, gridLayout, view, loadGrid]);

  window.Grids.OnReady = function(G) {
    if (!view) {
      G.ActionExport();
    }
    setShowGrid(true);
    setLoadGrid(false);
    setLoading(false);
  };

  window.Grids.OnShowMenu = function(G, M, C, P) {
    M.Items[0].Width = 400;
  };

  const redirect = useRedirect();
  const { permissions = '' } = props;
  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  const isAdmin = userPermissions.indexOf('admin') > -1;
  const isAdminAssistant = userPermissions.indexOf('admin_assistant') > -1;
  const isViewAdmin = userPermissions.indexOf('view_admin') > -1;
  const isSupervisor = userPermissions.indexOf('supervisor') > -1;
  const isTeamLead = userPermissions.indexOf('team_leader') > -1;
  const canViewAllFacilities = isAdmin || isAdminAssistant || isViewAdmin;
  const canViewBulkReports =
    isAdmin || isAdminAssistant || isViewAdmin || isSupervisor || isTeamLead;
  if (isBuilder && !isAdmin) {
    redirect('/');
    return null;
  }

  const handleClose = e => {
    setEventModal(false);
    if (!e) {
      //don't clear data when cancel is pressed
      clearPresetData();
      setRefreshPresetList(!refreshPresetList);
    }
  };

  return (
    <Fragment>
      <div>
        <div style={{ paddingTop: 33 }}>
          <span className={classes.title}>
            {isBuilder
              ? 'Custom Report Builder'
              : isBulk
              ? 'Global Reports'
              : 'Reports'}
          </span>
        </div>
        <div style={{ maxWidth: maxWidth }}>
          <SelectReport
            setLoading={setLoading}
            setGridData={setGridData}
            setGridLayout={setGridLayout}
            setReportId={setReportId}
            setPresetId={setPresetId}
            notify={notify}
            classes={classes}
            reportId={reportId}
            setView={setView}
            setReportType={setReportType}
            reportType={reportType}
            setLoadGrid={setLoadGrid}
            setDivision={setDivision}
            division={division}
            presetId={presetId}
            setEventModal={setEventModal}
            setFacilityIds={setFacilityIds}
            setGroupIds={setGroupIds}
            setUserIds={setUserIds}
            setClientGroups={setClientGroups}
            clientGroups={clientGroups}
            setIsEdit={setIsEdit}
            setPresetName={setPresetName}
            isEdit={isEdit}
            setShowGrid={setShowGrid}
            refresh={refresh}
            presetName={presetName}
            showGrid={showGrid}
            isBuilder={isBuilder}
            setBulkLevel={setBulkLevel}
            bulkLevel={bulkLevel}
            setSaveNew={setSaveNew}
            clearPresetData={clearPresetData}
            setFormData={setFormData}
            formData={formData}
            facilitySelection={facilitySelection}
            setFacilitySelection={setFacilitySelection}
            refreshPresetList={refreshPresetList}
            setRefreshPresetList={setRefreshPresetList}
            userPermissions={userPermissions}
            isAdmin={isAdmin}
            canViewBulkReports={canViewBulkReports}
            canViewAllFacilities={canViewAllFacilities}
          />
        </div>
        <Loader open={loading} />
        {gridData && gridLayout && (
          <div
            id='main'
            style={{ width: '100%', display: showGrid ? 'block' : 'none' }}
          >
            <Grid
              gridData={gridData}
              gridLayout={gridLayout}
              loading={loading}
            />
          </div>
        )}
      </div>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleClose}
          form='preset'
          refresh={refresh}
          isEdit={isEdit}
          params={{
            bulkLevel,
            reportId,
            reportType,
            facilityIds,
            groupIds,
            userIds,
            division,
            clientGroups,
            presetId,
            presetName,
            grid: window.Grids[0],
            saveNew,
          }}
        />
      )}
    </Fragment>
  );
};

const Grid = ({ gridData, gridLayout }) => {
  // try {
  //   const str = gridLayout.replace(/\/\/.*/gm, '').replace(/\n/gm, '');
  //   const pl = JSON.parse(str);
  //   console.log({ pl });
  // } catch (error) {
  //   console.error(error.message, error.at);
  // }
  const baseUrl = getBaseReportUrl();
  const token = localStorage.getItem('token');
  const userId = useSelector(state => state.user.profile.id);
  const coveredUserId = useSelector(state => state.facility.covered_user_id);
  const user = coveredUserId || userId;
  return (
    <treegrid
      is='treegrid'
      Debug='0'
      Layout_Data={gridLayout}
      Data_Data={gridData}
      Sync='1'
      Upload_Url={`${baseUrl}/download?${stringify({
        token,
        report: 'save-preset',
        userId: user,
      })}`}
      Upload_Type='Cookie'
    ></treegrid>
  );
};

const SelectReport = ({
  setLoading,
  setGridData,
  setGridLayout,
  gridData,
  gridLayout,
  setReportId,
  setPresetId,
  notify,
  classes,
  reportId,
  setView,
  setReportType,
  setLoadGrid,
  setDivision,
  division,
  presetId,
  setEventModal,
  setFacilityIds,
  setGroupIds,
  setUserIds,
  setClientGroups,
  setIsEdit,
  setPresetName,
  isEdit,
  setShowGrid,
  refresh,
  presetName,
  showGrid,
  isBuilder,
  setBulkLevel,
  bulkLevel,
  setSaveNew,
  clearPresetData,
  setFormData,
  formData,
  facilitySelection,
  setFacilitySelection,
  refreshPresetList,
  setRefreshPresetList,
  userPermissions,
  isAdmin,
  canViewBulkReports,
  canViewAllFacilities,
}) => {
  const onSubmit = value => {};
  const [groupCaseManager, selectCaseManagerToggle] = useState(false);
  const [reportDescription, setReportDescription] = useState();
  const [showActive, setShowActive] = useState(true);
  const [showInactive, setShowInactive] = useState(false);
  const [showResolved, setShowResolved] = useState(false);
  const [deleting, setDeleting] = useState({
    active: false,
    id: 0,
    name: '',
  });
  const [reportDisabled, setReportsDisabled] = useState(false);

  async function getReportDescription(reportId) {
    const response = await authGet(`/report-description/${reportId}`);
    if (response.error) return;
    const { description } = response.data;
    setReportDescription(description);
  }

  const handleDeleteClose = e => {
    setDeleting({ active: false });
    if (!e) {
      //don't clear data when cancel is pressed
      setTimeout(() => {
        clearPresetData();
        setRefreshPresetList(!refreshPresetList);
      }, 4000);
    }
  };

  useEffect(() => {
    setShowGrid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [division]);

  useEffect(() => {
    setFacilityIds(formData.facility_ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.facility_ids]);

  useEffect(() => {
    setGroupIds(formData.group_ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.group_ids]);

  useEffect(() => {
    setUserIds(formData.user_ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.user_ids]);

  useEffect(() => {
    setClientGroups(formData.client_groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.client_groups]);

  useEffect(() => {
    setReportType(
      formData.details === 'true'
        ? 'detail'
        : groupCaseManager
        ? 'case_manager'
        : 'facility',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCaseManager]);

  useEffect(() => {
    if (isBuilder) {
      setReportId();
      setReportDescription();
      clearPresetData();
      setFormData({
        ...formData,
        report_id: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkLevel]);

  useEffect(() => {
    async function setPresetData() {
      setShowGrid(false);
      const response = await authGet(`/report-presets/${presetId}`);
      if (response.error) return;
      const {
        name,
        facility_ids,
        group_ids,
        user_ids,
        division,
        type,
        report_id,
        client_groups,
      } = response.data;
      if (bulkLevel && !isBuilder) {
        setReportId(report_id);
        getReportDescription(report_id);
      }
      setPresetName(name);
      setDivision(division);
      setClientGroups(client_groups);
      if (!facility_ids && !group_ids && !user_ids) {
        setFacilitySelection('facility');
      } else {
        setFacilitySelection(
          group_ids
            ? 'group'
            : user_ids && isAdmin
            ? 'case_manager'
            : 'facility',
        );
      }
      setShowActive(true);
      setShowInactive(true);
      selectCaseManagerToggle(type === 'case_manager' ? true : false);
      setFormData({
        ...formData,
        report_id: bulkLevel && !isBuilder ? report_id : reportId,
        group_ids: group_ids || [],
        facility_ids: facility_ids || [],
        user_ids: isAdmin ? user_ids || [] : [],
        active: true,
        inactive: !facility_ids && !group_ids ? false : true,
        is_completed: type === 'closed' ? 'true' : 'false',
        details: type === 'detail' ? 'true' : 'false',
        all_facilities: !facility_ids && !group_ids && !user_ids,
        client_groups: client_groups || [],
      });
    }
    if (presetId && (isEdit || !isBuilder)) {
      setPresetData();
    } else {
      clearPresetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetId, isEdit]);

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    setShowGrid(false);
    if (name === 'report_id') {
      setReportId(value);
      getReportDescription(value);
      clearPresetData();
      setDivision(
        bulkLevel || !hideShowBothDivisionsReports.includes(value)
          ? 'all'
          : 'subacute',
      );
      if (value === report_types.BULK_TASKS_REPORT) {
        formData.is_completed === 'true'
          ? setReportType('closed')
          : setReportType('open');
      } else if (value === report_types.CLIENT_DETAILS) {
        setReportType(formData.details === 'true' ? 'detail' : 'summary');
      } else if (value === report_types.STAFF_CASELOAD) {
        setReportType(
          formData.details === 'true'
            ? 'detail'
            : groupCaseManager
            ? 'case_manager'
            : 'facility',
        );
      } else if (value === report_types.RETRO_STAFF_CASELOAD) {
        setReportType(groupCaseManager ? 'case_manager' : 'facility');
      } else if (bulkLevel) {
        setReportType('bulk');
      } else {
        setReportType();
      }
    }
    if (name === 'preset_id') {
      setPresetId(value);
    }
    if (name === 'division') {
      setDivision(value);
    }
    if (name === 'details') {
      if (reportId === report_types.STAFF_CASELOAD) {
        setReportType(
          value === 'true'
            ? 'detail'
            : groupCaseManager
            ? 'case_manager'
            : 'facility',
        );
      }
      if (reportId === report_types.CLIENT_DETAILS) {
        setReportType(value === 'true' ? 'detail' : 'summary');
      }
    }
    if (name === 'is_completed') {
      setReportType(value === 'true' ? 'closed' : 'open');
    }
    if (name === 'active') {
      setShowActive(value);
      setFormData({
        ...formData,
        facility_ids: [],
        [name]: value,
      });
      return;
    }
    if (name === 'inactive') {
      setShowInactive(value);
      setFormData({
        ...formData,
        facility_ids: [],
        [name]: value,
      });
      return;
    }
    if (name === 'resolved') {
      setShowResolved(value);
      setFormData({
        ...formData,
        [name]: value,
      });
      return;
    }
    if (name === 'new_report') {
      if (value === 'false') {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }

    if (name === 'all_facilities') {
      if (value === true) {
        setFacilityIds();
        setGroupIds();
        setUserIds();
        setClientGroups();
        setFacilitySelection('facility');
        setFormData({
          ...formData,
          facility_ids: [],
          group_ids: [],
          user_ids: [],
          client_groups: [],
          active: true,
          inactive: false,
          [name]: value,
        });
        return;
      } else {
        setFacilitySelection('facility');
        setFormData({
          ...formData,
          active: true,
          inactive: false,
          [name]: value,
        });
        return;
      }
    }
    if (name === 'client_groups') {
      setClientGroups(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  const getData = async (values, view) => {
    if (window.Grids[0] && !view && showGrid) {
      window.Grids[0].ActionExport();
    } else {
      setLoading(true);
      const response = await authGet([
        `/reports/tree-grid`,
        {
          division: division,
          bulk: bulkLevel || undefined,
          group_type: groupCaseManager ? 'case_manager' : 'facility',
          ...values,
        },
      ]);
      const { data, error } = response;
      if (error) {
        setLoading(false);
        return notify(error.message, 'warning');
      }
      const { layout, dataset } = data;

      if (gridLayout !== layout) setGridLayout(layout);
      if (gridData !== dataset) setGridData(dataset);
      setView(view);
      setLoadGrid(true);
    }
  };

  const openModal = saveNew => {
    if (saveNew) {
      setSaveNew(true);
    } else {
      setSaveNew(false);
    }
    setEventModal(true);
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({ values }) => {
          const disabled =
            (bulkLevel &&
              !values.facility_ids.length &&
              !values.group_ids.length &&
              !values.user_ids.length &&
              !formData.all_facilities &&
              !allFacilitiesReports.includes(reportId)) ||
            reportDisabled;
          return (
            <Fragment>
              <div
                style={{
                  display: isBuilder ? 'block' : 'flex',
                }}
              >
                <div
                  style={{
                    paddingTop: 10,
                    display: 'flex',
                  }}
                >
                  {isBuilder && (
                    <div style={{ marginTop: 15 }}>
                      <GroupFacilityToggle
                        selectGroup={bulkLevel}
                        selectGroupToggle={setBulkLevel}
                        facilityLabel='Facility Reports'
                        groupLabel='Global Reports'
                      />
                    </div>
                  )}
                  {(isBuilder || !bulkLevel || canViewBulkReports) && (
                    <div
                      style={{
                        marginTop: 2,
                        marginLeft: isBuilder ? 10 : 0,
                        width: 400,
                      }}
                    >
                      <AutocompleteInput
                        openOnFocus
                        autocompleteProps={{ openOnFocus: true }}
                        trimLength={1}
                        viewAll
                        key={bulkLevel}
                        fullWidth
                        reference='reports'
                        name='report_id'
                        label='Report type'
                        customOnChange={customOnChange}
                        options={{
                          filter: {
                            bulk: bulkLevel || undefined,
                            treegrid: true,
                          },
                        }}
                      />
                      {reportId && (
                        <Typography
                          variant='body2'
                          style={{
                            marginLeft: 10,
                            marginTop: 5,
                            marginBottom: 5,
                          }}
                        >
                          {reportDescription}
                        </Typography>
                      )}
                    </div>
                  )}

                  {isBuilder && reportId && (
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 10,
                      }}
                    >
                      <RadioInput
                        row
                        customOnChange={customOnChange}
                        name='new_report'
                        value={formData.new_report}
                        label=''
                        radios={[
                          {
                            label: 'New Report',
                            value: 'true',
                          },
                          {
                            label: 'Edit Reports',
                            value: 'false',
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>

                {((reportId && (isEdit || !isBuilder)) ||
                  (bulkLevel && !isBuilder && !canViewBulkReports)) && (
                  <div
                    style={{
                      width: 630,
                      marginLeft:
                        isBuilder || (bulkLevel && !canViewBulkReports)
                          ? 0
                          : 10,
                      marginTop: isBuilder ? 0 : 12,
                      marginBottom: isBuilder || bulkLevel ? 20 : 0,
                    }}
                  >
                    <ReferenceInput
                      key={[
                        (isBuilder ||
                          (!isBuilder && (!bulkLevel || canViewBulkReports))) &&
                          reportId,
                        refreshPresetList,
                      ]}
                      reference='presets/list'
                      name='preset_id'
                      label='Select a preset'
                      customOnChange={customOnChange}
                      options={{
                        filter: {
                          report_id:
                            bulkLevel && !isBuilder && !canViewBulkReports
                              ? undefined
                              : values.report_id,
                          bulk: bulkLevel || undefined,
                        },
                      }}
                      fullWidth
                      resetOption={
                        isBuilder || (bulkLevel && !canViewBulkReports)
                          ? undefined
                          : { id: 0, name: 'Clear presets' }
                      }
                    />
                  </div>
                )}
              </div>

              {((reportId && (!isBuilder || !isEdit)) ||
                (isBuilder && presetId)) && (
                <Fragment>
                  <Divider />
                  <div
                    style={{
                      paddingTop: 10,
                      paddingBottom:
                        bulkLevel && !allFacilitiesReports.includes(reportId)
                          ? 20
                          : 0,
                    }}
                  >
                    <span className={classes.subtitle}>{`${
                      presetId || (isBuilder && isEdit) ? 'Preset' : ''
                    } Settings`}</span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    {reportId && (
                      <Fragment>
                        {bulkLevel && !noFacilityGroup.includes(reportId) && (
                          <FacilityGroupFilter
                            customOnChange={customOnChange}
                            setFormData={setFormData}
                            facilitySelection={facilitySelection}
                            setFacilitySelection={setFacilitySelection}
                            setFacilityIds={setFacilityIds}
                            setUserIds={setUserIds}
                            setGroupIds={setGroupIds}
                            setClientGroups={setClientGroups}
                            showActive={showActive}
                            showInactive={showInactive}
                            disabled={!isBuilder && presetId}
                            formData={formData}
                            classes={classes}
                            userPermissions={userPermissions}
                            isBuilder={isBuilder}
                            isAdmin={isAdmin}
                            canViewAllFacilities={canViewAllFacilities}
                          />
                        )}
                        {!noFacilityGroup.includes(reportId) && (
                          <div style={{ marginLeft: 10 }}>
                            {denialFilters.includes(reportId) ? (
                              <div
                                style={{
                                  marginTop: -14,
                                  display: 'flex',
                                  marginLeft: 10,
                                  paddingLeft: 60,
                                  paddingBottom: -10,
                                }}
                              >
                                <CheckboxInput
                                  name='resolved'
                                  customOnChange={customOnChange}
                                  label='Include Resolved'
                                  checked={showResolved}
                                  size='small'
                                  style={{ color: '#1061A0' }}
                                  className={classes.checkbox}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  marginTop: 15,
                                  marginLeft: 10,
                                  display: 'flex',
                                  width: 10,
                                }}
                              />
                            )}
                            {!noDivisionReports.includes(reportId) && (
                              <div
                                style={{
                                  paddingTop: denialFilters.includes(reportId)
                                    ? 1
                                    : 10,
                                  marginLeft: bulkLevel ? 10 : 0,
                                }}
                              >
                                <ToggleButton
                                  initialDivision={division}
                                  setDivision={setDivision}
                                  className={classes.toggleWrapper}
                                  showBoth={
                                    bulkLevel
                                      ? true
                                      : !hideShowBothDivisionsReports.includes(
                                          reportId,
                                        )
                                  }
                                  showPartB={showAncillaryReports.includes(
                                    reportId,
                                  )}
                                  disabled={!isBuilder && presetId}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {caseloadReports.includes(reportId) && (
                          <CaseloadFilter
                            formData={formData}
                            setFormData={setFormData}
                            customOnChange={customOnChange}
                            groupCaseManager={groupCaseManager}
                            selectCaseManagerToggle={selectCaseManagerToggle}
                            reportId={reportId}
                            disabled={!isBuilder && presetId}
                          />
                        )}
                        {taskFilters.includes(reportId) && (
                          <TaskFilter
                            formData={formData}
                            customOnChange={customOnChange}
                            disabled={!isBuilder && presetId}
                          />
                        )}
                        {detailSummaryReports.includes(reportId) && (
                          <DetailSummaryToggle
                            formData={formData}
                            customOnChange={customOnChange}
                            disabled={!isBuilder && presetId}
                          />
                        )}

                        {!noDateFilter.includes(reportId) && (
                          <Fragment>
                            <Divider
                              orientation='vertical'
                              variant='middle'
                              flexItem
                            />
                            <div>
                              <div
                                style={{
                                  fontStyle: 'Nunito Sans',
                                  fontSize: 14,
                                  fontWeight: 300,
                                  color: '#1061A0',
                                  contentAlign: 'left',
                                }}
                              >
                                {isBuilder ? 'Sample dates' : 'Run for dates'}
                              </div>
                              <DateFilter
                                classes={classes}
                                customOnChange={customOnChange}
                                formData={formData}
                                reportId={reportId}
                                setFormData={setFormData}
                                setReportsDisabled={setReportsDisabled}
                              />
                            </div>

                            <Divider
                              orientation='vertical'
                              variant='middle'
                              flexItem
                            />
                          </Fragment>
                        )}
                        <div
                          style={{
                            marginTop: 25,
                            marginLeft: 10,
                          }}
                        >
                          <CustomButton
                            onClick={() => getData(values, true)}
                            style={{
                              color: disabled ? 'rgba(168,168,168)' : '#1061A0',
                              backgroundColor: disabled
                                ? 'rgba(220,220,220)'
                                : '#EFF4FB',
                              textTransform: 'capitalize',
                              width: 100,
                              marginBottom: 10,
                            }}
                            disabled={disabled}
                            label={isBuilder ? 'Preview' : 'View'}
                            Icon={Visibility}
                            type='button'
                            variant='text'
                            size='small'
                          />
                          {!isBuilder && !showGrid && (
                            <CustomButton
                              onClick={() => getData(values, false)}
                              variant='text'
                              style={{
                                color: disabled
                                  ? 'rgba(168,168,168)'
                                  : '#1061A0',
                                backgroundColor: disabled
                                  ? 'rgba(220,220,220)'
                                  : '#EFF4FB',
                                textTransform: 'capitalize',
                                width: 120,
                                marginLeft: 5,
                                marginBottom: 10,
                              }}
                              Icon={DownloadIcon}
                              label='Run & Export'
                              disabled={disabled}
                              size='small'
                            />
                          )}
                          {!isBuilder && showGrid && (
                            <CustomButton
                              onClick={() => getData(values, false)}
                              variant='text'
                              style={{
                                color: disabled
                                  ? 'rgba(168,168,168)'
                                  : '#1061A0',
                                backgroundColor: disabled
                                  ? 'rgba(220,220,220)'
                                  : '#EFF4FB',
                                textTransform: 'capitalize',
                                width: 120,
                                marginLeft: 5,
                                marginBottom: 10,
                              }}
                              Icon={DownloadIcon}
                              label='Export'
                              disabled={disabled}
                              size='small'
                            />
                          )}

                          {isBuilder && (
                            <CustomButton
                              onClick={() => openModal(false)}
                              disabled={disabled || !showGrid}
                              style={{
                                color:
                                  disabled || !showGrid
                                    ? 'rgba(168,168,168)'
                                    : '#1061A0',
                                backgroundColor:
                                  disabled || !showGrid
                                    ? 'rgba(220,220,220)'
                                    : '#EFF4FB',
                                textTransform: 'capitalize',
                                marginLeft: 5,
                                marginBottom: 10,
                                width: 100,
                              }}
                              label='Save'
                              Icon={SaveIcon}
                              type='button'
                              variant='text'
                              size='small'
                            />
                          )}

                          {presetId && isBuilder && (
                            <CustomButton
                              onClick={() => openModal(true)}
                              disabled={disabled || !showGrid}
                              style={{
                                color:
                                  disabled || !showGrid
                                    ? 'rgba(168,168,168)'
                                    : '#1061A0',
                                backgroundColor:
                                  disabled || !showGrid
                                    ? 'rgba(220,220,220)'
                                    : '#EFF4FB',
                                textTransform: 'capitalize',
                                marginLeft: 5,
                                marginBottom: 10,
                                width: 100,
                                fontSize: 10,
                              }}
                              label='Save as New'
                              Icon={AddIcon}
                              type='button'
                              variant='text'
                              size='small'
                            />
                          )}
                          {presetId && isBuilder && (
                            <CustomButton
                              onClick={() => {
                                setDeleting({
                                  active: true,
                                  id: presetId,
                                  name: presetName,
                                });
                              }}
                              Icon={DeleteIcon}
                              type='button'
                              variant='text'
                              label='DELETE'
                              color='rgb(220, 0, 78)'
                              backgroundColor='rgba(249,  233, 234)'
                              style={{
                                marginLeft: 5,
                                marginBottom: 10,
                                width: 100,
                              }}
                              size='small'
                            />
                          )}
                        </div>
                        {!isBuilder &&
                          !bulkLevel &&
                          pdfReportExports.includes(reportId) && (
                            <PdfWordExport
                              formData={formData}
                              customOnChange={customOnChange}
                              division={division}
                              classes={classes}
                              reportId={reportId}
                            />
                          )}
                      </Fragment>
                    )}
                  </div>
                  <div style={{ paddingTop: 10 }}></div>
                  <Divider />
                </Fragment>
              )}
            </Fragment>
          );
        }}
      ></Form>
      <DeleteModal
        open={deleting.active}
        onClose={handleDeleteClose}
        onDelete={refresh}
        url={'report-presets/' + deleting.id}
        name={deleting.name}
        successMessage='Report deleted'
      />
    </Fragment>
  );
};

const DateFilter = ({
  classes,
  customOnChange,
  formData,
  reportId,
  setFormData,
  setReportsDisabled,
}) => {
  const [startMonthDate, setStartMonthDate] = useState(
    startOfMonth(subMonths(new Date(), 1)),
  );
  const [endMonthDate, setEndMonthDate] = useState(
    startOfMonth(subMonths(new Date(), 1)),
  );

  const onChange = (e, name) => {
    let formatDate;
    if (name === 'start_date') {
      setStartMonthDate(e);
      formatDate = format(startOfMonth(new Date(e)), 'yyyy-MM-dd');
      setFormData(f => ({ ...f, start_date: formatDate }));
    } else if (name === 'end_date') {
      setEndMonthDate(e);
      formatDate = format(lastDayOfMonth(new Date(e)), 'yyyy-MM-dd');
      setFormData(f => ({ ...f, end_date: formatDate }));
    }
  };
  const validate = (value, formData, e) => {
    const startDate = new Date(`${formData.start_date} 00:00`);
    const endDate = new Date(`${formData.end_date} 00:00`);
    if (
      (formData.facility_ids.length > 3 || formData.all_facilities) &&
      differenceInMonths(endDate, startDate) >= 2
    ) {
      setReportsDisabled(true);
      return 'The software only allows running the report for 3 or more facilities for up to 2 months.  For anything more than that please contact the developer to have the data emailed to you';
    } else if (differenceInYears(endDate, startDate) >= 2) {
      setReportsDisabled(true);
      return "The software only allows running the report for 1-3 facilities for up 2 years' worth of data. For anything more than that please contact the developer to have the data emailed to you";
    } else if (
      formData.report_id === report_types.LOS_DISCHARGE &&
      (formData.group_ids?.length > 0 ||
        formData.user_ids?.length > 0 ||
        formData.client_groups?.length > 0) &&
      format(endDate, 'MM') !== format(startDate, 'MM')
    ) {
      setReportsDisabled(true);
      return 'The software only allows running the report for multiple facilities for up to 1 month. For anything more than that please contact the developer to have the data emailed to you';
    } else {
      setReportsDisabled(false);
    }
  };
  return (
    <div className={classes.filterContainer} style={{ display: 'flex' }}>
      {!weekFilterReports.includes(reportId) &&
        !monthFilterReports.includes(reportId) && (
          <div>
            <span style={{ marginRight: 15 }}>
              <DateInput
                label='From'
                source='start_date'
                options={{
                  variant: 'standard',
                  required: true,
                }}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    max:
                      reportId === report_types.TRIPLE_CHECK
                        ? format(new Date(), 'yyyy-MM-dd')
                        : undefined,
                  },
                }}
                style={{ color: '#1061A0' }}
                onChange={customOnChange}
                defaultValue={formData.start_date}
              />
            </span>
            <DateInput
              label='To'
              source='end_date'
              options={{ variant: 'standard', required: true }}
              InputProps={{
                disableUnderline: true,
                components: { OpenPickerIcon: ArrowDropDownIcon },
                inputProps: {
                  max:
                    reportId === report_types.TRIPLE_CHECK
                      ? format(new Date(), 'yyyy-MM-dd')
                      : undefined,
                },
              }}
              style={{ color: '#1061A0' }}
              onChange={customOnChange}
              defaultValue={formData.end_date}
            />
            <div
              style={{
                color: '#f44336',
                fontSize: '0.75rem',
                fontWeight: '400',
                lineHeight: '1.66',
                letterSpacing: '0.03333em',
                maxWidth: 500,
                marginTop: -20,
              }}
            >
              {validate(undefined, formData)}
            </div>
          </div>
        )}
      {weekFilterReports.includes(reportId) && (
        <DateInput
          label='Week of'
          source='start_date'
          alwaysOn
          options={{ variant: 'standard', required: true }}
          InputProps={{
            disableUnderline: true,
          }}
          style={{ color: '#1061A0' }}
          onChange={customOnChange}
        />
      )}
      {monthFilterReports.includes(reportId) && (
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <DatePicker
                views={['year', 'month']}
                label='From'
                openTo='month'
                value={startMonthDate}
                onChange={e => onChange(e, 'start_date')}
                required
                autoOk
                style={{ marginRight: -30, color: '#1061A0' }}
                InputProps={{
                  disableUnderline: true,
                }}
                margin='dense'
              />
              <DatePicker
                margin='dense'
                views={['year', 'month']}
                label='To'
                value={endMonthDate}
                openTo='month'
                onChange={e => onChange(e, 'end_date')}
                minDate={
                  reportId !== report_types.CLIENT_DETAILS
                    ? formData.start_date
                    : undefined
                }
                minDateMessage='End date should not be before start date'
                required
                autoOk
                style={{ marginRight: -60, color: '#1061A0' }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
          <div
            style={{
              color: '#f44336',
              fontSize: '0.75rem',
              fontWeight: '400',
              lineHeight: '1.66',
              letterSpacing: '0.03333em',
              maxWidth: 500,
            }}
          >
            {validate(undefined, formData)}
          </div>
        </div>
      )}
    </div>
  );
};

const FacilityGroupFilter = ({
  customOnChange,
  setFormData,
  setUserIds,
  facilitySelection,
  setFacilitySelection,
  setFacilityIds,
  setGroupIds,
  showActive,
  showInactive,
  disabled,
  formData,
  classes,
  isBuilder,
  isAdmin,
  canViewAllFacilities,
}) => {
  useEffect(() => {
    if (facilitySelection === 'group') {
      setFormData(f => ({ ...f, facility_ids: [], user_ids: [] }));
      setFacilityIds();
      setUserIds();
    } else if (facilitySelection === 'case_manager') {
      setFormData(f => ({
        ...f,
        group_ids: [],
        facility_ids: [],
      }));
      setFacilityIds();
      setGroupIds();
    } else {
      setFormData(f => ({ ...f, group_ids: [], user_ids: [] }));
      setGroupIds();
      setUserIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilitySelection]);
  return (
    <Fragment>
      <div style={{ marginTop: -5 }}>
        <div
          style={{
            marginTop: -10,
            display: 'flex',
          }}
        >
          <CheckboxInput
            color='#1061A0'
            name='all_facilities'
            customOnChange={customOnChange}
            checked={formData.all_facilities}
            label={`Run for ${
              canViewAllFacilities ? 'all' : 'my'
            } active facilities`}
            style={{ color: '#1061A0' }}
            className={classes.checkbox}
            disabled={
              formData.report_id === report_types.DISMISSED_PCC_CENSUS ||
              formData.report_id === report_types.LOS_DISCHARGE
            }
          />
        </div>
        <ToggleCaseManagerButton
          initialDivision={facilitySelection}
          setDivision={setFacilitySelection}
          showBoth={isAdmin}
          disabled={disabled || formData.all_facilities}
        />
      </div>
      <div style={{ marginLeft: 10, width: 350 }}>
        {facilitySelection === 'facility' ? (
          <div
            style={{
              marginTop: -9,
              display: 'flex',
              marginLeft: 10,
            }}
          >
            <SwitchInput
              name='active'
              customOnChange={customOnChange}
              label='Active'
              checked={showActive}
              size='small'
              disabled={disabled || formData.all_facilities}
            />
            <SwitchInput
              name='inactive'
              customOnChange={customOnChange}
              label='Inactive'
              checked={showInactive}
              size='small'
              disabled={disabled || formData.all_facilities}
            />
          </div>
        ) : (
          <div
            style={{
              marginTop: 15,
              marginLeft: 25,
              display: 'flex',
              width: 200,
            }}
          />
        )}
        {facilitySelection === 'facility' && (
          <ReferenceInput
            key={[formData.active, formData.inactive]}
            reference='facilities/list'
            customOnChange={customOnChange}
            setFormData={setFormData}
            name='facility_ids'
            label='Select facilities'
            required
            multiple
            fullWidth
            perPage={500}
            selectAll
            options={{
              filter: {
                bulk: true,
                active: formData.active,
                inactive: formData.inactive,
              },
              sort: {
                field: 'name',
                order: 'ASC',
              },
            }}
            className={classes.label}
            style={{
              marginRight: 0,
            }}
            disabled={formData.all_facilities}
          />
        )}
        {formData.facility_ids.length > 0 && isBuilder && (
          <div
            style={{
              color: 'orange',
              fontSize: '0.75rem',
              fontWeight: '400',
              lineHeight: '1.66',
              letterSpacing: '0.03333em',
              maxWidth: 500,
            }}
          >
            Selecting facilities in drop down will restrict report to those
            facilities; newly created or deactivated facilities will not be
            updated in the saved preset.
          </div>
        )}
        {facilitySelection === 'group' && (
          <ReferenceInput
            reference='facility-groups'
            customOnChange={customOnChange}
            setFormData={setFormData}
            name='group_ids'
            label='Select groups'
            required
            multiple
            fullWidth
            perPage={500}
            selectAll
            options={{
              filter: {},
              sort: {
                field: 'name',
                order: 'ASC',
              },
            }}
            className={classes.label}
            style={{ marginRight: 0 }}
            disabled={formData.all_facilities}
          />
        )}
        {facilitySelection === 'case_manager' && (
          <ReferenceInput
            reference='facility-users/list'
            customOnChange={customOnChange}
            name='user_ids'
            label='Select case managers'
            required
            multiple
            fullWidth
            perPage={500}
            options={{
              filter: { all_facilities: true, is_active: true },
              sort: { field: 'last_name', order: 'ASC' },
            }}
            className={classes.label}
            style={{
              marginRight: 0,
            }}
            disabled={formData.all_facilities}
          />
        )}
      </div>
      <ClientGroupFilter
        formData={formData}
        setFormData={setFormData}
        customOnChange={customOnChange}
      />
    </Fragment>
  );
};

const CaseloadFilter = ({
  setFormData,
  formData,
  customOnChange,
  groupCaseManager,
  selectCaseManagerToggle,
  reportId,
  disabled,
}) => {
  return (
    <Fragment>
      <div style={{ marginTop: 27, marginRight: 10 }}>
        <GroupFacilityToggle
          selectGroup={groupCaseManager}
          selectGroupToggle={selectCaseManagerToggle}
          facilityLabel='Facilities'
          groupLabel='Case Managers'
          disabled={disabled}
        />
      </div>
      {reportId === report_types.STAFF_CASELOAD && (
        <div
          style={{
            marginTop: 25,
            marginLeft: 10,
            marginBottom: 10,
            width: 400,
          }}
        >
          <RadioInput
            row
            name='details'
            customOnChange={customOnChange}
            value={formData.details}
            label=''
            radios={[
              {
                label: 'Caseload Summary',
                value: 'false',
              },
              {
                label: 'Detailed Caseload',
                value: 'true',
              },
            ]}
            disabled={disabled}
          />
        </div>
      )}
      {reportId === report_types.RETRO_STAFF_CASELOAD && !groupCaseManager && (
        <ClientGroupFilter
          formData={formData}
          setFormData={setFormData}
          customOnChange={customOnChange}
        />
      )}
    </Fragment>
  );
};

const ClientGroupFilter = ({ formData, setFormData, customOnChange }) => {
  return (
    <div
      style={{
        marginTop: 15,
        marginLeft: 10,
        marginRight: -20,
      }}
    >
      <SelectComponent
        multiple
        style={{ width: 135 }}
        customOnChange={customOnChange}
        label='Client group'
        name='client_groups'
        choices={client_group_choices}
        selectAll
        setFormData={setFormData}
        disabled={formData.all_facilities}
      />
    </div>
  );
};

const TaskFilter = ({ formData, customOnChange, disabled }) => {
  return (
    <div style={{ marginLeft: 10, marginRight: -5, width: 100 }}>
      <RadioInput
        row
        name='is_completed'
        customOnChange={customOnChange}
        value={formData.is_completed}
        label=''
        radios={[
          {
            label: 'Open',
            value: 'false',
          },
          {
            label: 'Closed',
            value: 'true',
          },
        ]}
        disabled={disabled}
      />
    </div>
  );
};

const DetailSummaryToggle = ({ formData, customOnChange, disabled }) => {
  return (
    <div style={{ marginTop: 20, marginLeft: 15 }}>
      <RadioInput
        row
        name='details'
        customOnChange={customOnChange}
        value={formData.details}
        label=''
        radios={[
          {
            label: 'Detail',
            value: 'true',
          },
          {
            label: 'Summary',
            value: 'false',
          },
        ]}
        disabled={disabled}
      />
    </div>
  );
};
const PdfWordExport = ({ formData, classes, reportId, division, disabled }) => {
  const token = localStorage.getItem('token');
  const baseUrl = getBaseReportUrl();
  const facilityId = useSelector(state => state.facility.id);
  const [selectOpen, setSelectOpen] = useState(false);

  let reportName;
  switch (reportId) {
    case report_types.UPDATES_SCHEDULE:
      reportName = 'updates-due';
      break;
    case report_types.PAYERS:
      reportName = 'payer-rates';
      break;
    case report_types.CLIENT_DETAILS:
      reportName = 'client-details';
      break;
    default:
  }

  const handleChange = e => {
    const {
      target: { value },
    } = e;
    const doc_type = value;
    window.open(
      `${baseUrl}/download?${stringify({
        _facilityId: facilityId,
        token,
        report: reportName,
        _start: 0,
        _end: 1000,
        division: division,
        doc_type,
        date_run: format(Date.now(), 'M/dd/yyyy h:mm a'),
        start_date: formData.start_date,
        end_date: !weekFilterReports.includes(reportId)
          ? formData.end_date
          : undefined,
      })}`,
      '_blank',
    );
    setSelectOpen(false);
  };
  return (
    <div style={{ marginTop: 25, marginLeft: 5 }}>
      {!selectOpen && (
        <CustomButton
          disabled={disabled}
          Icon={PdfIcon}
          style={{
            color: disabled ? 'rgba(168,168,168)' : '#1061A0',
            backgroundColor: disabled ? 'rgba(220,220,220)' : '#EFF4FB',
          }}
          label='PDF Export'
          type='button'
          variant='text'
          onClick={e =>
            reportId === report_types.UPDATES_SCHEDULE
              ? setSelectOpen(true)
              : handleChange(e)
          }
          size='small'
        />
      )}
      {selectOpen && (
        <Form
          onSubmit={() => {}}
          render={() => (
            <SelectComponent
              classes={{
                notchedOutline: classes.notchedOutline,
              }}
              style={{
                width: 150,
                marginTop: -5,
                backgroundColor: 'EFF4FB',
              }}
              fullWidth
              label='Export as'
              customOnChange={handleChange}
              name='doc_type'
              choices={[
                { id: 'pdf', name: 'PDF' },
                { id: 'docx', name: 'Word Doc' },
              ]}
            />
          )}
        />
      )}
    </div>
  );
};

function getScreenWidth() {
  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  let width;
  if (typeof window.innerWidth != 'undefined') {
    width = window.innerWidth;
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (
    typeof document.documentElement != 'undefined' &&
    typeof document.documentElement.clientWidth != 'undefined' &&
    document.documentElement.clientWidth !== 0
  ) {
    width = document.documentElement.clientWidth;
  }

  // older versions of IE
  else {
    width = document.getElementsByTagName('body')[0].clientWidth;
  }
  return width;
}
