import React, { useState, useEffect, Fragment } from 'react';

import clsx from 'clsx';
import { Form } from 'react-final-form';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import { FormTextField, getDateInputValue, handleNumbers } from '.';
import { useStyles } from '../modal.styles';
import { SaveButton } from '../../../../design';

export const CategoryForm = ({ record = {}, isEdit, handleClose }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (isEdit && record) {
      setFormData(f => ({
        ...f,
        ...record,
      }));
    }
  }, [isEdit, record]);

  const onSubmit = value => {
    if (isEdit) {
      return dataProvider
        .update('categories', {
          id: record.id,
          data: { ...value },
          previousData: { ...record },
        })
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('categories', {
          data: { ...value },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };
  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    setFormData({ ...formData, [name]: value });
  };
  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={clsx(classes.inputContainerWrap)}>
                  <FormTextField
                    name='category'
                    label='Category'
                    customOnChange={customOnChange}
                    style={{ marginRight: 0 }}
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
