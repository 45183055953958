import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import React from 'react';
import {
  FunctionField,
  Show,
  ShowView,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { DateField, TrackUser } from '../../components/common';
import { useShowController } from '../../hooks';
import { ResidentShowActions, ResidentShowFields } from '../../resources';
import { constants } from '../../utils';
import { TimelineShowActions } from './TimelineShowActions';

const { eventTypes } = constants;

export const CaseBedholdShow = ({
  closeAside,
  classes,
  residentId,
  caseId,
  setRefreshTimeline,
  isViewAdmin,
  ...props
}) => {
  const { resource, id, ...rest } = props;
  const showProps = useShowController({ ...props });
  const { record = {} } = showProps;
  return (
    <div className='row-wrapper'>
      <ShowView
        actions={
          <TimelineShowActions
            closeAside={closeAside}
            title='Bedhold'
            resourceRecord={record}
            eventForm='bedhold'
            eventId={record.id}
            eventType='Bedhold'
            eventName={eventTypes.BEDHOLD_NAME}
            caseId={caseId}
            setRefreshTimeline={setRefreshTimeline}
            data={record}
            isViewAdmin={isViewAdmin}
          />
        }
        {...showProps}
      >
        <SimpleShowLayout className={clsx(classes.showLayout)}>
          <DateField
            source='start_date'
            label='Start date'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='end_date'
            label='End date'
            className={classes.showItem}
            render={record =>
              record.end_date && (
                <DateField
                  record={record}
                  source='end_date'
                  textAlign='right'
                  className={classes.funcShowItem}
                />
              )
            }
          />
          <FunctionField
            source='end_date'
            label='Readmitted'
            className={classes.showItem}
            render={record =>
              record.resolution === 'readmitted' && (
                <DateField
                  record={record}
                  source='end_date'
                  textAlign='right'
                  className={classes.funcShowItem}
                />
              )
            }
          />
          <DateField
            source='auth_obtained_date'
            className={classes.showItem}
            label='Auth obtained'
            textAlign='right'
          />
          <TextField
            source='status'
            label='Status'
            textAlign='right'
            className={classes.showItem}
          />
          <FunctionField
            source='resolution'
            label='Resolution'
            className={classes.showItem}
            render={record =>
              record.resolution ? startCase(record.resolution) : null
            }
          />
          <FunctionField
            source='bedhold_type'
            label='Type'
            className={classes.showItem}
            render={record =>
              record.bedhold_type ? startCase(record.bedhold_type) : null
            }
          />
          {/* needed for spacing */}
          <div className={classes.showItem} />
          <div className={classes.showItem} />
          <FunctionField
            source='bedholdCreatedBy.name'
            label='Created by'
            className={classes.showItem}
            render={record => {
              const { bedholdCreatedBy, created_at } = record;
              return (
                <TrackUser
                  userField={bedholdCreatedBy}
                  timestamp={created_at}
                />
              );
            }}
          />
          <FunctionField
            source='bedholdUpdatedBy.name'
            label='Last modified'
            className={classes.showItem}
            render={record => {
              const { bedholdUpdatedBy, updated_at } = record;
              return (
                <TrackUser
                  userField={bedholdUpdatedBy}
                  timestamp={updated_at}
                />
              );
            }}
          />

          {/* needed for spacing */}
          <div className={classes.showItem} />
        </SimpleShowLayout>
      </ShowView>
      <Divider className={classes.residentDivider} />
      <p className={classes.casesTitle} style={{ paddingLeft: 16 }}>
        Resident info
      </p>
      <Show
        actions={
          <ResidentShowActions
            closeAside={closeAside}
            isResidentPage={false}
            setRefreshTimeline={setRefreshTimeline}
            isViewAdmin={isViewAdmin}
          />
        }
        {...rest}
        resource='residents'
        id={residentId}
      >
        <ResidentShowFields classes={classes} includeCases />
      </Show>
    </div>
  );
};
