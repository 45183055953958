import React from 'react';

import { Notification as AdminNotification } from 'react-admin';
import { Portal } from '@material-ui/core';

export const Notification = props => (
  // needed to show above modals. https://github.com/mui-org/material-ui/issues/12201
  <Portal>
    <AdminNotification {...props} />
  </Portal>
);
