import { makeStyles } from '@material-ui/core';
import { styles } from '../../design';
import { style } from '../../resources/residents.styles';
export const useStyles = makeStyles(theme => ({
  ...style(theme),
  listItemLabel: {
    whiteSpace: 'nowrap',
  },
  funcShowItem: {
    fontSize: 11,
  },
  actionButton: {
    color: '#66afdb',
    textTransform: 'capitalize',
  },
  showItemLabel: {
    fontWeight: 600,
    fontSize: 40,
    color: 'red',
  },
  showLayout: {
    ...styles.flexRow,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0 auto',
    maxWidth: 920,
    header: { fontWeight: 'bold', color: 'black' },
  },
  showItem: {
    color: 'black',
    flex: '1 0 30%',
    fontSize: 15,
    marginRight: 25,
    marginBottom: 26,
  },
  showFullWidth: {
    minWidth: '100%',
    marginBottom: 26,
  },
  checkbox: {
    color: 'rgba(0,0,0,.87)',
  },
  sectionHeader: {
    fontSize: 20,
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 25,
  },
  marginBottom0: {
    marginBottom: 0,
  },
  marginRight0: {
    marginRight: 0,
  },
  userTracking: {
    marginRight: '100px !important',
  },
  attchListIcon: {
    color: '#5C738E',
  },
  listActionWrapper: {
    ...styles.flexRow,
    ...styles.justifyBetween,
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: 0,
  },
}));
