import React, { useState, Fragment } from 'react';
import {
  List,
  TextField,
  BooleanField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  Pagination,
  // DateInput,
  FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jsonExport from 'jsonexport/dist';
import { format, startOfMonth, subMonths, lastDayOfMonth } from 'date-fns';
import { useSidebarOpen, useGetUserDivision } from '../../hooks';
import { ToggleButton, DateField, Datagrid } from '../../components/common';
import { useStyles } from './reports.styles';

const exporter = fields => {
  const fieldsForExport = fields.map(field => {
    const { id, insurance, lob, ...rest } = field;
    rest.payer_lob = `${insurance}/${lob}`;
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'name',
        'admit_date',
        'payer_lob',
        'auth_thru_date',
        'days',
        'discharge_date',
        'discharge_location',
        'is_denial',
        'flagged',
        'reason',
      ],
      rename: [
        'Patient Name',
        'Admit Date',
        'Insurance/LOB',
        'Auth Thru Date',
        'Days',
        'Discharge Date',
        'Discharge Location',
        'Denial',
        'Flagged',
        'Reason',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `LOS-by-Discharge-report-${format(new Date(), 'MM/dd/yyyy')}`,
      );
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  classes,
  ...rest
}) => {
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <div>
        <div style={{ display: 'flex', paddingBottom: 20 }}>
          <span className={classes.title} style={{ paddingRight: 20 }}>
            LOS by discharge report
          </span>
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
          />
        </div>
        <FiltersView
          classes={classes}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          className={classes.exportButton}
        />
      </div>
    </TopToolbar>
  );
};

const FiltersView = ({
  classes,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  ...props
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ marginRight: 25 }}>
        <DatePicker
          InputProps={{
            disableUnderline: true,
          }}
          margin='dense'
          views={['year', 'month']}
          label='From'
          openTo='month'
          value={startDate}
          onChange={setStartDate}
          required
          autoOk
          style={{ marginRight: 25, width: 171, color: '#5C738E' }}
        />
        <DatePicker
          InputProps={{
            disableUnderline: true,
          }}
          margin='dense'
          views={['year', 'month']}
          label='to'
          value={endDate}
          openTo='month'
          onChange={setEndDate}
          minDate={startDate}
          autoOk
          minDateMessage='End date should not be before start date'
          required
          style={{ width: 171, color: '#5C738E' }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const ReportListView = ({ formBasePath, ...props }) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  return (
    <div className={classes.listContainer}>
      <Datagrid {...props}>
        <TextField
          source='name'
          label='Resident name'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
          sortBy='last_name'
        />
        <DateField
          source='admit_date'
          label='Admit date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='insurance'
          label='Insurance'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='lob'
          label='LOB'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <DateField
          source='auth_thru_date'
          label='Auth thru date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <TextField
          source='days'
          label='Days'
          sortable={false}
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <DateField
          source='discharge_date'
          label='Discharge date'
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />

        <TextField
          source='discharge_location'
          label='Discharge location'
          sortable={false}
          headerClassName={classes.noLineBreak}
          className={classes.noLineBreak}
        />
        <FunctionField
          source='is_denial'
          label='Denial'
          headerClassName={classes.listItemLabel}
          render={record => {
            return (
              <BooleanField
                source='is_denial'
                headerClassName={classes.listItemLabel}
                style={{
                  color: record.is_denial ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
        <FunctionField
          source='flagged'
          label='Flagged'
          headerClassName={classes.listItemLabel}
          render={record => {
            return (
              <BooleanField
                source='flagged'
                headerClassName={classes.listItemLabel}
                style={{
                  color: record.is_denial ? '#44C87A' : '#5C738E',
                }}
              />
            );
          }}
        />
      </Datagrid>
    </div>
  );
};

export const DischargeReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(userDivision);
  const [startDate, setStartDate] = useState(
    startOfMonth(subMonths(new Date(), 1)),
  );
  const [endDate, setEndDate] = useState(
    lastDayOfMonth(subMonths(new Date(), 1)),
  );

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={
          <ListActions
            setDivision={setDivision}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            classes={classes}
          />
        }
        filter={{
          division,
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
        }}
        exporter={exporter}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100, 500]}
            style={{
              display: 'flex',
            }}
          />
        }
        perPage={500}
      >
        <ReportListView formBasePath={props} />
      </List>
    </Fragment>
  );
};

export const dischargeReportResource = {
  name: 'los-discharge-report',
  list: DischargeReportList,
};

function getResourceProps(props) {
  const basePath = '/los-discharge-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/los-discharge-report',
        hasList: true,
        hasEdit: false,
        hasShow: false,
        hasCreate: false,
      };
  return { ...props, basePath, ...resource };
}
