import React, { useState, cloneElement, Fragment } from 'react';
// import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  Filter,
  DateInput,
  Pagination,
  // FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { useGetUserDivision } from '../../hooks';
import { ToggleButton, DateField, Datagrid } from '../../components/common';
import { useStyles } from './reports.styles';
import { format, subMonths } from 'date-fns';
const exporter = ({ fields, facilityName }) => {
  const fieldsForExport = fields.map(field => {
    const { id, 'authorization.created_at': createdAt, ...rest } = field;
    return rest;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [
        'first_name',
        'last_name',
        'start_date',
        'end_date',
        'retro_date',
        'payer_name',
        'lob',
        'authorization_status',
        'authorization_type',
        'created_at',
      ],
      rename: [
        'First Name',
        'Last Name',
        'Start Date',
        'End Date',
        'Retro Date',
        'Payer',
        'LOB',
        'Auth Status',
        'Rate Type',
        'Created At',
      ],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `retro-auths-report-${facilityName}-${format(
          new Date(),
          'MM/dd/yyyy',
        )}`,
      );
    },
  );
};

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  filter,
  setFilter,
  classes,
}) => {
  return (
    <TopToolbar className={classes.listActionWrapper}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <div style={{ flex: '1 0 16px' }} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        className={classes.exportButton}
      />
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='From'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard' }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
      <DateInput
        label='To'
        source='end_date'
        alwaysOn
        options={{ variant: 'standard' }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
    </Filter>
  );
};

export const RetroAuthReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const facilityName = useSelector(state => state.facility.name);
  const userDivision = useGetUserDivision();
  const [division, setDivision] = useState(userDivision);

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <div className={classes.title} style={{ paddingTop: 30 }}>
          Retro auth report
        </div>
        <div style={{ padding: 30 }}>
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
          />
        </div>
      </div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions setDivision={setDivision} classes={classes} />}
        filter={{ division }}
        exporter={fields => exporter({ fields, facilityName })}
        filters={<FiltersView classes={classes} />}
        sort={{ field: 'retro_date', order: 'DESC' }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
        filterDefaultValues={{
          start_date: format(subMonths(new Date(), 6), 'yyyy-MM-dd'),
          end_date: format(new Date(), 'yyyy-MM-dd'),
        }}
      >
        <Datagrid>
          <TextField source='first_name' label='First name' />
          <TextField source='last_name' label='Last name' />
          <DateField source='start_date' label='Start date' />
          <DateField source='end_date' label='End date' />
          <TextField source='retro_date' label='Retro date' />
          <TextField source='payer_name' label='Payer name' />
          <TextField source='lob' label='LOB' />
          <TextField source='authorization_status' label='Auth status' />
          <TextField source='authorization_type' label='Rate type' />
          <TextField source='created_at' label='Created at' sortable={false} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export const retroAuthReportResource = {
  name: 'retro-auths-report',
  list: RetroAuthReportList,
};

function getResourceProps(props) {
  const basePath = '/retro-auths-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/retro-auths-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!retroAuthReportResource.show,
        hasCreate: !!retroAuthReportResource.create,
      };
  return { ...props, basePath, ...resource };
}
