import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import startCase from 'lodash/startCase';
import propTypes from 'prop-types';
import { usePermissions } from 'react-admin';
import {
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
} from '@material-ui/core';
import { authGet } from '../../server';
const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: '-10px',
    marginRight: 5,
    marginLeft: 5,
    minWidth: 200,
  },
}));

export function CaseManagerSelect({
  filterManager,
  initialValue,
  showForCaseManagers,
}) {
  const classes = useStyles();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const facilityId = useSelector(state => state.facility.id);
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState([]);
  const [show, setShow] = useState(false);
  const [inputObj, setInputObj] = useState({});

  useEffect(() => {
    async function fetchManagers() {
      const response = await authGet('/facility-users/list');
      if (!response.error) {
        setManagers(response.data);
      }
    }
    if (facilityId) {
      fetchManagers();
    }
  }, [facilityId]);

  useEffect(() => {
    const values = [];
    const obj = managers.reduce((acc, cur) => {
      acc[cur.id] = cur.name;
      values.push(cur.id);
      return acc;
    }, {});
    setInputObj(obj);
  }, [managers]);

  useEffect(() => {
    if (initialValue) {
      setManager(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      showForCaseManagers ||
      (Array.isArray(userPermissions) &&
        (userPermissions.indexOf('admin') > -1 ||
          userPermissions.indexOf('supervisor') > -1))
    ) {
      setShow(true);
    }
  }, [userPermissions, showForCaseManagers]);

  const handleChange = useCallback(
    e => {
      const value = e.target.value;
      setManager(value);
      filterManager(value);
    },
    [filterManager],
  );

  if (!show) return null;
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Case manager</InputLabel>
      <Select
        value={manager}
        onChange={handleChange}
        multiple
        renderValue={selected => selected.map(s => inputObj[s]).join(' / ')}
        input={<Input disableUnderline></Input>}
      >
        {managers.map(m => {
          const { id, first_name, last_name } = m;
          return (
            <MenuItem key={id} value={id}>
              {startCase(last_name) + ', ' + startCase(first_name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

CaseManagerSelect.propTypes = {
  filterManager: propTypes.func.isRequired,
  showForCaseManagers: propTypes.bool,
};
