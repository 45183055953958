import React, { useState, useEffect, Fragment } from 'react';
import {
  useListController,
  ListView,
  TextField,
  TopToolbar,
  // Filter,
  // SelectInput,
  FunctionField,
  useRefresh,
  useRedirect,
  Pagination,
} from 'react-admin';
import clsx from 'clsx';
import { Chip } from '@material-ui/core';
import { useSidebarOpen } from '../hooks';
import { NewEvent } from '../components/common/modals';
import { Datagrid } from '../components/common';
import { CustomButton } from '../design/material-ui';
import { useStyles } from './users.styles';
import { AddIcon, EditIcon } from '../design';
import { getChip } from '../utils';

const ListActions = ({ permissions }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [eventOpen, setEventModal] = useState(false);
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>User Coverage</span>
        <div>
          {permissions.indexOf('view_admin') === -1 ? (
            <CustomButton
              Icon={AddIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Add'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
            />
          ) : null}
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='userCoverage'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const View = ({ userPermissions, ...props }) => {
  const isSidebarOpen = useSidebarOpen();
  const refresh = useRefresh();
  const classes = useStyles({ isSidebarOpen });
  const [eventOpen, setEventModal] = useState(false);
  const [userRecord, setUserRecord] = useState();

  useEffect(() => {
    // see comment on case_communication
    if (userRecord) {
      setEventModal(true);
    }
  }, [userRecord]);
  const handleModalClose = () => {
    setEventModal(false);
    setUserRecord(undefined);
  };

  return (
    <div className={classes.listContainer}>
      <Datagrid {...props}>
        <TextField
          sortable={false}
          variant='outlined'
          source='user'
          label='User'
          headerClassName={classes.listItemLabel}
        />
        <TextField
          sortable={false}
          variant='outlined'
          source='covering_for'
          label='Covering for'
          headerClassName={classes.listItemLabel}
        />
        <FunctionField
          label='Status'
          source='inactive'
          render={record => {
            const { inactive } = record;
            const status = inactive ? 'Inactive' : 'Active';
            return (
              <Chip
                size='small'
                label={status}
                className={clsx(classes.chip, classes[getChip(status)])}
              />
            );
          }}
        />
        <FunctionField
          source=''
          label=''
          sortable={false}
          render={record => {
            return (
              Array.isArray(userPermissions) &&
              (userPermissions.indexOf('admin') > -1 ||
                userPermissions.indexOf('admin_assistant') > -1) && (
                <CustomButton
                  Icon={EditIcon}
                  color='#829CB5'
                  type='button'
                  variant='text'
                  size='small'
                  onClick={() => setUserRecord(record)}
                  className={classes.noWrap}
                />
              )
            );
          }}
        />
      </Datagrid>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={handleModalClose}
          record={userRecord}
          form='userCoverage'
          isEdit
          refresh={refresh}
        />
      )}
    </div>
  );
};

export const UserCoverageView = ({ record, id, userPermissions, ...props }) => {
  const controllerProps = useListController({
    ...props,
    resource: 'user-coverage',
    basePath: '/user-coverage',
  });

  return (
    <ListView
      empty={false}
      {...controllerProps}
      exporter={false}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
      actions={<ListActions permissions={userPermissions} />}
    >
      <View userPermissions={userPermissions} />
    </ListView>
  );
};

export const UserCoverage = props => {
  const redirect = useRedirect();
  const { permissions = '' } = props;
  if (!permissions) return null;
  const userPermissions = permissions.split(',');
  if (
    userPermissions.indexOf('admin') === -1 &&
    userPermissions.indexOf('supervisor') === -1 &&
    userPermissions.indexOf('view_admin') === -1 &&
    userPermissions.indexOf('admin_assistant') === -1
  ) {
    redirect('/');
    return null;
  }
  return <UserCoverageView {...props} userPermissions={userPermissions} />;
};
